<template>
  <div class="py-5 container-fluid">
    <div id="topinfo">
      <div class="row mt-4 justify-content-center">
        <div class="col-12">
          <div class="mx-auto col-xl-8 col-lg-9 col-md-10 text-start mt-3 mb-0">
            <button
              class="btn btn-outline-secondary btn-sm px-3 ms-2"
              @click="$router.push(`/applications/blueprint/linevisits/${lineId}`)"
            >
              <font-awesome-icon :icon="['fas', 'arrow-rotate-left']" />
              Go back to workflows
            </button>
          </div>
          <div class="mx-auto col-xl-8 col-lg-9 col-md-10 d-flex mb-2 flex-column card card-body blur shadow-blur">
            <div class="card-header text-start">
              <h3 class="font-weight-bolder text-success text-gradient">
                {{ $t("Benchmark & Target") }}
              </h3>
              <p class="mb-0"> Provide your current line parameters and perform initial benchmark measurements </p>
            </div>
          </div>
          <div class="mx-auto col-xl-8 col-lg-9 col-md-10 d-flex flex-column">
            <div class="row my-4">
              <div class="multisteps-form mt-3">
                <div class="multisteps-form__progress">
                  <button
                    class="multisteps-form__progress-btn js-active"
                    type="button"
                    title="Current Settings"
                    :class="currentPage >= 0 ? 'js-active position-relative' : ''"
                    @click="currentPage = 0"
                  >
                    1. Current Settings
                  </button>
                  <button
                    class="multisteps-form__progress-btn"
                    type="button"
                    title="Thickness Benchmark"
                    :class="currentPage >= 1 ? 'js-active position-relative' : ''"
                    @click="nextStep(currentPage == 0 ? thicknessBenchmarkStepIsFilled : true, false); currentPage = 1"
                  >
                    2. Thickness Benchmark
                  </button>
                  <button
                    class="multisteps-form__progress-btn"
                    type="button"
                    title="Summary"
                    :class="currentPage >= 2 ? 'js-active position-relative' : ''"
                    @click="currentPage = 2"
                  >
                    3. Summary
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="mx-auto col-xl-8 col-lg-9 col-md-10 d-flex flex-column">
            <div class="card row px-2">
              <div
                v-if="currentPage === 0 || currentPage === 2"
                class="mt-3"
              >
                <h5 class="mt-2">Line Layout</h5>
                <canvas
                    id="lineLayoutCanvas"
                    class="col-12 mt-0 mb-4 justify-content-center"
                    width="700"
                    height="300"
                    :style="`max-width: 700px; ${line.id !== null ? '' : 'display: none;'}`"
                  >
                </canvas>

                <h5>Gun Settings</h5>
                <div
                  class="table-responsive mt-2"
                  style="overflow-x: auto"
                >
                  <table class="table table-sm text-center text-xs mt-2">
                    <thead>
                      <tr>
                        <th class="text-center">Gun</th>
                        <th class="text-center">Voltage</th>
                        <th class="text-center">Amps</th>
                        <th class="text-center">Air</th>
                        <th class="text-center">Gun to substrate distance</th>
                        <th class="text-center">Powder Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(gun, index) in benchmark_form.gun_settings"
                        :key="index"
                      >
                        <td>{{ index + 1 }}</td>
                        <td>
                          <div v-if="currentPage === 0">
                            <input
                              v-model="gun.voltage"
                              type="number"
                              class="form-control text-center text-sm"
                              style="width: 70%; margin: 0 auto"
                              @keydown="numericOnly"
                              @change="autoFillGunSettings(index, 'voltage')"
                            />
                          </div>
                          <div v-if="currentPage === 2">
                            <p>{{ gun.voltage }}</p>
                          </div>
                        </td>
                        <td>
                          <div v-if="currentPage === 0">
                            <input
                              v-model="gun.amps"
                              type="number"
                              class="form-control text-center text-sm"
                              style="width: 70%; margin: 0 auto"
                              @keydown="numericOnly"
                              @change="autoFillGunSettings(index, 'amps')"
                            />
                          </div>
                          <div v-if="currentPage === 2">
                            <p>{{ gun.amps }}</p>
                          </div>
                        </td>
                        <td>
                          <div v-if="currentPage === 0">
                            <input
                              v-model="gun.air"
                              type="number"
                              class="form-control text-center text-sm"
                              style="width: 70%; margin: 0 auto"
                              @keydown="numericOnly"
                              @change="autoFillGunSettings(index, 'air')"
                            />
                          </div>
                          <div v-if="currentPage === 2">
                            <p>{{ gun.air }}</p>
                          </div>
                        </td>
                        <td>
                          <div v-if="currentPage === 0">
                            <input
                              v-model="gun.distance"
                              type="number"
                              class="form-control text-center text-sm"
                              step="0.1"
                              style="width: 70%; margin: 0 auto"
                              @keydown="numericOnly"
                              @change="autoFillGunSettings(index, 'distance')"
                            />
                          </div>
                          <div v-if="currentPage === 2">
                            <p>{{ gun.distance }}</p>
                          </div>
                        </td>
                        <td>
                          <div v-if="currentPage === 0">
                            <input
                              v-model="gun.powder"
                              type="number"
                              class="form-control text-center text-sm"
                              style="width: 70%; margin: 0 auto"
                              @keydown="numericOnly"
                              @change="autoFillGunSettings(index, 'powder')"
                            />
                          </div>
                          <div v-if="currentPage === 2">
                            <p>{{ gun.powder }}</p>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="row mt-3 mb-3">
                  <h5>Reciprocator Settings</h5>
                  <div class="row mb-3">
                    <div class="col-6">
                      <label>
                        Reciprocator Period [s]
                        <i
                          class="fa fa-info-circle"
                          aria-hidden="true"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Time of one reciprocator stroke, i.e. seconds it takes for one gun to move from its lowest point to its highest point and back"
                          data-container="body"
                          data-animation="true"
                        ></i>
                      </label>
                      <div v-if="currentPage === 0">
                        <input
                          v-model="benchmark_form.reciprocator_period"
                          type="number"
                          class="form-control my-2"
                          placeholder="Reciprocator movement period in seconds"
                          @keydown="numericOnly"
                          @change="benchmark_form.reciprocator_period = benchmark_form.reciprocator_period === '' ? null : benchmark_form.reciprocator_period"
                        />
                      </div>
                      <div v-if="currentPage === 2">
                        <p style="padding-left: 5px">
                          {{ benchmark_form.reciprocator_period == null ? "":benchmark_form.reciprocator_period + "seconds" }}
                        </p>
                      </div>
                    </div>
                    <div class="col-6">
                      <label>
                        Reciprocator Stroke [{{
                          this.$store.state.units_system[this.$store.state.user_data.unit_system].distance
                        }}]
                        <i
                          class="fa fa-info-circle"
                          aria-hidden="true"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Distance between highest and lowest point of one moving gun"
                          data-container="body"
                          data-animation="true"
                        ></i>
                      </label
                      >
                      <div v-if="currentPage === 0">
                        <input
                          v-model="gunsMovementRange"
                          type="number"
                          class="form-control my-2"
                          placeholder="Reciprocator stroke"
                          @keydown="numericOnly"
                        />
                      </div>
                      <div v-if="currentPage === 2">
                        <p style="padding-left: 5px">
                          {{ gunsMovementRange === null ? "" :
                            gunsMovementRange +
                            " " +
                            this.$store.state.units_system[this.$store.state.user_data.unit_system].distance
                          }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-4">
                      <label
                        >Line Speed [{{
                          this.$store.state.units_system[this.$store.state.user_data.unit_system].velocity_minutes
                        }}]
                        <i
                          class="fa fa-info-circle"
                          aria-hidden="true"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Conveyor velocity in meters/minute"
                          data-container="body"
                          data-animation="true"
                        >
                        </i>
                      </label>
                      <div v-if="currentPage === 0">
                        <input
                          v-model="lineSpeed"
                          type="number"
                          min="0"
                          class="form-control my-2"
                          placeholder="Line speed"
                          @keydown="numericOnly"
                        />
                      </div>
                      <div v-if="currentPage === 2">
                        <p style="padding-left: 5px">
                          {{
                            lineSpeed === null ? "" :
                            lineSpeed +
                            " " +
                            this.$store.state.units_system[this.$store.state.user_data.unit_system].velocity_minutes
                          }}
                        </p>
                      </div>
                    </div>
                    <div class="col-4">
                      <label
                        >Covered Height [{{
                          this.$store.state.units_system[this.$store.state.user_data.unit_system].distance
                        }}]
                      <i
                        class="fa fa-info-circle"
                        aria-hidden="true"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Vertical height of the substrates i.e. height for which the reciprocator settings will be optimised"
                        data-container="body"
                        data-animation="true"
                      >
                      </i>
                      </label>
                      <div v-if="currentPage === 0">
                        <input
                          v-model.lazy="coatedHeight"
                          type="number"
                          class="form-control my-2"
                          placeholder="COVERED HEIGHT"
                          @keydown="numericOnly"
                        />
                      </div>
                      <div v-if="currentPage === 2">
                        <p style="padding-left: 5px">
                          {{
                            coatedHeight === null ? "" :
                            coatedHeight +
                            " " +
                            this.$store.state.units_system[this.$store.state.user_data.unit_system].distance
                          }}
                        </p>
                      </div>
                    </div>
                    <div class="col-4">
                      <label>
                        Nozzle Angle
                        <i
                          class="fa fa-info-circle"
                          aria-hidden="true"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-container="body"
                          data-animation="true"
                          :title="nozzleAngleTooltipImage()"
                        ></i>
                      </label>
                      <div v-if="currentPage === 0">
                        <select
                          id="choices-category"
                          v-model="benchmark_form.nozzle_angle"
                          class="form-control my-2"
                          style="appearance: listbox"
                        >
                          <option
                            disabled
                            :value="null"
                          >
                            Please select one
                          </option>
                          <option :value="0">{{ "0 degrees" }} (3h)</option>
                          <option :value="15">{{ "15 degrees" }} (2:30h)</option>
                          <option :value="30">{{ "30 degrees" }} (2h)</option>
                          <option :value="45">{{ "45 degrees" }} (1:30h)</option>
                          <option :value="60">{{ "60 degrees" }} (1h)</option>
                          <option :value="75">{{ "75 degrees" }} (0:30h)</option>
                          <option :value="90">{{ "90 degrees" }} (12h)</option>
                        </select>
                      </div>
                      <div v-if="currentPage === 2">
                        <p style="padding-left: 5px">
                          {{ benchmark_form.nozzle_angle == null ? "": benchmark_form.nozzle_angle + " degrees"}}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div
                  v-if="currentPage === 1 || currentPage === 2"
                  class="row"
                >
                  <div class="row mb-3 mt-3">
                    <h5>Target Thickness</h5>
                    <div class="row mb-3">
                      <div class="col-3">
                        <label
                          >Min [{{
                            this.$store.state.units_system[this.$store.state.user_data.unit_system].thickness
                          }}]</label
                        >
                        <div v-if="currentPage === 1">
                          <input
                            v-model="benchmark_form.minimum_target_thickness"
                            type="number"
                            class="form-control my-2"
                            placeholder="Minimum thickness"
                            @keydown="numericOnly"
                            @change="benchmark_form.minimum_target_thickness = benchmark_form.minimum_target_thickness === '' ? null : benchmark_form.minimum_target_thickness"
                          />
                        </div>
                        <div v-if="currentPage === 2">
                          <p style="padding-left: 5px">
                            {{ benchmark_form.minimum_target_thickness == null ? "": benchmark_form.minimum_target_thickness }}
                            {{ benchmark_form.minimum_target_thickness == null ? "": this.$store.state.units_system[this.$store.state.user_data.unit_system].thickness }}
                          </p>
                        </div>
                      </div>
                      <div class="col-3">
                        <label
                          >Max [{{
                            this.$store.state.units_system[this.$store.state.user_data.unit_system].thickness
                          }}]</label
                        >
                        <div v-if="currentPage === 1">
                          <input
                            v-model="benchmark_form.maximum_target_thickness"
                            type="number"
                            class="form-control my-2"
                            placeholder="Maximum thickness"
                            @keydown="numericOnly"
                            @change="benchmark_form.maximum_target_thickness = benchmark_form.maximum_target_thickness === '' ? null : benchmark_form.maximum_target_thickness"
                          />
                        </div>
                        <div v-if="currentPage === 2">
                          <p style="padding-left: 5px">
                            {{ benchmark_form.maximum_target_thickness == null ? "": benchmark_form.maximum_target_thickness }}
                            {{ benchmark_form.maximum_target_thickness == null ? "": this.$store.state.units_system[this.$store.state.user_data.unit_system].thickness }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="row">
                      <div class="col-12">
                        <h5>
                          Thickness Measurements [{{
                            this.$store.state.units_system[this.$store.state.user_data.unit_system].thickness
                          }}]
                        </h5>
                        <div class="table-responsive">
                          <table class="table table-sm text-center text-xs">
                            <thead>
                              <tr>
                                <th>Measurement</th>
                                <th>Thickness</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(_, index) in (currentPage == 2 ? cleanThicknessMeasurementsArray(thickness_measurements):thickness_measurements)"
                                :key="index"
                              >
                                <td>{{ currentPage === 2 && thickness_measurements[index] == "" ? "" : index + 1 }}</td>
                                <td>
                                  <div v-if="currentPage === 1">
                                    <input
                                      v-model="thickness_measurements[index]"
                                      type="number"
                                      class="form-control text-center"
                                      style="width: 50%; margin: 0 auto"
                                      min="0"
                                      @input="checkLastRow()"
                                      @keydown="numericOnly"
                                    />
                                  </div>
                                  <div
                                    v-if="currentPage === 2 &&
                                    thickness_measurements[index] !== '' &&
                                    thickness_measurements[index] !== null"
                                  >
                                    <p>
                                      {{ thickness_measurements[index] }}
                                      {{
                                        this.$store.state.units_system[this.$store.state.user_data.unit_system]
                                          .thickness
                                      }}
                                    </p>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <!-- <div
                            v-if="currentPage === 1"
                            class="text-center mt-0 mb-0"
                          >
                            <button
                              class="mt-2 text-center btn btn-primary"
                              @click="showGraph()"
                            >
                              Show thickness distribution chart
                            </button>
                          </div> -->
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 mb-3">
                    <gaussian-chart
                      :title="emptyThicknessMeasurements ?  '':'Thickness Distribution Chart'"
                      chart-name="gaussianBenchmarkChart"
                    />
                    <thickness-measurement-statistic :benchmark-thickness-measurements="statistics_thickness_measurements" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-3 mb-2 mx-auto col-xl-8 col-lg-9 col-md-10 d-flex button-row">
            <soft-button
              v-if="currentPage > 0"
              color="secondary"
              variant="gradient"
              class="me-auto js-btn-prev w-20"
              style="max-width: 220px;"
              title="Previous"
              @click="currentPage = currentPage - 1;"
            >
              Previous
            </soft-button>
            <soft-button
              v-if="currentPage === 0 && currentSettingsStepFormIsUpdated"
              type="button"
              color="success"
              variant="gradient"
              class="mb-0 mx-auto js-btn-next w-100 "
              title="Save changes"
              @click="updateBenchmarkData(); showSavedDataSwal('Current settings');"
            >
              Save changes
            </soft-button>
            <soft-button
              v-if="currentPage === 1 && thicknessBenchmarkStepFormIsUpdated"
              type="button"
              color="success"
              variant="gradient"
              class="mb-0 ms-auto js-btn-next w-70"
              title="Save changes"
              @click="updateBenchmarkData(); showSavedDataSwal('Thickness Benchmark'); showGraph();"
            >
              Save changes
            </soft-button>
            <soft-button
              v-if="currentPage === 0 && !currentSettingsStepFormIsUpdated"
              type="button"
              :color="currentSettingsStepIsFilled ? 'success':'secondary'"
              :variant="currentSettingsStepIsFilled ? 'gradient':'solid'"
              class="mb-0 ms-auto js-btn-next w-20"
              title="Next"
              @click="nextStep(currentSettingsStepIsFilled, true);"
            >
              Next &nbsp;&nbsp;<font-awesome-icon :icon="['fas', 'arrow-right']" inverse/>
            </soft-button>
            <soft-button
              v-if="currentPage === 1 && !thicknessBenchmarkStepFormIsUpdated"
              type="button"
              :color="thicknessBenchmarkStepIsFilled ? 'success':'secondary'"
              :variant="thicknessBenchmarkStepIsFilled ? 'gradient':'solid'"
              class="mb-0 ms-auto js-btn-next w-20"
              title="Next"
              @click="nextStep(thicknessBenchmarkStepIsFilled, true);"
            >
              Next &nbsp;&nbsp;<font-awesome-icon :icon="['fas', 'arrow-right']" inverse/>
            </soft-button>
            <soft-button
              v-if="currentPage === 2"
              type="button"
              color="success"
              variant="gradient"
              class="mb-0 ms-auto js-btn-next w-40"
              style="max-width: 300px;"
              title="Continue to health check stage"
              @click="$router.push('/applications/blueprint/healthcheck/' + lineId + '/' + visitId);"
            >
              Continue to health check &nbsp;
              <font-awesome-icon :icon="['fas', 'circle-chevron-right']" size="lg"/>
            </soft-button>
          </div>
        </div>
        <stepper-line-visit
          :line-id="lineId"
          :visit="visit"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faCircleChevronRight } from '@fortawesome/free-solid-svg-icons';
import { faArrowRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { generateX, gaussian, generateY, calculateMu, calculateSigma } from "./utils/gaussianchart.js";
import { library } from '@fortawesome/fontawesome-svg-core'
import { nextTick } from "vue";
import { useGetLine } from "@/views/composables.js";
import axios from "axios";
import eventBus from "./utils/eventBus.js";
import GaussianChart from "./components/GaussianChart.vue";
import setTooltip from "@/assets/js/tooltip.js";
import SoftButton from "../../../components/SoftButton.vue";
import StepperLineVisit from "./components/StepperLineVisit.vue";
import ThicknessMeasurementStatistic from "./components/ThicknessMeasurementStatistic.vue";
import {
  numericOnly,
  useGetBenchmark,
  useGetVisit,
} from "./utils/utils.js";

library.add([faCircleChevronRight, faArrowRight, faArrowRotateLeft]);

export default {
  name: "BenchmarkAndTarget",
  components: {
    StepperLineVisit,
    GaussianChart,
    ThicknessMeasurementStatistic,
    SoftButton,
    FontAwesomeIcon,
  },
  props: {
    lineId: {
      type: String,
      default: "",
    },
    visitId: {
      type: String,
      default: "",
    },
  },
  setup() {
    const { visit, getVisit } = useGetVisit();
    const { line, getLine } = useGetLine();
    const { benchmark_stage_data, getBenchmarkData } = useGetBenchmark();
    return { visit, getVisit, benchmark_stage_data, getBenchmarkData, line, getLine };
  },
  data() {
    return {
      currentPage: 0,
      benchmark_form: {
        minimum_target_thickness: null,
        maximum_target_thickness: null,
        reciprocator_period: null,
        guns_movement_range: null,
        line_speed: null,
        coated_height: null,
        nozzle_angle: null,
        gun_settings: [],
        thickness_measurements: Array(5).fill(null),
        powder: null,
      },
      thickness_measurements: Array(5).fill(null),
      statistics_thickness_measurements: Array(5).fill(null),
      canvas_width: null,
      canvas_height: null,
      ctx_line_layout: null,
      line_layout_image: [],
      max_imperial_decimals: 2,
      max_metric_decimals: 1,
      s3_storage_base_URL: "https://d21tewxstkbxbj.cloudfront.net/flightpath-platform/public-assets/static/",
    };
  },
  computed: {
    currentSettingsStepFormIsUpdated() {
      const current_settings_step_form_is_updated = (
        this.benchmark_form.reciprocator_period !== this.benchmark_stage_data.reciprocator_period ||
        this.benchmark_form.guns_movement_range !== this.benchmark_stage_data.guns_movement_range ||
        this.benchmark_form.line_speed !== this.benchmark_stage_data.line_speed ||
        this.benchmark_form.coated_height !== this.benchmark_stage_data.coated_height ||
        this.benchmark_form.nozzle_angle !== this.benchmark_stage_data.nozzle_angle ||
        JSON.stringify(this.benchmark_form.gun_settings) !== JSON.stringify(this.benchmark_stage_data.gun_settings)
      );
      return current_settings_step_form_is_updated;
    },
    thicknessBenchmarkStepFormIsUpdated() {
      return (
        this.benchmark_form.minimum_target_thickness !== this.benchmark_stage_data.minimum_target_thickness ||
        this.benchmark_form.maximum_target_thickness !== this.benchmark_stage_data.maximum_target_thickness ||
        JSON.stringify(this.cleanThicknessMeasurementsArray(this.thickness_measurements)) !== JSON.stringify(
          this.cleanThicknessMeasurementsArray(this.benchmark_stage_data.thickness_measurements))
      );
    },
    thicknessBenchmarkStepIsFilled() {
      const thickness_benchmark_step_is_filled = (
        this.benchmark_form.minimum_target_thickness !== null && this.benchmark_form.minimum_target_thickness !== "" &&
        this.benchmark_form.maximum_target_thickness !== null && this.benchmark_form.maximum_target_thickness !== "" &&
        this.thickness_measurements.filter(m => m !== null && m !== "").length >= 2
      );
      return thickness_benchmark_step_is_filled;
    },
    currentSettingsStepIsFilled() {
      const current_settings_step_is_filled = (
        this.benchmark_form.gun_settings.every(gun =>
          Object.values(gun).every(value => value !== null && value !== ""),
        ) &&
        this.benchmark_form.reciprocator_period !== null && this.benchmark_form.reciprocator_period !== "" &&
        this.benchmark_form.guns_movement_range !== null && this.benchmark_form.guns_movement_range !== "" &&
        this.benchmark_form.line_speed !== null && this.benchmark_form.line_speed !== "" &&
        this.benchmark_form.coated_height !== null && this.benchmark_form.coated_height !== "" &&
        this.benchmark_form.nozzle_angle !== null && this.benchmark_form.nozzle_angle !== ""
      );
      return current_settings_step_is_filled;
    },
    emptyThicknessMeasurements() {
      if (
        this.thickness_measurements == null ||
        this.thickness_measurements == undefined ||
        this.thickness_measurements.length <= 2 ||
        this.thickness_measurements.every(m => m == null || m == "")
      ) {
        return true;
      }
      return false;
    },
    gunsMovementRange: {
      get() {
        if (this.benchmark_form.guns_movement_range === null || this.benchmark_form.guns_movement_range === "") {
          return null;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (this.benchmark_form.guns_movement_range * this.$store.state.conversion_factors.cm_to_in).toFixed(
              this.max_imperial_decimals,
            ),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(this.benchmark_form.guns_movement_range.toFixed(this.max_metric_decimals));
        } else {
          return this.benchmark_form.guns_movement_range;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.benchmark_form.guns_movement_range = null;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.benchmark_form.guns_movement_range = value / this.$store.state.conversion_factors.cm_to_in;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.benchmark_form.guns_movement_range = value;
          }
        }
      },
    },
    lineSpeed: {
      get() {
        if (this.benchmark_form.line_speed === null || this.benchmark_form.line_speed === "") {
          return null;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (this.benchmark_form.line_speed * this.$store.state.conversion_factors.m_to_ft).toFixed(
              this.max_imperial_decimals,
            ),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(this.benchmark_form.line_speed.toFixed(this.max_metric_decimals));
        } else {
          return parseFloat(this.benchmark_form.line_speed);
        }
      },
      set(value) {
        if (value == null || value === "") {
          this.benchmark_form.line_speed = null;
        } else {
          value = parseFloat(value);
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.benchmark_form.line_speed = value / this.$store.state.conversion_factors.m_to_ft;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.benchmark_form.line_speed = value;
          }
        }
      },
    },
    coatedHeight: {
      get() {
        if (this.benchmark_form.coated_height == null || this.benchmark_form.coated_height == "") {
          return null;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (this.benchmark_form.coated_height * this.$store.state.conversion_factors.cm_to_in).toFixed(
              this.max_imperial_decimals,
            ),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(this.benchmark_form.coated_height.toFixed(this.max_metric_decimals));
        } else {
          return this.benchmark_form.coated_height;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.benchmark_form.coated_height = null;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.benchmark_form.coated_height = value / this.$store.state.conversion_factors.cm_to_in;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.benchmark_form.coated_height = value;
          }
        }
      },
    },
    progressStatus() {
      const fields = [
        this.benchmark_form.minimum_target_thickness,
        this.benchmark_form.maximum_target_thickness,
        this.benchmark_form.reciprocator_period,
        this.benchmark_form.guns_movement_range,
        this.thickness_measurements,
        this.benchmark_form.line_speed,
        this.benchmark_form.coated_height,
        this.benchmark_form.nozzle_angle,
      ];
      let notEmptyMeasurements = this.thickness_measurements.filter(m => m !== null && m !== "");
      if (
        fields.every(field => field !== null || field !== "") &&
        this.benchmark_form.gun_settings.every(gun =>
          Object.values(gun).every(value => value !== null || value !== ""),
        ) &&
        notEmptyMeasurements.length >= 2
      ) {
        return "Completed";
      } else if (
        fields.some(field => field !== null || field !== "") ||
        this.benchmark_form.gun_settings.some(gun =>
          Object.values(gun).some(value => value !== null || value !== ""),
        ) ||
        notEmptyMeasurements.length > 0
      ) {
        return "In Progress";
      } else {
        return "Pending";
      }
    },
    totalLineGuns() {
      return this.line.total_pistols.reduce((a, b) => a + b, 0);
    },
  },
  watch: {
    "currentPage"() {
      if (this.currentPage === 1 || this.currentPage === 2) {
        this.showGraph();
      }
      if (this.currentPage === 0 || this.currentPage === 2) {
        this.fillLineLayoutCanvas();
      }
    },
  },
  mounted() {
    var lineLayoutCanvas = document.getElementById("lineLayoutCanvas");
    this.canvas_width = lineLayoutCanvas.width;
    this.canvas_height = lineLayoutCanvas.height;
    this.ctx_line_layout = lineLayoutCanvas.getContext("2d");

    this.getData();
    this.$store.state.isAbsolute = true;
    setTooltip(this.$store.state.bootstrap);
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
  },

  methods: {
    numericOnly,
    autoFillGunSettings(index, property_key) {
      if (index !== 0) {
        return;
      }

      const inputValue = this.benchmark_form.gun_settings[0][property_key];
      const emptyInputValue = inputValue === "" || inputValue === null;

      const isColumnEmpty = this.benchmark_form.gun_settings
        .slice(1)
        .every(gun => gun[property_key] === "" || gun[property_key] === null);

      if (!emptyInputValue && isColumnEmpty) {
        this.benchmark_form.gun_settings.forEach(gun => {
          gun[property_key] = inputValue;
        });
        return;
      }

      if (emptyInputValue && !isColumnEmpty) {
        const allValuesAreTheSame = this.benchmark_form.gun_settings
          .slice(1)
          .every(gun => gun[property_key] === this.benchmark_form.gun_settings[1][property_key]);
        if (allValuesAreTheSame) {
          this.benchmark_form.gun_settings.forEach(gun => {
            gun[property_key] = "";
          });
        }
      }
    },
    showGraph() {
      if (!this.emptyThicknessMeasurements) {
        nextTick(() => {
          this.thicknessMeasurementsUpdateChartData();
          this.statistics_thickness_measurements = JSON.parse(JSON.stringify(this.thickness_measurements));
          eventBus.emit("draw-gaussian-chart");
        });
      }
    },
    checkLastRow() {
      while (this.thickness_measurements.length < 5) {
        this.thickness_measurements.push("");
      }

      if (this.thickness_measurements.every(m => m !== null && m !== "" && m !== 0)) {
        this.thickness_measurements.push("");
      } else if (
        (this.thickness_measurements[this.thickness_measurements.length - 1] == null ||
          this.thickness_measurements[this.thickness_measurements.length - 1] == "") &&
        (this.thickness_measurements[this.thickness_measurements.length - 2] == null ||
          this.thickness_measurements[this.thickness_measurements.length - 2] == "") &&
        this.thickness_measurements.length > 5
      ) {
        this.thickness_measurements.pop();
      }
    },
    async getData() {
      await Promise.all([this.getLine(this.lineId), this.getVisit(this.visitId)])
      console.log("this.totalLineGuns", this.totalLineGuns);
      await this.getBenchmarkData(this.visitId, this.totalLineGuns);
      this.benchmark_form = JSON.parse(JSON.stringify(this.benchmark_stage_data));
      this.thickness_measurements = JSON.parse(JSON.stringify(this.benchmark_stage_data.thickness_measurements));
      this.checkLastRow();

      await this.getLineLayoutImage();
      this.fillLineLayoutCanvas();

      this.$store.state.isLoaded = false;

      if (this.benchmarkStatus() === "Completed") {
        this.currentPage = 2;
      }
      else if (this.currentSettingsStepIsFilled) {
        this.currentPage = 1;
      }
    },
    async getLineLayoutImage() {
      try {
        var body = {
          line: this.lineId,
          canvas_width: this.canvas_width,
          canvas_height: this.canvas_height,
        };
        var response = await axios.post("/api/v1/fp/computepowderamountlineimage/", body);
        this.line_layout_image = response.data["layout_image"];
      } catch (error) {
        console.error(error);
      }
    },
    fillLineLayoutCanvas() {
      nextTick(() => {
        var lineLayoutCanvas = document.getElementById("lineLayoutCanvas");
        this.canvas_width = lineLayoutCanvas.width;
        this.canvas_height = lineLayoutCanvas.height;
        this.ctx_line_layout = lineLayoutCanvas.getContext("2d");;
  
        let line_layout_imageData = new ImageData(Uint8ClampedArray.from(this.line_layout_image.values()), this.canvas_width, this.canvas_height);
        this.ctx_line_layout.putImageData(line_layout_imageData, 0, 0);
        });
    },
    benchmarkStatus() {
      const fields = [
        this.benchmark_form.reciprocator_period,
        this.benchmark_form.guns_movement_range,
        this.benchmark_form.line_speed,
        this.benchmark_form.coated_height,
        this.benchmark_form.nozzle_angle,
        this.benchmark_form.minimum_target_thickness,
        this.benchmark_form.maximum_target_thickness,
        this.thickness_measurements,
      ];

      let notEmptyMeasurements = this.thickness_measurements.filter(m => m !== null && m !== "");
      if (
        fields.every(field => field !== null && field !== "") &&
        this.benchmark_form.gun_settings.every(gun =>
          Object.values(gun).every(value => value !== null && value !== ""),
        ) &&
        notEmptyMeasurements.length >= 2
      ) {
        return "Completed";
      } else if (
        fields.some(field => field !== null || field !== "") ||
        this.benchmark_form.gun_settings.some(gun =>
          Object.values(gun).some(value => value !== null && value !== ""),
        ) ||
        notEmptyMeasurements.length > 0
      ) {
        return "In Progress";
      } else {
        return "Pending";
      }
    },
    closeModal() {
      const dismissButton = this.$refs.dismissButton;
      if (dismissButton) {
        dismissButton.click();
      }
    },
    async updateBenchmarkData() {
      let nonEmptyThicknessMeasurements = this.thickness_measurements.filter(m => m !== null && m !== "" && m !== 0);
      try {
        const gun_settings_as_numbers = this.benchmark_form.gun_settings.map(gun => {
          return {
            voltage: gun.voltage === "" || gun.voltage == null ? null:Number(gun.voltage),
            amps: gun.amps === "" || gun.amps == null ? null:Number(gun.amps),
            air: gun.air === "" || gun.air == null ? null:Number(gun.air),
            distance: gun.distance === "" || gun.distance == null ? null:Number(gun.distance),
            powder: gun.powder === "" || gun.powder == null ? null:Number(gun.powder)
          };
        });

        await axios.patch(`/api/v1/blueprint/benchmarkstage/` + this.visitId + "/", {
          minimum_target_thickness: this.benchmark_form.minimum_target_thickness === "" ? null : this.benchmark_form.minimum_target_thickness,
          maximum_target_thickness: this.benchmark_form.maximum_target_thickness === "" ? null : this.benchmark_form.maximum_target_thickness,
          reciprocator_period: this.benchmark_form.reciprocator_period === "" ? null : this.benchmark_form.reciprocator_period,
          guns_movement_range: this.benchmark_form.guns_movement_range === "" ? null : this.benchmark_form.guns_movement_range,
          line_speed: this.benchmark_form.line_speed === "" ? null : this.benchmark_form.line_speed,
          coated_height: this.benchmark_form.coated_height === "" ? null : this.benchmark_form.coated_height,
          nozzle_angle: this.benchmark_form.nozzle_angle ? this.benchmark_form.nozzle_angle : 0,
          gun_settings: gun_settings_as_numbers,
          thickness_measurements: nonEmptyThicknessMeasurements,
          progress_status: this.progressStatus,
        });

        await this.getBenchmarkData(this.visitId, this.totalLineGuns);
        this.benchmark_form = JSON.parse(JSON.stringify(this.benchmark_stage_data));

      } catch (error) {
        console.error(error);
      }
    },
    showSavedDataSwal(stepTitle) {
      this.$swal({
        title: this.$t("Changes saved"),
        text: stepTitle + " " + " saved successfully",
        icon: "success",
        confirmButtonText: "OK",
      });
    },
    nextStep(formIsFilled, increaseCurrentPage) {
      if (!formIsFilled) {
        this.$swal({
          title: "There are missing fields. Are you sure you want to continue?",
          icon: "info",
          confirmButtonText: "Continue",
          customClass: {
            confirmButton: "btn btn-info w-40 px-1 m-2",
            cancelButton: "btn btn-secondary w-40 px-1 m-2",
          },
          reverseButtons: true,
          buttonsStyling: false,
          showCancelButton: true,
        }).then((result) => {
          if (result.isConfirmed && increaseCurrentPage) {
            this.currentPage++;
          }
        });
      } else if (increaseCurrentPage) {
        this.currentPage++;
      }
    },
    cleanThicknessMeasurementsArray(array) {
      return array.filter(m => m !== null && m !== "");
    },
    generateX,
    gaussian,
    generateY,
    calculateMu,
    calculateSigma,
    thicknessMeasurementsUpdateChartData() {
      const mu = this.calculateMu(this.thickness_measurements);
      const sigma = this.calculateSigma(this.thickness_measurements, mu);

      this.$store.state.gaussianBenchmarkChart.labels = this.generateX(mu, sigma);
      this.$store.state.gaussianBenchmarkChart.datasets[0] = {
        label: "Initial Benchmark",
        data: this.generateY(sigma, mu),
      };

      if (
        this.benchmark_form.maximum_target_thickness !== null &&
        this.benchmark_form.minimum_target_thickness !== null
      ) {
        const arr = this.$store.state.gaussianBenchmarkChart.labels.map(num => parseFloat(num));
        let index = arr.findIndex(num => num > this.benchmark_form.minimum_target_thickness);
        let index_max = arr.findIndex(num => num > this.benchmark_form.maximum_target_thickness);

        this.$store.state.maxTargetThickness = index_max > 0 ? index_max - 1 : arr.length - 1;
        this.$store.state.minTargetThickness = index > 0 ? index - 1 : index;
      }
    },
    nozzleAngleTooltipImage() {
      let url = this.s3_storage_base_URL + "angle-0-200x200.jpg";
      return `<img src="${url}" alt="Tooltip Image">`;
    },
  },
};
</script>
<style scoped>
.table input {
  width: 60px;
  text-align: center;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.container-fluid {
  padding-top: 20px;
}

.chart-canvas {
  max-height: 400px;
}
</style>

<i18n>
  {
    "EN": {
      "Benchmark & Target": "Benchmark & Target",
      "Save": "Save",
      "Changes saved": "Changes saved",
      "was saved successfully": "was saved successfully",
    },
    "DE": {
      "Benchmark & Target": "Benchmark & Ziel",
      "Save": "Speichern",
      "Changes saved": "Änderungen gespeichert",
      "was saved successfully": "wurde erfolgreich gespeichert",
    },
    "ES": {
      "Benchmark & Target": "Punto de referencia y objetivo",
      "Save": "Guardar",
      "Changes saved": "Cambios guardados",
      "was saved successfully": "se guardó correctamente",
    },
    "FR": {
      "Benchmark & Target": "Référence et objectif",
      "Save": "Sauvegarder",
      "Changes saved": "Modifications enregistrées",
      "was saved successfully": "a été enregistré avec succès",
    },
    "IT": {
      "Benchmark & Target": "Benchmark e obiettivo",
      "Save": "Salva",
      "Changes saved": "Modifiche salvate",
      "was saved successfully": "è stato salvato con successo",
    },
    "PL": {
      "Benchmark & Target": "Punkt odniesienia i cel",
      "Save": "Zapisz",
      "Changes saved": "Zmiany zapisane",
      "was saved successfully": "został zapisany pomyślnie",
    },
    "TR": {
      "Benchmark & Target": "Referans ve Hedef",
      "Save": "Kaydet",
      "Changes saved": "Değişiklikler kaydedildi",
      "was saved successfully": "başarıyla kaydedildi",
    },
    "ZH": {
      "Benchmark & Target": "基准和目标",
      "Save": "保存",
      "Changes saved": "更改已保存",
      "was saved successfully": "已成功保存",
    }
  }
</i18n>
