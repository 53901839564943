<template>
  <nav aria-label="breadcrumb">
    <ol
      :class="`px-0 pt-1 pb-0 mb-0 bg-transparent breadcrumb text-${textWhite}`"
    >
      <li :class="`text-${textWhite}`">
        <a
          :class="`text-${textWhite} cursor-pointer`"
          href="/"
        >
          <i class="fa fa-home px-1" :style="`color: ${textWhite};`"></i>
          {{ $t('Home') }}
        </a>
      </li>
      &nbsp;|&nbsp;
      <a
        :class="`text-${textWhite} cursor-pointer`"
        @click="goBack();"
      >
        {{ $t('Go Back') }} &nbsp;
    <i class="fa fa-undo px-1" :style="`color: ${textWhite}; scale: 1`"></i>
    </a>
    <div v-if="this.$store.state.user_data.powder_savings_calculator_license">
      &nbsp;|&nbsp;
      <a
        :class="`text-${textWhite} cursor-pointer`"
        @click="$router.push('/powdersavingscalculator/expo');">{{ $t('Powder Savings Calculator') }} &nbsp;
      </a>
    </div>
    </ol>
  </nav>
</template>

<script>
import { faHouse } from "@fortawesome/free-solid-svg-icons";
export default {
  name: "Breadcrumbs",
  components: {
  },
  props: {
    textWhite: {
      type: String,
      default: "white",
    },
  },
  data() {
    this.$i18n.locale = this.$store.state.user_data.language;
    return {
      faHouse,
    };
  },
  watch:{
    '$store.state.user_data.language' () {
      this.$i18n.locale = this.$store.state.user_data.language;
    }
  },
  methods: {
    goBack() {
      if (this.$route.name === 'FlightPath'){
        this.$router.go(0);
      } else {
        this.$router.go(-1);
      }
    },
  }
};
</script>

<i18n>
  {
    "EN": {
        "Home":"Home",
        "Go Back":"Go Back",
        "Powder Savings Calculator":"Powder Savings Calculator",
    },
    "DE": {
        "Home":"Hauptseite",
        "Go Back":"Zurückgehen",
        "Powder Savings Calculator":"Pulvereinsparungenrechner",
    },
    "ES": {
        "Home":"Inicio",
        "Go Back":"Volver",
        "Powder Savings Calculator":"Calculadora de ahorro de polvo",
    },
    "FR": {
        "Home":"Page d'accueil",
         "Go Back":"Arrière",
          "Powder Savings Calculator":"Calculateur d'économie de poudre",
    },
    "IT": {
        "Home":"Pagina iniziale",
        "Go Back":"Indietro",
        "Powder Savings Calculator":"Calcolatore di risparmio di polvere",
    },
    "PL": {
        "Home":"Strona główna",
        "Go Back":"Powrót",
        "Powder Savings Calculator":"Kalkulator oszczędności proszku",
    },
    "TR": {
        "Home":"Ana Sayfa",
        "Go Back":"Geri dön",
        "Powder Savings Calculator":"Toz Tasarruf Hesaplayıcısı",
    },
    "ZH": {
        "Home":"主页",
        "Go Back":"转回去",
        "Powder Savings Calculator":"粉末节约计算器",
    }
  }
</i18n>
