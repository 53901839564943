<template>
  <div
    class="flip-card card p-3 mb-4 col-lg-4 col-md-6"
    style="box-shadow: none"
    @click="toggleFlip"
  >
    <div
      class="flip-card-inner"
      :style="{ transform: isActive ? 'rotateY(180deg)' : 'none' }"
      style="min-height: 630px"
    >
      <div class="flip-card-front border-radius-lg">
        <div
          class="p-3 card-body"
          style="height: 100%"
        >
          <h3 class="text-center">{{ lineName }}</h3>
          <div class="row mt-2 mb-2">
            <div class="col- text-center">
              <p class="mb-0 text-sm text-dark font-weight-bold">{{ $t("Created") }}</p>
              <div class="my-auto mb-0 text-xs">
                <p style="font-size: 0.8rem">
                  <b>{{ dateTime }}</b>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flip-card-back border-radius-lg">
        <div class="p-3 card-body">
          <div class="row-12">
            <div
              class="col-auto"
              style="display: flex; align-items: center; justify-content: space-between; cursor: pointer"
            >
              <h6
                style="cursor: pointer; margin-top: auto; margin-bottom: auto"
                onmouseover="this.style.textDecoration = 'underline'"
                onmouseout="this.style.textDecoration = 'none'"
                @click="
                  this.$store.state.isLoading = true;
                  $router.push('/applications/line/' + lineId);
                "
              >
                {{ lineName }}
              </h6>
              <a
                class="btn btn-link text-dark px-3 mb-0 my-auto"
                @click="
                  this.$store.state.isLoading = true;
                  $router.push('/applications/line/' + lineId);
                "
              >
                <i
                  class="fas fa-eye text-dark me-2"
                  aria-hidden="true"
                ></i>
                {{ $t("Details") }}
              </a>
            </div>
          </div>
          <hr class="horizontal dark" />
          <div style="height: 100px">
            <button
              class="w-100 h-100 mt-2 mb-0 btn ms-lg-auto me-lg-0 me-auto mt-lg-0"
              type="button"
              style="background-color: rgb(231, 245, 221); font-size: 0.9rem"
              @click="
                this.$store.state.isLoading = true;
                $router.push('/applications/flightpath/optimization/' + lineId);
              "
            >
              {{ $t("New Optimization") }}
            </button>
          </div>
          <hr class="horizontal dark" />
          <button
            class="w-100 mt-2 mb-0 btn ms-lg-auto me-lg-0 me-auto mt-lg-0"
            type="button"
            style="background-color: rgb(241, 241, 241); min-height: 60px"
            @click="
              this.$store.state.isLoading = true;
              $router.push('/applications/flightpath/optimizationslibrary/' + lineId);
            "
          >
          <i
              class="fa fa-book"
              aria-hidden="true"
            ></i>
            {{ $t("Optimizations Library") }}
          </button>
          <hr class="horizontal dark" />
          <button
            class="w-100 mt-2 mb-0 btn ms-lg-auto me-lg-0 me-auto mt-lg-0"
            type="button"
            style="background-color: rgb(241, 241, 241); min-height: 60px"
            @click="
              this.$store.state.isLoading = true;
              $router.push('/applications/flightpath/powdermodelslibrary/' + lineId);
            "
          >
            <i class="fa fa-dot-circle-o"></i>
            {{ $t("Powder Models Library") }}
          </button>
          <hr class="horizontal dark" />
          <button
            v-if="!isMixedLayout"
            class="w-100 mt-2 mb-0 btn ms-lg-auto me-lg-0 me-auto mt-lg-0"
            type="button"
            style="background-color: rgb(241, 241, 241); min-height: 60px;"
            @click="this.$store.state.isLoading=true;$router.push('/applications/flightpath/powderamountcalibrationslibrary/' + lineId)"
          >
            <i class="fa fa-dot-circle-o"></i>
            {{$t('Powder Amount Library')}}
          </button>
          <hr class="horizontal dark" />
          <div class="row mt-2 mb-2">
            <div class="col- text-end">
              <p class="mb-0 text-sm text-dark font-weight-bold">{{ $t("Created") }}</p>
              <div class="my-auto mb-0 text-xs">
                <p style="font-size: 0.8rem">
                  <b>{{ dateTime }}</b>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LineCard",
  props: {
    lineName: {
      type: String,
      default: "",
    },
    lineId: {
      type: String,
      default: "",
    },
    dateTime: {
      type: String,
      default: "",
    },
    isActive: {
      type: Boolean,
      required: true,
    },
    lineLayout: {
      type: String,
      default: "",
    },
  },
  emits: ["card-clicked"],
  data() {
    this.$i18n.locale = this.$store.state.user_data.language;
    return {
      isFlipped: false,
      // showMenu: false,
    };
  },
  computed: {
  isMixedLayout() {
      return this.lineLayout === "m" | this.lineLayout == "fixedmixed";
    },
  },
  watch: {
    "$store.state.user_data.language"() {
      this.$i18n.locale = this.$store.state.user_data.language;
    },
  },
  methods: {
    toggleFlip() {
      this.$emit("card-clicked");
    },
  },
};
</script>

<style scoped>
.flip-card {
  perspective: 1000px;
  position: relative;
  display: flex;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s ease-in-out, box-shadow 0.3s ease-in-out;
  transform-style: preserve-3d;
  transform: rotateY(180deg);
  cursor: pointer;
}

.flip-card-inner:hover,
.flip-card-front:hover {
  transform: scale(1.05);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.flip-card-front {
  color: black;
  background-color: rgb(230, 230, 230);
  border-radius: large;
}

.flip-card-back {
  color: black;
  background-color: rgb(230, 230, 230);
  transform: rotateY(180deg);
  border-radius: large;
}
</style>
<i18n>
  {
    "EN": {
        "Created":"Created",
        "Details":"Details",
        "Optimizations Library":"Optimizations Library",
        "New Optimization":"New Optimization",
        "Powder Models Library":"Powder Models Library",
        "Powder Amount Library":"Powder Amount Library",
    },
    "DE": {
        "Created":"Erstellt",
        "Details":"Details",
        "Optimizations Library":"Optimierungsliste",
        "New Optimization":"Neue Optimierung",
        "Powder Models Library":"Bibliothek der Sprühbilder",
        "Powder Amount Library":"Pulvermengenbibliothek",
    },
    "ES": {
        "Created":"Creada",
        "Details":"Detalles",
        "Optimizations Library":"Lista de Optimizaciones",
        "New Optimization":"Nueva Optimización",
        "Powder Models Library":"Lista de Modelos de Polvo",
        "Powder Amount Library":"Biblioteca de Flujo de Pintura",
    },
    "FR": {
        "Created":"Créée",
        "Details":"Détails",
        "Optimizations Library":"Liste des Optimisations",
        "New Optimization":"Nouvelle Optimisation",
        "Powder Models Library":"Bibliothèque de Modèles de Poudres",
        "Powder Amount Library":"Bibliothèque de Quantité de Poudre",
    },
    "IT": {
        "Created":"Creata",
        "Details":"Dettagli",
        "Optimizations Library":"Elenco delle Ottimizzazioni",
        "New Optimization":"Nuova Ottimizzazione",
        "Powder Models Library":"Libreria dei Modelli di Polvere",
        "Powder Amount Library":"Libreria della Quantità di Polvere",
    },
    "PL": {
        "Created":"Utworzony",
        "Details":"Informacje",
        "Optimizations Library":"Lista Optymalizacji",
        "New Optimization":"Nowa optymalizacja",
        "Powder Models Library":"Biblioteka Modeli Proszkowych",
        "Powder Amount Library":"Biblioteka Ilości Proszku",
    },
    "TR": {
        "Created":"Oluşturuldu",
        "Details":"Detaylar",
        "Optimizations Library":"Optimizasyonlar Listesi",
        "New Optimization":"Yeni Optimizasyon",
        "Powder Models Library":"Toz Modelleri Kütüphanesi",
        "Powder Amount Library":"Toz Miktarı Kütüphanesi",
    },
    "ZH": {
        "Created":"创建",
        "Details":"资料",
        "Optimizations Library":"优化列表",
        "New Optimization":"新优化",
        "Powder Models Library":"粉末模型库",
        "Powder Amount Library":"粉末量库",
    },
  }
</i18n>
