export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "EN": {
        "Horizontal distance between two adjacent guns or stacks of guns.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horizontal distance between two adjacent guns or stacks of guns."])},
        "Number of guns or vertical stacks of guns, next to each other.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of guns or vertical stacks of guns, next to each other."])},
        "Height offset between two adjacent guns or stacks of guns.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Height offset between two adjacent guns or stacks of guns."])},
        "Number of guns on top of each other.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of guns on top of each other."])},
        "Vertical distance between two guns on top of each other.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertical distance between two guns on top of each other."])},
        "Horizontal distance offset between even and odd rows.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horizontal distance offset between even and odd rows."])},
        "Minimum gun velocity with activated reciprocator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum gun velocity with activated reciprocator"])},
        "Maximum gun velocity of the reciprocator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum gun velocity of the reciprocator"])},
        "Maximum gun acceleration of the reciprocator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum gun acceleration of the reciprocator"])},
        "The minimum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The minimum is"])},
        "and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and the maximum is"])},
        "Static Calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Static Calibration"])},
        "Gun Max Velocity must be greater than Gun Min Velocity by at least": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun Max Velocity must be greater than Gun Min Velocity by at least"])},
        "Gun column height offsets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun column height offsets"])},
        "Nozzle rotation angle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nozzle rotation angle"])},
        "Total Guns per column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Guns per column"])},
        "Fixed Mixed (non moving with multiple columns of guns)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed Mixed (non moving with multiple columns of guns)"])},
        "Line changes saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Line changes saved"])},
        "Line created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Line created"])},
        "was saved successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["was saved successfully"])},
        "The following fields are required:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The following fields are required:"])},
        "Air Parameter Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Air Parameter Name"])},
        "Air Parameter Units": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Air Parameter Units"])},
        "Number of Gun Columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of Gun Columns"])},
        "Total Guns Column 1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Guns Column 1"])},
        "Total Guns Column 2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Guns Column 2"])},
        "Total Guns Column 3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Guns Column 3"])},
        "Total Guns Column 4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Guns Column 4"])},
        "Total Guns Column 5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Guns Column 5"])},
        "Gun to Gun Distance Column 1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun to Gun Distance Column 1"])},
        "Gun to Gun Distance Column 2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun to Gun Distance Column 2"])},
        "Gun to Gun Distance Column 3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun to Gun Distance Column 3"])},
        "Gun to Gun Distance Column 4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun to Gun Distance Column 4"])},
        "Gun to Gun Distance Column 5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun to Gun Distance Column 5"])},
        "Gun Column Distance 1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun Column Distance 1"])},
        "Gun Column Distance 2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun Column Distance 2"])},
        "Gun Column Distance 3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun Column Distance 3"])},
        "Gun Column Distance 4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun Column Distance 4"])},
        "Gun Column Distance 5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun Column Distance 5"])},
        "Gun Column Vertical Offset 1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun Column Vertical Offset 1"])},
        "Gun Column Vertical Offset 2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun Column Vertical Offset 2"])},
        "Gun Column Vertical Offset 3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun Column Vertical Offset 3"])},
        "Gun Column Vertical Offset 4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun Column Vertical Offset 4"])},
        "Gun Column Vertical Offset 5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun Column Vertical Offset 5"])},
        "Input value out of range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input value out of range"])},
        "Gun to gun distance cannot be 0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun to gun distance cannot be 0"])},
        "Gun to Gun distance must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun to Gun distance must be greater than"])},
        "Gun to Gun distance must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun to Gun distance must be smaller than"])},
        "Gun max movement range cannot be 0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun max movement range cannot be 0"])},
        "Gun max movement range must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun max movement range must be greater than"])},
        "Gun max movement range must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun max movement range must be smaller than"])},
        "Gun Min Velocity cannot be 0 for Horizontal Gun Layout.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun Min Velocity cannot be 0 for Horizontal Gun Layout."])},
        "Gun Min Velocity must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun Min Velocity must be greater than"])},
        "Gun Max Velocity cannot be 0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun Max Velocity cannot be 0"])},
        "Gun Min Velocity cannot be greater than or equal to the Gun Max Velocity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun Min Velocity cannot be greater than or equal to the Gun Max Velocity"])},
        "Gun max acceleration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun max acceleration"])},
        "Missing Required Fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Missing Required Fields"])},
        "Vertical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertical"])},
        "Horizontal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horizontal"])},
        "Mixed (Vertical and Horizontal Hybrid)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mixed (Vertical and Horizontal Hybrid)"])},
        "Simple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simple"])},
        "Fixed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed"])},
        "Advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced"])},
        "Which type of Powder Model do you want to create?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Which type of Powder Model do you want to create?"])},
        "Please select one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select one"])},
        "Add new Line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new Line"])},
        "Edit Line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Line"])},
        "Line Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Line Details"])},
        "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "Line Properties": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Line Properties"])},
        "Line Manufacturer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Line Manufacturer"])},
        "Air Parameter Name e.g. [DosageAir]": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Air Parameter Name e.g. [DosageAir]"])},
        "Air Parameter Units e.g. [bar]": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Air Parameter Units e.g. [bar]"])},
        "Pump Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pump Type"])},
        "Gun min velocity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun min velocity"])},
        "Gun max velocity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun max velocity"])},
        "Gun Layout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun Layout"])},
        "Gun max movement range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun max movement range"])},
        "Total Guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Guns"])},
        "Gun to gun distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun to gun distance"])},
        "Total number of vertical gun stacks or columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total number of vertical gun stacks or columns"])},
        "columns of guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["columns of guns"])},
        "1st guns column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1st guns column"])},
        "Total number of guns of the 1st vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total number of guns of the 1st vertical stack"])},
        "Gun to gun distance of the 1st vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun to gun distance of the 1st vertical stack"])},
        "2nd guns column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2nd guns column"])},
        "Total number of guns of the 2nd vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total number of guns of the 2nd vertical stack"])},
        "Gun to gun distance of the 2nd vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun to gun distance of the 2nd vertical stack"])},
        "Horizontal distance between 1st and 2nd vertical stacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horizontal distance between 1st and 2nd vertical stacks"])},
        "2nd vertical guns stack height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2nd vertical guns stack height offset"])},
        "3rd guns column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3rd guns column"])},
        "Total number of guns of the 3rd vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total number of guns of the 3rd vertical stack"])},
        "Gun to gun distance of the 3rd vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun to gun distance of the 3rd vertical stack"])},
        "Horizontal distance between 2nd and 3rd vertical stacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horizontal distance between 2nd and 3rd vertical stacks"])},
        "3rd vertical guns stack height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3rd vertical guns stack height offset"])},
        "4th guns column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4th guns column"])},
        "Total number of guns of the 4th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total number of guns of the 4th vertical stack"])},
        "Gun to gun distance of the 4th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun to gun distance of the 4th vertical stack"])},
        "Horizontal distance between 3rd and 4th vertical stacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horizontal distance between 3rd and 4th vertical stacks"])},
        "4th vertical guns stack height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4th vertical guns stack height offset"])},
        "5th guns column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5th guns column"])},
        "Total number of guns of the 5th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total number of guns of the 5th vertical stack"])},
        "Gun to gun distance of the 5th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun to gun distance of the 5th vertical stack"])},
        "Horizontal distance between the 4th and 5th stacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horizontal distance between the 4th and 5th stacks"])},
        "5th vertical guns stack height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5th vertical guns stack height offset"])},
        "Line Details are not editable. If you want to make changes to your line, contact us at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Line Details are not editable. If you want to make changes to your line, contact us at"])},
        "Create Line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Line"])},
        "Line Preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Line Preview"])},
        "New Powder Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Powder Model"])},
        "Go Back Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go Back Home"])},
        "Select Vertical if the guns are arranged in one on top of each other in a single column. Select Horizontal if they are arranged one next to each other in a single row. Select Mixed for more complex arrangements that combine multiple vertical stacks or columns.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Vertical if the guns are arranged in one on top of each other in a single column. Select Horizontal if they are arranged one next to each other in a single row. Select Mixed for more complex arrangements that combine multiple vertical stacks or columns."])},
        "The maximum movement range in the vertical axis that one gun can move, i.e. distance that one gun can move from its highest to its lowest position. By default is set to the gun to gun distance.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The maximum movement range in the vertical axis that one gun can move, i.e. distance that one gun can move from its highest to its lowest position. By default is set to the gun to gun distance."])},
        "A mixed hybrid line is composed of multiple vertical stacks or columns of guns. If your Line has only 1 vertical gun column, then select Vertical instead of Mixed on the Gun Layout option.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A mixed hybrid line is composed of multiple vertical stacks or columns of guns. If your Line has only 1 vertical gun column, then select Vertical instead of Mixed on the Gun Layout option."])},
        "Input the vertical distance between guns of the 1st column of guns, the column that is most to the left.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input the vertical distance between guns of the 1st column of guns, the column that is most to the left."])},
        "Input the vertical distance between guns of the second column of guns, the second column from the left.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input the vertical distance between guns of the second column of guns, the second column from the left."])},
        "Input the horizontal distance measured between the first column and the second column of guns.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input the horizontal distance measured between the first column and the second column of guns."])},
        "Input the vertical guns stack height offset as the difference in respect to the previous one on the left. If the gun stacks are at equal height leave the default 0 offset. A positive value means the column is higher than the previous one, and a negative means the second stack is shorter than the first one.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input the vertical guns stack height offset as the difference in respect to the previous one on the left. If the gun stacks are at equal height leave the default 0 offset. A positive value means the column is higher than the previous one, and a negative means the second stack is shorter than the first one."])},
        "Input the vertical distance between guns of the third column of guns, the third column from the left.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input the vertical distance between guns of the third column of guns, the third column from the left."])},
        "Input the horizontal distance measured between the second column and the third column of guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input the horizontal distance measured between the second column and the third column of guns"])},
        "Input the vertical distance between guns of the fourth column of guns, i.e the fourth column from the left.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input the vertical distance between guns of the fourth column of guns, i.e the fourth column from the left."])},
        "Input the horizontal distance measured between the third column and the fourth column of guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input the horizontal distance measured between the third column and the fourth column of guns"])},
        "Input the vertical distance between guns of the fifth column of guns, i.e the fifth column from the left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input the vertical distance between guns of the fifth column of guns, i.e the fifth column from the left"])},
        "Input the horizontal distance measured between the fourth column and the fifth column of guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input the horizontal distance measured between the fourth column and the fifth column of guns"])},
        "Other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
        "Dense Phase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dense Phase"])},
        "Injector / Venturi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Injector / Venturi"])},
        "Save Changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Changes"])},
        "No Changes to Save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Changes to Save"])},
        "Wagner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wagner"])},
        "MS-Carlisle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MS-Carlisle"])},
        "GEMA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GEMA"])},
        "Nordson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nordson"])},
        "Fixed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed"])},
        "Dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dynamic"])},
        "Line Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Line Type"])},
        "Select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
        "Select moving or fixed line. A fixed line has a static reciprocator that does not move, hence guns have 0 movement range and 0 velocity.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select moving or fixed line. A fixed line has a static reciprocator that does not move, hence guns have 0 movement range and 0 velocity."])},
        "guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["guns"])},
        "Do you have a recycling powder system?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you have a recycling powder system?"])},
        "Yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
        "No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
        "Recycling System Efficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recycling System Efficiency"])},
        "Number of Columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of Columns"])},
        "Column Distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Column Distance"])},
        "Column Shift": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Column Shift"])},
        "Number of Rows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of Rows"])},
        "Row Distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Row Distance"])},
        "Row Shift": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Row Shift"])},
        "Advanced Gun Layout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced Gun Layout"])},
        "6th guns column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6th guns column"])},
        "7th guns column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7th guns column"])},
        "8th guns column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8th guns column"])},
        "9th guns column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9th guns column"])},
        "10th guns column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10th guns column"])},
        "Total number of guns of the 6th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total number of guns of the 6th vertical stack"])},
        "Total number of guns of the 7th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total number of guns of the 7th vertical stack"])},
        "Total number of guns of the 8th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total number of guns of the 8th vertical stack"])},
        "Total number of guns of the 9th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total number of guns of the 9th vertical stack"])},
        "Total number of guns of the 10th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total number of guns of the 10th vertical stack"])},
        "Gun to gun distance of the 6th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun to gun distance of the 6th vertical stack"])},
        "Gun to gun distance of the 7th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun to gun distance of the 7th vertical stack"])},
        "Gun to gun distance of the 8th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun to gun distance of the 8th vertical stack"])},
        "Gun to gun distance of the 9th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun to gun distance of the 9th vertical stack"])},
        "Gun to gun distance of the 10th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun to gun distance of the 10th vertical stack"])},
        "Input the vertical distance between guns of the sixth column of guns, i.e the sixth column from the left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input the vertical distance between guns of the sixth column of guns, i.e the sixth column from the left"])},
        "Input the vertical distance between guns of the seventh column of guns, i.e the seventh column from the left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input the vertical distance between guns of the seventh column of guns, i.e the seventh column from the left"])},
        "Input the vertical distance between guns of the eighth column of guns, i.e the eighth column from the left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input the vertical distance between guns of the eighth column of guns, i.e the eighth column from the left"])},
        "Input the vertical distance between guns of the ninth column of guns, i.e the ninth column from the left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input the vertical distance between guns of the ninth column of guns, i.e the ninth column from the left"])},
        "Input the vertical distance between guns of the tenth column of guns, i.e the tenth column from the left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input the vertical distance between guns of the tenth column of guns, i.e the tenth column from the left"])},
        "Horizontal distance between the 5th and 6th stacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horizontal distance between the 5th and 6th stacks"])},
        "Horizontal distance between the 6th and 7th stacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horizontal distance between the 6th and 7th stacks"])},
        "Horizontal distance between the 7th and 8th stacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horizontal distance between the 7th and 8th stacks"])},
        "Horizontal distance between the 8th and 9th stacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horizontal distance between the 8th and 9th stacks"])},
        "Horizontal distance between the 9th and 10th stacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horizontal distance between the 9th and 10th stacks"])},
        "Input the horizontal distance measured between the fifth column and the sixth column of guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input the horizontal distance measured between the fifth column and the sixth column of guns"])},
        "Input the horizontal distance measured between the sixth column and the seventh column of guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input the horizontal distance measured between the sixth column and the seventh column of guns"])},
        "Input the horizontal distance measured between the seventh column and the eighth column of guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input the horizontal distance measured between the seventh column and the eighth column of guns"])},
        "Input the horizontal distance measured between the eighth column and the ninth column of guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input the horizontal distance measured between the eighth column and the ninth column of guns"])},
        "Input the horizontal distance measured between the ninth column and the tenth column of guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input the horizontal distance measured between the ninth column and the tenth column of guns"])},
        "6th vertical guns stack height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6th vertical guns stack height offset"])},
        "7th vertical guns stack height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7th vertical guns stack height offset"])},
        "8th vertical guns stack height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8th vertical guns stack height offset"])},
        "9th vertical guns stack height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9th vertical guns stack height offset"])},
        "10th vertical guns stack height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10th vertical guns stack height offset"])},
        "Switch to advanced Line configurator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switch to advanced Line configurator"])},
        "Switch to standard Line configurator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switch to standard Line configurator"])},
        "Horizontal layout is not allowed for Fixed Lines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horizontal layout is not allowed for Fixed Lines"])},
        "For Mixed Lines the number of gun columns must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For Mixed Lines the number of gun columns must be smaller than"])},
        "Attention!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention!"])},
        "Changing to standard configuration might modify your line. Do you want to continue?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changing to standard configuration might modify your line. Do you want to continue?"])},
        "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
        "Total Guns Column 6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Guns Column 6"])},
        "Total Guns Column 7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Guns Column 7"])},
        "Total Guns Column 8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Guns Column 8"])},
        "Total Guns Column 9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Guns Column 9"])},
        "Total Guns Column 10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Guns Column 10"])},
        "Gun to Gun Distance Column 6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun to Gun Distance Column 6"])},
        "Gun to Gun Distance Column 7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun to Gun Distance Column 7"])},
        "Gun to Gun Distance Column 8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun to Gun Distance Column 8"])},
        "Gun to Gun Distance Column 9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun to Gun Distance Column 9"])},
        "Gun to Gun Distance Column 10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun to Gun Distance Column 10"])},
        "Gun Column Distance 6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun Column Distance 6"])},
        "Gun Column Distance 7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun Column Distance 7"])},
        "Gun Column Distance 8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun Column Distance 8"])},
        "Gun Column Distance 9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun Column Distance 9"])},
        "Gun Column Distance 10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun Column Distance 10"])},
        "Gun Column Vertical Offset 6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun Column Vertical Offset 6"])},
        "Gun Column Vertical Offset 7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun Column Vertical Offset 7"])},
        "Gun Column Vertical Offset 8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun Column Vertical Offset 8"])},
        "Gun Column Vertical Offset 9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun Column Vertical Offset 9"])},
        "Gun Column Vertical Offset 10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun Column Vertical Offset 10"])},
        "Number of columns cannot be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of columns cannot be greater than"])},
        "Number of rows cannot be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of rows cannot be greater than"])},
        "Row distance must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Row distance must be greater than"])},
        "Row distance must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Row distance must be smaller than"])},
        "Column distance must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Column distance must be greater than"])},
        "Column distance must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Column distance must be smaller than"])},
        "Column Shift must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Column Shift must be greater than"])},
        "Column Shift must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Column Shift must be smaller than"])},
        "Row Shift must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Row Shift must be greater than"])},
        "Row Shift must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Row Shift must be smaller than"])},
        "Reciprocator Min Velocity cannot be greater than or equal to the Reciprocator Max Velocity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reciprocator Min Velocity cannot be greater than or equal to the Reciprocator Max Velocity"])},
        "The number of guns must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The number of guns must be smaller than"])},
        "Lines with 1 gun per column are not allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lines with 1 gun per column are not allowed"])},
        "Advanced Gun Parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced Gun Parameters"])},
        "The gun's maximum acceleration value is outside the permissible range. Please enter a value within the allowed limits.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The gun's maximum acceleration value is outside the permissible range. Please enter a value within the allowed limits."])},
        "New Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Optimization"])}
      },
      "DE": {
        "Horizontal distance between two adjacent guns or stacks of guns.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horizontaler Abstand zwischen zwei benachbarten Pistolen oder Pistolenstapeln."])},
        "Number of guns or vertical stacks of guns, next to each other.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Pistolen oder vertikalen Pistolenstapel, die nebeneinander liegen."])},
        "Height offset between two adjacent guns or stacks of guns.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Höhenversatz zwischen zwei benachbarten Pistolen oder Pistolenstapeln."])},
        "Number of guns on top of each other.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Pistolen übereinander."])},
        "Vertical distance between two guns on top of each other.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertikaler Abstand zwischen zwei Pistolen übereinander."])},
        "Horizontal distance offset between even and odd rows.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horizontaler Abstand zwischen geraden und ungeraden Reihen."])},
        "Minimum gun velocity with activated reciprocator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestgeschwindigkeit des Geschützes mit aktiviertem Reziprokator"])},
        "Maximum gun velocity of the reciprocator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximale Geschwindigkeit des Reziprokators"])},
        "Maximum gun acceleration of the reciprocator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximale Beschleunigung des Reziprokators"])},
        "The minimum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Minimum ist"])},
        "and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["und das Maximum ist"])},
        "Static Calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statische Kalibrierung"])},
        "Gun Max Velocity must be greater than Gun Min Velocity by at least": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die maximale Geschwindigkeit des Geschützes muss mindestens so hoch sein wie die minimale Geschwindigkeit des Geschützes."])},
        "Gun column height offsets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Höhenversatz der Pistolensäule"])},
        "Nozzle rotation angle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drehwinkel der Düse"])},
        "Total Guns per column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtpistolen pro Spalte"])},
        "Fixed Mixed (non moving with multiple columns of guns)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statisch Gemischt (nicht beweglich mit mehreren Pistolenkolonnen)"])},
        "Line changes saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeilenänderungen gespeichert"])},
        "Line created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeile erstellt"])},
        "was saved successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wurde erfolgreich gespeichert"])},
        "The following fields are required:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die folgenden Felder sind erforderlich:"])},
        "Air Parameter Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name des Luftparameters"])},
        "Air Parameter Units": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einheiten der Luftparameter"])},
        "Number of Gun Columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Pistolenspalten"])},
        "Total Guns Column 1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtpistolen Spalte 1"])},
        "Total Guns Column 2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtpistolen Spalte 2"])},
        "Total Guns Column 3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pistolen gesamt Spalte 3"])},
        "Total Guns Column 4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pistolen gesamt Spalte 4"])},
        "Total Guns Column 5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pistolen gesamt Spalte 5"])},
        "Gun to Gun Distance Column 1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abstand von Pistole zu Pistole Spalte 1"])},
        "Gun to Gun Distance Column 2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abstand von Pistole zu Pistole Spalte 2"])},
        "Gun to Gun Distance Column 3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abstand Pistole zu Pistole Spalte 3"])},
        "Gun to Gun Distance Column 4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abstand Pistole zu Pistole Spalte 4"])},
        "Gun to Gun Distance Column 5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abstand Pistole zu Pistole Spalte 5"])},
        "Gun Column Distance 1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pistolensäule Abstand 1"])},
        "Gun Column Distance 2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pistolensäule Abstand 2"])},
        "Gun Column Distance 3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pistolensäule Abstand 3"])},
        "Gun Column Distance 4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pistolensäule Abstand 4"])},
        "Gun Column Distance 5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pistolensäule Abstand 5"])},
        "Gun Column Vertical Offset 1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pistolensäule Vertikaler Versatz 1"])},
        "Gun Column Vertical Offset 2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pistolensäule Vertikaler Versatz 2"])},
        "Gun Column Vertical Offset 3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pistolensäule Vertikaler Versatz 3"])},
        "Gun Column Vertical Offset 4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pistolensäule Vertikaler Versatz 4"])},
        "Gun Column Vertical Offset 5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pistolensäule Vertikaler Versatz 5"])},
        "Input value out of range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingabewert außerhalb des Bereichs"])},
        "Gun to gun distance cannot be 0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abstand von Pistole zu Pistole kann nicht 0 sein"])},
        "Gun to Gun distance must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abstand Pistole zu Pistole muss größer sein als"])},
        "Gun to Gun distance must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abstand von Pistole zu Pistole muss kleiner sein als"])},
        "Gun max movement range cannot be 0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximale Bewegungsreichweite der Pistole kann nicht 0 sein"])},
        "Gun max movement range must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximaler Bewegungsbereich der Pistole muss größer sein als"])},
        "Gun max movement range must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximaler Bewegungsbereich der Pistole muss kleiner sein als"])},
        "Gun Min Velocity cannot be 0 for Horizontal Gun Layout.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pistolen-Min-Geschwindigkeit kann bei horizontalem Pistolen-Layout nicht 0 sein."])},
        "Gun Min Velocity must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun Min Velocity muss größer sein als"])},
        "Gun Max Velocity cannot be 0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pistole Max Velocity kann nicht größer als 0 sein"])},
        "Gun Min Velocity cannot be greater than or equal to the Gun Max Velocity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pistolen-Min-Geschwindigkeit kann nicht größer oder gleich der Pistolen-Max-Geschwindigkeit sein"])},
        "Gun max acceleration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pistolen-Max-Beschleunigung"])},
        "Missing Required Fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehlende erforderliche Felder"])},
        "Vertical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertikal"])},
        "Horizontal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horizontal"])},
        "Mixed (Vertical and Horizontal Hybrid)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemischt (Vertikal und Horizontal Hybrid)"])},
        "Simple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einfach"])},
        "Fixed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statisch"])},
        "Advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erweitert"])},
        "Which type of Powder Model do you want to create?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welche Art von Pulvermodell möchten Sie erstellen?"])},
        "Please select one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wählen Sie eine"])},
        "Add new Line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Zeile hinzufügen"])},
        "Edit Line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeile bearbeiten"])},
        "Line Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anlagendetails"])},
        "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "Line Properties": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anlageneigenschaften"])},
        "Line Manufacturer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anlagenhersteller"])},
        "Air Parameter Name e.g. [DosageAir]": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luftparameter-Name z.B. [DosierLuft]"])},
        "Air Parameter Units e.g. [bar]": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luftparameter Einheiten z.B. [bar]"])},
        "Pump Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pumpentyp"])},
        "Gun min velocity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pistolen minimale Geschwindigkeit"])},
        "Gun max velocity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pistolen maximale Geschwindigkeit"])},
        "Gun Layout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pistolen Layout"])},
        "Gun max movement range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pistolen maximale Bewegungsreichweite"])},
        "Total Guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pistolen gesamt"])},
        "Gun to gun distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abstand von Pistole zu Pistole"])},
        "Total number of vertical gun stacks or columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtzahl der vertikalen Pistolenstapel oder -säulen"])},
        "columns of guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pistolensäulen"])},
        "1st guns column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. Pistolenspalte"])},
        "Total number of guns of the 1st vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtzahl der Pistolen des 1. vertikalen Stapels"])},
        "Gun to gun distance of the 1st vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abstand von Pistole zu Pistole des 1. vertikalen Stapels"])},
        "2nd guns column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spalte 2. Pistolen"])},
        "Total number of guns of the 2nd vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtzahl der Pistolen des 2. vertikalen Stapels"])},
        "Gun to gun distance of the 2nd vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abstand von Pistole zu Pistole des 2. vertikalen Stapels"])},
        "Horizontal distance between 1st and 2nd vertical stacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horizontaler Abstand zwischen dem 1. und 2. vertikalen Stapel"])},
        "2nd vertical guns stack height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Höhenversatz des 2. vertikalen Pistolenstapels"])},
        "3rd guns column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. Pistolenspalte"])},
        "Total number of guns of the 3rd vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtzahl der Pistolen des 3. vertikalen Stapels"])},
        "Gun to gun distance of the 3rd vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abstand von Pistole zu Pistole des 3. vertikalen Stapels"])},
        "Horizontal distance between 2nd and 3rd vertical stacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horizontaler Abstand zwischen dem 2. und 3. vertikalen Stapel"])},
        "3rd vertical guns stack height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Höhenversatz des 3. vertikalen Pistolenstapels"])},
        "4th guns column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4. Pistolensäule"])},
        "Total number of guns of the 4th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtzahl der Pistolen des 4. vertikalen Stapels"])},
        "Gun to gun distance of the 4th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abstand von Pistole zu Pistole des 4. vertikalen Stapels"])},
        "Horizontal distance between 3rd and 4th vertical stacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horizontaler Abstand zwischen dem 3. und 4. vertikalen Stapel"])},
        "4th vertical guns stack height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Höhenversatz des 4. vertikalen Pistolenstapels"])},
        "5th guns column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5. Pistolensäule"])},
        "Total number of guns of the 5th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtzahl der Pistolen des 5. vertikalen Stapels"])},
        "Gun to gun distance of the 5th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abstand von Pistole zu Pistole des 5. vertikalen Stapels"])},
        "Horizontal distance between the 4th and 5th stacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horizontaler Abstand zwischen dem 4. und 5"])},
        "5th vertical guns stack height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Höhenversatz des 5. vertikalen Pistolenstapels"])},
        "Line Details are not editable. If you want to make changes to your line, contact us at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liniendetails sind nicht editierbar. Wenn Sie Änderungen an Ihrer Linie vornehmen möchten, kontaktieren Sie uns unter"])},
        "Create Line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linie erstellen"])},
        "Line Preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anlagenvorschau"])},
        "New Powder Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Pulvermodell"])},
        "Go Back Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück zur Startseite"])},
        "Select Vertical if the guns are arranged in one on top of each other in a single column. Select Horizontal if they are arranged one next to each other in a single row. Select Mixed for more complex arrangements that combine multiple vertical stacks or columns.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie Vertikal, wenn die Pistolen in einer einzigen Spalte übereinander angeordnet sind. Wählen Sie Horizontal, wenn sie in einer Reihe nebeneinander angeordnet sind. Wählen Sie Gemischt für komplexere Anordnungen, die mehrere vertikale Stapel oder Spalten kombinieren."])},
        "The maximum movement range in the vertical axis that one gun can move, i.e. distance that one gun can move from its highest to its lowest position. By default is set to the gun to gun distance.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der maximale Bewegungsbereich in der vertikalen Achse, den ein Geschütz bewegen kann, d. h. die Entfernung, die ein Geschütz von seiner höchsten bis zu seiner niedrigsten Position zurücklegen kann. Standardmäßig ist dieser Wert auf die Entfernung von Geschütz zu Geschütz eingestellt."])},
        "A mixed hybrid line is composed of multiple vertical stacks or columns of guns. If your Line has only 1 vertical gun column, then select Vertical instead of Mixed on the Gun Layout option.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine gemischte Hybridlinie besteht aus mehreren vertikalen Pistolenstapeln oder -säulen. Wenn Ihre Linie nur eine vertikale Pistolensäule hat, wählen Sie bei der Option Pistolenlayout die Option Vertikal anstelle von Gemischt."])},
        "Input the vertical distance between guns of the 1st column of guns, the column that is most to the left.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie den vertikalen Abstand zwischen den Pistolen der ersten Pistolenspalte ein, also der Spalte, die am weitesten links liegt."])},
        "Input the vertical distance between guns of the second column of guns, the second column from the left.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie den vertikalen Abstand zwischen den Pistolen der zweiten Pistolenspalte ein, der zweiten Spalte von links."])},
        "Input the horizontal distance measured between the first column and the second column of guns.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie den horizontalen Abstand ein, der zwischen der ersten und der zweiten Pistolenspalte gemessen wird."])},
        "Input the vertical guns stack height offset as the difference in respect to the previous one on the left. If the gun stacks are at equal height leave the default 0 offset. A positive value means the column is higher than the previous one, and a negative means the second stack is shorter than the first one.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie den vertikalen Höhenversatz des Pistolenstapels als Differenz zum vorherigen Stapel auf der linken Seite ein. Wenn die Pistolenstapel auf gleicher Höhe sind, lassen Sie den Standardwert 0 stehen. Ein positiver Wert bedeutet, dass die Spalte höher ist als die vorherige, ein negativer Wert bedeutet, dass der zweite Stapel kürzer ist als der erste."])},
        "Input the vertical distance between guns of the third column of guns, the third column from the left.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie den vertikalen Abstand zwischen den Pistolen der dritten Pistolenspalte, der dritten Spalte von links, ein."])},
        "Input the horizontal distance measured between the second column and the third column of guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie den horizontalen Abstand ein, der zwischen der zweiten und der dritten Pistolenspalte gemessen wird."])},
        "Input the vertical distance between guns of the fourth column of guns, i.e the fourth column from the left.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingabe des vertikalen Abstands zwischen den Pistolen der vierten Pistolenspalte, d.h. der vierten Spalte von links."])},
        "Input the horizontal distance measured between the third column and the fourth column of guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingabe des horizontalen Abstands, gemessen zwischen der dritten und der vierten Pistolenspalte"])},
        "Input the vertical distance between guns of the fifth column of guns, i.e the fifth column from the left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingabe des vertikalen Abstands zwischen den Pistolen der fünften Pistolenspalte, d. h. der fünften Spalte von links"])},
        "Input the horizontal distance measured between the fourth column and the fifth column of guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingabe des horizontalen Abstands, gemessen zwischen der vierten und der fünften Pistolenspalte"])},
        "Other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere"])},
        "Dense Phase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dense Phase"])},
        "Injector / Venturi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Injector / Venturi"])},
        "Save Changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Änderungen speichern"])},
        "No Changes to Save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Änderungen zum Speichern"])},
        "Wagner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wagner"])},
        "MS-Carlisle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MS-Carlisle"])},
        "GEMA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GEMA"])},
        "Nordson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nordson"])},
        "Fixed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statisch"])},
        "Dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dynamisch"])},
        "Line Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anlagentyp"])},
        "Select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen"])},
        "Select moving or fixed line. A fixed line has a static reciprocator that does not move, hence guns have 0 movement range and 0 velocity.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie bewegliche oder statische Linie. Statische Linie hat den Reziprokator fixiert, daher haben die Pistolen einen Bewegungsbereich von 0 und eine Geschwindigkeit von 0."])},
        "guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pistolen"])},
        "Do you have a recycling powder system?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haben Sie ein Pulver-Rückgewinnungssystem?"])},
        "Yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
        "No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nein"])},
        "Recycling System Efficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effizienz des Rückgewinnungssystems"])},
        "Number of Columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Spalten"])},
        "Column Distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spaltenabstand"])},
        "Column Shift": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spaltenverschiebung"])},
        "Number of Rows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Reihen"])},
        "Row Distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reihenabstand"])},
        "Row Shift": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reihenverschiebung"])},
        "Advanced Gun Layout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erweitertes Pistolenlayout"])},
        "6th guns column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6. Pistolensäule"])},
        "7th guns column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7. Pistolensäule"])},
        "8th guns column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8. Pistolensäule"])},
        "9th guns column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9. Pistolensäule"])},
        "10th guns column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10. Pistolensäule"])},
        "Total number of guns of the 6th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtzahl der Pistolen des 6. vertikalen Stapels"])},
        "Total number of guns of the 7th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtzahl der Pistolen des 7. vertikalen Stapels"])},
        "Total number of guns of the 8th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtzahl der Pistolen des 8. vertikalen Stapels"])},
        "Total number of guns of the 9th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtzahl der Pistolen des 9. vertikalen Stapels"])},
        "Total number of guns of the 10th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtzahl der Pistolen des 10. vertikalen Stapels"])},
        "Gun to gun distance of the 6th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abstand von Pistole zu Pistole des 6. vertikalen Stapels"])},
        "Gun to gun distance of the 7th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abstand von Pistole zu Pistole des 7. vertikalen Stapels"])},
        "Gun to gun distance of the 8th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abstand von Pistole zu Pistole des 8. vertikalen Stapels"])},
        "Gun to gun distance of the 9th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abstand von Pistole zu Pistole des 9. vertikalen Stapels"])},
        "Gun to gun distance of the 10th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abstand von Pistole zu Pistole des 10. vertikalen Stapels"])},
        "Input the vertical distance between guns of the sixth column of guns, i.e the sixth column from the left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie den vertikalen Abstand zwischen den Pistolen der sechsten Pistolenspalte, d.h. der sechsten Spalte von links, ein"])},
        "Input the vertical distance between guns of the seventh column of guns, i.e the seventh column from the left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie den vertikalen Abstand zwischen den Pistolen der siebten Pistolenspalte, d.h. der siebten Spalte von links, ein"])},
        "Input the vertical distance between guns of the eighth column of guns, i.e the eighth column from the left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie den vertikalen Abstand zwischen den Pistolen der achten Pistolenspalte, d.h. der achten Spalte von links, ein"])},
        "Input the vertical distance between guns of the ninth column of guns, i.e the ninth column from the left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie den vertikalen Abstand zwischen den Pistolen der neunten Pistolenspalte, d.h. der neunten Spalte von links, ein"])},
        "Input the vertical distance between guns of the tenth column of guns, i.e the tenth column from the left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie den vertikalen Abstand zwischen den Pistolen der zehnten Pistolenspalte, d.h. der zehnten Spalte von links, ein"])},
        "Horizontal distance between the 5th and 6th stacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horizontaler Abstand zwischen dem 5. und 6. Stapel"])},
        "Horizontal distance between the 6th and 7th stacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horizontaler Abstand zwischen dem 6. und 7. Stapel"])},
        "Horizontal distance between the 7th and 8th stacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horizontaler Abstand zwischen dem 7. und 8. Stapel"])},
        "Horizontal distance between the 8th and 9th stacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horizontaler Abstand zwischen dem 8. und 9. Stapel"])},
        "Horizontal distance between the 9th and 10th stacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horizontaler Abstand zwischen dem 9. und 10. Stapel"])},
        "Input the horizontal distance measured between the fifth column and the sixth column of guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie den horizontalen Abstand ein, der zwischen der fünften und der sechsten Pistolenspalte gemessen wird"])},
        "Input the horizontal distance measured between the sixth column and the seventh column of guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie den horizontalen Abstand ein, der zwischen der sechsten und der siebten Pistolenspalte gemessen wird"])},
        "Input the horizontal distance measured between the seventh column and the eighth column of guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie den horizontalen Abstand ein, der zwischen der siebten und der achten Pistolenspalte gemessen wird"])},
        "Input the horizontal distance measured between the eighth column and the ninth column of guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie den horizontalen Abstand ein, der zwischen der achten und der neunten Pistolenspalte gemessen wird"])},
        "Input the horizontal distance measured between the ninth column and the tenth column of guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie den horizontalen Abstand ein, der zwischen der neunten und der zehnten Pistolenspalte gemessen wird"])},
        "6th vertical guns stack height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Höhenversatz des 6. vertikalen Pistolenstapels"])},
        "7th vertical guns stack height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Höhenversatz des 7. vertikalen Pistolenstapels"])},
        "8th vertical guns stack height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Höhenversatz des 8. vertikalen Pistolenstapels"])},
        "9th vertical guns stack height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Höhenversatz des 9. vertikalen Pistolenstapels"])},
        "10th vertical guns stack height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Höhenversatz des 10. vertikalen Pistolenstapels"])},
        "Switch to advanced Line configurator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wechseln Sie zum erweiterten Linienkonfigurator"])},
        "Switch to standard Line configurator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wechseln Sie zum Standard-Linienkonfigurator"])},
        "Horizontal layout is not allowed for Fixed Lines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horizontales Layout ist für statische Linien nicht zulässig"])},
        "For Mixed Lines the number of gun columns must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei Mixed Lines muss die Anzahl der Pistolenspalten kleiner sein als"])},
        "Attention!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achtung!"])},
        "Changing to standard configuration might modify your line. Do you want to continue?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Änderung auf die Standardkonfiguration könnte Ihre Linie verändern. Möchten Sie fortfahren?"])},
        "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
        "Total Guns Column 6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtpistolen Spalte 6"])},
        "Total Guns Column 7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtpistolen Spalte 7"])},
        "Total Guns Column 8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtpistolen Spalte 8"])},
        "Total Guns Column 9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtpistolen Spalte 9"])},
        "Total Guns Column 10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtpistolen Spalte 10"])},
        "Gun to Gun Distance Column 6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abstand von Pistole zu Pistole Spalte 6"])},
        "Gun to Gun Distance Column 7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abstand von Pistole zu Pistole Spalte 7"])},
        "Gun to Gun Distance Column 8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abstand von Pistole zu Pistole Spalte 8"])},
        "Gun to Gun Distance Column 9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abstand von Pistole zu Pistole Spalte 9"])},
        "Gun to Gun Distance Column 10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abstand von Pistole zu Pistole Spalte 10"])},
        "Gun Column Distance 6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pistolensäule Abstand 6"])},
        "Gun Column Distance 7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pistolensäule Abstand 7"])},
        "Gun Column Distance 8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pistolensäule Abstand 8"])},
        "Gun Column Distance 9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pistolensäule Abstand 9"])},
        "Gun Column Distance 10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pistolensäule Abstand 10"])},
        "Gun Column Vertical Offset 6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pistolensäule Vertikaler Versatz 6"])},
        "Gun Column Vertical Offset 7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pistolensäule Vertikaler Versatz 7"])},
        "Gun Column Vertical Offset 8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pistolensäule Vertikaler Versatz 8"])},
        "Gun Column Vertical Offset 9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pistolensäule Vertikaler Versatz 9"])},
        "Gun Column Vertical Offset 10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pistolensäule Vertikaler Versatz 10"])},
        "Number of columns cannot be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Spalten darf nicht größer sein als"])},
        "Number of rows cannot be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Reihen darf nicht größer sein als"])},
        "Row distance must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reihenabstand muss größer sein als"])},
        "Row distance must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reihenabstand muss kleiner sein als"])},
        "Column distance must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spaltenabstand muss größer sein als"])},
        "Column distance must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spaltenabstand muss kleiner sein als"])},
        "Column Shift must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spaltenverschiebung muss größer sein als"])},
        "Column Shift must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spaltenverschiebung muss kleiner sein als"])},
        "Row Shift must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reihenverschiebung muss größer sein als"])},
        "Row Shift must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reihenverschiebung muss kleiner sein als"])},
        "Reciprocator Min Velocity cannot be greater than or equal to the Reciprocator Max Velocity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reziprokator Min Velocity darf nicht größer oder gleich der Reziprokator Max Velocity sein"])},
        "The number of guns must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Anzahl der Pistolen muss kleiner sein als"])},
        "Lines with 1 gun per column are not allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linien mit 1 Pistole pro Spalte sind nicht zulässig"])},
        "Advanced Gun Parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erweiterte Pistolenparameter"])},
        "The gun's maximum acceleration value is outside the permissible range. Please enter a value within the allowed limits.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der maximale Beschleunigungswert der Pistole liegt außerhalb des zulässigen Bereichs. Bitte geben Sie einen Wert innerhalb der zulässigen Grenzen ein."])},
        "New Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Optimierung"])}
      },
      "ES": {
        "Horizontal distance between two adjacent guns or stacks of guns.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distancia horizontal entre dos pistolas adyacentes o pilas de pistolas."])},
        "Number of guns or vertical stacks of guns, next to each other.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de pistolas o pilas verticales de pistolas, una al lado de la otra."])},
        "Height offset between two adjacent guns or stacks of guns.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambio de altura entre dos pistolas adyacentes o pilas de pistolas de lado."])},
        "Number of guns on top of each other.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de pistolas una encima de la otra."])},
        "Vertical distance between two guns on top of each other.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distancia vertical entre dos pistolas una encima de la otra."])},
        "Horizontal distance offset between even and odd rows.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desplazamiento de la distancia horizontal entre filas pares e impares."])},
        "Minimum gun velocity with activated reciprocator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velocidad mínima de la pistola con recíproco activado"])},
        "Maximum gun velocity of the reciprocator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velocidad máxima de la pistola del recíproco"])},
        "Maximum gun acceleration of the reciprocator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceleración máxima de la pistola del recíproco"])},
        "The minimum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El mínimo es"])},
        "and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["y el máximo es"])},
        "Static Calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibración estática"])},
        "Gun Max Velocity must be greater than Gun Min Velocity by at least": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La velocidad máxima de la pistola debe ser mayor que la velocidad mínima por un margen de al menos"])},
        "Gun column height offsets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diferencia de altura entre columnas de pistolas"])},
        "Nozzle rotation angle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ángulo de la boquilla"])},
        "Total Guns per column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero de pistolas por columna"])},
        "Fixed Mixed (non moving with multiple columns of guns)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estática Mixta (sin movimiento con varias columnas de pistolas)"])},
        "Line changes saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Línea actualizada"])},
        "Line created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Línea creada"])},
        "was saved successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["se ha guardado correctamente"])},
        "The following fields are required:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los siguientes campos son obligatorios:"])},
        "Air Parameter Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre del parámetro del aire"])},
        "Air Parameter Units": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unidades del parámetro del aire"])},
        "Number of Gun Columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de columnas de pistolas"])},
        "Total Guns Column 1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Pistolas Columna 1"])},
        "Total Guns Column 2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Pistolas Columna 2"])},
        "Total Guns Column 3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Pistolas Columna 3"])},
        "Total Guns Column 4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Pistolas Columna 4"])},
        "Total Guns Column 5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Pistolas Columna 5"])},
        "Gun to Gun Distance Column 1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distancia de pistola a pistola Columna 1"])},
        "Gun to Gun Distance Column 2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distancia de pistola a pistola Columna 2"])},
        "Gun to Gun Distance Column 3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distancia de pistola a pistola Columna 3"])},
        "Gun to Gun Distance Column 4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distancia de pistola a pistola Columna 4"])},
        "Gun to Gun Distance Column 5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pistola a Pistola Distancia Columna 5"])},
        "Gun Column Distance 1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pistola Columna Distancia 1"])},
        "Gun Column Distance 2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pistola Columna Distancia 2"])},
        "Gun Column Distance 3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Columna Pistola Distancia 3"])},
        "Gun Column Distance 4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Columna Pistola Distancia 4"])},
        "Gun Column Distance 5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Columna Pistola Distancia 5"])},
        "Gun Column Vertical Offset 1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desplazamiento vertical de la columna de la pistola 1"])},
        "Gun Column Vertical Offset 2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desplazamiento vertical de la columna de la pistola 2"])},
        "Gun Column Vertical Offset 3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desplazamiento vertical de la columna de la pistola 3"])},
        "Gun Column Vertical Offset 4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desplazamiento vertical de la columna de la pistola 4"])},
        "Gun Column Vertical Offset 5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desplazamiento Vertical Columna Pistola 5"])},
        "Input value out of range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor fuera de rango"])},
        "Gun to gun distance cannot be 0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La distancia de pistola a pistola no puede ser 0"])},
        "Gun to Gun distance must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La distancia entre pistola y pistola debe ser mayor que"])},
        "Gun to Gun distance must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La distancia entre pistola y pistola debe ser menor que"])},
        "Gun max movement range cannot be 0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El rango de movimiento máximo de la pistola no puede ser 0"])},
        "Gun max movement range must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El rango de movimiento máximo de la pistola debe ser mayor que"])},
        "Gun max movement range must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El rango de movimiento máximo de la pistola debe ser menor que"])},
        "Gun Min Velocity cannot be 0 for Horizontal Gun Layout.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La velocidad mínima de la pistola no puede ser 0 para una linea horizontal."])},
        "Gun Min Velocity must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La velocidad mínima de las pistolas debe ser mayor que"])},
        "Gun Max Velocity cannot be 0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La velocidad máxima de las pistolas no puede ser 0"])},
        "Gun Min Velocity cannot be greater than or equal to the Gun Max Velocity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La velocidad mínima de la pistola no puede ser mayor ni igual que la velocidad máxima de la pistola."])},
        "Gun max acceleration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceleración máxima de la pistola"])},
        "Missing Required Fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faltan campos obligatorios"])},
        "Vertical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertical"])},
        "Horizontal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horizontal"])},
        "Mixed (Vertical and Horizontal Hybrid)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mixto (híbrido de vertical y horizontal)"])},
        "Simple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simple"])},
        "Fixed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estática"])},
        "Advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avanzado"])},
        "Which type of Powder Model do you want to create?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Qué tipo de Modelo de Polvo desea crear?"])},
        "Please select one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione una opción"])},
        "Add new Line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadir nueva línea"])},
        "Edit Line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar línea"])},
        "Line Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalles de línea"])},
        "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
        "Line Properties": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propiedades de la línea"])},
        "Line Manufacturer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabricante de la línea"])},
        "Air Parameter Name e.g. [DosageAir]": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre del parámetro de aire p. ej. [DosageAir]"])},
        "Air Parameter Units e.g. [bar]": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unidades del parámetro de aire, p. ej. [bar]"])},
        "Pump Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de bomba"])},
        "Gun min velocity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velocidad mínima de las pistolas"])},
        "Gun max velocity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velocidad máxima de las pistolas"])},
        "Gun Layout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disposición de las pistolas"])},
        "Gun max movement range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rango de movimiento máximo de las pistolas"])},
        "Total Guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero total de pistolas"])},
        "Gun to gun distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distancia de pistola a pistola"])},
        "Total number of vertical gun stacks or columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número total de columnas de pistolas"])},
        "columns of guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Columnas de pistolas"])},
        "1st guns column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1ª columna de pistolas"])},
        "Total number of guns of the 1st vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número total de pistolas de la 1ª columna"])},
        "Gun to gun distance of the 1st vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distancia entre pistolas de la 1ª columna"])},
        "2nd guns column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2ª columna de pistolas"])},
        "Total number of guns of the 2nd vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número total de pistolas de la 2ª columna"])},
        "Gun to gun distance of the 2nd vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distancia pistola a pistola de la 2ª columna"])},
        "Horizontal distance between 1st and 2nd vertical stacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distancia horizontal entre la 1ª y la 2ª columna"])},
        "2nd vertical guns stack height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diferencia de altura de la 2ª columna de pistolas"])},
        "3rd guns column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3ª columna de pistolas"])},
        "Total number of guns of the 3rd vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número total de pistolas en la 3ª columna"])},
        "Gun to gun distance of the 3rd vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distancia entre pistolas de la 3ª columna"])},
        "Horizontal distance between 2nd and 3rd vertical stacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distancia horizontal entre la 2ª y 3ª columna"])},
        "3rd vertical guns stack height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diferencia de altura de la 3ª columna de pistolas"])},
        "4th guns column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4ª columna de pistolas"])},
        "Total number of guns of the 4th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número total de pistolas en la 4ª columna"])},
        "Gun to gun distance of the 4th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distancia entre pistolas de la 4ª columna"])},
        "Horizontal distance between 3rd and 4th vertical stacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distancia horizontal entre la 3ª y 4ª columna"])},
        "4th vertical guns stack height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diferencia de altura de la 4ª columna de pistolas"])},
        "5th guns column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5ª columna de pistolas"])},
        "Total number of guns of the 5th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número total de pistolas en la 5ª columna"])},
        "Gun to gun distance of the 5th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distancia entre pistolas de la 5ª columna"])},
        "Horizontal distance between the 4th and 5th stacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distancia horizontal entre la 4ª y 5ª columna"])},
        "5th vertical guns stack height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diferencia de la altura de la 5ª columna de pistolas"])},
        "Line Details are not editable. If you want to make changes to your line, contact us at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los detalles de la línea no son editables. Si desea realizar cambios en su línea, póngase en contacto con nosotros a"])},
        "Create Line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear línea"])},
        "Line Preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vista previa de la línea"])},
        "New Powder Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuevo modelo de polvo"])},
        "Go Back Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver a Inicio"])},
        "Select Vertical if the guns are arranged in one on top of each other in a single column. Select Horizontal if they are arranged one next to each other in a single row. Select Mixed for more complex arrangements that combine multiple vertical stacks or columns.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione Vertical si las pistolas están dispuestas una encima de otra en una sola columna. Seleccione Horizontal si están dispuestas una al lado de la otra en una sola fila. Seleccione Mixto para disposiciones más complejas que combinen varias columnas verticales."])},
        "The maximum movement range in the vertical axis that one gun can move, i.e. distance that one gun can move from its highest to its lowest position. By default is set to the gun to gun distance.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El rango máximo de movimiento en el eje vertical que una pistola se puede mover, es decir, la distancia que una pistola recorre desde su posición más alta a su posición más baja. Por defecto se establece como la distancia vertical de pistola a pistola."])},
        "A mixed hybrid line is composed of multiple vertical stacks or columns of guns. If your Line has only 1 vertical gun column, then select Vertical instead of Mixed on the Gun Layout option.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una línea mixta se compone de múltiples columnas de pistolas. Si su Línea tiene sólo 1 columna vertical de pistolas, entonces seleccione Vertical en lugar de Mixta en la opción Disposición de Pistolas."])},
        "Input the vertical distance between guns of the 1st column of guns, the column that is most to the left.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduzca la distancia vertical entre pistolas de la 1ª columna de pistolas, la columna que está más a la izquierda."])},
        "Input the vertical distance between guns of the second column of guns, the second column from the left.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduzca la distancia vertical entre las pistolas de la segunda columna de pistolas."])},
        "Input the horizontal distance measured between the first column and the second column of guns.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduzca la distancia horizontal medida entre la primera columna y la segunda columna de pistolas."])},
        "Input the vertical guns stack height offset as the difference in respect to the previous one on the left. If the gun stacks are at equal height leave the default 0 offset. A positive value means the column is higher than the previous one, and a negative means the second stack is shorter than the first one.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduzca la diferencia de altura con respecto a la anterior columna a la izquierda. Si las pilas de pistolas están a la misma altura, deje el valor 0 por defecto. Un valor positivo significa que la columna es más alta que la anterior."])},
        "Input the vertical distance between guns of the third column of guns, the third column from the left.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduzca la distancia entre pistolas de la tercera columna."])},
        "Input the horizontal distance measured between the second column and the third column of guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduzca la distancia horizontal medida entre la segunda columna y la tercera columna de pistolas."])},
        "Input the vertical distance between guns of the fourth column of guns, i.e the fourth column from the left.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduzca la distancia vertical entre las pistolas de la cuarta columna."])},
        "Input the horizontal distance measured between the third column and the fourth column of guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduzca la distancia horizontal medida entre la tercera columna y la cuarta columna."])},
        "Input the vertical distance between guns of the fifth column of guns, i.e the fifth column from the left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduzca la distancia vertical entre las pistolas de la quinta columna."])},
        "Input the horizontal distance measured between the fourth column and the fifth column of guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduzca la distancia horizontal medida entre la cuarta columna y la quinta columna."])},
        "Other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otro"])},
        "Dense Phase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dense Phase"])},
        "Injector / Venturi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Injector / Venturi"])},
        "Save Changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar cambios"])},
        "No Changes to Save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay cambios que guardar"])},
        "Wagner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wagner"])},
        "MS-Carlisle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MS-Carlisle"])},
        "GEMA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GEMA"])},
        "Nordson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nordson"])},
        "Dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dinámica"])},
        "Line Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de línea"])},
        "Select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar"])},
        "Select moving or fixed line. A fixed line has a static reciprocator that does not move, hence guns have 0 movement range and 0 velocity.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione línea móvil o estática. La línea estática tiene el reciprocador fijo, por lo que las pistolas tienen un rango de movimiento de 0 y una velocidad de 0."])},
        "guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pistolas"])},
        "Do you have a recycling powder system?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Tiene un sistema de reciclaje de polvo?"])},
        "Yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sí"])},
        "No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
        "Recycling System Efficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eficiencia del sistema de reciclaje"])},
        "Number of Columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de columnas"])},
        "Column Distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distancia entre columnas"])},
        "Column Shift": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desplazamiento de columna"])},
        "Number of Rows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de filas"])},
        "Row Distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distancia entre filas"])},
        "Row Shift": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desplazamiento de fila"])},
        "Advanced Gun Layout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diseño avanzado de pistolas"])},
        "6th guns column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6ª columna de pistolas"])},
        "7th guns column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7ª columna de pistolas"])},
        "8th guns column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8ª columna de pistolas"])},
        "9th guns column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9ª columna de pistolas"])},
        "10th guns column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10ª columna de pistolas"])},
        "Total number of guns of the 6th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número total de pistolas de la 6ª columna"])},
        "Total number of guns of the 7th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número total de pistolas de la 7ª columna"])},
        "Total number of guns of the 8th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número total de pistolas de la 8ª columna"])},
        "Total number of guns of the 9th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número total de pistolas de la 9ª columna"])},
        "Total number of guns of the 10th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número total de pistolas de la 10ª columna"])},
        "Gun to gun distance of the 6th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distancia de pistola a pistola de la 6ª columna"])},
        "Gun to gun distance of the 7th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distancia de pistola a pistola de la 7ª columna"])},
        "Gun to gun distance of the 8th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distancia de pistola a pistola de la 8ª columna"])},
        "Gun to gun distance of the 9th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distancia de pistola a pistola de la 9ª columna"])},
        "Gun to gun distance of the 10th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distancia de pistola a pistola de la 10ª columna"])},
        "Input the vertical distance between guns of the sixth column of guns, i.e the sixth column from the left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduzca la distancia vertical entre las pistolas de la 6ª columna."])},
        "Input the vertical distance between guns of the seventh column of guns, i.e the seventh column from the left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduzca la distancia vertical entre las pistolas de la 7ª columna."])},
        "Input the vertical distance between guns of the eighth column of guns, i.e the eighth column from the left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduzca la distancia vertical entre las pistolas de la 8ª columna."])},
        "Input the vertical distance between guns of the ninth column of guns, i.e the ninth column from the left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduzca la distancia vertical entre las pistolas de la 9ª columna."])},
        "Input the vertical distance between guns of the tenth column of guns, i.e the tenth column from the left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduzca la distancia vertical entre las pistolas de la 10ª columna."])},
        "Horizontal distance between the 5th and 6th stacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distancia horizontal entre la 5ª y 6ª columna"])},
        "Horizontal distance between the 6th and 7th stacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distancia horizontal entre la 6ª y 7ª columna"])},
        "Horizontal distance between the 7th and 8th stacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distancia horizontal entre la 7ª y 8ª columna"])},
        "Horizontal distance between the 8th and 9th stacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distancia horizontal entre la 8ª y 9ª columna"])},
        "Horizontal distance between the 9th and 10th stacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distancia horizontal entre la 9ª y 10ª columna"])},
        "Input the horizontal distance measured between the fifth column and the sixth column of guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduzca la distancia horizontal medida entre la quinta columna y la sexta columna de pistolas."])},
        "Input the horizontal distance measured between the sixth column and the seventh column of guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduzca la distancia horizontal medida entre la sexta columna y la séptima columna de pistolas."])},
        "Input the horizontal distance measured between the seventh column and the eighth column of guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduzca la distancia horizontal medida entre la séptima columna y la octava columna de pistolas."])},
        "Input the horizontal distance measured between the eighth column and the ninth column of guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduzca la distancia horizontal medida entre la octava columna y la novena columna de pistolas."])},
        "Input the horizontal distance measured between the ninth column and the tenth column of guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduzca la distancia horizontal medida entre la novena columna y la décima columna de pistolas."])},
        "6th vertical guns stack height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diferencia de altura de la 6ª columna de pistolas"])},
        "7th vertical guns stack height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diferencia de altura de la 7ª columna de pistolas"])},
        "8th vertical guns stack height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diferencia de altura de la 8ª columna de pistolas"])},
        "9th vertical guns stack height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diferencia de altura de la 9ª columna de pistolas"])},
        "10th vertical guns stack height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diferencia de altura de la 10ª columna de pistolas"])},
        "Switch to advanced Line configurator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar al configurador de línea avanzado"])},
        "Switch to standard Line configurator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar al configurador de línea estándar"])},
        "Horizontal layout is not allowed for Fixed Lines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El diseño horizontal no está permitido para líneas estáticas"])},
        "For Mixed Lines the number of gun columns must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para líneas mixtas, el número de columnas de pistolas debe ser menor que"])},
        "Attention!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Atención!"])},
        "Changing to standard configuration might modify your line. Do you want to continue?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar a la configuración estándar podría modificar su línea. ¿Desea continuar?"])},
        "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
        "Total Guns Column 6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Pistolas Columna 6"])},
        "Total Guns Column 7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Pistolas Columna 7"])},
        "Total Guns Column 8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Pistolas Columna 8"])},
        "Total Guns Column 9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Pistolas Columna 9"])},
        "Total Guns Column 10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Pistolas Columna 10"])},
        "Gun to Gun Distance Column 6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distancia de pistola a pistola Columna 6"])},
        "Gun to Gun Distance Column 7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distancia de pistola a pistola Columna 7"])},
        "Gun to Gun Distance Column 8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distancia de pistola a pistola Columna 8"])},
        "Gun to Gun Distance Column 9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distancia de pistola a pistola Columna 9"])},
        "Gun to Gun Distance Column 10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distancia de pistola a pistola Columna 10"])},
        "Gun Column Distance 6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distancia de columna de pistola 6"])},
        "Gun Column Distance 7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distancia de columna de pistola 7"])},
        "Gun Column Distance 8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distancia de columna de pistola 8"])},
        "Gun Column Distance 9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distancia de columna de pistola 9"])},
        "Gun Column Distance 10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distancia de columna de pistola 10"])},
        "Gun Column Vertical Offset 6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desplazamiento vertical de la columna de pistola 6"])},
        "Gun Column Vertical Offset 7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desplazamiento vertical de la columna de pistola 7"])},
        "Gun Column Vertical Offset 8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desplazamiento vertical de la columna de pistola 8"])},
        "Gun Column Vertical Offset 9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desplazamiento vertical de la columna de pistola 9"])},
        "Gun Column Vertical Offset 10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desplazamiento vertical de la columna de pistola 10"])},
        "Number of columns cannot be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El número de columnas no puede ser mayor que"])},
        "Number of rows cannot be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El número de filas no puede ser mayor que"])},
        "Row distance must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La distancia entre filas debe ser mayor que"])},
        "Row distance must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La distancia entre filas debe ser menor que"])},
        "Column distance must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La distancia entre columnas debe ser mayor que"])},
        "Column distance must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La distancia entre columnas debe ser menor que"])},
        "Column Shift must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El desplazamiento de columna debe ser mayor que"])},
        "Column Shift must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El desplazamiento de columna debe ser menor que"])},
        "Row Shift must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El desplazamiento de fila debe ser mayor que"])},
        "Row Shift must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El desplazamiento de fila debe ser menor que"])},
        "Reciprocator Min Velocity cannot be greater than or equal to the Reciprocator Max Velocity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La velocidad mínima del reciprocador no puede ser mayor o igual que la velocidad máxima del reciprocador"])},
        "The number of guns must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El número de pistolas debe ser menor que"])},
        "Lines with 1 gun per column are not allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se puedes crear líneas con 1 pistola por columna"])},
        "Advanced Gun Parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parámetros avanzados de pistolas"])},
        "The gun's maximum acceleration value is outside the permissible range. Please enter a value within the allowed limits.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El valor de aceleración máxima de la pistola está fuera del rango permitido. Por favor, introduzca un valor dentro de los límites permitidos."])},
        "New Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva Optimización"])}
      },
      "FR": {
        "Horizontal distance between two adjacent guns or stacks of guns.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance horizontale entre deux canons ou piles de canons adjacents."])},
        "Number of guns or vertical stacks of guns, next to each other.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de canons ou de piles verticales de canons les uns à côté des autres."])},
        "Height offset between two adjacent guns or stacks of guns.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décalage en hauteur entre deux canons ou piles de canons adjacents."])},
        "Number of guns on top of each other.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de canons superposés."])},
        "Vertical distance between two guns on top of each other.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance verticale entre deux canons superposés."])},
        "Horizontal distance offset between even and odd rows.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance horizontale entre les rangées paires et impaires."])},
        "Minimum gun velocity with activated reciprocator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vitesse minimale du canon avec le réciprocateur activé"])},
        "Maximum gun velocity of the reciprocator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vitesse maximale des canons avec le réciprocateur activé"])},
        "Maximum gun acceleration of the reciprocator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accélération maximale du pistolet avec le réciprocateur"])},
        "The minimum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le minimum est de"])},
        "and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["et le maximum est de"])},
        "Static Calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibration statique"])},
        "Gun Max Velocity must be greater than Gun Min Velocity by at least": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La vitesse maximale du pistolet doit être supérieure à la vitesse minimale du pistolet d'au moins"])},
        "Gun column height offsets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décalage de la hauteur de la colonne du pistolet"])},
        "Nozzle rotation angle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angle de rotation de la buse"])},
        "Total Guns per column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total des pistolets par colonne"])},
        "Fixed Mixed (non moving with multiple columns of guns)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statique Mixte (sans mouvement avec plusieurs colonnes de pistolets)"])},
        "Line changes saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifications de la ligne sauvegardées"])},
        "Line created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ligne créée"])},
        "was saved successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a été sauvegardée avec succès"])},
        "The following fields are required:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les champs suivants sont obligatoires :"])},
        "Air Parameter Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du paramètre air"])},
        "Air Parameter Units": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unités du paramètre air"])},
        "Number of Gun Columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de colonnes de pistolets"])},
        "Total Guns Column 1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total des pistolets Colonne 1"])},
        "Total Guns Column 2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total des pistolets Colonne 2"])},
        "Total Guns Column 3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre total de pistolets Colonne 3"])},
        "Total Guns Column 4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre total de pistolets Colonne 4"])},
        "Total Guns Column 5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre total de pistolets Colonne 5"])},
        "Gun to Gun Distance Column 1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance entre les pistolets Colonne 1"])},
        "Gun to Gun Distance Column 2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance entre les pistolets Colonne 2"])},
        "Gun to Gun Distance Column 3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance entre les pistolets Colonne 3"])},
        "Gun to Gun Distance Column 4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance pistolet-pistolet Colonne 4"])},
        "Gun to Gun Distance Column 5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance pistolet-pistolet Colonne 5"])},
        "Gun Column Distance 1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pistolet Colonne Distance 1"])},
        "Gun Column Distance 2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance entre le pistolet et la colonne 2"])},
        "Gun Column Distance 3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance de la colonne de pistolet 3"])},
        "Gun Column Distance 4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colonne de pistolet Distance 4"])},
        "Gun Column Distance 5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance de la colonne du pistolet 5"])},
        "Gun Column Vertical Offset 1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décalage vertical de la colonne du pistolet 1"])},
        "Gun Column Vertical Offset 2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décalage vertical de la colonne du pistolet 2"])},
        "Gun Column Vertical Offset 3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décalage vertical de la colonne du pistolet 3"])},
        "Gun Column Vertical Offset 4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décalage vertical de la colonne du pistolet 4"])},
        "Gun Column Vertical Offset 5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décalage vertical de la colonne du pistolet 5"])},
        "Input value out of range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur d'entrée hors plage"])},
        "Gun to gun distance cannot be 0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La distance pistolet-pistolet ne peut pas être égale à 0"])},
        "Gun to Gun distance must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La distance pistolet-pistolet doit être supérieure à"])},
        "Gun to Gun distance must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La distance pistolet-pistolet doit être inférieure à"])},
        "Gun max movement range cannot be 0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La distance de mouvement maximale du pistolet ne peut pas être égale à 0"])},
        "Gun max movement range must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La plage de mouvement maximale du pistolet doit être supérieure à"])},
        "Gun max movement range must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La plage de mouvement maximale du pistolet doit être inférieure à"])},
        "Gun Min Velocity cannot be 0 for Horizontal Gun Layout.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La vitesse minimale du pistolet ne peut pas être égale à 0 pour la disposition horizontale des pistolets."])},
        "Gun Min Velocity must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La vitesse minimale du pistolet doit être supérieure à"])},
        "Gun Max Velocity cannot be 0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La vitesse maximale du pistolet ne peut pas être égale à 0"])},
        "Gun Min Velocity cannot be greater than or equal to the Gun Max Velocity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La vitesse minimale du pistolet ne peut pas être supérieure ou égale à la vitesse maximale du pistolet."])},
        "Gun max acceleration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accélération maximale du pistolet"])},
        "Missing Required Fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Champs obligatoires manquants"])},
        "Vertical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertical"])},
        "Horizontal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horizontal"])},
        "Mixed (Vertical and Horizontal Hybrid)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mixte (hybride vertical et horizontal)"])},
        "Simple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simple"])},
        "Fixed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statique"])},
        "Advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avancé"])},
        "Which type of Powder Model do you want to create?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quel type de modèle de poudre voulez-vous créer ?"])},
        "Please select one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner un"])},
        "Add new Line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une nouvelle ligne"])},
        "Edit Line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier la ligne"])},
        "Line Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails de la ligne"])},
        "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
        "Line Properties": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propriétés de la ligne"])},
        "Line Manufacturer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabricant de la ligne"])},
        "Air Parameter Name e.g. [DosageAir]": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du paramètre d'air, par exemple [DosageAir]"])},
        "Air Parameter Units e.g. [bar]": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unité du paramètre air, par exemple [bar]"])},
        "Pump Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de pompe"])},
        "Gun min velocity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vitesse minimale des pistolets"])},
        "Gun max velocity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vitesse maximale des pistolets"])},
        "Gun Layout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun Layout"])},
        "Gun max movement range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun max movement range"])},
        "Total Guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre total de pistolets"])},
        "Gun to gun distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance pistolet à pistolet"])},
        "Total number of vertical gun stacks or columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre total de colonnes ou de piles verticales de pistolets"])},
        "columns of guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["colonnes de pistolets"])},
        "1st guns column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1ère colonne de pistolets"])},
        "Total number of guns of the 1st vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre total de pistolets de la première pile verticale"])},
        "Gun to gun distance of the 1st vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance pistolet à pistolet de la première pile verticale"])},
        "2nd guns column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2ème colonne de pistolets"])},
        "Total number of guns of the 2nd vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre total de pistolets de la 2ème pile verticale"])},
        "Gun to gun distance of the 2nd vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance pistolet à pistolet de la 2ème pile verticale"])},
        "Horizontal distance between 1st and 2nd vertical stacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance horizontale entre les 1ère et 2ème piles verticales"])},
        "2nd vertical guns stack height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["décalage de la hauteur de la 2e pile verticale de pistolets"])},
        "3rd guns column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3ème colonne de pistolets"])},
        "Total number of guns of the 3rd vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre total de pistolets de la 3ème pile verticale"])},
        "Gun to gun distance of the 3rd vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance pistolet à pistolet de la 3ème pile verticale"])},
        "Horizontal distance between 2nd and 3rd vertical stacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance horizontale entre les 2e et 3e piles verticales"])},
        "3rd vertical guns stack height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["décalage de la hauteur de la 3e pile verticale de pistolets"])},
        "4th guns column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4ème colonne de pistolets"])},
        "Total number of guns of the 4th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre total de pistolets de la 4ème pile verticale"])},
        "Gun to gun distance of the 4th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance pistolet à pistolet de la 4ème pile verticale"])},
        "Horizontal distance between 3rd and 4th vertical stacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance horizontale entre les 3ème et 4ème piles verticales"])},
        "4th vertical guns stack height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["décalage de la hauteur de la 4e pile verticale de pistolets"])},
        "5th guns column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5ème colonne de pistolets"])},
        "Total number of guns of the 5th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre total de pistolets de la 5ème pile verticale"])},
        "Gun to gun distance of the 5th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance pistolet à pistolet de la 5e pile verticale"])},
        "Horizontal distance between the 4th and 5th stacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance horizontale entre les 4ème et 5ème piles"])},
        "5th vertical guns stack height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décalage de la hauteur de la pile du 5e pistolet vertical"])},
        "Line Details are not editable. If you want to make changes to your line, contact us at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les détails des lignes ne sont pas modifiables. Si vous souhaitez apporter des modifications à votre ligne, contactez-nous à"])},
        "Create Line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une ligne"])},
        "Line Preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aperçu de la ligne"])},
        "New Powder Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau modèle de poudre"])},
        "Go Back Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour à la page d'accueil"])},
        "Select Vertical if the guns are arranged in one on top of each other in a single column. Select Horizontal if they are arranged one next to each other in a single row. Select Mixed for more complex arrangements that combine multiple vertical stacks or columns.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez Vertical si les pistolets sont disposés les uns au-dessus des autres dans une seule colonne. Sélectionnez Horizontal si les pistolets sont disposés les uns à côté des autres sur une seule ligne. Sélectionnez Mixte pour des dispositions plus complexes combinant plusieurs piles ou colonnes verticales."])},
        "The maximum movement range in the vertical axis that one gun can move, i.e. distance that one gun can move from its highest to its lowest position. By default is set to the gun to gun distance.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'amplitude maximale de mouvement dans l'axe vertical qu'un pistolet peut effectuer, c'est-à-dire la distance qu'un pistolet peut parcourir de sa position la plus haute à sa position la plus basse. La valeur par défaut est la distance de pistolet à pistolet."])},
        "A mixed hybrid line is composed of multiple vertical stacks or columns of guns. If your Line has only 1 vertical gun column, then select Vertical instead of Mixed on the Gun Layout option.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une ligne hybride mixte est composée de plusieurs piles ou colonnes verticales de pistolets. Si votre ligne ne comporte qu'une seule colonne verticale de pistolets, sélectionnez Vertical au lieu de Mixte dans l'option Disposition des pistolets."])},
        "Input the vertical distance between guns of the 1st column of guns, the column that is most to the left.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez la distance verticale entre les pistolets de la première colonne de pistolets, la colonne la plus à gauche."])},
        "Input the vertical distance between guns of the second column of guns, the second column from the left.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduisez la distance verticale entre les pistolets de la deuxième colonne de pistolets, la deuxième colonne à partir de la gauche."])},
        "Input the horizontal distance measured between the first column and the second column of guns.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduire la distance horizontale mesurée entre la première colonne et la deuxième colonne de pistolets."])},
        "Input the vertical guns stack height offset as the difference in respect to the previous one on the left. If the gun stacks are at equal height leave the default 0 offset. A positive value means the column is higher than the previous one, and a negative means the second stack is shorter than the first one.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduire le décalage vertical de la hauteur de la pile de pistolets comme la différence par rapport à la pile précédente sur la gauche. Si les piles de pistolets sont de même hauteur, laissez le décalage par défaut de 0. Une valeur positive signifie que la colonne est plus haute que la précédente, et une valeur négative signifie que la deuxième pile est plus courte que la première."])},
        "Input the vertical distance between guns of the third column of guns, the third column from the left.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduire la distance verticale entre les pistolets de la troisième colonne de pistolets, la troisième colonne en partant de la gauche."])},
        "Input the horizontal distance measured between the second column and the third column of guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduire la distance horizontale mesurée entre la deuxième colonne et la troisième colonne de pistolets."])},
        "Input the vertical distance between guns of the fourth column of guns, i.e the fourth column from the left.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduire la distance verticale entre les pistolets de la quatrième colonne de pistolets, c'est-à-dire la quatrième colonne à partir de la gauche."])},
        "Input the horizontal distance measured between the third column and the fourth column of guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduire la distance horizontale mesurée entre la troisième colonne et la quatrième colonne de pistolets."])},
        "Input the vertical distance between guns of the fifth column of guns, i.e the fifth column from the left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduire la distance verticale entre les pistolets de la cinquième colonne de pistolets, c'est-à-dire la cinquième colonne à partir de la gauche."])},
        "Input the horizontal distance measured between the fourth column and the fifth column of guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduire la distance horizontale mesurée entre la quatrième colonne et la cinquième colonne de pistolets."])},
        "Other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre"])},
        "Dense Phase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dense Phase"])},
        "Injector / Venturi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Injector / Venturi"])},
        "Save Changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer les modifications"])},
        "No Changes to Save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune modification à enregistrer"])},
        "Wagner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wagner"])},
        "MS-Carlisle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MS-Carlisle"])},
        "GEMA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GEMA"])},
        "Nordson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nordson"])},
        "Fixed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statique"])},
        "Dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dynamique"])},
        "Line Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de ligne"])},
        "Select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner"])},
        "Select moving or fixed line. A fixed line has a static reciprocator that does not move, hence guns have 0 movement range and 0 velocity.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez une ligne mobile ou statique. La ligne statique a le réciprocateur fixe, donc les pistolets ont une plage de mouvement de 0 et une vitesse de 0."])},
        "guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pistolets"])},
        "Do you have a recycling powder system?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avez-vous un système de recyclage de poudre ?"])},
        "Yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])},
        "No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non"])},
        "Recycling System Efficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Efficacité du système de recyclage"])},
        "Number of Columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de colonnes"])},
        "Column Distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance entre colonnes"])},
        "Column Shift": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décalage de colonne"])},
        "Number of Rows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de rangées"])},
        "Row Distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance entre rangées"])},
        "Row Shift": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décalage de rangée"])},
        "Advanced Gun Layout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disposition avancée des pistolets"])},
        "6th guns column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6ème colonne de pistolets"])},
        "7th guns column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7ème colonne de pistolets"])},
        "8th guns column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8ème colonne de pistolets"])},
        "9th guns column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9ème colonne de pistolets"])},
        "10th guns column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10ème colonne de pistolets"])},
        "Total number of guns of the 6th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre total de pistolets de la 6ème pile verticale"])},
        "Total number of guns of the 7th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre total de pistolets de la 7ème pile verticale"])},
        "Total number of guns of the 8th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre total de pistolets de la 8ème pile verticale"])},
        "Total number of guns of the 9th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre total de pistolets de la 9ème pile verticale"])},
        "Total number of guns of the 10th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre total de pistolets de la 10ème pile verticale"])},
        "Gun to gun distance of the 6th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance pistolet à pistolet de la 6ème pile verticale"])},
        "Gun to gun distance of the 7th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance pistolet à pistolet de la 7ème pile verticale"])},
        "Gun to gun distance of the 8th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance pistolet à pistolet de la 8ème pile verticale"])},
        "Gun to gun distance of the 9th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance pistolet à pistolet de la 9ème pile verticale"])},
        "Gun to gun distance of the 10th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance pistolet à pistolet de la 10ème pile verticale"])},
        "Input the vertical distance between guns of the sixth column of guns, i.e the sixth column from the left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduisez la distance verticale entre les pistolets de la sixième colonne de pistolets, c'est-à-dire la sixième colonne à partir de la gauche"])},
        "Input the vertical distance between guns of the seventh column of guns, i.e the seventh column from the left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduisez la distance verticale entre les pistolets de la septième colonne de pistolets, c'est-à-dire la septième colonne à partir de la gauche"])},
        "Input the vertical distance between guns of the eighth column of guns, i.e the eighth column from the left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduisez la distance verticale entre les pistolets de la huitième colonne de pistolets, c'est-à-dire la huitième colonne à partir de la gauche"])},
        "Input the vertical distance between guns of the ninth column of guns, i.e the ninth column from the left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduisez la distance verticale entre les pistolets de la neuvième colonne de pistolets, c'est-à-dire la neuvième colonne à partir de la gauche"])},
        "Input the vertical distance between guns of the tenth column of guns, i.e the tenth column from the left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduisez la distance verticale entre les pistolets de la dixième colonne de pistolets, c'est-à-dire la dixième colonne à partir de la gauche"])},
        "Horizontal distance between the 5th and 6th stacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance horizontale entre les 5ème et 6ème piles"])},
        "Horizontal distance between the 6th and 7th stacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance horizontale entre les 6ème et 7ème piles"])},
        "Horizontal distance between the 7th and 8th stacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance horizontale entre les 7ème et 8ème piles"])},
        "Horizontal distance between the 8th and 9th stacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance horizontale entre les 8ème et 9ème piles"])},
        "Horizontal distance between the 9th and 10th stacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance horizontale entre les 9ème et 10ème piles"])},
        "Input the horizontal distance measured between the fifth column and the sixth column of guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduisez la distance horizontale mesurée entre la cinquième colonne et la sixième colonne de pistolets"])},
        "Input the horizontal distance measured between the sixth column and the seventh column of guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduisez la distance horizontale mesurée entre la sixième colonne et la septième colonne de pistolets"])},
        "Input the horizontal distance measured between the seventh column and the eighth column of guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduisez la distance horizontale mesurée entre la septième colonne et la huitième colonne de pistolets"])},
        "Input the horizontal distance measured between the eighth column and the ninth column of guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduisez la distance horizontale mesurée entre la huitième colonne et la neuvième colonne de pistolets"])},
        "Input the horizontal distance measured between the ninth column and the tenth column of guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduisez la distance horizontale mesurée entre la neuvième colonne et la dixième colonne de pistolets"])},
        "6th vertical guns stack height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décalage de la hauteur de la 6ème pile verticale de pistolets"])},
        "7th vertical guns stack height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décalage de la hauteur de la 7ème pile verticale de pistolets"])},
        "8th vertical guns stack height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décalage de la hauteur de la 8ème pile verticale de pistolets"])},
        "9th vertical guns stack height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décalage de la hauteur de la 9ème pile verticale de pistolets"])},
        "10th vertical guns stack height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décalage de la hauteur de la 10ème pile verticale de pistolets"])},
        "Switch to advanced Line configurator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passer au configurateur de ligne avancé"])},
        "Switch to standard Line configurator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passer au configurateur de ligne standard"])},
        "Horizontal layout is not allowed for Fixed Lines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La disposition horizontale n'est pas autorisée pour les lignes statiques"])},
        "For Mixed Lines the number of gun columns must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour les lignes mixtes, le nombre de colonnes de canon doit être inférieur à"])},
        "Attention!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention!"])},
        "Changing to standard configuration might modify your line. Do you want to continue?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le passage à la configuration standard pourrait modifier votre ligne. Voulez-vous continuer ?"])},
        "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
        "Total Guns Column 6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre total de pistolets Colonne 6"])},
        "Total Guns Column 7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre total de pistolets Colonne 7"])},
        "Total Guns Column 8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre total de pistolets Colonne 8"])},
        "Total Guns Column 9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre total de pistolets Colonne 9"])},
        "Total Guns Column 10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre total de pistolets Colonne 10"])},
        "Gun to Gun Distance Column 6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance pistolet à pistolet Colonne 6"])},
        "Gun to Gun Distance Column 7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance pistolet à pistolet Colonne 7"])},
        "Gun to Gun Distance Column 8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance pistolet à pistolet Colonne 8"])},
        "Gun to Gun Distance Column 9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance pistolet à pistolet Colonne 9"])},
        "Gun to Gun Distance Column 10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance pistolet à pistolet Colonne 10"])},
        "Gun Column Distance 6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance de colonne de pistolet 6"])},
        "Gun Column Distance 7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance de colonne de pistolet 7"])},
        "Gun Column Distance 8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance de colonne de pistolet 8"])},
        "Gun Column Distance 9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance de colonne de pistolet 9"])},
        "Gun Column Distance 10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance de colonne de pistolet 10"])},
        "Gun Column Vertical Offset 6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décalage vertical de la colonne de pistolet 6"])},
        "Gun Column Vertical Offset 7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décalage vertical de la colonne de pistolet 7"])},
        "Gun Column Vertical Offset 8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décalage vertical de la colonne de pistolet 8"])},
        "Gun Column Vertical Offset 9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décalage vertical de la colonne de pistolet 9"])},
        "Gun Column Vertical Offset 10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décalage vertical de la colonne de pistolet 10"])},
        "Number of columns cannot be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nombre de colonnes ne peut pas être supérieur à"])},
        "Number of rows cannot be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nombre de rangées ne peut pas être supérieur à"])},
        "Row distance must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La distance entre les rangées doit être supérieure à"])},
        "Row distance must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La distance entre les rangées doit être inférieure à"])},
        "Column distance must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La distance entre les colonnes doit être supérieure à"])},
        "Column distance must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La distance entre les colonnes doit être inférieure à"])},
        "Column Shift must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le décalage de colonne doit être supérieur à"])},
        "Column Shift must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le décalage de colonne doit être inférieur à"])},
        "Row Shift must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le décalage de rangée doit être supérieur à"])},
        "Row Shift must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le décalage de rangée doit être inférieur à"])},
        "Reciprocator Min Velocity cannot be greater than or equal to the Reciprocator Max Velocity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La vitesse minimale du réciprocateur ne peut pas être supérieure ou égale à la vitesse maximale du réciprocateur"])},
        "The number of guns must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nombre de pistolets doit être inférieur à"])},
        "Lines with 1 gun per column are not allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les lignes avec 1 pistolet par colonne ne sont pas autorisées"])},
        "Advanced Gun Parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres avancés des pistolets"])},
        "The gun's maximum acceleration value is outside the permissible range. Please enter a value within the allowed limits.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'accélération maximale peut être comprise entre "])},
        "New Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle Optimisation"])}
      },
      "IT": {
        "Horizontal distance between two adjacent guns or stacks of guns.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distanza orizzontale tra due cannoni o pile di cannoni adiacenti."])},
        "Number of guns or vertical stacks of guns, next to each other.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di cannoni o pile verticali di cannoni, uno accanto all'altro."])},
        "Height offset between two adjacent guns or stacks of guns.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sfalsamento in altezza tra due cannoni o pile di cannoni adiacenti."])},
        "Number of guns on top of each other.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di cannoni sovrapposti."])},
        "Vertical distance between two guns on top of each other.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distanza verticale tra due cannoni sovrapposti."])},
        "Horizontal distance offset between even and odd rows.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distanza orizzontale sfalsata tra file pari e dispari."])},
        "Minimum gun velocity with activated reciprocator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velocità minima del cannone con reciprocatore attivato"])},
        "Maximum gun velocity of the reciprocator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velocità massima del cannone con reciprocatore attivato"])},
        "Maximum gun acceleration of the reciprocator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accelerazione massima del reciprocatore"])},
        "The minimum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il minimo è"])},
        "and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e la massima è"])},
        "Static Calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibrazione statica"])},
        "Gun Max Velocity must be greater than Gun Min Velocity by at least": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La velocità massima dell'arma deve essere maggiore della velocità minima dell'arma di almeno"])},
        "Gun column height offsets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disassamento dell'altezza della colonna della pistola"])},
        "Nozzle rotation angle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angolo di rotazione dell'ugello"])},
        "Total Guns per column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di pistole per colonna"])},
        "Fixed Mixed (non moving with multiple columns of guns)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mista statica (non in movimento con colonne multiple di pistole)"])},
        "Line changes saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifiche alla linea salvate"])},
        "Line created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linea creata"])},
        "was saved successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["è stata salvata con successo"])},
        "The following fields are required:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I seguenti campi sono obbligatori:"])},
        "Air Parameter Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome del parametro aria"])},
        "Air Parameter Units": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unità del parametro aria"])},
        "Number of Gun Columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di colonne di pistole"])},
        "Total Guns Column 1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale pistole colonna 1"])},
        "Total Guns Column 2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale pistole Colonna 2"])},
        "Total Guns Column 3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale pistole Colonna 3"])},
        "Total Guns Column 4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale pistole Colonna 4"])},
        "Total Guns Column 5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale pistole Colonna 5"])},
        "Gun to Gun Distance Column 1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distanza pistola-pistola Colonna 1"])},
        "Gun to Gun Distance Column 2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distanza da pistola a pistola Colonna 2"])},
        "Gun to Gun Distance Column 3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distanza da pistola a pistola Colonna 3"])},
        "Gun to Gun Distance Column 4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distanza da pistola a pistola Colonna 4"])},
        "Gun to Gun Distance Column 5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distanza da pistola a pistola Colonna 5"])},
        "Gun Column Distance 1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colonna Pistola Distanza 1"])},
        "Gun Column Distance 2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distanza colonna pistola 2"])},
        "Gun Column Distance 3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distanza colonna pistola 3"])},
        "Gun Column Distance 4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distanza colonna pistola 4"])},
        "Gun Column Distance 5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distanza colonna pistola 5"])},
        "Gun Column Vertical Offset 1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offset verticale della colonna della pistola 1"])},
        "Gun Column Vertical Offset 2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offset verticale della colonna della pistola 2"])},
        "Gun Column Vertical Offset 3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offset verticale della colonna della pistola 3"])},
        "Gun Column Vertical Offset 4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offset verticale della colonna della pistola 4"])},
        "Gun Column Vertical Offset 5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offset verticale della colonna della pistola 5"])},
        "Input value out of range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valore di ingresso fuori campo"])},
        "Gun to gun distance cannot be 0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La distanza pistola-pistola non può essere 0"])},
        "Gun to Gun distance must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La distanza pistola-pistola deve essere maggiore di"])},
        "Gun to Gun distance must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La distanza pistola-pistola deve essere inferiore a"])},
        "Gun max movement range cannot be 0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La distanza massima di movimento della pistola non può essere 0"])},
        "Gun max movement range must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'intervallo di movimento massimo della pistola deve essere maggiore di"])},
        "Gun max movement range must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'intervallo di movimento massimo della pistola deve essere inferiore a"])},
        "Gun Min Velocity cannot be 0 for Horizontal Gun Layout.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La velocità minima della pistola non può essere 0 per il layout orizzontale delle pistole."])},
        "Gun Min Velocity must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La velocità minima della pistola deve essere maggiore di"])},
        "Gun Max Velocity cannot be 0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La velocità massima della pistola non può essere 0"])},
        "Gun Min Velocity cannot be greater than or equal to the Gun Max Velocity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La velocità minima della pistola non può essere maggiore o uguale alla velocità massima della pistola."])},
        "Gun max acceleration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accelerazione massima della pistola"])},
        "Missing Required Fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campi obbligatori mancanti"])},
        "Vertical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verticale"])},
        "Horizontal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orizzontale"])},
        "Mixed (Vertical and Horizontal Hybrid)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Misto (ibrido verticale e orizzontale)"])},
        "Simple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semplice"])},
        "Fixed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statico"])},
        "Advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avanzato"])},
        "Which type of Powder Model do you want to create?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quale tipo di Modello di polvere si desidera creare?"])},
        "Please select one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selezionare uno"])},
        "Add new Line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi nuova riga"])},
        "Edit Line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica riga"])},
        "Line Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli linea"])},
        "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
        "Line Properties": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proprietà della linea"])},
        "Line Manufacturer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produttore della linea"])},
        "Air Parameter Name e.g. [DosageAir]": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome del parametro d'aria, ad esempio [DosageAir]"])},
        "Air Parameter Units e.g. [bar]": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unità del parametro d'aria, ad esempio [bar]"])},
        "Pump Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di pompa"])},
        "Gun min velocity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velocità minima pistole"])},
        "Gun max velocity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velocità massima delle pistole"])},
        "Gun Layout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disposizione pistole"])},
        "Gun max movement range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rango massimo di movimento delle pistole"])},
        "Total Guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale pistole"])},
        "Gun to gun distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distanza pistola-pistola"])},
        "Total number of vertical gun stacks or columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero totale di colonne di pistole verticali"])},
        "columns of guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["colonne di pistole"])},
        "1st guns column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1º colonna di pistole"])},
        "Total number of guns of the 1st vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero totale di pistole della 1ª colonna"])},
        "Gun to gun distance of the 1st vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distanza pistola-pistola della 1ª colonna di pistole"])},
        "2nd guns column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2º colonna di pistole"])},
        "Total number of guns of the 2nd vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero totale di pistole della 2ª colonna"])},
        "Gun to gun distance of the 2nd vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distanza pistola-pistola della 2ª colonna di pistole"])},
        "Horizontal distance between 1st and 2nd vertical stacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distanza orizzontale tra la 1ª e la 2ª colonna di pistole"])},
        "2nd vertical guns stack height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sfalsamento dell'altezza della 2ª colonna di pistole"])},
        "3rd guns column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3° colonna di pistole"])},
        "Total number of guns of the 3rd vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero totale di pistole della 3ª colonna"])},
        "Gun to gun distance of the 3rd vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distanza pistola-pistola della 3ª colonna di pistole"])},
        "Horizontal distance between 2nd and 3rd vertical stacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distanza orizzontale tra la 2ª e la 3ª colonna di pistole"])},
        "3rd vertical guns stack height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sfalsamento dell'altezza della 3º colonna di pistole"])},
        "4th guns column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4º colonna di pistole"])},
        "Total number of guns of the 4th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero totale di pistole della 4º colonna"])},
        "Gun to gun distance of the 4th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distanza pistola-pistola della 4º colonna di pistole"])},
        "Horizontal distance between 3rd and 4th vertical stacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distanza orizzontale tra la 3º e la 4º colonna di pistole"])},
        "4th vertical guns stack height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sfalsamento dell'altezza della 4º colonna di pistole"])},
        "5th guns column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5° colonna di pistole"])},
        "Total number of guns of the 5th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero totale di pistole della 5º colonna"])},
        "Gun to gun distance of the 5th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distanza pistola-pistola della 5º colonna di pistole"])},
        "Horizontal distance between the 4th and 5th stacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distanza orizzontale tra la 4º e la 5º colonna di pistole"])},
        "5th vertical guns stack height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sfalsamento dell'altezza della 5º colonna di pistole"])},
        "Line Details are not editable. If you want to make changes to your line, contact us at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I dettagli delle linee non sono modificabili. Se si desidera apportare modifiche alla linea, contattare l'azienda al seguente indirizzo"])},
        "Create Line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea linea"])},
        "Line Preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizzazione della Linea"])},
        "New Powder Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuovo Modello di Polvere"])},
        "Go Back Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Torna a Home"])},
        "Select Vertical if the guns are arranged in one on top of each other in a single column. Select Horizontal if they are arranged one next to each other in a single row. Select Mixed for more complex arrangements that combine multiple vertical stacks or columns.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selezionare Verticale se le pistole sono disposte in colonna. Selezionare Orizzontale se le pistole sono disposte in riga. Selezionare Mista per disposizioni più complesse che combinano più colonne verticali."])},
        "The maximum movement range in the vertical axis that one gun can move, i.e. distance that one gun can move from its highest to its lowest position. By default is set to the gun to gun distance.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La distanza massima di movimento sull'asse verticale che una pistola può percorrere, ossia la distanza che una pistola può percorrere dalla posizione più alta a quella più bassa. Per impostazione predefinita è impostata sulla distanza pistola-pistola."])},
        "A mixed hybrid line is composed of multiple vertical stacks or columns of guns. If your Line has only 1 vertical gun column, then select Vertical instead of Mixed on the Gun Layout option.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una linea ibrida mista è composta da più colonne verticali di pistole. Se la linea ha solo una colonna verticale di pistole, selezionare Verticale invece di Mista nell'opzione Layout pistole."])},
        "Input the vertical distance between guns of the 1st column of guns, the column that is most to the left.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immettere la distanza verticale tra le pistole della prima colonna di pistole, quella più a sinistra."])},
        "Input the vertical distance between guns of the second column of guns, the second column from the left.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserire la distanza verticale tra le pistole della seconda colonna di pistole, la seconda colonna da sinistra."])},
        "Input the horizontal distance measured between the first column and the second column of guns.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immettere la distanza orizzontale misurata tra la prima colonna e la seconda colonna di pistole."])},
        "Input the vertical guns stack height offset as the difference in respect to the previous one on the left. If the gun stacks are at equal height leave the default 0 offset. A positive value means the column is higher than the previous one, and a negative means the second stack is shorter than the first one.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immettere l'offset verticale dell'altezza della pila di pistole come differenza rispetto a quella precedente a sinistra. Se le pile di pistole hanno un'altezza uguale, lasciare l'offset predefinito a 0. Un valore positivo significa che la colonna è più alta della precedente, mentre un valore negativo significa che la seconda pila è più bassa della prima."])},
        "Input the vertical distance between guns of the third column of guns, the third column from the left.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immettere la distanza verticale tra le pistole della terza colonna di pistole, la terza colonna da sinistra."])},
        "Input the horizontal distance measured between the second column and the third column of guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immettere la distanza orizzontale misurata tra la seconda colonna e la terza colonna di pistole."])},
        "Input the vertical distance between guns of the fourth column of guns, i.e the fourth column from the left.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immettere la distanza verticale tra le pistole della quarta colonna di pistole, cioè la quarta colonna da sinistra."])},
        "Input the horizontal distance measured between the third column and the fourth column of guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immettere la distanza orizzontale misurata tra la terza colonna e la quarta colonna di pistole."])},
        "Input the vertical distance between guns of the fifth column of guns, i.e the fifth column from the left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immettere la distanza verticale tra le pistole della quinta colonna di pistole, cioè la quinta colonna da sinistra."])},
        "Input the horizontal distance measured between the fourth column and the fifth column of guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immettere la distanza orizzontale misurata tra la quarta colonna e la quinta colonna di pistole."])},
        "Other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altro"])},
        "Dense Phase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dense Phase"])},
        "Injector / Venturi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Injector / Venturi"])},
        "Save Changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva modifiche"])},
        "No Changes to Save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna modifica da salvare"])},
        "Wagner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wagner"])},
        "MS-Carlisle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MS-Carlisle"])},
        "GEMA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GEMA"])},
        "Nordson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nordson"])},
        "Fixed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estatico"])},
        "Dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dinamica"])},
        "Line Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di linea"])},
        "Select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selezionare"])},
        "Select moving or fixed line. A fixed line has a static reciprocator that does not move, hence guns have 0 movement range and 0 velocity.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selezionare una linea mobile o statica. La linea statica ha il reciprocatore fisso, quindi le pistole hanno un intervallo di movimento e una velocità pari a 0."])},
        "guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pistole"])},
        "Do you have a recycling powder system?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hai un sistema di riciclaggio della polvere?"])},
        "Yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sì"])},
        "No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
        "Recycling System Efficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Efficienza del sistema di riciclaggio"])},
        "Number of Columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di colonne"])},
        "Column Distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distanza tra colonne"])},
        "Column Shift": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spostamento di colonna"])},
        "Number of Rows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di righe"])},
        "Row Distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distanza tra le righe"])},
        "Row Shift": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spostamento di riga"])},
        "Advanced Gun Layout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Layout avanzato delle pistole"])},
        "6th guns column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6ª colonna di pistole"])},
        "7th guns column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7ª colonna di pistole"])},
        "8th guns column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8ª colonna di pistole"])},
        "9th guns column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9ª colonna di pistole"])},
        "10th guns column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10ª colonna di pistole"])},
        "Total number of guns of the 6th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero totale di pistole della 6ª pila verticale"])},
        "Total number of guns of the 7th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero totale di pistole della 7ª pila verticale"])},
        "Total number of guns of the 8th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero totale di pistole della 8ª pila verticale"])},
        "Total number of guns of the 9th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero totale di pistole della 9ª pila verticale"])},
        "Total number of guns of the 10th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero totale di pistole della 10ª pila verticale"])},
        "Gun to gun distance of the 6th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distanza pistola-pistola della 6ª pila verticale"])},
        "Gun to gun distance of the 7th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distanza pistola-pistola della 7ª pila verticale"])},
        "Gun to gun distance of the 8th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distanza pistola-pistola della 8ª pila verticale"])},
        "Gun to gun distance of the 9th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distanza pistola-pistola della 9ª pila verticale"])},
        "Gun to gun distance of the 10th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distanza pistola-pistola della 10ª pila verticale"])},
        "Input the vertical distance between guns of the sixth column of guns, i.e the sixth column from the left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immettere la distanza verticale tra le pistole della sesta colonna di pistole, cioè la sesta colonna da sinistra"])},
        "Input the vertical distance between guns of the seventh column of guns, i.e the seventh column from the left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immettere la distanza verticale tra le pistole della settima colonna di pistole, cioè la settima colonna da sinistra"])},
        "Input the vertical distance between guns of the eighth column of guns, i.e the eighth column from the left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immettere la distanza verticale tra le pistole dell'ottava colonna di pistole, cioè l'ottava colonna da sinistra"])},
        "Input the vertical distance between guns of the ninth column of guns, i.e the ninth column from the left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immettere la distanza verticale tra le pistole della nona colonna di pistole, cioè la nona colonna da sinistra"])},
        "Input the vertical distance between guns of the tenth column of guns, i.e the tenth column from the left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immettere la distanza verticale tra le pistole della decima colonna di pistole, cioè la decima colonna da sinistra"])},
        "Horizontal distance between the 5th and 6th stacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distanza orizzontale tra la 5ª e la 6ª pila"])},
        "Horizontal distance between the 6th and 7th stacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distanza orizzontale tra la 6ª e la 7ª pila"])},
        "Horizontal distance between the 7th and 8th stacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distanza orizzontale tra la 7ª e la 8ª pila"])},
        "Horizontal distance between the 8th and 9th stacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distanza orizzontale tra la 8ª e la 9ª pila"])},
        "Horizontal distance between the 9th and 10th stacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distanza orizzontale tra la 9ª e la 10ª pila"])},
        "Input the horizontal distance measured between the fifth column and the sixth column of guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immettere la distanza orizzontale misurata tra la quinta colonna e la sesta colonna di pistole"])},
        "Input the horizontal distance measured between the sixth column and the seventh column of guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immettere la distanza orizzontale misurata tra la sesta colonna e la settima colonna di pistole"])},
        "Input the horizontal distance measured between the seventh column and the eighth column of guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immettere la distanza orizzontale misurata tra la settima colonna e l'ottava colonna di pistole"])},
        "Input the horizontal distance measured between the eighth column and the ninth column of guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immettere la distanza orizzontale misurata tra l'ottava colonna e la nona colonna di pistole"])},
        "Input the horizontal distance measured between the ninth column and the tenth column of guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immettere la distanza orizzontale misurata tra la nona colonna e la decima colonna di pistole"])},
        "6th vertical guns stack height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sfalsamento dell'altezza della 6ª pila verticale di pistole"])},
        "7th vertical guns stack height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sfalsamento dell'altezza della 7ª pila verticale di pistole"])},
        "8th vertical guns stack height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sfalsamento dell'altezza della 8ª pila verticale di pistole"])},
        "9th vertical guns stack height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sfalsamento dell'altezza della 9ª pila verticale di pistole"])},
        "10th vertical guns stack height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sfalsamento dell'altezza della 10ª pila verticale di pistole"])},
        "Switch to advanced Line configurator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passa al configuratore di linea avanzato"])},
        "Switch to standard Line configurator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passa al configuratore di linea standard"])},
        "Horizontal layout is not allowed for Fixed Lines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il layout orizzontale non è consentito per le linee statiche"])},
        "For Mixed Lines the number of gun columns must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per le Linee miste il numero di colonne di cannoni deve essere inferiore a"])},
        "Attention!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attenzione!"])},
        "Changing to standard configuration might modify your line. Do you want to continue?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il passaggio alla configurazione standard potrebbe modificare la tua linea. Vuoi continuare?"])},
        "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla"])},
        "Total Guns Column 6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale pistole Colonna 6"])},
        "Total Guns Column 7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale pistole Colonna 7"])},
        "Total Guns Column 8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale pistole Colonna 8"])},
        "Total Guns Column 9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale pistole Colonna 9"])},
        "Total Guns Column 10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale pistole Colonna 10"])},
        "Gun to Gun Distance Column 6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distanza pistola-pistola Colonna 6"])},
        "Gun to Gun Distance Column 7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distanza pistola-pistola Colonna 7"])},
        "Gun to Gun Distance Column 8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distanza pistola-pistola Colonna 8"])},
        "Gun to Gun Distance Column 9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distanza pistola-pistola Colonna 9"])},
        "Gun to Gun Distance Column 10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distanza pistola-pistola Colonna 10"])},
        "Gun Column Distance 6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distanza colonna pistola 6"])},
        "Gun Column Distance 7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distanza colonna pistola 7"])},
        "Gun Column Distance 8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distanza colonna pistola 8"])},
        "Gun Column Distance 9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distanza colonna pistola 9"])},
        "Gun Column Distance 10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distanza colonna pistola 10"])},
        "Gun Column Vertical Offset 6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offset verticale della colonna della pistola 6"])},
        "Gun Column Vertical Offset 7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offset verticale della colonna della pistola 7"])},
        "Gun Column Vertical Offset 8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offset verticale della colonna della pistola 8"])},
        "Gun Column Vertical Offset 9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offset verticale della colonna della pistola 9"])},
        "Gun Column Vertical Offset 10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offset verticale della colonna della pistola 10"])},
        "Number of columns cannot be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il numero di colonne non può essere maggiore di"])},
        "Number of rows cannot be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il numero di righe non può essere maggiore di"])},
        "Row distance must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La distanza tra le righe deve essere maggiore di"])},
        "Row distance must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La distanza tra le righe deve essere inferiore a"])},
        "Column distance must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La distanza tra le colonne deve essere maggiore di"])},
        "Column distance must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La distanza tra le colonne deve essere inferiore a"])},
        "Column Shift must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lo spostamento di colonna deve essere maggiore di"])},
        "Column Shift must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lo spostamento di colonna deve essere inferiore a"])},
        "Row Shift must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lo spostamento di riga deve essere maggiore di"])},
        "Row Shift must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lo spostamento di riga deve essere inferiore a"])},
        "Reciprocator Min Velocity cannot be greater than or equal to the Reciprocator Max Velocity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La velocità minima del reciprocatore non può essere maggiore o uguale alla velocità massima del reciprocatore"])},
        "The number of guns must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il numero di pistole deve essere inferiore a"])},
        "Lines with 1 gun per column are not allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non sono ammesse righe con 1 pistola per colonna."])},
        "Advanced Gun Parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametri avanzati delle pistole"])},
        "The gun's maximum acceleration value is outside the permissible range. Please enter a value within the allowed limits.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il valore massimo di accelerazione della pistola è al di fuori del range consentito. Inserire un valore entro i limiti consentiti."])},
        "New Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuova ottimizzazione"])}
      },
      "PL": {
        "Horizontal distance between two adjacent guns or stacks of guns.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pozioma odległość między dwoma sąsiadującymi działami lub stosami dział."])},
        "Number of guns or vertical stacks of guns, next to each other.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba dział lub pionowych stosów dział znajdujących się obok siebie."])},
        "Height offset between two adjacent guns or stacks of guns.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przesunięcie wysokości między dwoma sąsiednimi działami lub stosami dział."])},
        "Number of guns on top of each other.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba dział ustawionych jedno na drugim."])},
        "Vertical distance between two guns on top of each other.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pionowa odległość między dwoma działami na sobie."])},
        "Horizontal distance offset between even and odd rows.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przesunięcie w poziomie między rzędami parzystymi i nieparzystymi."])},
        "Minimum gun velocity with activated reciprocator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimalna prędkość pistoletu z aktywnym przeciwsobnikiem"])},
        "Maximum gun velocity of the reciprocator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksymalna prędkość działa z przeciwsobnikiem"])},
        "Maximum gun acceleration of the reciprocator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksymalne przyspieszenie działa przeciwsobnego"])},
        "The minimum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum wynosi"])},
        "and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a maksymalne wynosi"])},
        "Static Calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibracja statyczna"])},
        "Gun Max Velocity must be greater than Gun Min Velocity by at least": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksymalna prędkość pistoletu musi być większa od minimalnej prędkości pistoletu o co najmniej"])},
        "Gun column height offsets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przesunięcie wysokości kolumny pistoletu"])},
        "Nozzle rotation angle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kąt obrotu dyszy"])},
        "Total Guns per column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba pistoletów na kolumnę"])},
        "Fixed Mixed (non moving with multiple columns of guns)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statyczny mieszany (nieporuszający się z wieloma kolumnami pistoletów)"])},
        "Line changes saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisane zmiany linii"])},
        "Line created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utworzona linia"])},
        "was saved successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["została pomyślnie zapisana"])},
        "The following fields are required:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wymagane są następujące pola:"])},
        "Air Parameter Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa parametru powietrza"])},
        "Air Parameter Units": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jednostki parametru powietrza"])},
        "Number of Gun Columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba kolumn pistoletów"])},
        "Total Guns Column 1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Całkowita liczba pistoletów Kolumna 1"])},
        "Total Guns Column 2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Całkowita liczba pistoletów Kolumna 2"])},
        "Total Guns Column 3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Całkowita liczba pistoletów Kolumna 3"])},
        "Total Guns Column 4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Całkowita liczba pistoletów Kolumna 4"])},
        "Total Guns Column 5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Całkowita liczba pistoletów Kolumna 5"])},
        "Gun to Gun Distance Column 1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odległość od pistoletu do pistoletu Kolumna 1"])},
        "Gun to Gun Distance Column 2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odległość od pistoletu do pistoletu Kolumna 2"])},
        "Gun to Gun Distance Column 3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odległość od pistoletu do pistoletu Kolumna 3"])},
        "Gun to Gun Distance Column 4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odległość od pistoletu do pistoletu Kolumna 4"])},
        "Gun to Gun Distance Column 5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odległość od pistoletu do pistoletu Kolumna 5"])},
        "Gun Column Distance 1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odległość między kolumnami pistoletów 1"])},
        "Gun Column Distance 2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odległość kolumny pistoletów 2"])},
        "Gun Column Distance 3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odległość kolumny pistoletów 3"])},
        "Gun Column Distance 4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odległość kolumny pistoletów 4"])},
        "Gun Column Distance 5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odległość kolumny pistoletu 5"])},
        "Gun Column Vertical Offset 1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przesunięcie pionowe kolumny pistoletu 1"])},
        "Gun Column Vertical Offset 2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przesunięcie pionowe kolumny pistoletu 2"])},
        "Gun Column Vertical Offset 3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przesunięcie pionowe kolumny pistoletu 3"])},
        "Gun Column Vertical Offset 4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przesunięcie pionowe kolumny pistoletu 4"])},
        "Gun Column Vertical Offset 5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przesunięcie pionowe kolumny pistoletu 5"])},
        "Input value out of range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wartość wejściowa poza zakresem"])},
        "Gun to gun distance cannot be 0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odległość między pistoletami nie może wynosić 0"])},
        "Gun to Gun distance must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odległość od pistoletu do pistoletu musi być większa niż"])},
        "Gun to Gun distance must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odległość między pistoletem a pistoletem musi być mniejsza niż"])},
        "Gun max movement range cannot be 0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksymalny zakres ruchu pistoletu nie może wynosić 0"])},
        "Gun max movement range must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksymalny zakres ruchu pistoletu musi być większy niż"])},
        "Gun max movement range must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksymalny zakres ruchu pistoletu musi być mniejszy niż"])},
        "Gun Min Velocity cannot be 0 for Horizontal Gun Layout.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimalna prędkość pistoletu nie może wynosić 0 dla poziomego układu pistoletów."])},
        "Gun Min Velocity must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimalna prędkość pistoletu musi być większa niż"])},
        "Gun Max Velocity cannot be 0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksymalna prędkość pistoletu nie może wynosić 0"])},
        "Gun Min Velocity cannot be greater than or equal to the Gun Max Velocity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimalna prędkość pistoletu nie może być większa lub równa maksymalnej prędkości pistoletu."])},
        "Gun max acceleration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksymalne przyspieszenie pistoletu"])},
        "Missing Required Fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brakujące wymagane pola"])},
        "Vertical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pionowo"])},
        "Horizontal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poziomy"])},
        "Mixed (Vertical and Horizontal Hybrid)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mieszany (hybryda pionowa i pozioma)"])},
        "Simple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prosty"])},
        "Fixed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statyczny"])},
        "Advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaawansowany"])},
        "Which type of Powder Model do you want to create?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Który typ modelu proszku chcesz utworzyć?"])},
        "Please select one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz jedną"])},
        "Add new Line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj nową linię"])},
        "Edit Line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edytuj linię"])},
        "Line Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szczegóły linii"])},
        "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa"])},
        "Line Properties": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Właściwości linii"])},
        "Line Manufacturer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Producent linii"])},
        "Air Parameter Name e.g. [DosageAir]": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa parametru powietrza np. [DosageAir]"])},
        "Air Parameter Units e.g. [bar]": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jednostki parametrów powietrza, np. [bar]"])},
        "Pump Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ pompy"])},
        "Gun min velocity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimalna prędkość pistoletów"])},
        "Gun max velocity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksymalna prędkość pistoletów"])},
        "Gun Layout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Układ pistoletów"])},
        "Gun max movement range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksymalny zasięg ruchu pistoletów"])},
        "Total Guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Łączna liczba pistoletów"])},
        "Gun to gun distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odległość między pistoletami"])},
        "Total number of vertical gun stacks or columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Łączna liczba pionowych stosów lub kolumn pistoletów"])},
        "columns of guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kolumny pistoletów"])},
        "1st guns column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. kolumna pistoletów"])},
        "Total number of guns of the 1st vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Łączna liczba pistoletów w 1. pionowym stosie"])},
        "Gun to gun distance of the 1st vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odległość między pistoletami w 1. pionowym stosie"])},
        "2nd guns column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. kolumna pistoletów"])},
        "Total number of guns of the 2nd vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Całkowita liczba pistoletów w 2. pionowym stosie"])},
        "Gun to gun distance of the 2nd vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odległość między pistoletami w 2. pionowym stosie"])},
        "Horizontal distance between 1st and 2nd vertical stacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odległość pozioma między 1. i 2. stosem pionowym"])},
        "2nd vertical guns stack height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przesunięcie wysokości 2. pionowego stosu pistoletów"])},
        "3rd guns column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. kolumna pistoletów"])},
        "Total number of guns of the 3rd vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Całkowita liczba pistoletów w 3. pionowym stosie"])},
        "Gun to gun distance of the 3rd vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odległość między pistoletami w 3. pionowym stosie"])},
        "Horizontal distance between 2nd and 3rd vertical stacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pozioma odległość między 2. i 3. pionowym stosem"])},
        "3rd vertical guns stack height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przesunięcie wysokości 3. pionowego stosu pistoletów"])},
        "4th guns column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4. kolumna pistoletów"])},
        "Total number of guns of the 4th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Całkowita liczba pistoletów w 4. pionowym stosie"])},
        "Gun to gun distance of the 4th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odległość między pistoletami w 4. pionowym stosie"])},
        "Horizontal distance between 3rd and 4th vertical stacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pozioma odległość między 3. i 4. pionowym stosem"])},
        "4th vertical guns stack height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przesunięcie wysokości 4. pionowego stosu pistoletów"])},
        "5th guns column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5. kolumna pistoletów"])},
        "Total number of guns of the 5th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Całkowita liczba pistoletów w 5. pionowym stosie"])},
        "Gun to gun distance of the 5th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odległość między pistoletami w 5. pionowym stosie"])},
        "Horizontal distance between the 4th and 5th stacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pozioma odległość między 4. i 5. stosem"])},
        "5th vertical guns stack height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przesunięcie wysokości piątego pionowego stosu pistoletów"])},
        "Line Details are not editable. If you want to make changes to your line, contact us at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szczegółów linii nie można edytować. Jeśli chcesz wprowadzić zmiany w linii, skontaktuj się z nami pod adresem"])},
        "Create Line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz linię"])},
        "Line Preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podgląd linii"])},
        "New Powder Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nowy model proszku"])},
        "Go Back Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wróć do domu"])},
        "Select Vertical if the guns are arranged in one on top of each other in a single column. Select Horizontal if they are arranged one next to each other in a single row. Select Mixed for more complex arrangements that combine multiple vertical stacks or columns.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz opcję Pionowo, jeśli pistolety są ułożone jeden na drugim w jednej kolumnie. Wybierz Poziomo, jeśli pistolety są ułożone jeden obok drugiego w jednym rzędzie. Wybierz opcję Mieszane dla bardziej złożonych układów, które łączą wiele pionowych stosów lub kolumn."])},
        "The maximum movement range in the vertical axis that one gun can move, i.e. distance that one gun can move from its highest to its lowest position. By default is set to the gun to gun distance.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksymalny zakres ruchu w osi pionowej, jaki może wykonać jeden pistolet, tj. odległość, na jaką jeden pistolet może przesunąć się z najwyższej do najniższej pozycji. Domyślnie jest to odległość między pistoletami."])},
        "A mixed hybrid line is composed of multiple vertical stacks or columns of guns. If your Line has only 1 vertical gun column, then select Vertical instead of Mixed on the Gun Layout option.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mieszana linia hybrydowa składa się z wielu pionowych stosów lub kolumn pistoletów. Jeśli linia ma tylko 1 pionową kolumnę pistoletów, wybierz opcję Pionowy zamiast Mieszany w opcji Układ pistoletów."])},
        "Input the vertical distance between guns of the 1st column of guns, the column that is most to the left.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadź pionową odległość między pistoletami w 1. kolumnie pistoletów, kolumnie znajdującej się najbardziej po lewej stronie."])},
        "Input the vertical distance between guns of the second column of guns, the second column from the left.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadź odległość w pionie między pistoletami w drugiej kolumnie pistoletów, drugiej kolumnie od lewej."])},
        "Input the horizontal distance measured between the first column and the second column of guns.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadź odległość w poziomie między pierwszą a drugą kolumną pistoletów."])},
        "Input the vertical guns stack height offset as the difference in respect to the previous one on the left. If the gun stacks are at equal height leave the default 0 offset. A positive value means the column is higher than the previous one, and a negative means the second stack is shorter than the first one.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadź przesunięcie wysokości stosu pistoletów w pionie jako różnicę w stosunku do poprzedniego stosu po lewej stronie. Jeśli stosy pistoletów znajdują się na równej wysokości, pozostaw domyślne przesunięcie 0. Wartość dodatnia oznacza, że kolumna jest wyższa niż poprzednia, a wartość ujemna oznacza, że drugi stos jest krótszy niż pierwszy."])},
        "Input the vertical distance between guns of the third column of guns, the third column from the left.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadź pionową odległość między pistoletami w trzeciej kolumnie pistoletów, trzeciej kolumnie od lewej."])},
        "Input the horizontal distance measured between the second column and the third column of guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadź odległość w poziomie między drugą a trzecią kolumną pistoletów."])},
        "Input the vertical distance between guns of the fourth column of guns, i.e the fourth column from the left.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadź odległość w pionie między pistoletami w czwartej kolumnie pistoletów, tj. w czwartej kolumnie od lewej."])},
        "Input the horizontal distance measured between the third column and the fourth column of guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadź odległość w poziomie zmierzoną między trzecią a czwartą kolumną pistoletów."])},
        "Input the vertical distance between guns of the fifth column of guns, i.e the fifth column from the left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadź pionową odległość między pistoletami w piątej kolumnie pistoletów, tj. w piątej kolumnie od lewej."])},
        "Input the horizontal distance measured between the fourth column and the fifth column of guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadź odległość poziomą zmierzoną między czwartą kolumną a piątą kolumną pistoletów."])},
        "Other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inne"])},
        "Dense Phase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dense Phase"])},
        "Injector / Venturi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Injector / Venturi"])},
        "Save Changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz zmiany"])},
        "No Changes to Save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak zmian do zapisania"])},
        "Wagner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wagner"])},
        "MS-Carlisle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MS-Carlisle"])},
        "GEMA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GEMA"])},
        "Nordson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nordson"])},
        "Fixed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statyczny"])},
        "Dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dynamiczny"])},
        "Line Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ linii"])},
        "Select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz"])},
        "Select moving or fixed line. A fixed line has a static reciprocator that does not move, hence guns have 0 movement range and 0 velocity.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz linię ruchomą lub statyczną. Linia statyczna ma nieruchomy reciprocator, dlatego pistolety mają zakres ruchu 0 i prędkość 0."])},
        "guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pistolety"])},
        "Do you have a recycling powder system?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czy masz system recyklingu proszku?"])},
        "Yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tak"])},
        "No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie"])},
        "Recycling System Efficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wydajność systemu recyklingu"])},
        "Number of Columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba kolumn"])},
        "Column Distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odległość między kolumnami"])},
        "Column Shift": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przesunięcie kolumny"])},
        "Number of Rows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba wierszy"])},
        "Row Distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odległość między wierszami"])},
        "Row Shift": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przesunięcie wiersza"])},
        "Advanced Gun Layout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaawansowany układ pistoletów"])},
        "6th guns column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6. kolumna pistoletów"])},
        "7th guns column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7. kolumna pistoletów"])},
        "8th guns column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8. kolumna pistoletów"])},
        "9th guns column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9. kolumna pistoletów"])},
        "10th guns column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10. kolumna pistoletów"])},
        "Total number of guns of the 6th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Całkowita liczba pistoletów 6. pionowego stosu"])},
        "Total number of guns of the 7th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Całkowita liczba pistoletów 7. pionowego stosu"])},
        "Total number of guns of the 8th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Całkowita liczba pistoletów 8. pionowego stosu"])},
        "Total number of guns of the 9th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Całkowita liczba pistoletów 9. pionowego stosu"])},
        "Total number of guns of the 10th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Całkowita liczba pistoletów 10. pionowego stosu"])},
        "Gun to gun distance of the 6th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odległość między pistoletami 6. pionowego stosu"])},
        "Gun to gun distance of the 7th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odległość między pistoletami 7. pionowego stosu"])},
        "Gun to gun distance of the 8th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odległość między pistoletami 8. pionowego stosu"])},
        "Gun to gun distance of the 9th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odległość między pistoletami 9. pionowego stosu"])},
        "Gun to gun distance of the 10th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odległość między pistoletami 10. pionowego stosu"])},
        "Input the vertical distance between guns of the sixth column of guns, i.e the sixth column from the left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadź pionową odległość między pistoletami w szóstej kolumnie pistoletów, tj. w szóstej kolumnie od lewej"])},
        "Input the vertical distance between guns of the seventh column of guns, i.e the seventh column from the left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadź pionową odległość między pistoletami w siódmej kolumnie pistoletów, tj. w siódmej kolumnie od lewej"])},
        "Input the vertical distance between guns of the eighth column of guns, i.e the eighth column from the left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadź pionową odległość między pistoletami w ósmej kolumnie pistoletów, tj. w ósmej kolumnie od lewej"])},
        "Input the vertical distance between guns of the ninth column of guns, i.e the ninth column from the left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadź pionową odległość między pistoletami w dziewiątej kolumnie pistoletów, tj. w dziewiątej kolumnie od lewej"])},
        "Input the vertical distance between guns of the tenth column of guns, i.e the tenth column from the left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadź pionową odległość między pistoletami w dziesiątej kolumnie pistoletów, tj. w dziesiątej kolumnie od lewej"])},
        "Horizontal distance between the 5th and 6th stacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odległość pozioma między 5. a 6. stosami"])},
        "Horizontal distance between the 6th and 7th stacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odległość pozioma między 6. a 7. stosami"])},
        "Horizontal distance between the 7th and 8th stacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odległość pozioma między 7. a 8. stosami"])},
        "Horizontal distance between the 8th and 9th stacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odległość pozioma między 8. a 9. stosami"])},
        "Horizontal distance between the 9th and 10th stacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odległość pozioma między 9. a 10. stosami"])},
        "Input the horizontal distance measured between the fifth column and the sixth column of guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadź odległość poziomą zmierzoną między piątą a szóstą kolumną pistoletów"])},
        "Input the horizontal distance measured between the sixth column and the seventh column of guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadź odległość poziomą zmierzoną między szóstą a siódmą kolumną pistoletów"])},
        "Input the horizontal distance measured between the seventh column and the eighth column of guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadź odległość poziomą zmierzoną między siódmą a ósmą kolumną pistoletów"])},
        "Input the horizontal distance measured between the eighth column and the ninth column of guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadź odległość poziomą zmierzoną między ósmą a dziewiątą kolumną pistoletów"])},
        "Input the horizontal distance measured between the ninth column and the tenth column of guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadź odległość poziomą zmierzoną między dziewiątą a dziesiątą kolumną pistoletów"])},
        "6th vertical guns stack height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przesunięcie wysokości 6. pionowego stosu pistoletów"])},
        "7th vertical guns stack height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przesunięcie wysokości 7. pionowego stosu pistoletów"])},
        "8th vertical guns stack height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przesunięcie wysokości 8. pionowego stosu pistoletów"])},
        "9th vertical guns stack height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przesunięcie wysokości 9. pionowego stosu pistoletów"])},
        "10th vertical guns stack height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przesunięcie wysokości 10. pionowego stosu pistoletów"])},
        "Switch to advanced Line configurator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przełącz się na zaawansowany konfigurator linii"])},
        "Switch to standard Line configurator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przełącz się na standardowy konfigurator linii"])},
        "Horizontal layout is not allowed for Fixed Lines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Układ poziomy nie jest dozwolony dla linii statycznych"])},
        "For Mixed Lines the number of gun columns must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W przypadku linii mieszanych liczba kolumn pistoletu musi być mniejsza niż"])},
        "Attention!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uwaga!"])},
        "Changing to standard configuration might modify your line. Do you want to continue?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmiana na standardową konfigurację może zmodyfikować Twoją linię. Czy chcesz kontynuować?"])},
        "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anuluj"])},
        "Total Guns Column 6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Całkowita liczba pistoletów Kolumna 6"])},
        "Total Guns Column 7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Całkowita liczba pistoletów Kolumna 7"])},
        "Total Guns Column 8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Całkowita liczba pistoletów Kolumna 8"])},
        "Total Guns Column 9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Całkowita liczba pistoletów Kolumna 9"])},
        "Total Guns Column 10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Całkowita liczba pistoletów Kolumna 10"])},
        "Gun to Gun Distance Column 6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odległość od pistoletu do pistoletu Kolumna 6"])},
        "Gun to Gun Distance Column 7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odległość od pistoletu do pistoletu Kolumna 7"])},
        "Gun to Gun Distance Column 8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odległość od pistoletu do pistoletu Kolumna 8"])},
        "Gun to Gun Distance Column 9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odległość od pistoletu do pistoletu Kolumna 9"])},
        "Gun to Gun Distance Column 10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odległość od pistoletu do pistoletu Kolumna 10"])},
        "Gun Column Distance 6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odległość między kolumnami pistoletów 6"])},
        "Gun Column Distance 7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odległość między kolumnami pistoletów 7"])},
        "Gun Column Distance 8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odległość między kolumnami pistoletów 8"])},
        "Gun Column Distance 9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odległość między kolumnami pistoletów 9"])},
        "Gun Column Distance 10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odległość między kolumnami pistoletów 10"])},
        "Gun Column Vertical Offset 6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przesunięcie pionowe kolumny pistoletu 6"])},
        "Gun Column Vertical Offset 7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przesunięcie pionowe kolumny pistoletu 7"])},
        "Gun Column Vertical Offset 8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przesunięcie pionowe kolumny pistoletu 8"])},
        "Gun Column Vertical Offset 9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przesunięcie pionowe kolumny pistoletu 9"])},
        "Gun Column Vertical Offset 10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przesunięcie pionowe kolumny pistoletu 10"])},
        "Number of columns cannot be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba kolumn nie może być większa niż"])},
        "Number of rows cannot be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba wierszy nie może być większa niż"])},
        "Row distance must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odległość między wierszami musi być większa niż"])},
        "Row distance must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odległość między wierszami musi być mniejsza niż"])},
        "Column distance must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odległość między kolumnami musi być większa niż"])},
        "Column distance must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odległość między kolumnami musi być mniejsza niż"])},
        "Column Shift must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przesunięcie kolumny musi być większe niż"])},
        "Column Shift must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prezunięcie kolumny musi być mniejsze niż"])},
        "Row Shift must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przesunięcie wiersza musi być większe niż"])},
        "Row Shift must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przesunięcie wiersza musi być mniejsze niż"])},
        "Reciprocator Min Velocity cannot be greater than or equal to the Reciprocator Max Velocity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimalna prędkość reciprokatorka nie może być większa lub równa maksymalnej prędkości reciprokatorka"])},
        "The number of guns must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba pistoletów musi być mniejsza niż"])},
        "Lines with 1 gun per column are not allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linie z 1 pistoletem na kolumnę nie są dozwolone"])},
        "Advanced Gun Parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaawansowane parametry pistoletów"])},
        "The gun's maximum acceleration value is outside the permissible range. Please enter a value within the allowed limits.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksymalna wartość przyspieszenia pistoletu jest poza dopuszczalnym zakresem. Proszę wprowadzić wartość w granicach dopuszczalnych."])},
        "New Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nowa optymalizacja"])}
      },
      "TR": {
        "Horizontal distance between two adjacent guns or stacks of guns.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İki bitişik silah veya silah yığını arasındaki yatay mesafe."])},
        "Number of guns or vertical stacks of guns, next to each other.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yan yana bulunan silahların veya dikey silah yığınlarının sayısı."])},
        "Height offset between two adjacent guns or stacks of guns.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İki bitişik silah veya silah yığını arasındaki yükseklik mesafesi."])},
        "Number of guns on top of each other.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birbirinin üstündeki silah sayısı."])},
        "Vertical distance between two guns on top of each other.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üst üste iki silah arasındaki dikey mesafe."])},
        "Horizontal distance offset between even and odd rows.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çift ve tek sıralar arasında yatay mesafe ofseti."])},
        "Minimum gun velocity with activated reciprocator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif reciprocator ile minimum tabanca hızı"])},
        "Maximum gun velocity of the reciprocator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resiprokatörün maksimum tabanca hızı"])},
        "Maximum gun acceleration of the reciprocator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resiprokatörün maksimum tabanca ivmesi"])},
        "The minimum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum değer"])},
        "and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ve maksimum değer"])},
        "Static Calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statik Kalibrasyon"])},
        "Gun Max Velocity must be greater than Gun Min Velocity by at least": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabanca Maksimum Hızı, Tabanca Minimum Hızından en az şu kadar büyük olmalıdır"])},
        "Gun column height offsets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabanca kolon yüksekliği ofsetleri"])},
        "Nozzle rotation angle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nozul dönüş açısı"])},
        "Total Guns per column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sütun başına Tabanca Sayısı"])},
        "Fixed Mixed (non moving with multiple columns of guns)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statik Karma (birden fazla tabanca sütunu ile hareket etmeyen)"])},
        "Line changes saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hat değişiklikleri kaydedildi"])},
        "Line created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oluşturulan hat"])},
        "was saved successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hat başarıyla kaydedildi"])},
        "The following fields are required:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aşağıdaki alanlar gereklidir:"])},
        "Air Parameter Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hava Parametresi Adı"])},
        "Air Parameter Units": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hava Parametresi Birimleri"])},
        "Number of Gun Columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabanca Kolon Sayısı"])},
        "Total Guns Column 1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toplam Tabanca Sütun 1"])},
        "Total Guns Column 2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toplam Tabanca Sütun 2"])},
        "Total Guns Column 3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toplam Tabanca Sütun 3"])},
        "Total Guns Column 4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toplam Tabanca Sütun 4"])},
        "Total Guns Column 5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toplam Tabanca Sütun 5"])},
        "Gun to Gun Distance Column 1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabancadan Tabancaya Mesafe Sütun 1"])},
        "Gun to Gun Distance Column 2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabancadan Tabancaya Mesafe Sütun 2"])},
        "Gun to Gun Distance Column 3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabancadan Tabancaya Mesafe Sütun 3"])},
        "Gun to Gun Distance Column 4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabancadan Tabancaya Mesafe Sütun 4"])},
        "Gun to Gun Distance Column 5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabancadan Tabancaya Mesafe Sütun 5"])},
        "Gun Column Distance 1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabanca Kolon Mesafesi 1"])},
        "Gun Column Distance 2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabanca Kolon Mesafesi 2"])},
        "Gun Column Distance 3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabanca Kolon Mesafesi 3"])},
        "Gun Column Distance 4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabanca Kolon Mesafesi 4"])},
        "Gun Column Distance 5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabanca Kolon Mesafesi 5"])},
        "Gun Column Vertical Offset 1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabanca Kolonu Dikey Ofset 1"])},
        "Gun Column Vertical Offset 2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabanca Kolonu Dikey Ofset 2"])},
        "Gun Column Vertical Offset 3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabanca Kolonu Dikey Ofset 3"])},
        "Gun Column Vertical Offset 4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabanca Kolonu Dikey Ofset 4"])},
        "Gun Column Vertical Offset 5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabanca Kolonu Dikey Ofset 5"])},
        "Input value out of range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giriş değeri aralık dışında"])},
        "Gun to gun distance cannot be 0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabancadan tabancaya mesafe 0 olamaz"])},
        "Gun to Gun distance must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabanca-Tabanca mesafesi aşağıdakilerden daha büyük olmalıdır"])},
        "Gun to Gun distance must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabanca-Tabanca mesafesi aşağıdakilerden daha küçük olmalıdır"])},
        "Gun max movement range cannot be 0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabanca maksimum hareket aralığı 0 olamaz"])},
        "Gun max movement range must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabanca maksimum hareket menzili aşağıdakilerden daha büyük olmalıdır"])},
        "Gun max movement range must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabanca maksimum hareket menzili aşağıdakilerden daha küçük olmalıdır"])},
        "Gun Min Velocity cannot be 0 for Horizontal Gun Layout.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yatay Tabanca Düzeni için Tabanca Min Hızı 0 olamaz."])},
        "Gun Min Velocity must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabanca Min Hızı aşağıdakilerden büyük olmalıdır"])},
        "Gun Max Velocity cannot be 0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabanca Maksimum Hızı 0 olamaz"])},
        "Gun Min Velocity cannot be greater than or equal to the Gun Max Velocity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabanca Minimum Hızı, Tabanca Maksimum Hızından büyük veya eşit olamaz"])},
        "Gun max acceleration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabanca maksimum ivme"])},
        "Missing Required Fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eksik Gerekli Alanlar"])},
        "Vertical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dikey"])},
        "Horizontal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yatay"])},
        "Mixed (Vertical and Horizontal Hybrid)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karma (Dikey ve Yatay Hibrit)"])},
        "Simple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basit"])},
        "Fixed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statik"])},
        "Advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelişmiş"])},
        "Which type of Powder Model do you want to create?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne tür bir Toz Modeli oluşturmak istiyorsunuz?"])},
        "Please select one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen birini seçin"])},
        "Add new Line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Satır Ekle"])},
        "Edit Line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hattı Düzenle"])},
        "Line Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hat Detayları"])},
        "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İsim"])},
        "Line Properties": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hat Özellikleri"])},
        "Line Manufacturer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hat Üreticisi"])},
        "Air Parameter Name e.g. [DosageAir]": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hava Parametresi Adı, örneğin [DosageAir]"])},
        "Air Parameter Units e.g. [bar]": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hava Parametresi Birimleri, örneğin [bar]"])},
        "Pump Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pompa Tipi"])},
        "Gun min velocity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabanca min hızı"])},
        "Gun max velocity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabanca maksimum hızı"])},
        "Gun Layout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabanca Düzeni"])},
        "Gun max movement range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabancaların maksimum hareket aralığı"])},
        "Total Guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toplam Tabanca"])},
        "Gun to gun distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabancadan tabancaya mesafe"])},
        "Total number of vertical gun stacks or columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toplam dikey tabanca yığını veya sütunu sayısı"])},
        "columns of guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tabanca sütunları"])},
        "1st guns column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. tabanca sütunu"])},
        "Total number of guns of the 1st vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. dikey yığının toplam tabanca sayısı"])},
        "Gun to gun distance of the 1st vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. dikey yığının tabancadan tabancaya mesafesi"])},
        "2nd guns column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. tabanca sütunu"])},
        "Total number of guns of the 2nd vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. dikey yığının toplam tabanca sayısı"])},
        "Gun to gun distance of the 2nd vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. dikey istifin tabancadan tabancaya mesafesi"])},
        "Horizontal distance between 1st and 2nd vertical stacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. ve 2. dikey yığınlar arasındaki yatay mesafe"])},
        "2nd vertical guns stack height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. dikey tabanca yığını yükseklik ofseti"])},
        "3rd guns column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. tabanca sütunu"])},
        "Total number of guns of the 3rd vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. dikey istifin toplam tabanca sayısı"])},
        "Gun to gun distance of the 3rd vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. dikey yığının tabancadan tabancaya mesafesi"])},
        "Horizontal distance between 2nd and 3rd vertical stacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. ve 3. dikey yığınlar arasındaki yatay mesafe"])},
        "3rd vertical guns stack height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. dikey tabanca yığını yükseklik ofseti"])},
        "4th guns column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4. tabanca sütunu"])},
        "Total number of guns of the 4th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4. dikey istifin toplam tabanca sayısı"])},
        "Gun to gun distance of the 4th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4. dikey yığının tabancadan tabancaya mesafesi"])},
        "Horizontal distance between 3rd and 4th vertical stacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. ve 4. dikey yığınlar arasındaki yatay mesafe"])},
        "4th vertical guns stack height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4. dikey tabanca yığını yükseklik ofseti"])},
        "5th guns column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5. tabanca sütunu"])},
        "Total number of guns of the 5th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5. dikey istifin toplam tabanca sayısı"])},
        "Gun to gun distance of the 5th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5. dikey yığının tabancadan tabancaya mesafesi"])},
        "Horizontal distance between the 4th and 5th stacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4. ve 5. yığınlar arasındaki yatay mesafe"])},
        "5th vertical guns stack height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5. dikey tabanca yığını yükseklik ofseti"])},
        "Line Details are not editable. If you want to make changes to your line, contact us at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Satır Ayrıntıları düzenlenemez. Hattınızda değişiklik yapmak istiyorsanız, bizimle iletişime geçin"])},
        "Create Line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çizgi Oluştur"])},
        "Line Preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hat Önizlemesi"])},
        "New Powder Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Toz Model"])},
        "Go Back Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ana Sayfaya Dön"])},
        "Select Vertical if the guns are arranged in one on top of each other in a single column. Select Horizontal if they are arranged one next to each other in a single row. Select Mixed for more complex arrangements that combine multiple vertical stacks or columns.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabancalar tek bir sütunda üst üste dizilmişse Dikey'i seçin. Tek bir sıra halinde yan yana dizilmişlerse Yatay'ı seçin. Birden fazla dikey yığın veya sütunu birleştiren daha karmaşık düzenlemeler için Karışık'ı seçin."])},
        "The maximum movement range in the vertical axis that one gun can move, i.e. distance that one gun can move from its highest to its lowest position. By default is set to the gun to gun distance.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir tabancanın dikey eksende hareket edebileceği maksimum hareket aralığı, yani bir tabancanın en yüksek konumundan en düşük konumuna hareket edebileceği mesafe. Varsayılan olarak tabancadan tabancaya mesafeye ayarlanmıştır."])},
        "A mixed hybrid line is composed of multiple vertical stacks or columns of guns. If your Line has only 1 vertical gun column, then select Vertical instead of Mixed on the Gun Layout option.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karma bir hibrit hat, birden fazla dikey tabanca yığını veya sütunundan oluşur. Hattınızda yalnızca 1 dikey tabanca sütunu varsa, Tabanca Düzeni seçeneğinde Karışık yerine Dikey'i seçin."])},
        "Input the vertical distance between guns of the 1st column of guns, the column that is most to the left.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En soldaki sütun olan 1. tabanca sütununun tabancaları arasındaki dikey mesafeyi girin."])},
        "Input the vertical distance between guns of the second column of guns, the second column from the left.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabancaların ikinci sütununun tabancaları arasındaki dikey mesafeyi girin, soldan ikinci sütun."])},
        "Input the horizontal distance measured between the first column and the second column of guns.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabancaların birinci sütunu ile ikinci sütunu arasında ölçülen yatay mesafeyi girin."])},
        "Input the vertical guns stack height offset as the difference in respect to the previous one on the left. If the gun stacks are at equal height leave the default 0 offset. A positive value means the column is higher than the previous one, and a negative means the second stack is shorter than the first one.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dikey tabanca yığını yüksekliği ofsetini soldaki bir öncekine göre fark olarak girin. Tabanca yığınları eşit yükseklikte ise varsayılan 0 ofseti bırakın. Pozitif bir değer, sütunun bir öncekinden daha yüksek olduğu anlamına gelir ve negatif, ikinci yığının birinciden daha kısa olduğu anlamına gelir."])},
        "Input the vertical distance between guns of the third column of guns, the third column from the left.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üçüncü tabanca sütununun tabancaları arasındaki dikey mesafeyi girin, soldan üçüncü sütun."])},
        "Input the horizontal distance measured between the second column and the third column of guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İkinci sütun ile üçüncü tabanca sütunu arasında ölçülen yatay mesafeyi girin"])},
        "Input the vertical distance between guns of the fourth column of guns, i.e the fourth column from the left.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dördüncü tabanca sütununun tabancaları arasındaki dikey mesafeyi girin, yani soldan dördüncü sütun."])},
        "Input the horizontal distance measured between the third column and the fourth column of guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üçüncü sütun ile dördüncü tabanca sütunu arasında ölçülen yatay mesafeyi girin"])},
        "Input the vertical distance between guns of the fifth column of guns, i.e the fifth column from the left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beşinci tabanca sütununun tabancaları arasındaki dikey mesafeyi girin, yani soldan beşinci sütun"])},
        "Input the horizontal distance measured between the fourth column and the fifth column of guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabancaların dördüncü sütunu ile beşinci sütunu arasında ölçülen yatay mesafeyi girin"])},
        "Other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diğer"])},
        "Dense Phase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dense Phase"])},
        "Injector / Venturi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Injector / Venturi"])},
        "Save Changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değişiklikleri Kaydet"])},
        "No Changes to Save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaydedilecek Değişiklik Yok"])},
        "Wagner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wagner"])},
        "MS-Carlisle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MS-Carlisle"])},
        "GEMA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GEMA"])},
        "Nordson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nordson"])},
        "Fixed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statik"])},
        "Dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dinamik"])},
        "Line Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hat Tipi"])},
        "Select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seç"])},
        "Select moving or fixed line. A fixed line has a static reciprocator that does not move, hence guns have 0 movement range and 0 velocity.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hareketli veya statik hat seçin. Statik hat, reciprocator sabit olduğundan, tabancaların 0 hareket aralığı ve 0 hızı vardır."])},
        "guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tabancalar"])},
        "Do you have a recycling powder system?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geri dönüşüm toz sisteminiz var mı?"])},
        "Yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evet"])},
        "No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hayır"])},
        "Recycling System Efficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geri Dönüşüm Sistemi Verimliliği"])},
        "Number of Columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sütun Sayısı"])},
        "Column Distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sütun Mesafesi"])},
        "Column Shift": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sütun Kaydırma"])},
        "Number of Rows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Satır Sayısı"])},
        "Row Distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Satır Mesafesi"])},
        "Row Shift": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Satır Kaydırma"])},
        "Advanced Gun Layout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelişmiş Tabanca Düzeni"])},
        "6th guns column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6. tabanca sütunu"])},
        "7th guns column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7. tabanca sütunu"])},
        "8th guns column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8. tabanca sütunu"])},
        "9th guns column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9. tabanca sütunu"])},
        "10th guns column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10. tabanca sütunu"])},
        "Total number of guns of the 6th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6. dikey yığının toplam tabanca sayısı"])},
        "Total number of guns of the 7th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7. dikey yığının toplam tabanca sayısı"])},
        "Total number of guns of the 8th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8. dikey yığının toplam tabanca sayısı"])},
        "Total number of guns of the 9th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9. dikey yığının toplam tabanca sayısı"])},
        "Total number of guns of the 10th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10. dikey yığının toplam tabanca sayısı"])},
        "Gun to gun distance of the 6th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6. dikey yığının tabancadan tabancaya mesafesi"])},
        "Gun to gun distance of the 7th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7. dikey yığının tabancadan tabancaya mesafesi"])},
        "Gun to gun distance of the 8th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8. dikey yığının tabancadan tabancaya mesafesi"])},
        "Gun to gun distance of the 9th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9. dikey yığının tabancadan tabancaya mesafesi"])},
        "Gun to gun distance of the 10th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10. dikey yığının tabancadan tabancaya mesafesi"])},
        "Input the vertical distance between guns of the sixth column of guns, i.e the sixth column from the left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altıncı tabanca sütununun tabancaları arasındaki dikey mesafeyi girin, yani soldan altıncı sütun"])},
        "Input the vertical distance between guns of the seventh column of guns, i.e the seventh column from the left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yedinci tabanca sütununun tabancaları arasındaki dikey mesafeyi girin, yani soldan yedinci sütun"])},
        "Input the vertical distance between guns of the eighth column of guns, i.e the eighth column from the left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sekizinci tabanca sütununun tabancaları arasındaki dikey mesafeyi girin, yani soldan sekizinci sütun"])},
        "Input the vertical distance between guns of the ninth column of guns, i.e the ninth column from the left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokuzuncu tabanca sütununun tabancaları arasındaki dikey mesafeyi girin, yani soldan dokuzuncu sütun"])},
        "Input the vertical distance between guns of the tenth column of guns, i.e the tenth column from the left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onuncu tabanca sütununun tabancaları arasındaki dikey mesafeyi girin, yani soldan onuncu sütun"])},
        "Horizontal distance between the 5th and 6th stacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5. ve 6. yığınlar arasındaki yatay mesafe"])},
        "Horizontal distance between the 6th and 7th stacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6. ve 7. yığınlar arasındaki yatay mesafe"])},
        "Horizontal distance between the 7th and 8th stacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7. ve 8. yığınlar arasındaki yatay mesafe"])},
        "Horizontal distance between the 8th and 9th stacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8. ve 9. yığınlar arasındaki yatay mesafe"])},
        "Horizontal distance between the 9th and 10th stacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9. ve 10. yığınlar arasındaki yatay mesafe"])},
        "Input the horizontal distance measured between the fifth column and the sixth column of guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beşinci sütun ile altıncı tabanca sütunu arasında ölçülen yatay mesafeyi girin"])},
        "Input the horizontal distance measured between the sixth column and the seventh column of guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altıncı sütun ile yedinci tabanca sütunu arasında ölçülen yatay mesafeyi girin"])},
        "Input the horizontal distance measured between the seventh column and the eighth column of guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yedinci sütun ile sekizinci tabanca sütunu arasında ölçülen yatay mesafeyi girin"])},
        "Input the horizontal distance measured between the eighth column and the ninth column of guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sekizinci sütun ile dokuzuncu tabanca sütunu arasında ölçülen yatay mesafeyi girin"])},
        "Input the horizontal distance measured between the ninth column and the tenth column of guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokuzuncu sütun ile onuncu tabanca sütunu arasında ölçülen yatay mesafeyi girin"])},
        "6th vertical guns stack height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6. dikey tabanca yığını yükseklik ofseti"])},
        "7th vertical guns stack height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7. dikey tabanca yığını yükseklik ofseti"])},
        "8th vertical guns stack height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8. dikey tabanca yığını yükseklik ofseti"])},
        "9th vertical guns stack height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9. dikey tabanca yığını yükseklik ofseti"])},
        "10th vertical guns stack height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10. dikey tabanca yığını yükseklik ofseti"])},
        "Switch to advanced Line configurator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelişmiş Konfigürasyona Geç"])},
        "Switch to standard Line configurator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standart Konfigürasyona Geç"])},
        "Horizontal layout is not allowed for Fixed Lines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statik Hatlar için Yatay düzen izin verilmez"])},
        "For Mixed Lines the number of gun columns must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karışık Hatlar için tabanca sütunlarının sayısı aşağıdakilerden küçük olmalıdır"])},
        "Attention!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dikkat!"])},
        "Changing to standard configuration might modify your line. Do you want to continue?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standart konfigürasyona geçmek hatınızı değiştirebilir. Devam etmek istiyor musunuz?"])},
        "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İptal"])},
        "Total Guns Column 6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toplam Tabanca Sütun 6"])},
        "Total Guns Column 7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toplam Tabanca Sütun 7"])},
        "Total Guns Column 8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toplam Tabanca Sütun 8"])},
        "Total Guns Column 9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toplam Tabanca Sütun 9"])},
        "Total Guns Column 10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toplam Tabanca Sütun 10"])},
        "Gun to Gun Distance Column 6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabancadan Tabancaya Mesafe Sütun 6"])},
        "Gun to Gun Distance Column 7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabancadan Tabancaya Mesafe Sütun 7"])},
        "Gun to Gun Distance Column 8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabancadan Tabancaya Mesafe Sütun 8"])},
        "Gun to Gun Distance Column 9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabancadan Tabancaya Mesafe Sütun 9"])},
        "Gun to Gun Distance Column 10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabancadan Tabancaya Mesafe Sütun 10"])},
        "Gun Column Distance 6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabanca Kolon Mesafesi 6"])},
        "Gun Column Distance 7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabanca Kolon Mesafesi 7"])},
        "Gun Column Distance 8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabanca Kolon Mesafesi 8"])},
        "Gun Column Distance 9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabanca Kolon Mesafesi 9"])},
        "Gun Column Distance 10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabanca Kolon Mesafesi 10"])},
        "Gun Column Vertical Offset 6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabanca Kolon Dikey Ofset 6"])},
        "Gun Column Vertical Offset 7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabanca Kolon Dikey Ofset 7"])},
        "Gun Column Vertical Offset 8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabanca Kolon Dikey Ofset 8"])},
        "Gun Column Vertical Offset 9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabanca Kolon Dikey Ofset 9"])},
        "Gun Column Vertical Offset 10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabanca Kolon Dikey Ofset 10"])},
        "Number of columns cannot be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sütun sayısı aşağıdakilerden daha büyük olamaz"])},
        "Number of rows cannot be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Satır sayısı aşağıdakilerden daha büyük olamaz"])},
        "Row distance must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Satır mesafesi aşağıdakilerden daha büyük olmalıdır"])},
        "Row distance must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Satır mesafesi aşağıdakilerden daha küçük olmalıdır"])},
        "Column distance must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sütun mesafesi aşağıdakilerden daha büyük olmalıdır"])},
        "Column distance must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sütun mesafesi aşağıdakilerden daha küçük olmalıdır"])},
        "Column Shift must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sütun kaydırma aşağıdakilerden daha büyük olmalıdır"])},
        "Column Shift must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sütun kaydırma aşağıdakilerden daha küçük olmalıdır"])},
        "Row Shift must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Satır kaydırma aşağıdakilerden daha büyük olmalıdır"])},
        "Row Shift must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Satır kaydırma aşağıdakilerden daha küçük olmalıdır"])},
        "Reciprocator Min Velocity cannot be greater than or equal to the Reciprocator Max Velocity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reciprocator Min Hızı, Reciprocator Maksimum Hızından büyük veya eşit olamaz"])},
        "The number of guns must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabanca sayısı aşağıdakilerden daha küçük olmalıdır"])},
        "Lines with 1 gun per column are not allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sütun başına 1 tabanca içeren satırlara izin verilmez"])},
        "Advanced Gun Parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelişmiş Tabanca Parametreleri"])},
        "The gun's maximum acceleration value is outside the permissible range. Please enter a value within the allowed limits.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabancanın maksimum ivme değeri izin verilen aralığın dışındadır. Lütfen izin verilen sınırlar içinde bir değer girin."])},
        "New Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Optimizasyon"])}
      },
      "ZH": {
        "Horizontal distance between two adjacent guns or stacks of guns.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["两个相邻火炮或火炮组之间的水平距离。"])},
        "Number of guns or vertical stacks of guns, next to each other.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["相邻火炮或垂直火炮堆的数量。"])},
        "Height offset between two adjacent guns or stacks of guns.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["两个相邻火炮或火炮组之间的高度偏移。"])},
        "Number of guns on top of each other.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["相邻火炮顶部的火炮数量。"])},
        "Vertical distance between two guns on top of each other.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["相邻两门火炮之间的垂直距离。"])},
        "Horizontal distance offset between even and odd rows.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["偶数行和奇数行之间的水平距离偏移。"])},
        "Minimum gun velocity with activated reciprocator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["启动往复机时的最小喷枪速度"])},
        "Maximum gun velocity of the reciprocator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["往复机的最大炮速"])},
        "Maximum gun acceleration of the reciprocator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["往复机的最大喷枪加速度"])},
        "The minimum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最小值为"])},
        "and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["，最大值为"])},
        "Static Calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["静态校准"])},
        "Gun Max Velocity must be greater than Gun Min Velocity by at least": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["喷枪最大速度必须大于喷枪最小速度至少"])},
        "Gun column height offsets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["枪柱高度偏移"])},
        "Nozzle rotation angle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["喷嘴旋转角度"])},
        "Total Guns per column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每列手枪数量"])},
        "Fixed Mixed (non moving with multiple columns of guns)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["静态混合（多列手枪，不移动）"])},
        "Line changes saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存的线路更改"])},
        "Line created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建线路"])},
        "was saved successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已成功保存行"])},
        "The following fields are required:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以下字段为必填字段："])},
        "Air Parameter Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["空气参数名称"])},
        "Air Parameter Units": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["空气参数单位"])},
        "Number of Gun Columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手枪列数"])},
        "Total Guns Column 1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手枪总数 第 1 列"])},
        "Total Guns Column 2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手枪总数 第 2 列"])},
        "Total Guns Column 3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手枪总数 第 3 列"])},
        "Total Guns Column 4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手枪总数 第 4 栏"])},
        "Total Guns Column 5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手枪总数 第 5 栏"])},
        "Gun to Gun Distance Column 1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手枪到手枪距离 第 1 栏"])},
        "Gun to Gun Distance Column 2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手枪到手枪距离 第 2 栏"])},
        "Gun to Gun Distance Column 3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手枪到手枪距离第 3 栏"])},
        "Gun to Gun Distance Column 4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手枪至手枪距离 第 4 栏"])},
        "Gun to Gun Distance Column 5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手枪至手枪距离 第5列"])},
        "Gun Column Distance 1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手枪列距离 1"])},
        "Gun Column Distance 2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手枪列距离 2"])},
        "Gun Column Distance 3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手枪列距离 3"])},
        "Gun Column Distance 4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手枪列距离 4"])},
        "Gun Column Distance 5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手枪列距离 5"])},
        "Gun Column Vertical Offset 1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手枪立柱垂直偏移 1"])},
        "Gun Column Vertical Offset 2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手枪列垂直偏移 2"])},
        "Gun Column Vertical Offset 3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手枪列垂直偏移 3"])},
        "Gun Column Vertical Offset 4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手枪立柱垂直偏移 4"])},
        "Gun Column Vertical Offset 5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手枪立柱垂直偏移 5"])},
        "Input value out of range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入值超出范围"])},
        "Gun to gun distance cannot be 0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手枪到手枪的距离不能为 0"])},
        "Gun to Gun distance must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手枪到手枪的距离必须大于"])},
        "Gun to Gun distance must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手枪到手枪的距离必须小于"])},
        "Gun max movement range cannot be 0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手枪最大移动范围不能为 0"])},
        "Gun max movement range must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手枪最大移动范围必须大于"])},
        "Gun max movement range must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手枪最大移动范围必须小于"])},
        "Gun Min Velocity cannot be 0 for Horizontal Gun Layout.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["水平手枪布局的手枪最小速度不能为 0。"])},
        "Gun Min Velocity must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手枪最小速度必须大于"])},
        "Gun Max Velocity cannot be 0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手枪最大速度不能为 0"])},
        "Gun Min Velocity cannot be greater than or equal to the Gun Max Velocity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手枪最小速度不能大于或等于手枪最大速度"])},
        "Gun max acceleration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手枪最大加速度"])},
        "Missing Required Fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["缺少必填字段"])},
        "Vertical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["垂直"])},
        "Horizontal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["横向"])},
        "Mixed (Vertical and Horizontal Hybrid)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["混合（纵向和横向混合）"])},
        "Simple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["简单"])},
        "Fixed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["静态"])},
        "Advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["高级"])},
        "Which type of Powder Model do you want to create?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您想创建哪种类型的粉末模型？"])},
        "Please select one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请选择一项"])},
        "Add new Line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加新"])},
        "Edit Line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑线"])},
        "Line Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["线路详细信"])},
        "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名"])},
        "Line Properties": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["生产线属"])},
        "Line Manufacturer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["生产线制造商"])},
        "Air Parameter Name e.g. [DosageAir]": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["空气参数名称，例如 [DosageAir]"])},
        "Air Parameter Units e.g. [bar]": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["空气参数单位，例如 [bar]"])},
        "Pump Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["泵类型"])},
        "Gun min velocity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手枪最大速度"])},
        "Gun max velocity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手枪布"])},
        "Gun Layout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手枪布"])},
        "Gun max movement range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手枪最大移动范"])},
        "Total Guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手枪总数"])},
        "Gun to gun distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手枪与手枪之间的距"])},
        "Total number of vertical gun stacks or columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["垂直手枪堆叠或列的总数"])},
        "columns of guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手枪列数"])},
        "1st guns column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第一列手"])},
        "Total number of guns of the 1st vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第 1 纵列的手枪总数 手枪到手枪的距"])},
        "Gun to gun distance of the 1st vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第 1 层垂直叠加的手枪到手枪距离"])},
        "2nd guns column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第 2 列手枪"])},
        "Total number of guns of the 2nd vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第 2 垂直叠加手枪总数"])},
        "Gun to gun distance of the 2nd vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第 2 层垂直叠加的手枪到手枪距离"])},
        "Horizontal distance between 1st and 2nd vertical stacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第 1 层和第 2 层垂直堆垛之间的水平距离"])},
        "2nd vertical guns stack height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第 2 组垂直手枪高度偏移"])},
        "3rd guns column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第 3 列手"])},
        "Total number of guns of the 3rd vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第 3 层垂直叠放的手枪总数"])},
        "Gun to gun distance of the 3rd vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第 3 层垂直叠放手枪与手枪之间的距"])},
        "Horizontal distance between 2nd and 3rd vertical stacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第 2 和第 3 纵列之间的水平距"])},
        "3rd vertical guns stack height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第 3 层垂直叠放手枪高度偏移"])},
        "4th guns column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第 4 列手"])},
        "Total number of guns of the 4th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第 4 垂直叠放手枪总数"])},
        "Gun to gun distance of the 4th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第 4 层垂直叠加的手枪到手枪距离"])},
        "Horizontal distance between 3rd and 4th vertical stacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第 3 和第 4 纵向枪堆之间的水平距离"])},
        "4th vertical guns stack height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第 4 层垂直叠放手枪高度偏"])},
        "5th guns column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第 5 列手"])},
        "Total number of guns of the 5th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第 5 层垂直叠加的手枪总数"])},
        "Gun to gun distance of the 5th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第 5 层垂直叠加的手枪到手枪距离"])},
        "Horizontal distance between the 4th and 5th stacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第 4 层和第 5 层之间的水平距离"])},
        "5th vertical guns stack height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第 5 垂直手枪堆叠高度偏移"])},
        "Line Details are not editable. If you want to make changes to your line, contact us at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["线条细节 不可编辑。如果要更改线路，请联系我们"])},
        "Create Line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建线条"])},
        "Line Preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["线条预览"])},
        "New Powder Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新粉末模型"])},
        "Go Back Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返回主页"])},
        "Select Vertical if the guns are arranged in one on top of each other in a single column. Select Horizontal if they are arranged one next to each other in a single row. Select Mixed for more complex arrangements that combine multiple vertical stacks or columns.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果手枪在一列中一个挨着一个排列，请选择 竖排。如果手枪在单行中一个挨着一个排列，则选择水平排列。如果要组合多个垂直堆叠或列的更复杂排列，请选择 混合。"])},
        "The maximum movement range in the vertical axis that one gun can move, i.e. distance that one gun can move from its highest to its lowest position. By default is set to the gun to gun distance.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一把手枪在垂直轴上的最大移动范围，即一把手枪从最高位置移动到最低位置的距离。默认设置为手枪到手枪的距离。"])},
        "A mixed hybrid line is composed of multiple vertical stacks or columns of guns. If your Line has only 1 vertical gun column, then select Vertical instead of Mixed on the Gun Layout option.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["混合混合型防线由多个垂直叠放或纵列的手枪组成。如果您的生产线只有一列垂直手枪，则在 手枪布局 选项中选择 垂直 而不是 混合。"])},
        "Input the vertical distance between guns of the 1st column of guns, the column that is most to the left.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入第一列手枪（最靠左的一列）之间的垂直距离。"])},
        "Input the vertical distance between guns of the second column of guns, the second column from the left.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入第二列手枪（从左数第二列）之间的垂直距离。"])},
        "Input the horizontal distance measured between the first column and the second column of guns.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入第一列和第二列手枪之间的水平距离。"])},
        "Input the vertical guns stack height offset as the difference in respect to the previous one on the left. If the gun stacks are at equal height leave the default 0 offset. A positive value means the column is higher than the previous one, and a negative means the second stack is shorter than the first one.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入垂直手枪堆叠高度偏移，即与左侧前一列手枪堆叠高度的差值。如果手枪堆高度相等，则默认偏移量为 0。正值表示该列比前一列高，负值表示第二列比第一列矮。"])},
        "Input the vertical distance between guns of the third column of guns, the third column from the left.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入从左数起第三列手枪之间的垂直距离。"])},
        "Input the horizontal distance measured between the second column and the third column of guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入第二列和第三列手枪之间的水平距离"])},
        "Input the vertical distance between guns of the fourth column of guns, i.e the fourth column from the left.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入第四列手枪之间的垂直距离，即左起第四列。"])},
        "Input the horizontal distance measured between the third column and the fourth column of guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入在第三列和第四列手枪之间测得的水平距离"])},
        "Input the vertical distance between guns of the fifth column of guns, i.e the fifth column from the left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入第五列手枪之间的垂直距离，即第五列从左起的距离"])},
        "Input the horizontal distance measured between the fourth column and the fifth column of guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入第四列和第五列手枪之间的水平距离"])},
        "Other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["其他"])},
        "Dense Phase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dense Phase"])},
        "Injector / Venturi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Injector / Venturi"])},
        "Save Changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存更改"])},
        "No Changes to Save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有更改保存"])},
        "Wagner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wagner"])},
        "MS-Carlisle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MS-Carlisle"])},
        "GEMA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GEMA"])},
        "Nordson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nordson"])},
        "Fixed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["静态"])},
        "Dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["动态"])},
        "Line Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["线路类型"])},
        "Select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择"])},
        "Select moving or fixed line. A fixed line has a static reciprocator that does not move, hence guns have 0 movement range and 0 velocity.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择移动或静态线。静态线具有固定的往复器，因此手枪的移动范围和速度为 0。"])},
        "guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手枪"])},
        "Do you have a recycling powder system?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您有回收粉末系统吗？"])},
        "Yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是"])},
        "No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不"])},
        "Recycling System Efficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回收系统效率"])},
        "Number of Columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["列数"])},
        "Column Distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["列距离"])},
        "Column Shift": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["列移位"])},
        "Number of Rows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["行数"])},
        "Row Distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["行距离"])},
        "Row Shift": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["行移位"])},
        "Advanced Gun Layout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["高级手枪布局"])},
        "6th guns column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第 6 列手枪"])},
        "7th guns column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第 7 列手枪"])},
        "8th guns column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第 8 列手枪"])},
        "9th guns column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第 9 列手枪"])},
        "10th guns column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第 10 列手枪"])},
        "Total number of guns of the 6th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第 6 垂直叠放手枪总数"])},
        "Total number of guns of the 7th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第 7 垂直叠放手枪总数"])},
        "Total number of guns of the 8th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第 8 垂直叠放手枪总数"])},
        "Total number of guns of the 9th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第 9 垂直叠放手枪总数"])},
        "Total number of guns of the 10th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第 10 垂直叠放手枪总数"])},
        "Gun to gun distance of the 6th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第 6 垂直叠放手枪到手枪的距离"])},
        "Gun to gun distance of the 7th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第 7 垂直叠放手枪到手枪的距离"])},
        "Gun to gun distance of the 8th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第 8 垂直叠放手枪到手枪的距离"])},
        "Gun to gun distance of the 9th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第 9 垂直叠放手枪到手枪的距离"])},
        "Gun to gun distance of the 10th vertical stack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第 10 垂直叠放手枪到手枪的距离"])},
        "Input the vertical distance between guns of the sixth column of guns, i.e the sixth column from the left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入第六列手枪之间的垂直距离，即从左起第六列"])},
        "Input the vertical distance between guns of the seventh column of guns, i.e the seventh column from the left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入第七列手枪之间的垂直距离，即从左起第七列"])},
        "Input the vertical distance between guns of the eighth column of guns, i.e the eighth column from the left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入第八列手枪之间的垂直距离，即从左起第八列"])},
        "Input the vertical distance between guns of the ninth column of guns, i.e the ninth column from the left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入第九列手枪之间的垂直距离，即从左起第九列"])},
        "Input the vertical distance between guns of the tenth column of guns, i.e the tenth column from the left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入第十列手枪之间的垂直距离，即从左起第十列"])},
        "Horizontal distance between the 5th and 6th stacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第 5 和第 6 堆之间的水平距离"])},
        "Horizontal distance between the 6th and 7th stacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第 6 和第 7 堆之间的水平距离"])},
        "Horizontal distance between the 7th and 8th stacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第 7 和第 8 堆之间的水平距离"])},
        "Horizontal distance between the 8th and 9th stacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第 8 和第 9 堆之间的水平距离"])},
        "Horizontal distance between the 9th and 10th stacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第 9 和第 10 堆之间的水平距离"])},
        "Input the horizontal distance measured between the fifth column and the sixth column of guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入第五列和第六列手枪之间测得的水平距离"])},
        "Input the horizontal distance measured between the sixth column and the seventh column of guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入第六列和第七列手枪之间测得的水平距离"])},
        "Input the horizontal distance measured between the seventh column and the eighth column of guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入第七列和第八列手枪之间测得的水平距离"])},
        "Input the horizontal distance measured between the eighth column and the ninth column of guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入第八列和第九列手枪之间测得的水平距离"])},
        "Input the horizontal distance measured between the ninth column and the tenth column of guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入第九列和第十列手枪之间测得的水平距离"])},
        "6th vertical guns stack height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第 6 垂直手枪堆叠高度偏移"])},
        "7th vertical guns stack height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第 7 垂直手枪堆叠高度偏移"])},
        "8th vertical guns stack height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第 8 垂直手枪堆叠高度偏移"])},
        "9th vertical guns stack height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第 9 垂直手枪堆叠高度偏移"])},
        "10th vertical guns stack height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第 10 垂直手枪堆叠高度偏移"])},
        "Switch to advanced Line configurator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["切换到高级配置"])},
        "Switch to standard Line configurator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["切换到标准配置"])},
        "Horizontal layout is not allowed for Fixed Lines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["静态线不允许水平布局"])},
        "For Mixed Lines the number of gun columns must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对于混合行，炮列数必须小于"])},
        "Attention!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注意！"])},
        "Changing to standard configuration might modify your line. Do you want to continue?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更改为标准配置可能会修改您的线路。您要继续吗？"])},
        "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
        "Total Guns Column 6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第 6 列手枪总数"])},
        "Total Guns Column 7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第 7 列手枪总数"])},
        "Total Guns Column 8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第 8 列手枪总数"])},
        "Total Guns Column 9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第 9 列手枪总数"])},
        "Total Guns Column 10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第 10 列手枪总数"])},
        "Gun to Gun Distance Column 6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第 6 列手枪到手枪的距离"])},
        "Gun to Gun Distance Column 7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第 7 列手枪到手枪的距离"])},
        "Gun to Gun Distance Column 8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第 8 列手枪到手枪的距离"])},
        "Gun to Gun Distance Column 9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第 9 列手枪到手枪的距离"])},
        "Gun to Gun Distance Column 10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第 10 列手枪到手枪的距离"])},
        "Gun Column Distance 6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第 6 列手枪列距离"])},
        "Gun Column Distance 7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第 7 列手枪列距离"])},
        "Gun Column Distance 8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第 8 列手枪列距离"])},
        "Gun Column Distance 9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第 9 列手枪列距离"])},
        "Gun Column Distance 10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第 10 列手枪列距离"])},
        "Gun Column Vertical Offset 6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第 6 列手枪列垂直偏移"])},
        "Gun Column Vertical Offset 7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第 7 列手枪列垂直偏移"])},
        "Gun Column Vertical Offset 8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第 8 列手枪列垂直偏移"])},
        "Gun Column Vertical Offset 9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第 9 列手枪列垂直偏移"])},
        "Gun Column Vertical Offset 10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第 10 列手枪列垂直偏移"])},
        "Number of columns cannot be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["列数不能大于"])},
        "Number of rows cannot be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["行数不能大于"])},
        "Row distance must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["行距离必须大于"])},
        "Row distance must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["行距离必须小于"])},
        "Column distance must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["列距离必须大于"])},
        "Column distance must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["列距离必须小于"])},
        "Column Shift must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["列移位必须大于"])},
        "Column Shift must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["列移位必须小于"])},
        "Row Shift must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["行移位必须大于"])},
        "Row Shift must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["行移位必须小于"])},
        "Reciprocator Min Velocity cannot be greater than or equal to the Reciprocator Max Velocity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["往复器最小速度不能大于或等于往复器最大速度"])},
        "The number of guns must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手枪数量必须小于"])},
        "Lines with 1 gun per column are not allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不允许每列有 1 支枪的行"])},
        "Advanced Gun Parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["高级枪支参数"])},
        "The gun's maximum acceleration value is outside the permissible range. Please enter a value within the allowed limits.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["枪支的最大加速度值超出了允许的范围。请在允许的限制范围内输入一个值。"])},
        "New Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新优化"])}
      }
    }
  })
}
