export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "EN": {
        "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "Enter your e-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your e-mail"])},
        "Reset Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset Password"])},
        "Email field is empty.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email field is empty."])},
        "Send Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Email"])},
        "Please enter your email address in the field below.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your email address in the field below."])},
        "We will send a link to your email to reset your password. Please check your inbox shortly.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We will send a link to your email to reset your password. Please check your inbox shortly."])},
        "Invalid email format.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid email format."])},
        "Please enter a valid email, with the format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid email, with the format"])},
        "No user exists with the provided email.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No user exists with the provided email."])},
        "User not found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User not found"])}
      },
      "DE": {
        "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
        "Enter your e-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie Ihre E-Mail-Adresse ein"])},
        "Reset Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort zurücksetzen"])},
        "Email field is empty.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das E-Mail-Feld ist leer."])},
        "Send Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail senden"])},
        "Please enter your email address in the field below.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie Ihre E-Mail-Adresse in das untenstehende Feld ein."])},
        "We will send a link to your email to reset your password. Please check your inbox shortly.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir senden Ihnen einen Link an Ihre E-Mail-Adresse, um Ihr Passwort zurückzusetzen. Bitte überprüfen Sie in Kürze Ihren Posteingang."])},
        "Invalid email format.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültiges E-Mail-Format."])},
        "Please enter a valid email, with the format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie eine gültige E-Mail-Adresse im Format ein"])},
        "No user exists with the provided email.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es existiert kein Benutzer mit der angegebenen E-Mail-Adresse."])},
        "User not found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer nicht gefunden"])}
      },
      "ES": {
        "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "Enter your e-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduzca su correo electrónico"])},
        "Reset Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restablecer la contraseña"])},
        "Email field is empty.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El campo de correo electrónico está vacío."])},
        "Send Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar correo electrónico"])},
        "Please enter your email address in the field below.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, introduzca su dirección de correo electrónico en el campo a continuación."])},
        "We will send a link to your email to reset your password. Please check your inbox shortly.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le enviaremos un enlace a su correo electrónico para restablecer su contraseña. Por favor, revise su bandeja de entrada en breve."])},
        "Invalid email format.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formato de correo electrónico no válido."])},
        "Please enter a valid email, with the format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, introduzca un correo electrónico válido, con el formato"])},
        "No user exists with the provided email.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No existe ningún usuario con el correo electrónico proporcionado."])},
        "User not found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuario no encontrado"])}
      },
      "FR": {
        "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "Enter your e-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez votre e-mail"])},
        "Reset Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser le mot de passe"])},
        "Email field is empty.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le champ E-mail est vide."])},
        "Send Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer l'e-mail"])},
        "Please enter your email address in the field below.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir votre adresse e-mail dans le champ ci-dessous."])},
        "We will send a link to your email to reset your password. Please check your inbox shortly.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous vous enverrons un lien à votre adresse e-mail pour réinitialiser votre mot de passe. Veuillez vérifier votre boîte de réception sous peu."])},
        "Invalid email format.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format d'e-mail invalide."])},
        "Please enter a valid email, with the format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir un e-mail valide, avec le format"])},
        "No user exists with the provided email.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun utilisateur n'existe avec l'e-mail fourni."])},
        "User not found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur non trouvé"])}
      },
      "IT": {
        "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "Enter your e-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci la tua email"])},
        "Reset Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reimposta la password"])},
        "Email field is empty.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il campo Email è vuoto."])},
        "Send Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invia email"])},
        "Please enter your email address in the field below.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci il tuo indirizzo email nel campo sottostante."])},
        "We will send a link to your email to reset your password. Please check your inbox shortly.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ti invieremo un link alla tua email per reimpostare la tua password. Controlla la tua casella di posta in arrivo a breve."])},
        "Invalid email format.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formato email non valido."])},
        "Please enter a valid email, with the format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci un'email valida, con il formato"])},
        "No user exists with the provided email.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun utente esiste con l'email fornita."])},
        "User not found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utente non trovato"])}
      },
      "PL": {
        "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
        "Enter your e-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadź swój adres e-mail"])},
        "Reset Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zresetuj hasło"])},
        "Email field is empty.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pole E-mail jest puste."])},
        "Send Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyślij e-mail"])},
        "Please enter your email address in the field below.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proszę wprowadzić swój adres e-mail w polu poniżej."])},
        "We will send a link to your email to reset your password. Please check your inbox shortly.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyślemy Ci link na Twój adres e-mail, aby zresetować hasło. Sprawdź swoją skrzynkę odbiorczą wkrótce."])},
        "Invalid email format.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieprawidłowy format adresu e-mail."])},
        "Please enter a valid email, with the format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadź prawidłowy adres e-mail, w formacie"])},
        "No user exists with the provided email.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie istnieje użytkownik z podanym adresem e-mail."])},
        "User not found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono użytkownika"])}
      },
      "TR": {
        "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-posta"])},
        "Enter your e-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-postanızı girin"])},
        "Reset Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifreyi sıfırla"])},
        "Email field is empty.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-posta alanı boş."])},
        "Send Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-posta gönder"])},
        "Please enter your email address in the field below.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen aşağıdaki alana e-posta adresinizi girin."])},
        "We will send a link to your email to reset your password. Please check your inbox shortly.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifrenizi sıfırlamak için e-postanıza bir bağlantı göndereceğiz. Lütfen gelen kutunuzu kısa süre içinde kontrol edin."])},
        "Invalid email format.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geçersiz e-posta formatı."])},
        "Please enter a valid email, with the format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen geçerli bir e-posta adresi girin, format"])},
        "No user exists with the provided email.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sağlanan e-posta ile kullanıcı yok."])},
        "User not found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı bulunamadı"])}
      },
      "ZH": {
        "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮件"])},
        "Enter your e-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入您的电子邮件"])},
        "Reset Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重设密码"])},
        "Email field is empty.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮件字段为空。"])},
        "Send Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发送电子邮件"])},
        "Please enter your email address in the field below.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请在下面的字段中输入您的电子邮件地址。"])},
        "We will send a link to your email to reset your password. Please check your inbox shortly.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们将向您的电子邮件发送一个链接，以重设您的密码。请稍后检查您的收件箱。"])},
        "Invalid email format.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮件格式无效。"])},
        "Please enter a valid email, with the format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入有效的电子邮件，格式为"])},
        "No user exists with the provided email.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提供的电子邮件不存在用户。"])},
        "User not found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到用户"])}
      }
    }
  })
}
