export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "EN": {
        "Main Page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main Page"])}
      },
      "DE": {
        "Main Page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hauptseite"])}
      },
      "ES": {
        "Main Page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Página Principal"])}
      },
      "FR": {
        "Main Page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page Principale"])}
      },
      "IT": {
        "Main Page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagina Iniziale"])}
      },
      "PL": {
        "Main Page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strona Główna"])}
      },
      "TR": {
        "Main Page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ana Sayfa"])}
      },
      "ZH": {
        "Main Page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主页"])}
      }
    }
  })
}
