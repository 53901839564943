export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "EN": {
        "Powder Model List for line ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powder Model List for line "])},
        "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "Updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated"])},
        "Created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created"])},
        "Remove Powder Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove Powder Model"])},
        "No PowderModels created.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No PowderModels created."])},
        "Are you sure you want to delete Powder Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete Powder Model"])},
        "You will not be able to revert this!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will not be able to revert this!"])},
        "Yes, delete it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, delete it"])},
        "No, cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No, cancel"])},
        "Deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleted"])},
        "Your powder model has been deleted.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your powder model has been deleted."])},
        "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])}
      },
      "DE": {
        "Powder Model List for line ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprühbildliste für inie "])},
        "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "Updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualisiert"])},
        "Created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellt"])},
        "Remove Powder Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pulvermodell entfernen"])},
        "No PowderModels created.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Sprühbilder erstellt."])},
        "Are you sure you want to delete Powder Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie das Pulvermodell wirklich löschen"])},
        "You will not be able to revert this!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können dies nicht rückgängig machen!"])},
        "Yes, delete it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja, lösche es"])},
        "No, cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nein, abbrechen"])},
        "Deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelöscht"])},
        "Your powder model has been deleted.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Pulvermodell wurde gelöscht."])},
        "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])}
      },
      "ES": {
        "Powder Model List for line ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de modelos de polvo para la línea "])},
        "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
        "Updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizado"])},
        "Created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creado"])},
        "Remove Powder Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar modelo de polvo"])},
        "No PowderModels created.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se ha creado ningún modelo de polvo."])},
        "Are you sure you want to delete Powder Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Estás seguro de que quieres eliminar el modelo de polvo"])},
        "You will not be able to revert this!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡No podrás deshacer esto!"])},
        "Yes, delete it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sí, bórralo"])},
        "No, cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No, cancelar"])},
        "Deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminado"])},
        "Your powder model has been deleted.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su modelo de polvo ha sido eliminado."])},
        "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar"])}
      },
      "FR": {
        "Powder Model List for line ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des modèles de poudres pour la ligne "])},
        "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
        "Updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis à jour"])},
        "Created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créé"])},
        "Remove Powder Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le modèle de poudre"])},
        "No PowderModels created.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun modèle de poudre n'a été créé."])},
        "Are you sure you want to delete Powder Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous vraiment supprimer le modèle de poudre"])},
        "You will not be able to revert this!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous ne pourrez pas revenir en arrière!"])},
        "Yes, delete it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui, supprimez-le"])},
        "No, cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non, annuler"])},
        "Deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimé"])},
        "Your powder model has been deleted.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre modèle de poudre a été supprimé."])},
        "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effacer"])}
      },
      "IT": {
        "Powder Model List for line ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elenco dei modelli di polvere per la linea "])},
        "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
        "Updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiornato"])},
        "Created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creato"])},
        "Remove Powder Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rimuovi modello di polvere"])},
        "No PowderModels created.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun modello di polvere creato."])},
        "Are you sure you want to delete Powder Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sei sicuro di voler eliminare il modello di polvere"])},
        "You will not be able to revert this!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non sarai in grado di annullare questa operazione!"])},
        "Yes, delete it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sì, cancellalo"])},
        "No, cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No, annulla"])},
        "Deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminato"])},
        "Your powder model has been deleted.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il tuo modello di polvere è stato eliminato."])},
        "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina"])}
      },
      "PL": {
        "Powder Model List for line ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista modeli proszków dla linii "])},
        "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa"])},
        "Updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaktualizowano"])},
        "Created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utworzony"])},
        "Remove Powder Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń model proszku"])},
        "No PowderModels created.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie utworzono modelu proszku."])},
        "Are you sure you want to delete Powder Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czy na pewno chcesz usunąć model proszku"])},
        "You will not be able to revert this!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie będziesz mógł tego cofnąć!"])},
        "Yes, delete it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tak, usuń to"])},
        "No, cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie, anuluj"])},
        "Deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usunięty"])},
        "Your powder model has been deleted.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twój model proszku został usunięty."])},
        "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kasować"])}
      },
      "TR": {
        "Powder Model List for line ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hat için Toz Model listesi "])},
        "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İsim"])},
        "Updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Güncellenmiş"])},
        "Created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oluşturuldu"])},
        "Remove Powder Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toz Modelini Çıkarın"])},
        "No PowderModels created.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toz Modeli oluşturulmadı."])},
        "Are you sure you want to delete Powder Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toz Modelini silmek istediğinizden emin misiniz"])},
        "You will not be able to revert this!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bunu geri alamayacaksın!"])},
        "Yes, delete it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evet, sil"])},
        "No, cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hayır, iptal et"])},
        "Deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silindi"])},
        "Your powder model has been deleted.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toz modeliniz silindi."])},
        "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sil"])}
      },
      "ZH": {
        "Powder Model List for line ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["生产线粉末模型列表 "])},
        "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["产品名称"])},
        "Updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已更新"])},
        "Created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已创建"])},
        "Remove Powder Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除粉末模型"])},
        "No PowderModels created.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未创建粉末模型"])},
        "Are you sure you want to delete Powder Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您确定要删除粉末模型吗"])},
        "You will not be able to revert this!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您将无法撤消此操作！"])},
        "Yes, delete it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是的，删除它"])},
        "No, cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不，取消"])},
        "Deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已删除"])},
        "Your powder model has been deleted.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的粉末模型已被删除。"])},
        "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除"])}
      }
    }
  })
}
