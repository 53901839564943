export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "EN": {
        "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "Experiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experiment"])},
        "Pistol to substrate distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pistol to substrate distance"])},
        "Line speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Line speed"])},
        "Air parameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Air parameter"])},
        "Booth side used for calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booth side used for calibration"])},
        "right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["right"])},
        "left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["left"])},
        "Nozzle Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nozzle Type"])},
        "Powder Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powder Amount"])},
        "Powder Manufacturer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powder Manufacturer"])},
        "Powder ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powder ID"])},
        "Additional Info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional Info"])},
        "Measurements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Measurements"])},
        "Point": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Point"])},
        "Powder Model Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powder Model Details"])},
        "Calibration type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibration type"])},
        "Powder Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powder Model"])},
        "static": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["static"])},
        "advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["advanced"])},
        "simple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["simple"])},
        "Calibrated line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibrated line"])},
        "Powder Model Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powder Model Type"])},
        "Pre-Calibrated Powder Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre-Calibrated Powder Model"])},
        "Calibration Parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibration Parameters"])},
        "MIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MIN"])},
        "MAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAX"])},
        "Calibration Measurements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibration Measurements"])},
        "PowderModel visualization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PowderModel visualization"])},
        "The scale of this simulation is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The scale of this simulation is"])},
        "Visualization size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualization size"])},
        "Distance and Air sliders only available for advanced powder models.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance and Air sliders only available for advanced powder models."])},
        "Compute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compute"])},
        "Continue to Create Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue to Create Optimization"])},
        "Go Back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go Back"])},
        "Calibration Plate Measurements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibration Plate Measurements"])},
        "-": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
        "left booth side": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["left booth side"])},
        "right booth side": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["right booth side"])},
        "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
        "evenly spaced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["evenly spaced"])},
        "unevenly spaced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unevenly spaced"])},
        "Booth Humidity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booth Humidity"])},
        "Booth Temperature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booth Temperature"])},
        "Pump Operational Hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pump Operational Hours"])},
        "Batch ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batch ID"])},
        "Guns Spacing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guns Spacing"])},
        "Other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
        "AkzoNobel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AkzoNobel"])},
        "Airflow or air volume selected in the powder coating machine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Airflow or air volume selected in the powder coating machine"])},
        "Quantity of powder dispensed by the gun per unit time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity of powder dispensed by the gun per unit time"])},
        "Operational Hours Since Last Maintanance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operational Hours Since Last Maintanance"])},
        "Operational Hours Injector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operational Hours Injector"])},
        "hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hours"])}
      },
      "DE": {
        "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "Experiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experiment"])},
        "Pistol to substrate distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abstand zwischen Pistole und Substrat"])},
        "Line speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kettengeschwindigkeit"])},
        "Air parameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luftparameter"])},
        "Booth side used for calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kabinenseite für die Kalibrierung"])},
        "right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rechts"])},
        "left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["links"])},
        "Nozzle Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Düsentyp"])},
        "Powder Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pulvermenge"])},
        "Powder Manufacturer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pulverhersteller"])},
        "Powder ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pulver ID"])},
        "Additional Info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusätzliche Informationen"])},
        "Measurements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messungen"])},
        "Point": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punkt"])},
        "Powder Model Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pulvermodell Details"])},
        "Calibration type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrierungstyp"])},
        "Powder Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pulvermodell"])},
        "static": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["statisch"])},
        "advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fortgeschritten"])},
        "simple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["einfach"])},
        "Calibrated line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrierte Linie"])},
        "Powder Model Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pulvermodelltyp"])},
        "Pre-Calibrated Powder Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorkalibriertes Pulvermodell"])},
        "Calibration Parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrierungsparameter"])},
        "MIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MIN"])},
        "MAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAX"])},
        "Calibration Measurements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrierungsmessungen"])},
        "PowderModel visualization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pulvermodell Visualisierung"])},
        "The scale of this simulation is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Skala dieser Simulation ist"])},
        "Visualization size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualisierungsgröße"])},
        "Distance and Air sliders only available for advanced powder models.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abstands und Luftregler nur für fortgeschrittene Pulvermodelle verfügbar."])},
        "Compute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berechnen"])},
        "Continue to Create Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter zur Erstellung der Optimierung"])},
        "Go Back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])},
        "Calibration Plate Measurements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrierplattenmessungen"])},
        "-": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
        "left booth side": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["linke Kabinenseite"])},
        "right booth side": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rechte Kabinenseite"])},
        "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hauptseite"])},
        "evenly spaced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gleichmäßig verteilt"])},
        "unevenly spaced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ungleichmäßig verteilt"])},
        "Booth Humidity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kabinenfeuchtigkeit"])},
        "Booth Temperature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kabinentemperatur"])},
        "Pump Operational Hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betriebsstunden der Pumpe"])},
        "Batch ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chargen-ID"])},
        "Guns Spacing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pistolenabstand"])},
        "Other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere"])},
        "AkzoNobel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AkzoNobel"])},
        "Airflow or air volume selected in the powder coating machine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luftstrom oder Luftvolumen, das in der Pulverbeschichtungsmaschine ausgewählt wurde"])},
        "Quantity of powder dispensed by the gun per unit time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menge an Pulver, die pro Zeiteinheit von der Pistole abgegeben wird"])},
        "Operational Hours Since Last Maintanance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betriebsstunden seit der letzten Wartung"])},
        "Operational Hours Injector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betriebsstunden Injektor"])},
        "hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stunden"])}
      },
      "ES": {
        "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
        "Experiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experimento"])},
        "Pistol to substrate distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distancia de la pistola al sustrato"])},
        "Line speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velocidad de la línea"])},
        "Air parameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parámetro de flujo aire"])},
        "Booth side used for calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lado de la cabina utilizado para la calibración"])},
        "right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["derecho"])},
        "left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["izquierdo"])},
        "Nozzle Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de boquilla"])},
        "Powder Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad de pintura en polvo"])},
        "Powder Manufacturer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabricante de pintura en polvo"])},
        "Powder ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de pintura en polvo"])},
        "Additional Info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información adicional"])},
        "Measurements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mediciones"])},
        "Point": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punto"])},
        "Powder Model Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalles del modelo de pintura en polvo"])},
        "Calibration type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de calibración"])},
        "Powder Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modelo de pintura en polvo"])},
        "static": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["estático"])},
        "advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["avanzado"])},
        "simple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["simple"])},
        "Calibrated line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Línea calibrada"])},
        "Powder Model Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de modelo de pintura en polvo"])},
        "Pre-Calibrated Powder Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modelo de pintura en polvo pre-calibrado"])},
        "Calibration Parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parámetros de calibración"])},
        "MIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MIN"])},
        "MAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAX"])},
        "Calibration Measurements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mediciones de calibración"])},
        "PowderModel visualization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualización del modelo de pintura en polvo"])},
        "The scale of this simulation is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La escala de esta simulación es"])},
        "Visualization size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamaño de la visualización"])},
        "Distance and Air sliders only available for advanced powder models.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los controles de distancia y aire solo están disponibles para modelos de pintura en polvo avanzados."])},
        "Compute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calcular"])},
        "Continue to Create Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar para crear optimización"])},
        "Go Back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver a"])},
        "Calibration Plate Measurements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mediciones de la placa de calibración"])},
        "-": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
        "left booth side": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lado izquierdo de la cabina"])},
        "right booth side": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lado derecho de la cabina"])},
        "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicio"])},
        "evenly spaced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["equidistantes"])},
        "unevenly spaced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no equidistantes"])},
        "Booth Humidity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Humedad de la cabina"])},
        "Booth Temperature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temperatura de la cabina"])},
        "Pump Operational Hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horas de funcionamiento de la bomba"])},
        "Batch ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID del lote"])},
        "Guns Spacing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espaciado de las pistolas"])},
        "Other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otro"])},
        "AkzoNobel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AkzoNobel"])},
        "Airflow or air volume selected in the powder coating machine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flujo de aire o volumen de aire seleccionado en la máquina de recubrimiento en polvo"])},
        "Quantity of powder dispensed by the gun per unit time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad de pintura en polvo dispensada por la pistola por unidad de tiempo"])},
        "Operational Hours Since Last Maintanance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horas de funcionamiento desde el último mantenimiento"])},
        "Operational Hours Injector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horas de funcionamiento del inyector"])},
        "hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["horas"])}
      },
      "FR": {
        "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
        "Experiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expérience"])},
        "Pistol to substrate distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance entre le pistolet et le substrat"])},
        "Line speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vitesse de la ligne"])},
        "Air parameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètre d'air"])},
        "Booth side used for calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Côté de la cabine utilisé pour l'étalonnage"])},
        "right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["droite"])},
        "left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gauche"])},
        "Nozzle Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de buse"])},
        "Powder Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantité de poudre"])},
        "Powder Manufacturer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabricant de poudre"])},
        "Powder ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poudre ID"])},
        "Additional Info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations supplémentaires"])},
        "Measurements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesures"])},
        "Point": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Point"])},
        "Powder Model Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails du modèle de poudre"])},
        "Calibration type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type d'étalonnage"])},
        "Powder Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modèle de poudre"])},
        "static": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["statique"])},
        "advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["avancé"])},
        "simple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["simple"])},
        "Calibrated line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ligne calibrée"])},
        "Powder Model Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de modèle de poudre"])},
        "Pre-Calibrated Powder Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modèle de poudre pré-étalonné"])},
        "Calibration Parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres d'étalonnage"])},
        "MIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MIN"])},
        "MAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAX"])},
        "Calibration Measurements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesures d'étalonnage"])},
        "PowderModel visualization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualisation du modèle de poudre"])},
        "The scale of this simulation is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'échelle de cette simulation est"])},
        "Visualization size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taille de la visualisation"])},
        "Distance and Air sliders only available for advanced powder models.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les curseurs de distance et d'air ne sont disponibles que pour les modèles de poudre avancés."])},
        "Compute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculer"])},
        "Continue to Create Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer à créer l'optimisation"])},
        "Go Back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arrière"])},
        "Calibration Plate Measurements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesures de la plaque de calibrage"])},
        "-": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
        "left booth side": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["côté cabine gauche"])},
        "right booth side": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["côté cabine droit"])},
        "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page d'accueil"])},
        "evenly spaced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["uniformément espacés"])},
        "unevenly spaced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["espacés de manière inégale"])},
        "Booth Humidity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Humidité de la cabine"])},
        "Booth Temperature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Température de la cabine"])},
        "Pump Operational Hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heures de fonctionnement de la pompe"])},
        "Batch ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID du lot"])},
        "Guns Spacing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espacement des pistolets"])},
        "Other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre"])},
        "AkzoNobel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AkzoNobel"])},
        "Airflow or air volume selected in the powder coating machine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Débit d'air ou volume d'air sélectionné dans la machine de revêtement en poudre"])},
        "Quantity of powder dispensed by the gun per unit time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantité de poudre distribuée par le pistolet par unité de temps"])},
        "Operational Hours Since Last Maintanance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heures de fonctionnement depuis la dernière maintenance"])},
        "Operational Hours Injector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heures de fonctionnement de l'injecteur"])},
        "hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["heures"])}
      },
      "IT": {
        "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
        "Experiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esperimento"])},
        "Pistol to substrate distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distanza pistola-substrato"])},
        "Line speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velocità di linea"])},
        "Air parameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametro dell'aria"])},
        "Booth side used for calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lato cabina utilizzato per la calibrazione"])},
        "right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["destra"])},
        "left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sinistra"])},
        "Nozzle Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di ugello"])},
        "Powder Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantità di polvere"])},
        "Powder Manufacturer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produttore di polvere"])},
        "Powder ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polvere ID"])},
        "Additional Info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni aggiuntive"])},
        "Measurements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Misure"])},
        "Point": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punto"])},
        "Powder Model Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli del modello di polvere"])},
        "Calibration type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di calibrazione"])},
        "Powder Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modello di polvere"])},
        "static": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["statico"])},
        "advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["avanzato"])},
        "simple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["semplice"])},
        "Calibrated line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linea calibrata"])},
        "Powder Model Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di modello di polvere"])},
        "Pre-Calibrated Powder Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modello di polvere pre-calibrato"])},
        "Calibration Parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametri di calibrazione"])},
        "MIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MIN"])},
        "MAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAX"])},
        "Calibration Measurements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Misure di calibrazione"])},
        "PowderModel visualization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizzazione del modello di polvere"])},
        "The scale of this simulation is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La scala di questa visualizzazione è"])},
        "Visualization size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dimensione della visualizzazione"])},
        "Distance and Air sliders only available for advanced powder models.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I cursori di distanza e aria sono disponibili solo per modelli di polvere avanzati."])},
        "Compute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calcolare"])},
        "Continue to Create Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continua a creare l'ottimizzazione"])},
        "Go Back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indietro"])},
        "Calibration Plate Measurements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Misure della piastra di calibrazione"])},
        "-": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
        "left booth side": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lato cabina sinistra"])},
        "right booth side": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lato cabina destra"])},
        "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagina iniziale"])},
        "evenly spaced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["uniformemente spaziati"])},
        "unevenly spaced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["spaziati in modo non uniforme"])},
        "Booth Humidity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umidità della cabina"])},
        "Booth Temperature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temperatura della cabina"])},
        "Pump Operational Hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ore operative della pompa"])},
        "Batch ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID batch"])},
        "Guns Spacing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spaziatura delle pistole"])},
        "Other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altro"])},
        "AkzoNobel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AkzoNobel"])},
        "Airflow or air volume selected in the powder coating machine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flusso d'aria o volume d'aria selezionato nella macchina di verniciatura a polvere"])},
        "Quantity of powder dispensed by the gun per unit time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantità di polvere erogata dalla pistola per unità di tempo"])},
        "Operational Hours Since Last Maintanance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ore operative dall'ultima manutenzione"])},
        "Operational Hours Injector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ore operative dell'iniettore"])},
        "hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ore"])}
      },
      "PL": {
        "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa"])},
        "Experiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eksperyment"])},
        "Pistol to substrate distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odległość pistoletu od podłoża"])},
        "Line speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prędkość linii"])},
        "Air parameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametr powietrza"])},
        "Booth side used for calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strona kabiny użyta do kalibracji"])},
        "right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["prawo"])},
        "left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lewo"])},
        "Nozzle Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ dyszy"])},
        "Powder Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilość proszku"])},
        "Powder Manufacturer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Producent proszku"])},
        "Powder ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proszku ID"])},
        "Additional Info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodatkowe informacje"])},
        "Measurements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pomiary"])},
        "Point": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punkt"])},
        "Powder Model Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szczegóły modelu proszku"])},
        "Calibration type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ kalibracji"])},
        "Powder Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Model proszku"])},
        "static": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["statyczny"])},
        "advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zaawansowany"])},
        "simple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["prosty"])},
        "Calibrated line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linia skalibrowana"])},
        "Powder Model Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ modelu proszku"])},
        "Pre-Calibrated Powder Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wstępnie skalibrowany model proszku"])},
        "Calibration Parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametry kalibracji"])},
        "MIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MIN"])},
        "MAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAX"])},
        "Calibration Measurements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pomiary kalibracyjne"])},
        "PowderModel visualization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wizualizacja modelu proszku"])},
        "The scale of this simulation is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skala tej symulacji to"])},
        "Visualization size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rozmiar wizualizacji"])},
        "Distance and Air sliders only available for advanced powder models.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suwaki odległości i powietrza dostępne są tylko dla zaawansowanych modeli proszkowych."])},
        "Compute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obliczać"])},
        "Continue to Create Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontynuuj tworzenie optymalizacji"])},
        "Go Back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powrót"])},
        "Calibration Plate Measurements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pomiary płyty kalibracyjnej"])},
        "-": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
        "left booth side": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lewa strona kabiny"])},
        "right booth side": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["prawa strona kabiny"])},
        "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strona główna"])},
        "evenly spaced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["równomiernie rozłożone"])},
        "unevenly spaced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unevenly spaced"])},
        "Booth Humidity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wilgotność kabiny"])},
        "Booth Temperature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temperatura kabiny"])},
        "Pump Operational Hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godziny pracy pompy"])},
        "Batch ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID partii"])},
        "Guns Spacing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rozstawienie pistoletów"])},
        "Other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inne"])},
        "AkzoNobel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AkzoNobel"])},
        "Airflow or air volume selected in the powder coating machine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przepływ powietrza lub objętość powietrza wybrana w maszynie do malowania proszkowego"])},
        "Quantity of powder dispensed by the gun per unit time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilość proszku wydawana przez pistolet na jednostkę czasu"])},
        "Operational Hours Since Last Maintanance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godziny pracy od ostatniego przeglądu"])},
        "Operational Hours Injector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godziny pracy wtryskiwacza"])},
        "hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["godziny"])}
      },
      "TR": {
        "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İsim"])},
        "Experiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deney"])},
        "Pistol to substrate distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabancadan alt tabakaya mesafe"])},
        "Line speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hat hızı"])},
        "Air parameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hava parametresi"])},
        "Booth side used for calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrasyon için kullanılan kabin tarafı"])},
        "right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sağ"])},
        "left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sol"])},
        "Nozzle Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nozul tipi"])},
        "Powder Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toz miktarı"])},
        "Powder Manufacturer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toz üreticisi"])},
        "Powder ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toz ID"])},
        "Additional Info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ek bilgi"])},
        "Measurements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ölçümler"])},
        "Point": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nokta"])},
        "Powder Model Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toz Modeli Detayları"])},
        "Calibration type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrasyon tipi"])},
        "Powder Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toz Modeli"])},
        "static": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["statik"])},
        "advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ileri"])},
        "simple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["basit"])},
        "Calibrated line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibre edilmiş çizgi"])},
        "Powder Model Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toz Modeli Tipi"])},
        "Pre-Calibrated Powder Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Önceden Kalibre Edilmiş Toz Modeli"])},
        "Calibration Parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrasyon Parametreleri"])},
        "MIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MIN"])},
        "MAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAX"])},
        "Calibration Measurements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrasyon Ölçümleri"])},
        "PowderModel visualization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toz Modeli görselleştirme"])},
        "The scale of this simulation is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu simülasyonun ölçeği"])},
        "Visualization size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görselleştirme boyutu"])},
        "Distance and Air sliders only available for advanced powder models.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesafe ve Hava kaydırıcıları yalnızca gelişmiş toz modelleri için kullanılabilir."])},
        "Compute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesaplamak"])},
        "Continue to Create Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimizasyon Oluşturmaya Devam Et"])},
        "Go Back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geri dön"])},
        "Calibration Plate Measurements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrasyon Plakası Ölçümleri"])},
        "-": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
        "left booth side": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sol kabin tarafı"])},
        "right booth side": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sağ kabin tarafı"])},
        "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ana Sayfa"])},
        "evenly spaced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eşit aralıklı"])},
        "unevenly spaced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eşit olmayan aralıklı"])},
        "Booth Humidity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kabin Nem"])},
        "Booth Temperature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kabin Sıcaklığı"])},
        "Pump Operational Hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pompa Çalışma Saatleri"])},
        "Batch ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toplu Kimlik"])},
        "Guns Spacing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabancaların aralığı"])},
        "Other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diğer"])},
        "AkzoNobel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AkzoNobel"])},
        "Airflow or air volume selected in the powder coating machine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toz kaplama makinesinde seçilen hava akışı veya hava hacmi"])},
        "Quantity of powder dispensed by the gun per unit time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabanca tarafından birim zamanda verilen toz miktarı"])},
        "Operational Hours Since Last Maintanance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Son Bakımdan Bu Yana Çalışma Saatleri"])},
        "Operational Hours Injector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enjektör Çalışma Saatleri"])},
        "hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["saat"])}
      },
      "ZH": {
        "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名称"])},
        "Experiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["实验"])},
        "Pistol to substrate distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手枪到基板的距离"])},
        "Line speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["线速度"])},
        "Air parameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["空气参数"])},
        "Booth side used for calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用于校准的展位侧"])},
        "right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对"])},
        "left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["左"])},
        "Nozzle Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["喷嘴类型"])},
        "Powder Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["粉末量"])},
        "Powder Manufacturer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["粉末制造商"])},
        "Powder ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["粉末ID"])},
        "Additional Info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["额外信息"])},
        "Measurements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["测量"])},
        "Point": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["点"])},
        "Powder Model Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["粉末模型细节"])},
        "Calibration type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["校准类型"])},
        "Powder Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["粉末模型"])},
        "static": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["静态"])},
        "advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["高级"])},
        "simple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["简单"])},
        "Calibrated line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["校准线"])},
        "Powder Model Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["粉末模型类型"])},
        "Pre-Calibrated Powder Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预校准粉末模型"])},
        "Calibration Parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["校准参数"])},
        "MIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最小"])},
        "MAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最大"])},
        "Calibration Measurements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["校准测量"])},
        "PowderModel visualization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["粉末模型可视化"])},
        "The scale of this simulation is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此模拟的比例是"])},
        "Visualization size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可视化大小"])},
        "Distance and Air sliders only available for advanced powder models.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["距离和空气滑块仅适用于高级粉末模型。"])},
        "Compute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["计算"])},
        "Continue to Create Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["继续创建优化"])},
        "Go Back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["转回去"])},
        "Calibration Plate Measurements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["校准板测量"])},
        "-": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
        "left booth side": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["左展位侧"])},
        "right booth side": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["右展位侧"])},
        "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主页"])},
        "evenly spaced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["均匀间隔"])},
        "unevenly spaced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不均匀间隔"])},
        "Booth Humidity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["展位湿度"])},
        "Booth Temperature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["展位温度"])},
        "Pump Operational Hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["泵操作小时"])},
        "Batch ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["批次ID"])},
        "Guns Spacing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["枪支间距"])},
        "Other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["其他"])},
        "AkzoNobel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AkzoNobel"])},
        "Airflow or air volume selected in the powder coating machine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["粉末涂层机中选择的气流或空气体积"])},
        "Quantity of powder dispensed by the gun per unit time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["枪每单位时间分配的粉末量"])},
        "Operational Hours Since Last Maintanance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上次维护以来的运行时间"])},
        "Operational Hours Injector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["喷油嘴运行小时"])},
        "hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小时"])}
      }
    }
  })
}
