export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "EN": {
        "Line Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Line Name"])},
        "Created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created"])}
      },
      "DE": {
        "Line Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linienname"])},
        "Created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellt"])}
      },
      "ES": {
        "Line Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de la Línea"])},
        "Created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creada"])}
      },
      "FR": {
        "Line Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de la Ligne"])},
        "Created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créée"])}
      },
      "IT": {
        "Line Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome della Linea"])},
        "Created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creata"])}
      },
      "PL": {
        "Line Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa Linii"])},
        "Created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utworzony"])}
      },
      "TR": {
        "Line Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hat İsmi"])},
        "Created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oluşturuldu"])}
      },
      "ZH": {
        "Line Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["线路名称"])},
        "Created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建"])}
      }
    }
  })
}
