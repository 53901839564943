export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "EN": {
        "Creating Powder Model for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creating Powder Model for"])},
        "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "Experiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experiment"])},
        "Gun Nozzle Slit orientation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun Nozzle Slit orientation"])},
        "Gun movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun movement"])},
        "Deactivated reciprocator. Non-moving guns.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deactivated reciprocator. Non-moving guns."])},
        "Gun to substrate distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun to substrate distance"])},
        "Nozzle orientation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nozzle orientation"])},
        "Powder model name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powder model name"])},
        "Line speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Line speed"])},
        "Optional Parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optional Parameters"])},
        "Air parameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Air parameter"])},
        "Booth side used for calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booth side used for calibration"])},
        "right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["right"])},
        "left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["left"])},
        "Nozzle Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nozzle Type"])},
        "Powder Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powder Amount"])},
        "Powder Manufacturer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powder Manufacturer"])},
        "Powder ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powder ID"])},
        "Additional Info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional Info"])},
        "Continue to Experiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue to Experiment"])},
        "Measurements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Measurements"])},
        "Point": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Point"])},
        "Fitting PowderModel Distribution from measurements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fitting PowderModel Distribution from measurements"])},
        "Create Powder Distribution Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Powder Distribution Model"])},
        "Powder Model created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powder Model created"])},
        "was created successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["was created successfully"])},
        "Configuration for Experiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration for Experiment"])},
        "Required Fields missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required Fields missing"])},
        "Please fill the following fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill the following fields"])},
        "PowderModel was not created. Try again.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PowderModel was not created. Try again."])},
        "Horizontal (standard orientation)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horizontal (standard orientation)"])},
        "Vertical (turned 90°)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertical (turned 90°)"])},
        "Calibration Parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibration Parameters"])},
        "MIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MIN"])},
        "MAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAX"])},
        "Measurements for Experiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Measurements for Experiment"])},
        "evenly spaced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["evenly spaced"])},
        "unevenly spaced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unevenly spaced"])},
        "Booth Humidity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booth Humidity"])},
        "Booth Temperature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booth Temperature"])},
        "Pump Operational Hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pump Operational Hours"])},
        "Batch ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batch ID"])},
        "Guns Spacing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guns Spacing"])},
        "Other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
        "AkzoNobel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AkzoNobel"])},
        "Airflow or air volume selected in the powder coating machine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Airflow or air volume selected in the powder coating machine"])},
        "Quantity of powder dispensed by the gun per unit time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity of powder dispensed by the gun per unit time"])},
        "Operational Hours Since Last Maintanance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operational Hours Since Last Maintanance"])},
        "Operational Hours Injector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operational Hours Injector"])}
      },
      "DE": {
        "Creating Powder Model for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellung des Sprühbild für"])},
        "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "Experiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experiment"])},
        "Gun Nozzle Slit orientation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausrichten des Düsenschlitzes der Spritzpistole"])},
        "Gun movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pistolenbewegung"])},
        "Deactivated reciprocator. Non-moving guns.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deaktiviertes Hubgerät. Pistolen stehen still."])},
        "Gun to substrate distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abstand zwischen Pistole und Substrat"])},
        "Nozzle orientation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausrichtung der Düsen"])},
        "Powder model name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name des Sprühbild"])},
        "Line speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kettengeschwindigkeit"])},
        "Optional Parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optionale Parameter"])},
        "Air parameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luftparameter"])},
        "Booth side used for calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kabinenseite für die Kalibrierung"])},
        "right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rechts"])},
        "left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["links"])},
        "Nozzle Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Düsentyp"])},
        "Powder Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pulvermenge"])},
        "Powder Manufacturer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pulverhersteller"])},
        "Powder ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pulver ID"])},
        "Additional Info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusätzliche Informationen"])},
        "Continue to Experiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter zum Experiment"])},
        "Measurements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messungen"])},
        "Point": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punkt"])},
        "Fitting PowderModel Distribution from measurements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anpassung der Pulvermodellverteilung anhand von Messungen"])},
        "Create Powder Distribution Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen des Sprühbilds"])},
        "Powder Model created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pulvermodell erstellt"])},
        "was created successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wurde erfolgreich erstellt"])},
        "Configuration for Experiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfiguration für Experiment"])},
        "Required Fields missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erforderliche Felder fehlen"])},
        "Please fill the following fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte füllen Sie die folgenden Felder aus"])},
        "PowderModel was not created. Try again.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprühbild wurde nicht erstellt. Versuchen Sie es erneut."])},
        "Horizontal (standard orientation)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horizontal (Standardausrichtung)"])},
        "Vertical (turned 90°)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertikal (um 90° gedreht)"])},
        "Calibration Parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrierungsparameter"])},
        "MIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MIN"])},
        "MAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAX"])},
        "Measurements for Experiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messungen für Experiment"])},
        "evenly spaced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gleichmäßig verteilt"])},
        "unevenly spaced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ungleichmäßig verteilt"])},
        "Booth Humidity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kabinenfeuchtigkeit"])},
        "Booth Temperature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kabinentemperatur"])},
        "Pump Operational Hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betriebsstunden der Pumpe"])},
        "Batch ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chargen-ID"])},
        "Guns Spacing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pistolenabstand"])},
        "Other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere"])},
        "AkzoNobel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AkzoNobel"])},
        "Airflow or air volume selected in the powder coating machine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luftstrom oder Luftvolumen, das in der Pulverbeschichtungsmaschine ausgewählt wurde"])},
        "Quantity of powder dispensed by the gun per unit time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menge an Pulver, die pro Zeiteinheit von der Pistole abgegeben wird"])},
        "Operational Hours Since Last Maintanance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betriebsstunden seit der letzten Wartung"])},
        "Operational Hours Injector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betriebsstunden Injektor"])}
      },
      "ES": {
        "Creating Powder Model for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creando modelo de pintura en polvo para"])},
        "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
        "Experiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experimento"])},
        "Gun Nozzle Slit orientation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orientación de la boquilla de las pistolas"])},
        "Gun movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Movimiento de las pistolas"])},
        "Deactivated reciprocator. Non-moving guns.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reciprocador desactivado. Pistolas inmóviles."])},
        "Gun to substrate distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distancia de la pistola al sustrato"])},
        "Nozzle orientation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orientación de la boquilla"])},
        "Powder model name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre del modelo de pintura en polvo"])},
        "Line speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velocidad de la línea"])},
        "Optional Parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parámetros opcionales"])},
        "Air parameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parámetro de flujo aire"])},
        "Booth side used for calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lado de la cabina utilizado para la calibración"])},
        "right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["derecho"])},
        "left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["izquierdo"])},
        "Nozzle Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de boquilla"])},
        "Powder Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad de pintura en polvo"])},
        "Powder Manufacturer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabricante de pintura en polvo"])},
        "Powder ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de pintura en polvo"])},
        "Additional Info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información adicional"])},
        "Continue to Experiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar con el experimento"])},
        "Measurements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mediciones"])},
        "Point": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punto"])},
        "Fitting PowderModel Distribution from measurements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimación del modelo a partir de las mediciones"])},
        "Create Powder Distribution Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear modelo de distribución de polvo"])},
        "Powder Model created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modelo de pintura en polvo creado"])},
        "was created successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["se creó con éxito"])},
        "Configuration for Experiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración para el experimento"])},
        "Required Fields missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faltan campos obligatorios"])},
        "Please fill the following fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, rellene los siguientes campos"])},
        "PowderModel was not created. Try again.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El modelo de pintura en polvo no se creó. Inténtalo de nuevo."])},
        "Horizontal (standard orientation)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horizontal (orientación estándar)"])},
        "Vertical (turned 90°)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertical (girado 90°)"])},
        "Calibration Parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parámetros de calibración"])},
        "MIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MIN"])},
        "MAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAX"])},
        "Measurements for Experiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mediciones para el experimento"])},
        "evenly spaced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["equidistantes"])},
        "unevenly spaced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no equidistantes"])},
        "Booth Humidity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Humedad de la cabina"])},
        "Booth Temperature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temperatura de la cabina"])},
        "Pump Operational Hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horas de funcionamiento de la bomba"])},
        "Batch ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID del lote"])},
        "Guns Spacing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espaciado de las pistolas"])},
        "Other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otro"])},
        "AkzoNobel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AkzoNobel"])},
        "Airflow or air volume selected in the powder coating machine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flujo de aire o volumen de aire seleccionado en la máquina de recubrimiento en polvo"])},
        "Quantity of powder dispensed by the gun per unit time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad de pintura en polvo dispensada por la pistola por unidad de tiempo"])},
        "Operational Hours Since Last Maintanance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horas de funcionamiento desde el último mantenimiento"])},
        "Operational Hours Injector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horas de funcionamiento del inyector"])}
      },
      "FR": {
        "Creating Powder Model for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Création du modèle de poudre pour"])},
        "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
        "Experiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expérience"])},
        "Gun Nozzle Slit orientation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orientation de la buse des pistolets"])},
        "Gun movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mouvement des pistolets"])},
        "Deactivated reciprocator. Non-moving guns.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reciprocator désactivé. Pistolets immobiles."])},
        "Gun to substrate distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance entre le pistolet et le substrat"])},
        "Nozzle orientation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orientation de la buse"])},
        "Powder model name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du modèle de poudre"])},
        "Line speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vitesse de la ligne"])},
        "Optional Parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres optionnels"])},
        "Air parameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètre d'air"])},
        "Booth side used for calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Côté de la cabine utilisé pour l'étalonnage"])},
        "right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["droite"])},
        "left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gauche"])},
        "Nozzle Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de buse"])},
        "Powder Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantité de poudre"])},
        "Powder Manufacturer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabricant de poudre"])},
        "Powder ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poudre ID"])},
        "Additional Info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations supplémentaires"])},
        "Continue to Experiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer l'expérience"])},
        "Measurements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesures"])},
        "Point": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Point"])},
        "Fitting PowderModel Distribution from measurements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajustement de la distribution du modèle de poudre à partir des mesures"])},
        "Create Powder Distribution Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un modèle de distribution de poudre"])},
        "Powder Model created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modèle de poudre créé"])},
        "was created successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a été créé avec succès"])},
        "Configuration for Experiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration pour l'expérience"])},
        "Required Fields missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Champs obligatoires manquants"])},
        "Please fill the following fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez remplir les champs suivants"])},
        "PowderModel was not created. Try again.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le modèle de poudre n'a pas été créé. Réessayer."])},
        "Horizontal (standard orientation)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horizontal (orientation standard)"])},
        "Vertical (turned 90°)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertical (tourné de 90°)"])},
        "Calibration Parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres d'étalonnage"])},
        "MIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MIN"])},
        "MAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAX"])},
        "Measurements for Experiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesures pour l'expérience"])},
        "evenly spaced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["uniformément espacés"])},
        "unevenly spaced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["espacés de manière inégale"])},
        "Booth Humidity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Humidité de la cabine"])},
        "Booth Temperature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Température de la cabine"])},
        "Pump Operational Hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heures de fonctionnement de la pompe"])},
        "Batch ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID du lot"])},
        "Guns Spacing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espacement des pistolets"])},
        "Other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre"])},
        "AkzoNobel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AkzoNobel"])},
        "Airflow or air volume selected in the powder coating machine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Débit d'air ou volume d'air sélectionné dans la machine de revêtement en poudre"])},
        "Quantity of powder dispensed by the gun per unit time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantité de poudre distribuée par le pistolet par unité de temps"])},
        "Operational Hours Since Last Maintanance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heures de fonctionnement depuis la dernière maintenance"])},
        "Operational Hours Injector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heures de fonctionnement de l'injecteur"])}
      },
      "IT": {
        "Creating Powder Model for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creazione del modello di polvere per"])},
        "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
        "Experiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esperimento"])},
        "Gun Nozzle Slit orientation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orientamento della bocchetta della pistola"])},
        "Gun movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Movimento delle pistole"])},
        "Deactivated reciprocator. Non-moving guns.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reciprocator disattivato. Pistole non in movimento."])},
        "Gun to substrate distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distanza pistola-substrato"])},
        "Nozzle orientation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orientamento della bocchetta"])},
        "Powder model name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome del modello di polvere"])},
        "Line speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velocità di linea"])},
        "Optional Parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametri opzionali"])},
        "Air parameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametro dell'aria"])},
        "Booth side used for calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lato cabina utilizzato per la calibrazione"])},
        "right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["destra"])},
        "left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sinistra"])},
        "Nozzle Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di ugello"])},
        "Powder Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantità di polvere"])},
        "Powder Manufacturer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produttore di polvere"])},
        "Powder ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polvere ID"])},
        "Additional Info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni aggiuntive"])},
        "Continue to Experiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continua con l'esperimento"])},
        "Measurements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Misure"])},
        "Point": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punto"])},
        "Fitting PowderModel Distribution from measurements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adattamento della distribuzione del modello di polvere dalle misurazioni"])},
        "Create Powder Distribution Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea modello di distribuzione di polvere"])},
        "Powder Model created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modello di polvere creato"])},
        "was created successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["è stato creato con successo"])},
        "Configuration for Experiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurazione per l'esperimento"])},
        "Required Fields missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campi obbligatori mancanti"])},
        "Please fill the following fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si prega di compilare i seguenti campi"])},
        "PowderModel was not created. Try again.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il modello di polvere non è stato creato. Riprova."])},
        "Horizontal (standard orientation)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orizzontale (orientamento standard)"])},
        "Vertical (turned 90°)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verticale (ruotato di 90°)"])},
        "Calibration Parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametri di calibrazione"])},
        "MIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MIN"])},
        "MAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAX"])},
        "Measurements for Experiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Misure per l'esperimento"])},
        "evenly spaced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["uniformemente spaziati"])},
        "unevenly spaced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["spaziati in modo non uniforme"])},
        "Booth Humidity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umidità della cabina"])},
        "Booth Temperature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temperatura della cabina"])},
        "Pump Operational Hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ore operative della pompa"])},
        "Batch ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID batch"])},
        "Guns Spacing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spaziatura delle pistole"])},
        "Other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altro"])},
        "AkzoNobel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AkzoNobel"])},
        "Airflow or air volume selected in the powder coating machine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flusso d'aria o volume d'aria selezionato nella macchina di verniciatura a polvere"])},
        "Quantity of powder dispensed by the gun per unit time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantità di polvere erogata dalla pistola per unità di tempo"])},
        "Operational Hours Since Last Maintanance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ore operative dall'ultima manutenzione"])},
        "Operational Hours Injector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ore operative dell'iniettore"])}
      },
      "PL": {
        "Creating Powder Model for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tworzenie modelu proszkowego dla"])},
        "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa"])},
        "Experiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eksperyment"])},
        "Gun Nozzle Slit orientation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orientacja szczeliny dyszy pistoletów"])},
        "Gun movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ruch pistoletów"])},
        "Deactivated reciprocator. Non-moving guns.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dezaktywowany reciprocator. Pistolety nieruchome."])},
        "Gun to substrate distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odległość pistoletu od podłoża"])},
        "Nozzle orientation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orientacja dyszy"])},
        "Powder model name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa modelu proszkowego"])},
        "Line speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prędkość linii"])},
        "Optional Parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametry opcjonalne"])},
        "Air parameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametr powietrza"])},
        "Booth side used for calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strona kabiny użyta do kalibracji"])},
        "right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["prawo"])},
        "left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lewo"])},
        "Nozzle Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ dyszy"])},
        "Powder Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilość proszku"])},
        "Powder Manufacturer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Producent proszku"])},
        "Powder ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proszku ID"])},
        "Additional Info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodatkowe informacje"])},
        "Continue to Experiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontynuuj eksperyment"])},
        "Measurements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pomiary"])},
        "Point": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punkt"])},
        "Fitting PowderModel Distribution from measurements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dopasowanie rozkładu modelu proszkowego z pomiarów"])},
        "Create Powder Distribution Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz model dystrybucji proszku"])},
        "Powder Model created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Model proszku utworzony"])},
        "was created successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["został utworzony pomyślnie"])},
        "Configuration for Experiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfiguracja do eksperymentu"])},
        "Required Fields missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brakujące wymagane pola"])},
        "Please fill the following fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proszę wypełnić następujące pola"])},
        "PowderModel was not created. Try again.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Model proszku nie został utworzony. Spróbuj ponownie."])},
        "Horizontal (standard orientation)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poziomo (standardowa orientacja)"])},
        "Vertical (turned 90°)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pionowo (obrócone o 90°)"])},
        "Calibration Parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametry kalibracji"])},
        "MIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MIN"])},
        "MAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAX"])},
        "Measurements for Experiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pomiary do eksperymentu"])},
        "evenly spaced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["równomiernie rozłożone"])},
        "unevenly spaced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unevenly spaced"])},
        "Booth Humidity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wilgotność kabiny"])},
        "Booth Temperature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temperatura kabiny"])},
        "Pump Operational Hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godziny pracy pompy"])},
        "Batch ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID partii"])},
        "Guns Spacing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rozstawienie pistoletów"])},
        "Other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inne"])},
        "AkzoNobel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AkzoNobel"])},
        "Airflow or air volume selected in the powder coating machine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przepływ powietrza lub objętość powietrza wybrana w maszynie do malowania proszkowego"])},
        "Quantity of powder dispensed by the gun per unit time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilość proszku wydawana przez pistolet na jednostkę czasu"])},
        "Operational Hours Since Last Maintanance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godziny pracy od ostatniego przeglądu"])},
        "Operational Hours Injector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godziny pracy wtryskiwacza"])}
      },
      "TR": {
        "Creating Powder Model for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İçin Toz Modeli Oluşturma"])},
        "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İsim"])},
        "Experiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deney"])},
        "Gun Nozzle Slit orientation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabancaların Nozul Yarığı Yönelimi"])},
        "Gun movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabancaların hareketi"])},
        "Deactivated reciprocator. Non-moving guns.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deaktive edilmiş reciprocator. Hareketsiz tabancalar."])},
        "Gun to substrate distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabancadan alt tabakaya mesafe"])},
        "Nozzle orientation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nozul yönelimi"])},
        "Powder model name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toz modeli adı"])},
        "Line speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hat hızı"])},
        "Optional Parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İsteğe bağlı parametreler"])},
        "Air parameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hava parametresi"])},
        "Booth side used for calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrasyon için kullanılan kabin tarafı"])},
        "right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sağ"])},
        "left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sol"])},
        "Nozzle Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nozul tipi"])},
        "Powder Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toz miktarı"])},
        "Powder Manufacturer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toz üreticisi"])},
        "Powder ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toz ID"])},
        "Additional Info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ek bilgi"])},
        "Continue to Experiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deneye devam et"])},
        "Measurements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ölçümler"])},
        "Point": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nokta"])},
        "Fitting PowderModel Distribution from measurements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ölçümlerden Toz Modeli Dağılımı Uyumu"])},
        "Create Powder Distribution Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toz Dağıtım Modeli Oluştur"])},
        "Powder Model created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toz Modeli oluşturuldu"])},
        "was created successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["başarıyla oluşturuldu"])},
        "Configuration for Experiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deney için yapılandırma"])},
        "Required Fields missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerekli Alanlar eksik"])},
        "Please fill the following fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen aşağıdaki alanları doldurun"])},
        "PowderModel was not created. Try again.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toz Modeli oluşturulamadı. Tekrar deneyin."])},
        "Horizontal (standard orientation)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yatay (standart yönelim)"])},
        "Vertical (turned 90°)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dikey (90° döndürülmüş)"])},
        "Calibration Parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrasyon Parametreleri"])},
        "MIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MIN"])},
        "MAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAX"])},
        "Measurements for Experiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deney için Ölçümler"])},
        "evenly spaced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eşit aralıklı"])},
        "unevenly spaced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eşit olmayan aralıklı"])},
        "Booth Humidity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kabin Nem"])},
        "Booth Temperature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kabin Sıcaklığı"])},
        "Pump Operational Hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pompa Çalışma Saatleri"])},
        "Batch ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toplu Kimlik"])},
        "Guns Spacing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabancaların aralığı"])},
        "Other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diğer"])},
        "AkzoNobel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AkzoNobel"])},
        "Airflow or air volume selected in the powder coating machine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toz kaplama makinesinde seçilen hava akışı veya hava hacmi"])},
        "Quantity of powder dispensed by the gun per unit time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabanca tarafından birim zamanda verilen toz miktarı"])},
        "Operational Hours Since Last Maintanance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Son Bakımdan Bu Yana Çalışma Saatleri"])},
        "Operational Hours Injector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enjektör Çalışma Saatleri"])}
      },
      "ZH": {
        "Creating Powder Model for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建粉末模型"])},
        "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名称"])},
        "Experiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["实验"])},
        "Gun Nozzle Slit orientation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手枪喷嘴缝隙方向"])},
        "Gun movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手枪运动"])},
        "Deactivated reciprocator. Non-moving guns.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已停用往复运动。 静止手枪。"])},
        "Gun to substrate distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手枪到基板的距离"])},
        "Nozzle orientation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["喷嘴方向"])},
        "Powder model name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["粉末模型名称"])},
        "Line speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["线速度"])},
        "Optional Parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可选参数"])},
        "Air parameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["空气参数"])},
        "Booth side used for calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用于校准的展位侧"])},
        "right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对"])},
        "left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["左"])},
        "Nozzle Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["喷嘴类型"])},
        "Powder Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["粉末量"])},
        "Powder Manufacturer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["粉末制造商"])},
        "Powder ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["粉末ID"])},
        "Additional Info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["额外信息"])},
        "Continue to Experiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["继续实验"])},
        "Measurements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["测量"])},
        "Point": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["点"])},
        "Fitting PowderModel Distribution from measurements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["从测量中拟合粉末模型分布"])},
        "Create Powder Distribution Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建粉末分布模型"])},
        "Powder Model created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["粉末模型已创建"])},
        "was created successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已成功创建"])},
        "Configuration for Experiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["实验配置"])},
        "Required Fields missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["缺少必填字段"])},
        "Please fill the following fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请填写以下字段"])},
        "PowderModel was not created. Try again.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未创建粉末模型。请重试。"])},
        "Horizontal (standard orientation)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["水平（标准方向）"])},
        "Vertical (turned 90°)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["垂直（旋转90°）"])},
        "Calibration Parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["校准参数"])},
        "MIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最小"])},
        "MAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最大"])},
        "Measurements for Experiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["实验测量"])},
        "evenly spaced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["均匀间隔"])},
        "unevenly spaced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不均匀间隔"])},
        "Booth Humidity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["展位湿度"])},
        "Booth Temperature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["展位温度"])},
        "Pump Operational Hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["泵操作小时"])},
        "Batch ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["批次ID"])},
        "Guns Spacing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["枪支间距"])},
        "Other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["其他"])},
        "AkzoNobel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AkzoNobel"])},
        "Airflow or air volume selected in the powder coating machine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["粉末涂层机中选择的气流或空气体积"])},
        "Quantity of powder dispensed by the gun per unit time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["枪每单位时间分配的粉末量"])},
        "Operational Hours Since Last Maintanance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上次维护以来的运行时间"])},
        "Operational Hours Injector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["喷油嘴运行小时"])}
      }
    }
  })
}
