export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "EN": {
        "Powder Models Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powder Models Library"])},
        "Create Powder Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Powder Model"])},
        "New Powder Calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Powder Calibration"])},
        "New Advanced Powder Calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Advanced Powder Calibration"])},
        "New Static Powder Calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Static Powder Calibration"])},
        "Estimated 10 mins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimated 10 mins"])},
        "Estimated 30 mins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimated 30 mins"])},
        "Estimated 6 mins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimated 6 mins"])},
        "Show advanced powder model calibration options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show advanced powder model calibration options"])}
      },
      "DE": {
        "Powder Models Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bibliothek der Sprühbilder"])},
        "Create Powder Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pulver-Modell Erstellen"])},
        "New Powder Calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Pulver-Kalibrierung"])},
        "New Advanced Powder Calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Erweiterte Pulver-Kalibrierung"])},
        "New Static Powder Calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue statische Pulver-Kalibrierung"])},
        "Estimated 10 mins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschätzte 10 Min"])},
        "Estimated 30 mins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschätzte 30 Min"])},
        "Estimated 6 mins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschätzte 6 Min"])},
        "Show advanced powder model calibration options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erweiterte Optionen zur Kalibrierung des Pulvermodells anzeigen"])}
      },
      "ES": {
        "Powder Models Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de Modelos de Polvo"])},
        "Create Powder Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear Modelo de Polvo"])},
        "New Powder Calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva Calibración de Modelo de Polvo"])},
        "New Advanced Powder Calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva Calibración Avanzada de Modelo de Polvo"])},
        "New Static Powder Calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva Calibración Estática de Modelo de Polvo"])},
        "Estimated 10 mins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiempo Estimado: 10 min"])},
        "Estimated 30 mins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiempo Estimado: 30 min"])},
        "Estimated 6 mins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiempo Estimado: 6 min"])},
        "Show advanced powder model calibration options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar opciones de calibración avanzadas"])}
      },
      "FR": {
        "Powder Models Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bibliothèque de Modèles de Poudres"])},
        "Create Powder Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer Modèle de Poudre"])},
        "New Powder Calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau Calibrage de la Poudre"])},
        "New Advanced Powder Calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau Calibrage Statique des Poudres"])},
        "New Static Powder Calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Static Powder Calibration"])},
        "Estimated 10 mins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimation de 10 min"])},
        "Estimated 30 mins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimation de 30 min"])},
        "Estimated 6 mins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimation de 6 min"])},
        "Show advanced powder model calibration options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher les options avancées d'étalonnage du modèle de poudre"])}
      },
      "IT": {
        "Powder Models Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libreria dei Modelli di Polvere"])},
        "Create Powder Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creare Modello di Polvere"])},
        "New Powder Calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuova Calibrazione del Modello di Polvere"])},
        "New Advanced Powder Calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuova Calibrazione Avanzata del Modello di Polvere"])},
        "New Static Powder Calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuova Calibrazione Statica del Modello di Polvere"])},
        "Estimated 10 mins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempo Stimato 10 min"])},
        "Estimated 30 mins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempo Stimato 30 min"])},
        "Estimated 6 mins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempo Stimato 6 min"])},
        "Show advanced powder model calibration options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostra le opzioni avanzate di calibrazione del modello di polvere"])}
      },
      "PL": {
        "Powder Models Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biblioteka Modeli Proszkowych"])},
        "Create Powder Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz Model Proszku"])},
        "New Powder Calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nowa Kalibracja Proszku"])},
        "New Advanced Powder Calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nowa Zaawansowana Kalibracja Proszku"])},
        "New Static Powder Calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nowa Statyczna Kalibracja Proszku"])},
        "Estimated 10 mins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szacunkowo 10 min"])},
        "Estimated 30 mins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szacunkowo 30 min"])},
        "Estimated 6 mins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szacunkowo 6 min"])},
        "Show advanced powder model calibration options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokaż zaawansowane opcje kalibracji modelu proszku"])}
      },
      "TR": {
        "Powder Models Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toz Modelleri Kütüphanesi"])},
        "Create Powder Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toz Modeli Oluşturun"])},
        "New Powder Calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Toz Kalibrasyonu"])},
        "New Advanced Powder Calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Gelişmiş Toz Kalibrasyonu"])},
        "New Static Powder Calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Statik Toz Kalibrasyonu"])},
        "Estimated 10 mins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tahmini 10 dakika"])},
        "Estimated 30 mins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tahmini 30 dakika"])},
        "Estimated 6 mins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tahmini 6 dakika"])},
        "Show advanced powder model calibration options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelişmiş toz modeli kalibrasyon seçeneklerini göster"])}
      },
      "ZH": {
        "Powder Models Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["粉末模型库"])},
        "Create Powder Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建粉末模型"])},
        "New Powder Calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新粉末校准"])},
        "New Advanced Powder Calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新型高级粉末校准"])},
        "New Static Powder Calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新型静态粉末校准"])},
        "Estimated 10 mins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预计 10 分钟"])},
        "Estimated 30 mins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预计 30 分钟"])},
        "Estimated 6 mins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预计 6 分钟"])},
        "Show advanced powder model calibration options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["显示高级粉末模型校准选项"])}
      }
    }
  })
}
