<template>
  <div class="card">
    <div
      v-if="calculationInProgress.includes(userEmail)"
      class="card-body text-center"
    >
      <div style="width: 100%; height: 100%">
        <div
          class="p-3 pb-0 card-header"
          style="text-align: center"
        >
          <h6>{{ $t("Calculation running") }}</h6>
        </div>
        <div
          class="spinner-border mb-2"
          style="margin: auto; display: table; width: 50px; height: 50px"
          role="status"
        ></div>
      </div>
      <div
        class="d-flex justify-content-center my-4"
        style="height: 100%"
      >
        <div
          id="waitingCarousel"
          class="carousel carousel-dark slide"
          data-bs-ride="carousel"
          style="width: 1024px"
        >
          <div class="carousel-indicators">
            <button
              type="button"
              data-bs-target="#waitingCarousel"
              data-bs-slide-to="0"
              class="active"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#waitingCarousel"
              data-bs-slide-to="1"
            ></button>
            <button
              type="button"
              data-bs-target="#waitingCarousel"
              data-bs-slide-to="2"
            ></button>
          </div>
          <div class="carousel-inner">
            <div
              class="carousel-item active"
              data-bs-interval="6000"
            >
              <img
                src="https://d21tewxstkbxbj.cloudfront.net/flightpath-platform/public-assets/static/powder_savings_calculator_waiting_slide_1.png"
                alt="1"
                class="d-block w-100"
              />
            </div>
            <div
              class="carousel-item"
              data-bs-interval="6000"
            >
              <img
                src="https://d21tewxstkbxbj.cloudfront.net/flightpath-platform/public-assets/static/powder_savings_calculator_waiting_slide_2.gif"
                alt="2"
                class="d-block w-100"
              />
            </div>
            <div
              class="carousel-item"
              data-bs-interval="6000"
            >
              <img
                src="https://d21tewxstkbxbj.cloudfront.net/flightpath-platform/public-assets/static/powder_savings_calculator_waiting_slide_3.png"
                alt="2"
                class="d-block w-100"
              />
            </div>
          </div>
          <button
            class="carousel-control-prev"
            type="button"
            data-bs-target="#waitingCarousel"
            data-bs-slide="prev"
          >
            <span
              class="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            class="carousel-control-next"
            type="button"
            data-bs-target="#waitingCarousel"
            data-bs-slide="next"
          >
            <span
              class="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>
      <soft-progress
        :percentage="calculation_progress_percentage"
        variant="gradient"
        color="secondary"
      />
    </div>
    <div
      v-if="!calculationInProgress.includes(userEmail) && !FlightpathCoatingAISettingResultComputed"
      class="card-body"
    >
      <div style="width: 100%; height: 100%">
        <div
          class="my-4"
          style="margin: auto; display: table"
          role="status"
        >
          <h6>{{ $t("Something went wrong...") }}</h6>
        </div>
      </div>
    </div>
    <div
      v-else-if="FlightpathCoatingAISettingResultComputed"
      class="card-body"
    >
      <div class="row ms-auto mt-xl-0 mt-4">
        <div class="row mb-3 justify-content-center">
          <h5 class="mt-2 text-left">{{ $t("Simulation results") }}</h5>
          <div
            v-if="currentSettingsReceived"
            class="col-md-6 d-flex justify-content-center p-0"
          >
            <div
              class="p-0 card d-flex align-items-center"
              style="width: 95%; background-image: linear-gradient(310deg, #2152ff 0%, #21d4fd 100%)"
            >
              <div class="p-0 card-body">
                <div class="p-1 d-flex justify-content-center">
                  <div class="row">
                    <p class="mb-0 text-md text-capitalize font-weight-bold text-center text-white">
                      {{ $t("Current Settings") }}
                    </p>
                    <div class="col">
                      <p class="mb-0 text-md text-capitalize font-weight-bold text-center text-white">
                        {{ $t("Uniformity") }}
                      </p>
                      <h5 class="mb-0 font-weight-bolder text-center">
                        <span class="text-md font-weight-bolder text-center text-white">
                          {{
                            (Math.round(powder_savings_report.current_setting_uniformity_percentage * 10) / 10).toFixed(
                              1,
                            ) + "%"
                          }}
                        </span>
                      </h5>
                    </div>
                    <div class="col">
                      <p class="mb-0 text-md text-capitalize font-weight-bold text-center text-white">
                        {{ $t("Powder Waste") }}
                      </p>
                      <h5 class="mb-0 font-weight-bolder text-center">
                        <span class="text-md font-weight-bolder text-center text-white">
                          {{
                            (
                              Math.round(powder_savings_report.current_setting_powder_waste_percentage * 10) / 10
                            ).toFixed(1) + "%"
                          }}
                        </span>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 d-flex justify-content-center p-0">
            <div
              class="p-0 card"
              style="width: 95%; background-image: linear-gradient(310deg, #2152ff 0%, #21d4fd 100%)"
            >
              <div class="p-0 card-body">
                <div class="p-1 d-flex justify-content-center">
                  <div class="row">
                    <p class="mb-0 text-md text-capitalize font-weight-bold text-center text-white">
                      {{ $t("CoatingAI Settings") }}
                    </p>
                    <div class="col justify-content-between">
                      <p class="mb-0 text-md text-capitalize font-weight-bold text-center text-white">
                        {{ $t("Uniformity") }}
                      </p>
                      <h5 class="mb-0 font-weight-bolder text-center">
                        <span class="text-md font-weight-bolder text-center text-white">
                          {{
                            (Math.round(powder_savings_report.optimal_setting_uniformity_percentage * 10) / 10).toFixed(
                              1,
                            ) + "%"
                          }}
                          {{ UniformityDifference }}
                        </span>
                      </h5>
                    </div>
                    <div class="col">
                      <p class="mb-0 text-md text-capitalize font-weight-bold text-center text-white">
                        {{ $t("Powder Waste") }}
                      </p>
                      <h5 class="mb-0 font-weight-bolder text-center">
                        <span class="text-md font-weight-bolder text-center text-white">
                          {{
                            (
                              Math.round(powder_savings_report.optimal_setting_powder_waste_percentage * 10) / 10
                            ).toFixed(1) + "%"
                          }}
                          {{ PowderWasteDifference }}
                        </span>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-check form-switch">
        <input
          class="form-check-input"
          type="checkbox"
          :checked="visualize_simulated_images_toggle"
          :disabled="false"
          @change="visualize_simulated_images_toggle = !visualize_simulated_images_toggle"
        />
        <label class="form-check-label">
          {{ $t("Visualize graphs") }}
          <slot />
        </label>
        &nbsp;
        <i
          class="fa fa-info-circle"
          aria-hidden="true"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          data-container="body"
          data-animation="true"
          :title="$t('Activate Toggle to visualize simulation images')"
          style="scale: 0.8"
        >
        </i>
      </div>
      <div
        v-if="visualize_simulated_images_toggle"
        id="compute-optimal-settings-view"
        class="tab-pane container"
      >
        <div class="d-flex align-items-center justify-content-center mt-0">
          <div v-if="calculationInProgress.includes(userEmail)">
            <div
              class="p-3 pb-0 card-header"
              style="text-align: center"
            >
              <h6>{{ $t("Calculation running") }}</h6>
            </div>
            <div class="p-3 card-body">
              <div style="width: 100%; height: 100%">
                <div
                  class="spinner-border"
                  style="margin: auto; display: table; width: 100px; height: 100px"
                  role="status"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div v-if="calculationInProgress.includes(userEmail)">
          <div>
            <soft-progress
              :percentage="calculation_progress_percentage"
              variant="gradient"
              color="secondary"
            />
          </div>
        </div>
      </div>
      <div v-if="FlightpathCoatingAISettingResultComputed && !calculationInProgress.includes(userEmail)">
        <hr class="horizontal dark" />
        <div class="row pt-1">
          <div class="col-lg-12">
            <div class="card">
              <div
                v-if="visualize_simulated_images_toggle"
                class="row"
              >
                <div class="nav-wrapper position-relative ms-auto">
                  <ul class="nav nav-tabs nav-fill nav-justified p-1">
                    <li class="nav-item">
                      <a
                        class="nav-link active px-0 py-1 mb-0"
                        data-bs-toggle="tab"
                        href="#statistical_distribution_img_view"
                        style="color: #344767"
                      >
                        {{ $t("Uniformity comparison") }}
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link px-0 py-1 mb-0"
                        data-bs-toggle="tab"
                        href="#substrate_surface_img_view"
                        style="color: #344767"
                      >
                        {{ $t("Applied spray") }}
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link px-0 py-1 mb-0"
                        data-bs-toggle="tab"
                        href="#pistol_movement_chart_img_view"
                        style="color: #344767"
                      >
                        {{ $t("Movement chart") }}
                      </a>
                    </li>
                    <li
                      v-if="succesfullAnimationGeneration"
                      class="nav-item"
                    >
                      <a
                        class="nav-link px-0 py-1 mb-0"
                        data-bs-toggle="tab"
                        href="#applied_spray_animation_view"
                        style="color: #344767"
                      >
                        {{ $t("Applied spray animation") }}
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link px-0 py-1 mb-0"
                        data-bs-toggle="tab"
                        href="#detailed_pie_chart_view"
                        style="color: #344767"
                      >
                        {{ $t("Powder Pie Chart") }}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                v-if="visualize_simulated_images_toggle && FlightpathCoatingAISettingResultComputed"
                class="mt-2 card-body"
              >
                <div class="tab-content">
                  <div
                    id="statistical_distribution_img_view"
                    class="tab-pane container active"
                  >
                    <img
                      alt="Image placeholder"
                      :src="
                        (powder_savings_report.static_resources_s3_storage ? '' : local_storage_base_URL) +
                        powder_savings_report.uniformity_comparison_chart_img
                      "
                      class="img-fluid shadow-lg border-radius-lg"
                      style="display: block; margin-left: auto; margin-right: auto; max-height: 500px"
                    />
                  </div>
                  <div
                    id="substrate_surface_img_view"
                    class="tab-pane container"
                  >
                    <div class="row">
                      <div
                        v-if="currentSettingsReceived"
                        :class="currentSettingsReceived ? 'col-lg-6 text-center' : 'text-center'"
                      >
                        <h6 style="text-align: center">{{ $t("Current Settings Coated Area") }}</h6>
                        <img
                          alt="Image placeholder"
                          :src="
                            (powder_savings_report.static_resources_s3_storage ? '' : local_storage_base_URL) +
                            powder_savings_report.current_setting_substrate_surface_img
                          "
                          class="img-fluid shadow-lg border-radius-lg"
                          style="max-height: 500px"
                        />
                      </div>
                      <div
                        class="text-center"
                        :class="currentSettingsReceived ? 'col-lg-6' : ''"
                      >
                        <h6 style="text-align: center">{{ $t("CoatingAI Settings Coated Area") }}</h6>
                        <img
                          alt="Image placeholder"
                          :src="
                            (powder_savings_report.static_resources_s3_storage ? '' : local_storage_base_URL) +
                            powder_savings_report.optimal_setting_substrate_surface_img
                          "
                          class="img-fluid shadow-lg border-radius-lg"
                          style="max-height: 500px"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    id="pistol_movement_chart_img_view"
                    class="tab-pane container"
                  >
                    <div class="row">
                      <div
                        v-if="currentSettingsReceived"
                        :class="currentSettingsReceived ? 'col-lg-6 text-center' : ''"
                      >
                        <h6 style="text-align: center">{{ $t("Current Settings Reciprocator Movement") }}</h6>
                        <img
                          alt="Image placeholder"
                          :src="
                            (powder_savings_report.static_resources_s3_storage ? '' : local_storage_base_URL) +
                            powder_savings_report.current_setting_pistol_movement_chart_img
                          "
                          class="img-fluid shadow-lg border-radius-lg"
                          style="max-height: 500px"
                        />
                      </div>
                      <div
                        class="text-center"
                        :class="currentSettingsReceived ? 'col-lg-6' : ''"
                      >
                        <h6 style="text-align: center">{{ $t("CoatingAI Settings Reciprocator Movement") }}</h6>
                        <img
                          alt="Image placeholder"
                          :src="
                            (powder_savings_report.static_resources_s3_storage ? '' : local_storage_base_URL) +
                            powder_savings_report.optimal_setting_pistol_movement_chart_img
                          "
                          class="img-fluid shadow-lg border-radius-lg"
                          style="max-height: 500px"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    id="applied_spray_animation_view"
                    class="tab-pane container"
                  >
                    <div class="row">
                      <div
                        v-if="currentSettingsReceived"
                        :class="currentSettingsReceived ? 'col-lg-6 text-center' : 'text-center'"
                      >
                        <h6 style="text-align: center">{{ $t("Current Settings Coated Area") }}</h6>
                        <img
                          alt="Image placeholder"
                          :src="
                            (powder_savings_report.static_resources_s3_storage ? '' : 'https://d21tewxstkbxbj.cloudfront.net/') +
                            powder_savings_report.current_setting_simulation_gif
                          "
                          class="img-fluid shadow-lg border-radius-lg"
                          style="max-height: 500px"
                        />
                      </div>
                      <div
                        class="text-center"
                        :class="currentSettingsReceived ? 'col-lg-6' : ''"
                      >
                        <h6 style="text-align: center">{{ $t("CoatingAI Settings Coated Area") }}</h6>
                        <img
                          alt="Image placeholder"
                          :src="
                            (powder_savings_report.static_resources_s3_storage ? '' : 'https://d21tewxstkbxbj.cloudfront.net/') +
                            powder_savings_report.optimal_setting_simulation_gif
                          "
                          class="img-fluid shadow-lg border-radius-lg"
                          style="max-height: 500px"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    id="detailed_pie_chart_view"
                    class="tab-pane container"
                  >
                    <div class="row">
                      <div
                        v-if="currentSettingsReceived"
                        :class="currentSettingsReceived ? 'col-lg-6 text-center' : 'text-center'"
                      >
                        <h6 style="text-align: center">{{ $t("Current Settings Pie Chart") }}</h6>
                        <img
                          alt="Image placeholder"
                          :src="
                            (powder_savings_report.static_resources_s3_storage ? '' : local_storage_base_URL) +
                            powder_savings_report.current_setting_powder_usage_pie_chart
                          "
                          class="img-fluid shadow-lg border-radius-lg"
                          style="max-height: 500px"
                        />
                      </div>
                      <div
                        class="text-center"
                        :class="currentSettingsReceived ? 'col-lg-6' : ''"
                      >
                        <h6 style="text-align: center">{{ $t("CoatingAI Settings Pie Chart") }}</h6>
                        <img
                          alt="Image placeholder"
                          :src="
                            (powder_savings_report.static_resources_s3_storage ? '' : local_storage_base_URL) +
                            powder_savings_report.optimal_setting_powder_usage_pie_chart
                          "
                          class="img-fluid shadow-lg border-radius-lg"
                          style="max-height: 500px"
                        />
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div
                        v-if="currentSettingsReceived"
                        :class="currentSettingsReceived ? 'col-lg-6 text-center' : 'text-center'"
                      >
                        <!-- <h6 style="text-align: center;">{{ $t('Current Settings Detailed Pie Chart') }}</h6> -->
                        <img
                          alt="Image placeholder"
                          :src="
                            (powder_savings_report.static_resources_s3_storage ? '' : local_storage_base_URL) +
                            powder_savings_report.current_setting_detailed_powder_usage_pie_chart
                          "
                          class="img-fluid shadow-lg border-radius-lg"
                          style="max-height: 500px"
                        />
                      </div>
                      <div
                        class="text-center"
                        :class="currentSettingsReceived ? 'col-lg-6' : ''"
                      >
                        <!-- <h6 style="text-align: center;">{{ $t('CoatingAI Settings Detailed Pie Chart') }}</h6> -->
                        <img
                          alt="Image placeholder"
                          :src="
                            (powder_savings_report.static_resources_s3_storage ? '' : local_storage_base_URL) +
                            powder_savings_report.optimal_setting_detailed_powder_usage_pie_chart
                          "
                          class="img-fluid shadow-lg border-radius-lg"
                          style="max-height: 500px"
                        />
                      </div>
                      <div class="text-center mt-3">
                        <p>
                          *<b>{{ $t("Overcoated powder:") }}</b>
                          {{
                            $t(
                              "The amount of extra powder applied because of an uneven powder distribution on the substrate.",
                            )
                          }}
                          <br />
                          *<b>{{ $t("Oversprayed powder:") }}</b>
                          {{
                            $t(
                              "The powder that does not stick to the part and is then not reclaimed by the recycling system.",
                            )
                          }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div
                v-if="powderConsumptionSaving > 0"
                class="row mt-2"
              >
                <h5 class="font-weight-bolder text-dark text-gradient text-center">
                  {{ $t("You could save up to ") }}{{ parseFloat(powderConsumptionSaving).toFixed(2) }}
                  {{ $store.state.units_system[user_information.unit_system].tonnes }}
                  {{ $t("per year!") }}
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import logo from "@/assets/img/logo-ct.png";
import setTooltip from "@/assets/js/tooltip.js";
import SoftProgress from "@/components/SoftProgress.vue";

export default {
  name: "PowderSavingsResults",
  components: {
    SoftProgress,
  },
  props: {
    userEmail: {
      type: String,
      default: null,
    },
    powderSavingsReportId: {
      type: Number,
      default: null,
    },
    userLanguage: {
      type: String,
      default: null,
    },
    unitSystem: {
      type: String,
      default: null,
    },
  },

  data() {
    this.$i18n.locale = this.userLanguage;
    return {
      logo,
      user_information: {
        language: "EN",
        unit_system: "metric",
        currency: "DOLLAR",
        email: "",
        name: "",
        company: "",
        powder_consumption: "",
        recycling_system: false,
        recycling_percentage: 100,
      },
      powder_savings_report: [],
      // end data from backend
      visualize_simulated_images_toggle: true,
      calculation_progress_interval: {},
      calculation_progress_percentage: 0,
      local_storage_base_URL: axios.defaults.baseURL + "/static/",
      max_imperial_decimals: 2,
      max_metric_decimals: 2,
      s3_storage_base_URL: "https://d21tewxstkbxbj.cloudfront.net/flightpath-platform/public-assets/static/",
    };
  },

  computed: {
    currentSettingsReceived() {
      return this.powder_savings_report.current_setting_uniformity_percentage != null;
    },
    succesfullAnimationGeneration() {
      if (!(typeof this.powder_savings_report.optimal_setting_simulation_gif === 'string')) {
        return false
      }

      if (this.powder_savings_report.optimal_setting_simulation_gif.includes('animation_generation_unsuccessful')) {
        return false
      }

      if (typeof this.powder_savings_report.current_setting_simulation_gif === 'string') {
        if (this.powder_savings_report.current_setting_simulation_gif.includes('animation_generation_unsuccessful')) {
          return false
        }
      }
      return true
    },
    UniformityDifference() {
      if (
        this.powder_savings_report.current_setting_uniformity_percentage != null &&
        this.powder_savings_report.optimal_setting_uniformity_percentage != null
      ) {
        let difference = Math.abs(
          this.powder_savings_report.current_setting_uniformity_percentage -
            this.powder_savings_report.optimal_setting_uniformity_percentage,
        ).toFixed(1);
        let difference_sign =
          this.powder_savings_report.optimal_setting_uniformity_percentage -
            this.powder_savings_report.current_setting_uniformity_percentage >
          0
            ? "+"
            : "-";
        return "(" + difference_sign + difference + "%)";
      } else {
        return null;
      }
    },
    PowderWasteDifference() {
      if (
        this.powder_savings_report.current_setting_powder_waste_percentage != null &&
        this.powder_savings_report.optimal_setting_powder_waste_percentage != null
      ) {
        let difference = Math.abs(
          this.powder_savings_report.current_setting_powder_waste_percentage -
            this.powder_savings_report.optimal_setting_powder_waste_percentage,
        ).toFixed(1);
        let difference_sign =
          this.powder_savings_report.optimal_setting_powder_waste_percentage -
            this.powder_savings_report.current_setting_powder_waste_percentage >
          0
            ? "+"
            : "-";
        return "(" + difference_sign + difference + "%)";
      } else {
        return null;
      }
    },
    FlightpathCoatingAISettingResultComputed: function () {
      return this.powder_savings_report.optimal_setting_uniformity_percentage != null;
    },
    calculationInProgress: function () {
      return this.$store.state.powder_savings_calculation_in_progress_email_ids;
    },
    powderConsumptionSaving: {
      get() {
        if (
          this.powder_savings_report.powder_cost_saving == null ||
          this.powder_savings_report.powder_cost_saving == ""
        ) {
          return this.powder_savings_report.powder_cost_saving;
        }
        if (this.user_information.unit_system === "imperial") {
          return parseFloat(
            (
              this.powder_savings_report.powder_cost_saving * this.$store.state.conversion_factors.tonnes_to_tons
            ).toFixed(2),
          );
        } else if (this.user_information.unit_system === "metric") {
          return parseFloat(this.powder_savings_report.powder_cost_saving.toFixed(this.max_metric_decimals));
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powder_savings_report.powder_cost_saving = value;
        } else {
          if (this.user_information.unit_system === "imperial") {
            this.powder_savings_report.powder_cost_saving = value / this.$store.state.conversion_factors.tonnes_to_tons;
          } else if (this.user_information.unit_system === "metric") {
            this.powder_savings_report.powder_cost_saving = value;
          } else {
            this.powder_savings_report.powder_cost_saving = null;
          }
        }
      },
    },
  },
  watch: {
    "$store.state.powder_savings_calculation_in_progress_email_ids": function () {
      if (!this.calculationInProgress.includes(this.userEmail)) {
        this.$store.state.isLoading = true;
        this.getData()
        .then( () => {this.animationGenerationAlertCheck();})
      }
    },
    "$store.state.user_data.language"() {
      this.$i18n.locale = this.$store.state.user_data.language;
    },
    userLanguage() {
      this.$i18n.locale = this.userLanguage;
    },
    unitSystem() {
      this.user_information.unit_system = this.unitSystem;
    },
  },
  mounted() {
    this.StartCalculationInProgress();
    setTooltip(this.$store.state.bootstrap);
  },

  updated() {
    setTooltip(this.$store.state.bootstrap);
  },

  methods: {
    capitalize(value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    async getData() {
      clearInterval(this.calculation_progress_interval);
      this.hideTooltip();
      try {
        let powder_savings_report_response = await axios.get(
          "/api/v1/powdersavingscalculator/powdersavingsreport/" + this.powderSavingsReportId,
        );
        this.powder_savings_report = Object.assign({}, powder_savings_report_response.data);
      } catch (error) {
        console.log(error);
        setTimeout(
          function () {
            this.getData();
          }.bind(this),
          5000,
        );
      }
      if (!this.FlightpathCoatingAISettingResultComputed) {
        setTimeout(
          function () {
            this.getData();
          }.bind(this),
          5000,
        );
      }
      this.calculation_progress_percentage = 0;
      this.$store.state.isLoading = false;
    },
    StartCalculationInProgress() {
      let progress_bar_duration;
      progress_bar_duration = 320;
      this.calculation_progress_percentage = 0;
      this.calculation_progress_interval = setInterval(() => {
        this.calculation_progress_percentage += 0.2;
      }, progress_bar_duration);
    },
    animationGenerationAlertCheck() {
      console.log('this.powder_savings_report')
      console.log(this.powder_savings_report)
      if (!(typeof this.powder_savings_report.optimal_setting_simulation_gif === 'string')) {
        this.animationGenerationAlert()
        return
      }
      
      if (this.powder_savings_report.optimal_setting_simulation_gif.includes('animation_generation_unsuccessful')) {
        this.animationGenerationAlert()
        return
      }
      
      if (typeof this.powder_savings_report.current_setting_simulation_gif === 'string') {
        if (this.powder_savings_report.current_setting_simulation_gif.includes('animation_generation_unsuccessful')) {
          this.animationGenerationAlert()
          return
        }
      }
    },
    animationGenerationAlert() {
      this.$swal({
        title: this.$t("Animation generation unsuccessful"),
        text: this.$t("Applied powder cannot be visualized as an animation for this line and parameters. Optimal settings and results images are still available in the results section."),
        icon: "warning",
        confirmButtonText: "OK",
      });
      this.animation_generation_warning_shown = true;
    },
    hideTooltip() {
      const elements = document.querySelectorAll(".tooltip");

      elements.forEach(element => {
        element.remove();
      });
    },
    numericOnly(event) {
      let key = event.key;
      if (
        [
          "Delete",
          "Backspace",
          "Tab",
          "Enter",
          "ArrowUp",
          "ArrowRight",
          "ArrowDown",
          "ArrowLeft",
          ".",
          "Shift",
          "Control",
          ",",
        ].includes(key) ||
        ((event.ctrlKey || event.metaKey) && ["a", "c", "v", "x", "z", "y"].includes(key.toLowerCase()))
      ) {
        return;
      }
      if (!/\d/.test(key)) {
        event.preventDefault();
      }
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.container_optimization {
  @import "~@/assets/css/coating_expert_chat.scss";
}
</style>

<i18n>
  {
    "EN": {
      "Animation generation unsuccessful": "Animation generation unsuccessful",
      "Applied powder cannot be visualized as an animation for this line and parameters. Optimal settings and results images are still available in the results section.": "Applied powder cannot be visualized as an animation for this line and parameters. Optimal settings and results images are still available in the results section.",
      "Something went wrong...": "Something went wrong...",
      "Simulation results": "Simulation results",
      "Current Settings": "Current Settings",
      "Uniformity": "Uniformity",
      "Powder Waste": "Powder Waste",
      "CoatingAI Settings": "CoatingAI Settings",
      "Visualize graphs": "Visualize graphs",
      "Activate Toggle to visualize simulation images": "Activate Toggle to visualize simulation images",
      "Calculation running": "Calculation running",
      "Uniformity comparison": "Uniformity comparison",
      "Applied spray": "Applied spray",
      "Movement chart": "Movement chart",
      "Applied spray animation": "Applied spray animation",
      "Powder Pie Chart": "Powder Pie Chart",
      "Current Settings Coated Area": "Current Settings Coated Area",
      "CoatingAI Settings Coated Area": "CoatingAI Settings Coated Area",
      "Current Settings Reciprocator Movement": "Current Settings Reciprocator Movement",
      "CoatingAI Settings Reciprocator Movement": "CoatingAI Settings Reciprocator Movement",
      "Current Settings Pie Chart": "Current Settings Pie Chart",
      "CoatingAI Settings Pie Chart": "CoatingAI Settings Pie Chart",
      "You could save up to ": "You could save up to ",
      "per year!": "per year!",
      "tons": "tons",
      "tonnes": "tonnes",
      "Overcoated powder:" : "Overcoated powder:",
      "Oversprayed powder:" : "Oversprayed powder:",
      "The amount of extra powder applied because of an uneven powder distribution on the substrate.": "The amount of extra powder applied because of an uneven powder distribution on the substrate.",
      "The powder that does not stick to the part and is then not reclaimed by the recycling system.": "The powder that does not stick to the part and is then not reclaimed by the recycling system.",
    },
    "DE": {
      "Animation generation unsuccessful": "Erzeugung von Animationen erfolglos",
      "Applied powder cannot be visualized as an animation for this line and parameters. Optimal settings and results images are still available in the results section.": "Das aufgetragene Pulver kann für diese Linie und Parameter nicht als Animation dargestellt werden. Optimale Einstellungen und Ergebnisbilder sind weiterhin im Ergebnisbereich verfügbar.",
      "Something went wrong...": "Etwas ist schief gelaufen...",
      "Simulation results": "Simulationsergebnisse",
      "Current Settings": "Aktuelle Einstellungen",
      "Uniformity": "Gleichmäßigkeit",
      "Powder Waste": "Pulverabfall",
      "CoatingAI Settings": "CoatingAI Einstellungen",
      "Visualize graphs": "Visualisieren Sie Diagramme",
      "Activate Toggle to visualize simulation images": "Aktivieren Sie den Umschalter, um Simulationen zu visualisieren",
      "Calculation running": "Berechnung läuft",
      "Uniformity comparison": "Gleichmäßigkeitsvergleich",
      "Applied spray": "Aufgetragener Sprühnebel",
      "Movement chart": "Bewegungsdiagramm",
      "Applied spray animation": "Aufgetragene Sprühnebelanimation",
      "Powder Pie Chart": "Pulverkuchen-Diagramm",
      "Current Settings Coated Area": "Aktuelle Einstellungen Beschichtete Fläche",
      "CoatingAI Settings Coated Area": "CoatingAI Einstellungen Beschichtete Fläche",
      "Current Settings Reciprocator Movement": "Aktuelle Einstellungen Pistolenbewegung",
      "CoatingAI Settings Reciprocator Movement": "CoatingAI Einstellungen Pistolenbewegung",
      "Current Settings Pie Chart": "Aktuelle Einstellungen Kuchen-Diagramm",
      "CoatingAI Settings Pie Chart": "CoatingAI Einstellungen Kuchen-Diagramm",
      "You could save up to ": "Sie könnten bis zu ",
      "per year!": "pro Jahr sparen!",
      "tons": "Tonnen",
      "tonnes": "Tonnen",
      "Overcoated powder:" : "Overcoated powder:",
      "Oversprayed powder:" : "Oversprayed powder:",
      "The amount of extra powder applied because of an uneven powder distribution on the substrate.": "Die Menge an zusätzlichem Pulver, die aufgrund einer ungleichmäßigen Verteilung des Pulvers auf dem Substrat aufgetragen wird.",
      "The powder that does not stick to the part and is then not reclaimed by the recycling system.": "Das Pulver, das nicht auf dem Teil haften bleibt und vom Recycling-System nicht zurückgewonnen wird.",
    },
    "ES": {
      "Animation generation unsuccessful": "La animación no se ha podido generar",
      "Applied powder cannot be visualized as an animation for this line and parameters. Optimal settings and results images are still available in the results section.": "El polvo aplicado no puede visualizarse como animación para esta línea y parámetros. Los ajustes óptimos y las imágenes de los resultados siguen estando disponibles en la sección de resultados.",
      "Something went wrong...": "Algo salió mal...",
      "Simulation results": "Resultados de la simulación",
      "Current Settings": "Configuración actual",
      "Uniformity": "Uniformidad",
      "Powder Waste": "Desperdicio de pintura en polvo",
      "CoatingAI Settings": "Configuración de CoatingAI",
      "Visualize graphs": "Visualizar gráficos",
      "Activate Toggle to visualize simulation images": "Activar el interruptor para visualizar imágenes de simulación",
      "Calculation running": "Cálculo en proceso",
      "Uniformity comparison": "Comparación de la uniformidad",
      "Applied spray": "Pintura aplicada",
      "Movement chart": "Gráfico del movimiento",
      "Applied spray animation": "Animación de la pintura aplicada",
      "Powder Pie Chart": "Grafico del uso de la pintura en polvo",
      "Current Settings Coated Area": "Área recubierta de la configuración actual",
      "CoatingAI Settings Coated Area": "Área recubierta de la configuración de CoatingAI",
      "Current Settings Reciprocator Movement": "Movimiento del reciprocador de la configuración actual",
      "CoatingAI Settings Reciprocator Movement": "Movimiento del reciprocador de la configuración de CoatingAI",
      "Current Settings Pie Chart": "Configuración actual",
      "CoatingAI Settings Pie Chart": "Configuración de CoatingAI",
      "You could save up to ": "¡Podrías ahorrar hasta ",
      "per year!": "por año!",
      "tons": "toneladas cortas",
      "tonnes": "toneladas",
      "Overcoated powder:" : "Overcoated powder:",
      "Oversprayed powder:" : "Oversprayed powder:",
      "The amount of extra powder applied because of an uneven powder distribution on the substrate.": "La cantidad de polvo adicional aplicada debido a una distribución desigual del polvo sobre el sustrato.",
      "The powder that does not stick to the part and is then not reclaimed by the recycling system.": "El polvo que no se adhiere a la pieza y que no es recuperado por el sistema de reciclado.",
    },
    "FR": {
      "Animation generation unsuccessful": "La génération d'animation n'a pas abouti",
      "Applied powder cannot be visualized as an animation for this line and parameters. Optimal settings and results images are still available in the results section.": "La poudre appliquée ne peut pas être visualisée sous forme d'animation pour cette ligne et ces paramètres. Les réglages optimaux et les images des résultats sont toujours disponibles dans la section des résultats.",
      "Something went wrong...": "Quelque chose s'est mal passé...",
      "Simulation results": "Résultats de la simulation",
      "Current Settings": "Paramètres actuels",
      "Uniformity": "Uniformité",
      "Powder Waste": "Déchets de poudre",
      "CoatingAI Settings": "Paramètres de CoatingAI",
      "Visualize graphs": "Visualiser les graphiques",
      "Activate Toggle to visualize simulation images": "Activer le basculement pour visualiser les images de simulation",
      "Calculation running": "Calcul en cours",
      "Uniformity comparison": "Comparaison de l'uniformité",
      "Applied spray": "Pulvérisation appliquée",
      "Movement chart": "Graphique de mouvement",
      "Applied spray animation": "Animation de la pulvérisation appliquée",
      "Powder Pie Chart": "Diagramme circulaire de la poudre",
      "Current Settings Coated Area": "Zone recouverte des paramètres actuels",
      "CoatingAI Settings Coated Area": "Zone recouverte des paramètres de CoatingAI",
      "Current Settings Reciprocator Movement": "Mouvement du pistolet des paramètres actuels",
      "CoatingAI Settings Reciprocator Movement": "Mouvement du pistolet des paramètres de CoatingAI",
      "Current Settings Pie Chart": "Diagramme circulaire des paramètres actuels",
      "CoatingAI Settings Pie Chart": "Diagramme circulaire des paramètres de CoatingAI",
      "You could save up to ": "Vous pourriez économiser jusqu'à ",
      "per year!": "par an!",
      "tons": "tonnes courtes",
      "tonnes": "tonnes",
      "Overcoated powder:" : "Overcoated powder:",
      "Oversprayed powder:" : "Oversprayed powder:",
      "The amount of extra powder applied because of an uneven powder distribution on the substrate.": "La quantité de poudre supplémentaire appliquée en raison d'une distribution inégale de la poudre sur le substrat.",
      "The powder that does not stick to the part and is then not reclaimed by the recycling system.": "La poudre qui n'adhère pas à la pièce et qui n'est donc pas récupérée par le système de recyclage.",
    },
  }
</i18n>
