export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "EN": {
        "Powder Amount List for line ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powder Amount List for line "])},
        "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "Powder Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powder Model"])},
        "Created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created"])},
        "Remove Calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove Calibration"])},
        "Are you sure you want to delete Powder Amount Calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete Powder Amount Calibration"])},
        "You will not be able to revert this!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will not be able to revert this!"])},
        "Yes, delete it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, delete it"])},
        "No, cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No, cancel"])},
        "Deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleted"])},
        "Your Powder Amount has been deleted.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Powder Amount has been deleted."])},
        "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
        "No Powder Amount created.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Powder Amount created."])}
      },
      "DE": {
        "Powder Amount List for line ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pulverbetrag Liste für Linie"])},
        "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "Powder Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pulvermodell"])},
        "Created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellt"])},
        "Remove Calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrierung entfernen"])},
        "Are you sure you want to delete Powder Amount Calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sind Sie sicher, dass Sie die Pulverbetrag Kalibrierung löschen möchten?"])},
        "You will not be able to revert this!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können dies nicht rückgängig machen!"])},
        "Yes, delete it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja, lösche es"])},
        "No, cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nein, abbrechen"])},
        "Deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelöscht"])},
        "Your Powder Amount has been deleted.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Pulverbetrag wurde gelöscht."])},
        "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
        "No Powder Amount created.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein Pulverbetrag erstellt."])}
      },
      "ES": {
        "Powder Amount List for line ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de Flujos de Pintura para la línea "])},
        "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
        "Powder Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modelo de Pintura en Polvo"])},
        "Created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creada"])},
        "Remove Calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar Calibración"])},
        "Are you sure you want to delete Powder Amount Calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Estás seguro de que quieres eliminar la calibración"])},
        "You will not be able to revert this!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡No podrás deshacer esto!"])},
        "Yes, delete it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sí, bórrala"])},
        "No, cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No, cancelar"])},
        "Deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminada"])},
        "Your Powder Amount has been deleted.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su calibración ha sido eliminada."])},
        "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar"])},
        "No Powder Amount created.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No existe ninguna calibración"])}
      },
      "FR": {
        "Powder Amount List for line ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des quantités de poudre pour la ligne"])},
        "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
        "Powder Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modèle de Poudre"])},
        "Created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créée"])},
        "Remove Calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer l'optimisation"])},
        "Are you sure you want to delete Powder Amount Calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir supprimer l'optimisation de la quantité de poudre"])},
        "You will not be able to revert this!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous ne pourrez pas revenir en arrière!"])},
        "Yes, delete it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui, supprimez-le"])},
        "No, cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non, annuler"])},
        "Deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimé"])},
        "Your Powder Amount has been deleted.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre quantité de poudre a été supprimée."])},
        "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effacer"])},
        "No Powder Amount created.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune quantité de poudre créée."])}
      },
      "IT": {
        "Powder Amount List for line ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elenco delle quantità di polvere per la linea"])},
        "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
        "Powder Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modello di polvere"])},
        "Created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creata"])},
        "Remove Calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rimuovi calibrazione"])},
        "Are you sure you want to delete Powder Amount Calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sei sicuro di voler eliminare la calibrazione della quantità di polvere"])},
        "You will not be able to revert this!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non sarai in grado di annullare questa operazione!"])},
        "Yes, delete it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sì, cancellalo"])},
        "No, cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No, annulla"])},
        "Deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminato"])},
        "Your Powder Amount has been deleted.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La tua quantità di polvere è stata eliminata."])},
        "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina"])},
        "No Powder Amount created.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna quantità di polvere creata."])}
      },
      "PL": {
        "Powder Amount List for line ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista ilości proszku dla linii"])},
        "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa"])},
        "Powder Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Model Proszku"])},
        "Created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utworzony"])},
        "Remove Calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń kalibrację"])},
        "Are you sure you want to delete Powder Amount Calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czy na pewno chcesz usunąć kalibrację ilości proszku"])},
        "You will not be able to revert this!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie będziesz mógł tego cofnąć!"])},
        "Yes, delete it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tak, usuń to"])},
        "No, cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie, anuluj"])},
        "Deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usunięty"])},
        "Your Powder Amount has been deleted.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twoja ilość proszku została usunięta."])},
        "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kasować"])},
        "No Powder Amount created.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie utworzono żadnej ilości proszku."])}
      },
      "TR": {
        "Powder Amount List for line ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hat için Toz Miktarı Listesi"])},
        "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İsim"])},
        "Powder Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toz Modeli"])},
        "Created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oluşturuldu"])},
        "Remove Calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrasyonu Kaldır"])},
        "Are you sure you want to delete Powder Amount Calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toz Miktarı Kalibrasyonunu silmek istediğinizden emin misiniz?"])},
        "You will not be able to revert this!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bunu geri alamayacaksın!"])},
        "Yes, delete it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evet, sil"])},
        "No, cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hayır, iptal et"])},
        "Deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silindi"])},
        "Your Powder Amount has been deleted.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toz Miktarınız silindi."])},
        "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sil"])},
        "No Powder Amount created.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oluşturulan Toz Miktarı yok."])}
      },
      "ZH": {
        "Powder Amount List for line ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["线路的粉量列表"])},
        "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名称"])},
        "Powder Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["粉末模型"])},
        "Created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建"])},
        "Remove Calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除校准"])},
        "Are you sure you want to delete Powder Amount Calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您确定要删除粉量校准吗？"])},
        "You will not be able to revert this!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您将无法撤消此操作！"])},
        "Yes, delete it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是的，删除它"])},
        "No, cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不，取消"])},
        "Deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已删除"])},
        "Your Powder Amount has been deleted.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的粉量已被删除。"])},
        "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除"])},
        "No Powder Amount created.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未创建粉量。"])}
      }
    }
  })
}
