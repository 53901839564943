<template>
  <main class="mt-0 main-content main-content-bg">
    <section>
      <div class="page-header min-vh-75">
        <div class="container">
          <div class="row">
            <div class="px-5 col-xl-5 col-lg-6 col-md-8 col-12 d-flex flex-column">
              <div class="mt-8 card card-plain">
                <div class="pb-0 text-left card-header">
                  <h3 class="text-info text-gradient">{{ $t("Reset Password") }}</h3>
                  <p class="mb-0">
                    {{ $t("You are required to reset your password.") }}
                  </p>
                </div>
                <div class="pb-3 card-body">
                  <form role="form">
                    <label>{{ $t("Email") }}</label>
                    <div class="mb-3">
                      <p>{{ $store.state.user_data.email }}</p>
                    </div>
                    <label>{{ $t("Current password") }}</label
                    >&nbsp;
                    <i
                      :class="showPassword ? 'fa fa-eye-slash' : 'fa fa-eye'"
                      style="font-size: 0.73em"
                      @click="showPassword = !showPassword"
                    >
                    </i>
                    <div class="mb-3">
                      <input
                        v-model="current_password"
                        :class="current_password_input_error ? `form-control is-invalid` : `form-control`"
                        :type="showPassword ? 'text' : 'password'"
                        :placeholder="$t('Enter current password')"
                        @input="current_password_input_error = false"
                      />
                    </div>
                    <label>{{ $t("New password") }}</label
                    >&nbsp;
                    <i
                      :class="showPassword ? 'fa fa-eye-slash' : 'fa fa-eye'"
                      style="font-size: 0.73em"
                      @click="showPassword = !showPassword"
                    >
                    </i>
                    <div class="mb-3">
                      <input
                        v-model="new_password"
                        :class="new_password_input_error ? `form-control is-invalid` : `form-control`"
                        :type="showPassword ? 'text' : 'password'"
                        :placeholder="$t('Enter new password')"
                        @input="new_password_input_error = false"
                      />
                    </div>
                    <label>{{ $t("Repeat new password") }}</label
                    >&nbsp;
                    <i
                      :class="showPassword ? 'fa fa-eye-slash' : 'fa fa-eye'"
                      style="font-size: 0.73em"
                      @click="showPassword = !showPassword"
                    >
                    </i>
                    <div class="mb-3">
                      <input
                        v-model="re_new_password"
                        :class="re_new_password_input_error ? `form-control is-invalid` : `form-control`"
                        :type="showPassword ? 'text' : 'password'"
                        :placeholder="$t('Repeat new password')"
                        @input="re_new_password_input_error = false"
                      />
                    </div>
                    <div class="text-center">
                      <button
                        class="w-100 mt-2 mb-0 btn bg-gradient-info ms-lg-auto me-lg-0 me-auto mt-lg-0"
                        type="button"
                        @click="ResetPassword"
                      >
                        {{ $t("Reset Password") }}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="top-0 oblique position-absolute h-100 d-md-block d-none me-n8">
                <div
                  class="bg-cover oblique-image position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6"
                  :style="{
                    backgroundImage: 'url(' + require('@/assets/img/curved-images/curved6.jpg') + ')',
                  }"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import axios from "axios";
import router from "@/router";

const body = document.getElementsByTagName("body")[0];

export default {
  name: "ResetPassword",
  components: {},
  data() {
    this.$i18n.locale = this.$store.state.user_data.language;
    return {
      current_password: "",
      new_password: "",
      re_new_password: "",
      current_password_input_error: false,
      new_password_input_error: false,
      re_new_password_input_error: false,
      showPassword: false,
    };
  },
  watch: {
    "$store.state.user_data.language"() {
      this.$i18n.locale = this.$store.state.user_data.language;
    },
  },
  created() {
    this.$store.state.showSidenav = !this.$store.state.showSidenav;
    body.classList.remove("bg-gray-100");
    this.$store.state.isLoading = false;
  },
  beforeUnmount() {
    this.$store.state.showSidenav = !this.$store.state.showSidenav;
    body.classList.add("bg-gray-100");
  },
  mounted() {
    this.email = this.$store.state.user_data.email;
  },
  methods: {
    async ResetPassword() {
      let bad_form = false;

      if (this.current_password == null || this.current_password == "") {
        this.$swal({
          title: `${this.$t("Current Password field is empty.")}`,
          text: `${this.$t("Please enter your current password.")}`,
          icon: "warning",
          confirmButtonText: "OK",
        });
        this.current_password_input_error = true;
        bad_form = true;
      } else if (this.new_password == null || this.new_password == "") {
        this.$swal({
          title: `${this.$t("New Password field is empty.")}`,
          text: `${this.$t("Please enter your new password.")}`,
          icon: "warning",
          confirmButtonText: "OK",
        });
        this.new_password_input_error = true;
        bad_form = true;
      } else if (this.re_new_password == null || this.re_new_password == "") {
        this.$swal({
          title: `${this.$t("Repeated New Password field is empty.")}`,
          text: `${this.$t("Please repeat your new password.")}`,
          icon: "warning",
          confirmButtonText: "OK",
        });
        this.re_new_password_input_error = true;
        bad_form = true;
      } else if (this.new_password != this.re_new_password) {
        this.$swal({
          title: `${this.$t("The repeated password does not match.")}`,
          text: `${this.$t("Please make sure the new password and repeated new password match.")}`,
          icon: "warning",
          confirmButtonText: "OK",
        });
        this.new_password_input_error = true;
        this.re_new_password_input_error = true;
        this.new_password = "";
        this.re_new_password = "";
        bad_form = true;
      }
      if (!bad_form) {
        try {
          await this.postResetPassword();
          await this.putUserChangedPassword();
          await axios.post("/api/v1/token/logout/");
          this.$store.commit("removeToken");
          router.push({ name: "SignIn" });
        } catch (error) {
          console.error(error);
        }
      }
    },
    async postResetPassword() {
      const maxRetries = 3;
      let retries = 0;
      let error_message = "";

      while (retries < maxRetries) {
        try {
          await axios.post("/api/v1/users/set_password/", {
            email: this.email,
            current_password: this.current_password,
            new_password: this.new_password,
            re_new_password: this.re_new_password,
          });
          // If request succeeds, break out of the loop
          break;
        } catch (error) {
          console.error(error);

          error_message = Object.values(error.response.data)[0][0];

          // error_message must be string
          if (typeof error_message !== "string") {
            error_message = "";
          }

          await new Promise(resolve => setTimeout(resolve, 200));
          retries++;
        }
      }

      if (retries === maxRetries) {
        this.$swal({
          title: this.$t("Reset password error"),
          text: this.$t("An error occurred while trying to reset your password. Error: ") + error_message,
          icon: "error",
          confirmButtonText: "OK",
        });
        throw new Error("Reset password error");
      }
    },
    async putUserChangedPassword() {
      const maxRetries = 5;
      let retries = 0;
      let error_message = "";

      while (retries < maxRetries) {
        try {
          // update changed password user field to True
          await axios.put("/api/v1/userchangedpassword/", { user: this.$store.state.user_data.id });
          // If request succeeds, break out of the loop
          break;
        } catch (error) {
          console.error(error);
          error_message = Object.values(error.response.data)[0][0];
          await new Promise(resolve => setTimeout(resolve, 200));
          retries++;
        }
      }

      if (retries === maxRetries) {
        this.$swal({
          title: this.$t("Reset password error"),
          text:
            error_message === ""
              ? "An error occurred while trying to reset your password. Please try again later."
              : error_message,
          icon: "error",
          confirmButtonText: "OK",
        });
        throw new Error("Reset password error");
      }
    },
  },
};
</script>
<i18n>
  {
    "EN": {
      "Reset password error": "Reset password error",
      "An error occurred while trying to reset your password. Error: ": "An error occurred while trying to reset your password. Error: ",
      "Email": "Email",
      "Reset Password": "Reset Password",
      "You are required to reset your password.": "You are required to reset your password.",
      "Current password": "Current password",
      "New password": "New password",
      "Repeat new password": "Repeat new password",
      "Enter current password": "Enter current password",
      "Enter new password": "Enter new password",
      "Email does not match logged in user.": "Email does not match logged in user.",
      "Please enter the email you used to sign up.": "Please enter the email you used to sign up.",
      "Current Password field is empty.": "Current Password field is empty.",
      "Please enter your current password.": "Please enter your current password.",
      "New Password field is empty.": "New Password field is empty.",
      "Please enter your new password.": "Please enter your new password.",
      "Repeated New Password field is empty.": "Repeated New Password field is empty.",
      "Please repeat your new password.": "Please repeat your new password.",
      "The repeated password does not match.": "The repeated password does not match.",
      "Please make sure the new password and repeated new password match.": "Please make sure the new password and repeated new password match.",
      "Invalid email format.": "Invalid email format.",
      "Please enter a valid email, with the format": "Please enter a valid email, with the format",
    },
    "DE": {
      "Reset password error": "Fehler beim Zurücksetzen des Passworts",
      "An error occurred while trying to reset your password. Error: ": "Beim Versuch, Ihr Passwort zurückzusetzen, ist ein Fehler aufgetreten. Fehler: ",
      "Email": "E-Mail",
      "Reset Password": "Passwort zurücksetzen",
      "You are required to reset your password.": "Sie müssen Ihr Passwort zurücksetzen.",
      "Current password": "Aktuelles Passwort",
      "New password": "Neues Passwort",
      "Repeat new password": "Neues Passwort wiederholen",
      "Enter current password": "Aktuelles Passwort eingeben",
      "Enter new password": "Neues Passwort eingeben",
      "Email does not match logged in user.": "E-Mail passt nicht zum angemeldeten Benutzer.",
      "Please enter the email you used to sign up.": "Bitte geben Sie die E-Mail-Adresse ein, mit der Sie sich angemeldet haben.",
      "Current Password field is empty.": "Das Feld für das aktuelle Passwort ist leer.",
      "Please enter your current password.": "Bitte geben Sie Ihr aktuelles Passwort ein.",
      "New Password field is empty.": "Das Feld für das neue Passwort ist leer.",
      "Please enter your new password.": "Bitte geben Sie Ihr neues Passwort ein.",
      "Repeated New Password field is empty.": "Das Feld für das wiederholte neue Passwort ist leer.",
      "Please repeat your new password.": "Bitte wiederholen Sie Ihr neues Passwort.",
      "The repeated password does not match.": "Das wiederholte Passwort stimmt nicht überein.",
      "Please make sure the new password and repeated new password match.": "Bitte stellen Sie sicher, dass das neue Passwort und das wiederholte neue Passwort übereinstimmen.",
      "Invalid email format.": "Ungültiges E-Mail-Format.",
      "Please enter a valid email, with the format": "Bitte geben Sie eine gültige E-Mail-Adresse im Format ein",
    },
    "ES": {
      "Reset password error": "Error al restablecer la contraseña",
      "An error occurred while trying to reset your password. Error: ": "Se ha producido un error al intentar restablecer su contraseña. Error: ",
      "Email": "Email",
      "Reset Password": "Restablecer Contraseña",
      "You are required to reset your password.": "Debe restablecer su contraseña.",
      "Current password": "Contraseña actual",
      "New password": "Nueva contraseña",
      "Repeat new password": "Repetir nueva contraseña",
      "Enter current password": "Introduzca la contraseña actual",
      "Enter new password": "Introduzca la nueva contraseña",
      "Email does not match logged in user.": "El correo electrónico no coincide con el usuario conectado.",
      "Please enter the email you used to sign up.": "Por favor, introduzca el correo electrónico que utilizó para registrarse.",
      "Current Password field is empty.": "El campo de la contraseña actual está vacío.",
      "Please enter your current password.": "Por favor, introduzca su contraseña actual.",
      "New Password field is empty.": "El campo de la nueva contraseña está vacío.",
      "Please enter your new password.": "Por favor, introduzca su nueva contraseña.",
      "Repeated New Password field is empty.": "El campo de la nueva contraseña repetida está vacío.",
      "Please repeat your new password.": "Por favor, repita su nueva contraseña.",
      "The repeated password does not match.": "La contraseña repetida no coincide.",
      "Please make sure the new password and repeated new password match.": "Por favor, asegúrese de que la nueva contraseña y la nueva contraseña repetida coinciden.",
      "Invalid email format.": "Formato de correo electrónico no válido.",
      "Please enter a valid email, with the format": "Por favor, introduzca un correo electrónico válido, con el formato",
    },
    "FR": {
      "Reset password error": "Erreur de réinitialisation du mot de passe",
      "An error occurred while trying to reset your password. Error: ": "Une erreur s'est produite lors de la réinitialisation de votre mot de passe. Erreur : ",
      "Email": "Email",
      "Reset Password": "Réinitialiser le mot de passe",
      "You are required to reset your password.": "Vous devez réinitialiser votre mot de passe.",
      "Current password": "Mot de passe actuel",
      "New password": "Nouveau mot de passe",
      "Repeat new password": "Répéter le nouveau mot de passe",
      "Enter current password": "Entrez le mot de passe actuel",
      "Enter new password": "Entrez le nouveau mot de passe",
      "Email does not match logged in user.": "L'e-mail ne correspond pas à l'utilisateur connecté.",
      "Please enter the email you used to sign up.": "Veuillez saisir l'e-mail que vous avez utilisé pour vous inscrire.",
      "Current Password field is empty.": "Le champ Mot de passe actuel est vide.",
      "Please enter your current password.": "Veuillez saisir votre mot de passe actuel.",
      "New Password field is empty.": "Le champ Nouveau mot de passe est vide.",
      "Please enter your new password.": "Veuillez saisir votre nouveau mot de passe.",
      "Repeated New Password field is empty.": "Le champ Nouveau mot de passe répété est vide.",
      "Please repeat your new password.": "Veuillez répéter votre nouveau mot de passe.",
      "The repeated password does not match.": "Le mot de passe répété ne correspond pas.",
      "Please make sure the new password and repeated new password match.": "Veuillez vous assurer que le nouveau mot de passe et le nouveau mot de passe répété correspondent.",
      "Invalid email format.": "Format d'e-mail invalide.",
      "Please enter a valid email, with the format": "Veuillez saisir un e-mail valide, avec le format",
    },
    "IT": {
      "Reset password error": "Errore di reimpostazione della password",
      "An error occurred while trying to reset your password. Error: ": "Si è verificato un errore durante il tentativo di reimpostare la password. Errore: ",
      "Email": "Email",
      "Reset Password": "Reimpostare la password",
      "You are required to reset your password.": "È necessario reimpostare la password.",
      "Current password": "Password attuale",
      "New password": "Nuova password",
      "Repeat new password": "Ripeti la nuova password",
      "Enter current password": "Inserisci la password corrente",
      "Enter new password": "Inserisci la nuova password",
      "Email does not match logged in user.": "L'email non corrisponde all'utente connesso.",
      "Please enter the email you used to sign up.": "Inserisci l'email che hai usato per registrarti.",
      "Current Password field is empty.": "Il campo Password corrente è vuoto.",
      "Please enter your current password.": "Inserisci la tua password corrente.",
      "New Password field is empty.": "Il campo Nuova password è vuoto.",
      "Please enter your new password.": "Inserisci la tua nuova password.",
      "Repeated New Password field is empty.": "Il campo Nuova password ripetuta è vuoto.",
      "Please repeat your new password.": "Ripeti la tua nuova password.",
      "The repeated password does not match.": "La password ripetuta non corrisponde.",
      "Please make sure the new password and repeated new password match.": "Assicurati che la nuova password e la nuova password ripetuta corrispondano.",
      "Invalid email format.": "Formato email non valido.",
      "Please enter a valid email, with the format": "Inserisci un'email valida, con il formato",
    },
    "PL": {
      "Reset password error": "Błąd resetowania hasła",
      "An error occurred while trying to reset your password. Error: ": "Wystąpił błąd podczas próby zresetowania hasła. Błąd: ",
      "Email": "E-mail",
      "Email field is empty.": "Pole E-mail jest puste.",
      "Reset Password": "Zresetuj hasło",
      "You are required to reset your password.": "Musisz zresetować swoje hasło.",
      "Current password": "Aktualne hasło",
      "New password": "Nowe hasło",
      "Repeat new password": "Powtórz nowe hasło",
      "Enter current password": "Wprowadź aktualne hasło",
      "Enter new password": "Wprowadź nowe hasło",
      "Email does not match logged in user.": "Adres e-mail nie pasuje do zalogowanego użytkownika.",
      "Please enter the email you used to sign up.": "Wprowadź adres e-mail, który użyłeś do rejestracji.",
      "Current Password field is empty.": "Pole Aktualne hasło jest puste.",
      "Please enter your current password.": "Wprowadź swoje aktualne hasło.",
      "New Password field is empty.": "Pole Nowe hasło jest puste.",
      "Please enter your new password.": "Wprowadź swoje nowe hasło.",
      "Repeated New Password field is empty.": "Pole Powtórz nowe hasło jest puste.",
      "Please repeat your new password.": "Powtórz swoje nowe hasło.",
      "The repeated password does not match.": "Powtórzone hasło nie pasuje.",
      "Please make sure the new password and repeated new password match.": "Upewnij się, że nowe hasło i powtórzone nowe hasło są zgodne.",
      "Invalid email format.": "Nieprawidłowy format adresu e-mail.",
      "Please enter a valid email, with the format": "Wprowadź prawidłowy adres e-mail, w formacie",
    },
    "TR": {
      "Reset password error": "Şifre sıfırlama hatası",
      "An error occurred while trying to reset your password. Error: ": "Şifrenizi sıfırlamaya çalışırken bir hata oluştu. Hata oluştu: ",
      "Email": "E-posta",
      "Reset Password": "Şifreyi yenile",
      "You are required to reset your password.": "Şifrenizi sıfırlamanız gerekiyor.",
      "Current password": "Geçerli şifre",
      "New password": "Yeni şifre",
      "Repeat new password": "Yeni şifreyi tekrarlayın",
      "Enter current password": "Geçerli şifreyi girin",
      "Enter new password": "Yeni şifreyi girin",
      "Email does not match logged in user.": "E-posta, oturum açmış kullanıcıyla eşleşmiyor.",
      "Please enter the email you used to sign up.": "Kaydolurken kullandığınız e-postayı girin.",
      "Current Password field is empty.": "Geçerli Şifre alanı boş.",
      "Please enter your current password.": "Lütfen geçerli şifrenizi girin.",
      "New Password field is empty.": "Yeni Şifre alanı boş.",
      "Please enter your new password.": "Lütfen yeni şifrenizi girin.",
      "Repeated New Password field is empty.": "Yeni Şifre Tekrarı alanı boş.",
      "Please repeat your new password.": "Lütfen yeni şifrenizi tekrarlayın.",
      "The repeated password does not match.": "Tekrarlanan şifre eşleşmiyor.",
      "Please make sure the new password and repeated new password match.": "Yeni şifre ve tekrarlanan yeni şifrenin eşleştiğinden emin olun.",
      "Invalid email format.": "Geçersiz e-posta formatı.",
      "Please enter a valid email, with the format": "Lütfen geçerli bir e-posta adresi girin, format",
    },
    "ZH": {
      "Reset password error": "重置密码错误",
      "An error occurred while trying to reset your password. Error: ": "尝试重置密码时发生错误。错误： ",
      "Email": "电子邮件",
      "Reset Password": "重设密码",
      "You are required to reset your password.": "您需要重设密码。",
      "Current password": "当前密码",
      "New password": "新密码",
      "Repeat new password": "重复新密码",
      "Enter current password": "输入当前密码",
      "Enter new password": "输入新密码",
      "Email does not match logged in user.": "电子邮件与登录用户不匹配。",
      "Please enter the email you used to sign up.": "请输入您用于注册的电子邮件。",
      "Current Password field is empty.": "当前密码字段为空。",
      "Please enter your current password.": "请输入您的当前密码。",
      "New Password field is empty.": "新密码字段为空。",
      "Please enter your new password.": "请输入您的新密码。",
      "Repeated New Password field is empty.": "重复新密码字段为空。",
      "Please repeat your new password.": "请重复您的新密码。",
      "The repeated password does not match.": "重复的密码不匹配。",
      "Please make sure the new password and repeated new password match.": "请确保新密码和重复的新密码匹配。",
      "Invalid email format.": "电子邮件格式无效。",
      "Please enter a valid email, with the format": "请输入有效的电子邮件，格式为",
    },
  }
</i18n>
