export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "EN": {
        "Go Back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go Back"])},
        "Page not found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ooops.. We couldn't find the page you were looking for."])},
        "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])}
      },
      "DE": {
        "Go Back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück gehen"])},
        "Page not found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seite nicht gefunden"])},
        "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startseite"])}
      },
      "ES": {
        "Go Back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver atrás"])},
        "Page not found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Página no encontrada"])},
        "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicio"])}
      },
      "FR": {
        "Go Back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retourner à la page précédente"])},
        "Page not found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page non trouvée"])},
        "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accueil"])}
      },
      "IT": {
        "Go Back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Torna Torna indietro"])},
        "Page not found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagina non trovata"])},
        "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La casa"])}
      },
      "PL": {
        "Go Back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wróć"])},
        "Page not found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono strony"])},
        "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strona główna"])}
      },
      "TR": {
        "Go Back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geri Dön"])},
        "Page not found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sayfa bulunamadı"])},
        "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ev"])}
      },
      "ZH": {
        "Page not found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返回"])},
        "Go Back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["页面未找到"])},
        "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["首页"])}
      }
    }
  })
}
