export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "EN": {
        "Sign In": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign In"])},
        "Language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
        "Welcome back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome back"])},
        "Enter your email and password to sign in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your email and password to sign in"])},
        "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
        "Empty email or password field.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empty email or password field."])},
        "Please enter the email and password.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the email and password."])},
        "Error validating credentials.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error validating credentials."])},
        "Input correct credentials and try again.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input correct credentials and try again."])},
        "Invalid email format.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid email format."])},
        "Please enter a valid email, with the format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid email, with the format"])},
        "Click here to reset it.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click here to reset it."])},
        "Forgot your password?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot your password?"])}
      },
      "DE": {
        "Sign In": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden"])},
        "Language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache"])},
        "Welcome back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Willkommen zurück"])},
        "Enter your email and password to sign in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie Ihre E-Mail-Adresse und Ihr Passwort ein, um sich anzumelden"])},
        "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
        "Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
        "Empty email or password field.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leeres E-Mail oder Passwortfeld."])},
        "Please enter the email and password.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie die E-Mail-Adresse und das Passwort ein."])},
        "Error validating credentials.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler beim Überprüfen der Anmeldeinformationen."])},
        "Input correct credentials and try again.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie die richtigen Anmeldeinformationen ein und versuchen Sie es erneut."])},
        "Invalid email format.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültiges E-Mail-Format."])},
        "Please enter a valid email, with the format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie eine gültige E-Mail-Adresse im Format ein"])},
        "Click here to reset it.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klicken Sie hier, um es zurückzusetzen."])},
        "Forgot your password?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort vergessen?"])}
      },
      "ES": {
        "Sign In": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar Sesión"])},
        "Language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idioma"])},
        "Welcome back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenido"])},
        "Enter your email and password to sign in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduzca su correo electrónico y contraseña para iniciar sesión"])},
        "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña"])},
        "Empty email or password field.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campos de correo electrónico o contraseña vacíos."])},
        "Please enter the email and password.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor introduzca el correo electrónico y la contraseña."])},
        "Error validating credentials.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error al validar las credenciales."])},
        "Input correct credentials and try again.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduzca las credenciales correctas e inténtelo de nuevo."])},
        "Invalid email format.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formato de correo electrónico no válido."])},
        "Please enter a valid email, with the format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, introduzca un correo electrónico válido, con el formato"])},
        "Click here to reset it.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haga clic aquí para restablecerlo."])},
        "Forgot your password?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Olvidaste tu contraseña?"])}
      },
      "FR": {
        "Sign In": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter"])},
        "Language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langue"])},
        "Welcome back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenue"])},
        "Enter your email and password to sign in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez votre email et votre mot de passe pour vous connecter"])},
        "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
        "Empty email or password field.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Champ e-mail ou mot de passe vide."])},
        "Please enter the email and password.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir l'e-mail et le mot de passe."])},
        "Error validating credentials.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur de validation des informations d'identification."])},
        "Input correct credentials and try again.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez les informations d'identification correctes et réessayez."])},
        "Invalid email format.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format d'e-mail invalide."])},
        "Please enter a valid email, with the format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir un e-mail valide, avec le format"])},
        "Click here to reset it.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez ici pour le réinitialiser."])},
        "Forgot your password?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe oublié?"])}
      },
      "IT": {
        "Sign In": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accedi"])},
        "Language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lingua"])},
        "Welcome back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bentornato"])},
        "Enter your email and password to sign in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci la tua email e la tua password per accedere"])},
        "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
        "Empty email or password field.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campo email o password vuoto."])},
        "Please enter the email and password.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci l'email e la password."])},
        "Error validating credentials.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore di convalida delle credenziali."])},
        "Input correct credentials and try again.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci le credenziali corrette e riprova."])},
        "Invalid email format.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formato email non valido."])},
        "Please enter a valid email, with the format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci un'email valida, con il formato"])},
        "Click here to reset it.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clicca qui per reimpostarlo."])},
        "Forgot your password?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hai dimenticato la password?"])}
      },
      "PL": {
        "Sign In": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaloguj"])},
        "Language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Język"])},
        "Welcome back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Witamy z powrotem"])},
        "Enter your email and password to sign in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wpisz swój adres e-mail i hasło, aby się zalogować"])},
        "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
        "Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hasło"])},
        "Empty email or password field.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puste pole e-mail lub hasło."])},
        "Please enter the email and password.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proszę podać adres e-mail i hasło."])},
        "Error validating credentials.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Błąd weryfikacji poświadczeń."])},
        "Input correct credentials and try again.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadź poprawne dane logowania i spróbuj ponownie."])},
        "Invalid email format.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieprawidłowy format adresu e-mail."])},
        "Please enter a valid email, with the format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadź prawidłowy adres e-mail, w formacie"])},
        "Click here to reset it.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kliknij tutaj, aby zresetować."])},
        "Forgot your password?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapomniałeś hasła?"])}
      },
      "TR": {
        "Sign In": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oturum Aç"])},
        "Language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dil"])},
        "Welcome back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tekrar Hoşgeldiniz"])},
        "Enter your email and password to sign in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oturum açmak için e-posta adresinizi ve şifrenizi girin"])},
        "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-posta"])},
        "Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifre"])},
        "Empty email or password field.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boş e-posta veya şifre alanı."])},
        "Please enter the email and password.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen e-posta ve şifreyi girin."])},
        "Error validating credentials.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kimlik bilgileri doğrulanamadı."])},
        "Input correct credentials and try again.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doğru kimlik bilgilerini girin ve tekrar deneyin."])},
        "Invalid email format.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geçersiz e-posta formatı."])},
        "Please enter a valid email, with the format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen geçerli bir e-posta adresi girin, format"])},
        "Click here to reset it.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sıfırlamak için buraya tıklayın."])},
        "Forgot your password?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifrenizi mi unuttunuz?"])}
      },
      "ZH": {
        "Sign In": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录"])},
        "Language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["语言"])},
        "Welcome back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["欢迎回来"])},
        "Enter your email and password to sign in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入您的电子邮件和密码登录"])},
        "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮件"])},
        "Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码"])},
        "Empty email or password field.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["空电子邮件或密码字段。"])},
        "Please enter the email and password.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入电子邮件和密码。"])},
        "Error validating credentials.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["验证凭据时出错。"])},
        "Input correct credentials and try again.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入正确的凭据，然后重试。"])},
        "Invalid email format.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮件格式无效。"])},
        "Please enter a valid email, with the format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入有效的电子邮件，格式为"])},
        "Click here to reset it.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["单击此处重置。"])},
        "Forgot your password?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["忘记密码了吗？"])}
      }
    }
  })
}
