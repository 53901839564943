export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "EN": {
        "Reset password error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset password error"])},
        "An error occurred while trying to reset your password. Error: ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred while trying to reset your password. Error: "])},
        "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "Reset Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset Password"])},
        "You are required to reset your password.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are required to reset your password."])},
        "Current password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current password"])},
        "New password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password"])},
        "Repeat new password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat new password"])},
        "Enter current password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter current password"])},
        "Enter new password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter new password"])},
        "Email does not match logged in user.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email does not match logged in user."])},
        "Please enter the email you used to sign up.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the email you used to sign up."])},
        "Current Password field is empty.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Password field is empty."])},
        "Please enter your current password.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your current password."])},
        "New Password field is empty.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Password field is empty."])},
        "Please enter your new password.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your new password."])},
        "Repeated New Password field is empty.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeated New Password field is empty."])},
        "Please repeat your new password.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please repeat your new password."])},
        "The repeated password does not match.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The repeated password does not match."])},
        "Please make sure the new password and repeated new password match.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please make sure the new password and repeated new password match."])},
        "Invalid email format.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid email format."])},
        "Please enter a valid email, with the format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid email, with the format"])}
      },
      "DE": {
        "Reset password error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler beim Zurücksetzen des Passworts"])},
        "An error occurred while trying to reset your password. Error: ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Versuch, Ihr Passwort zurückzusetzen, ist ein Fehler aufgetreten. Fehler: "])},
        "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
        "Reset Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort zurücksetzen"])},
        "You are required to reset your password.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie müssen Ihr Passwort zurücksetzen."])},
        "Current password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelles Passwort"])},
        "New password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Passwort"])},
        "Repeat new password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Passwort wiederholen"])},
        "Enter current password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelles Passwort eingeben"])},
        "Enter new password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Passwort eingeben"])},
        "Email does not match logged in user.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail passt nicht zum angemeldeten Benutzer."])},
        "Please enter the email you used to sign up.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie die E-Mail-Adresse ein, mit der Sie sich angemeldet haben."])},
        "Current Password field is empty.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Feld für das aktuelle Passwort ist leer."])},
        "Please enter your current password.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie Ihr aktuelles Passwort ein."])},
        "New Password field is empty.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Feld für das neue Passwort ist leer."])},
        "Please enter your new password.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie Ihr neues Passwort ein."])},
        "Repeated New Password field is empty.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Feld für das wiederholte neue Passwort ist leer."])},
        "Please repeat your new password.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wiederholen Sie Ihr neues Passwort."])},
        "The repeated password does not match.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das wiederholte Passwort stimmt nicht überein."])},
        "Please make sure the new password and repeated new password match.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte stellen Sie sicher, dass das neue Passwort und das wiederholte neue Passwort übereinstimmen."])},
        "Invalid email format.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültiges E-Mail-Format."])},
        "Please enter a valid email, with the format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie eine gültige E-Mail-Adresse im Format ein"])}
      },
      "ES": {
        "Reset password error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error al restablecer la contraseña"])},
        "An error occurred while trying to reset your password. Error: ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha producido un error al intentar restablecer su contraseña. Error: "])},
        "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "Reset Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restablecer Contraseña"])},
        "You are required to reset your password.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe restablecer su contraseña."])},
        "Current password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña actual"])},
        "New password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva contraseña"])},
        "Repeat new password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repetir nueva contraseña"])},
        "Enter current password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduzca la contraseña actual"])},
        "Enter new password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduzca la nueva contraseña"])},
        "Email does not match logged in user.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El correo electrónico no coincide con el usuario conectado."])},
        "Please enter the email you used to sign up.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, introduzca el correo electrónico que utilizó para registrarse."])},
        "Current Password field is empty.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El campo de la contraseña actual está vacío."])},
        "Please enter your current password.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, introduzca su contraseña actual."])},
        "New Password field is empty.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El campo de la nueva contraseña está vacío."])},
        "Please enter your new password.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, introduzca su nueva contraseña."])},
        "Repeated New Password field is empty.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El campo de la nueva contraseña repetida está vacío."])},
        "Please repeat your new password.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, repita su nueva contraseña."])},
        "The repeated password does not match.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La contraseña repetida no coincide."])},
        "Please make sure the new password and repeated new password match.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, asegúrese de que la nueva contraseña y la nueva contraseña repetida coinciden."])},
        "Invalid email format.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formato de correo electrónico no válido."])},
        "Please enter a valid email, with the format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, introduzca un correo electrónico válido, con el formato"])}
      },
      "FR": {
        "Reset password error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur de réinitialisation du mot de passe"])},
        "An error occurred while trying to reset your password. Error: ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur s'est produite lors de la réinitialisation de votre mot de passe. Erreur : "])},
        "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "Reset Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser le mot de passe"])},
        "You are required to reset your password.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez réinitialiser votre mot de passe."])},
        "Current password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe actuel"])},
        "New password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau mot de passe"])},
        "Repeat new password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Répéter le nouveau mot de passe"])},
        "Enter current password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez le mot de passe actuel"])},
        "Enter new password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez le nouveau mot de passe"])},
        "Email does not match logged in user.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'e-mail ne correspond pas à l'utilisateur connecté."])},
        "Please enter the email you used to sign up.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir l'e-mail que vous avez utilisé pour vous inscrire."])},
        "Current Password field is empty.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le champ Mot de passe actuel est vide."])},
        "Please enter your current password.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir votre mot de passe actuel."])},
        "New Password field is empty.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le champ Nouveau mot de passe est vide."])},
        "Please enter your new password.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir votre nouveau mot de passe."])},
        "Repeated New Password field is empty.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le champ Nouveau mot de passe répété est vide."])},
        "Please repeat your new password.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez répéter votre nouveau mot de passe."])},
        "The repeated password does not match.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot de passe répété ne correspond pas."])},
        "Please make sure the new password and repeated new password match.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez vous assurer que le nouveau mot de passe et le nouveau mot de passe répété correspondent."])},
        "Invalid email format.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format d'e-mail invalide."])},
        "Please enter a valid email, with the format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir un e-mail valide, avec le format"])}
      },
      "IT": {
        "Reset password error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore di reimpostazione della password"])},
        "An error occurred while trying to reset your password. Error: ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si è verificato un errore durante il tentativo di reimpostare la password. Errore: "])},
        "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "Reset Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reimpostare la password"])},
        "You are required to reset your password.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È necessario reimpostare la password."])},
        "Current password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password attuale"])},
        "New password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuova password"])},
        "Repeat new password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ripeti la nuova password"])},
        "Enter current password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci la password corrente"])},
        "Enter new password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci la nuova password"])},
        "Email does not match logged in user.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'email non corrisponde all'utente connesso."])},
        "Please enter the email you used to sign up.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci l'email che hai usato per registrarti."])},
        "Current Password field is empty.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il campo Password corrente è vuoto."])},
        "Please enter your current password.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci la tua password corrente."])},
        "New Password field is empty.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il campo Nuova password è vuoto."])},
        "Please enter your new password.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci la tua nuova password."])},
        "Repeated New Password field is empty.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il campo Nuova password ripetuta è vuoto."])},
        "Please repeat your new password.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ripeti la tua nuova password."])},
        "The repeated password does not match.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La password ripetuta non corrisponde."])},
        "Please make sure the new password and repeated new password match.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assicurati che la nuova password e la nuova password ripetuta corrispondano."])},
        "Invalid email format.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formato email non valido."])},
        "Please enter a valid email, with the format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci un'email valida, con il formato"])}
      },
      "PL": {
        "Reset password error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Błąd resetowania hasła"])},
        "An error occurred while trying to reset your password. Error: ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wystąpił błąd podczas próby zresetowania hasła. Błąd: "])},
        "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
        "Email field is empty.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pole E-mail jest puste."])},
        "Reset Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zresetuj hasło"])},
        "You are required to reset your password.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Musisz zresetować swoje hasło."])},
        "Current password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualne hasło"])},
        "New password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nowe hasło"])},
        "Repeat new password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powtórz nowe hasło"])},
        "Enter current password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadź aktualne hasło"])},
        "Enter new password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadź nowe hasło"])},
        "Email does not match logged in user.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres e-mail nie pasuje do zalogowanego użytkownika."])},
        "Please enter the email you used to sign up.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadź adres e-mail, który użyłeś do rejestracji."])},
        "Current Password field is empty.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pole Aktualne hasło jest puste."])},
        "Please enter your current password.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadź swoje aktualne hasło."])},
        "New Password field is empty.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pole Nowe hasło jest puste."])},
        "Please enter your new password.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadź swoje nowe hasło."])},
        "Repeated New Password field is empty.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pole Powtórz nowe hasło jest puste."])},
        "Please repeat your new password.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powtórz swoje nowe hasło."])},
        "The repeated password does not match.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powtórzone hasło nie pasuje."])},
        "Please make sure the new password and repeated new password match.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upewnij się, że nowe hasło i powtórzone nowe hasło są zgodne."])},
        "Invalid email format.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieprawidłowy format adresu e-mail."])},
        "Please enter a valid email, with the format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadź prawidłowy adres e-mail, w formacie"])}
      },
      "TR": {
        "Reset password error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifre sıfırlama hatası"])},
        "An error occurred while trying to reset your password. Error: ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifrenizi sıfırlamaya çalışırken bir hata oluştu. Hata oluştu: "])},
        "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-posta"])},
        "Reset Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifreyi yenile"])},
        "You are required to reset your password.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifrenizi sıfırlamanız gerekiyor."])},
        "Current password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geçerli şifre"])},
        "New password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni şifre"])},
        "Repeat new password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni şifreyi tekrarlayın"])},
        "Enter current password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geçerli şifreyi girin"])},
        "Enter new password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni şifreyi girin"])},
        "Email does not match logged in user.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-posta, oturum açmış kullanıcıyla eşleşmiyor."])},
        "Please enter the email you used to sign up.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaydolurken kullandığınız e-postayı girin."])},
        "Current Password field is empty.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geçerli Şifre alanı boş."])},
        "Please enter your current password.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen geçerli şifrenizi girin."])},
        "New Password field is empty.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Şifre alanı boş."])},
        "Please enter your new password.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen yeni şifrenizi girin."])},
        "Repeated New Password field is empty.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Şifre Tekrarı alanı boş."])},
        "Please repeat your new password.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen yeni şifrenizi tekrarlayın."])},
        "The repeated password does not match.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tekrarlanan şifre eşleşmiyor."])},
        "Please make sure the new password and repeated new password match.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni şifre ve tekrarlanan yeni şifrenin eşleştiğinden emin olun."])},
        "Invalid email format.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geçersiz e-posta formatı."])},
        "Please enter a valid email, with the format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen geçerli bir e-posta adresi girin, format"])}
      },
      "ZH": {
        "Reset password error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重置密码错误"])},
        "An error occurred while trying to reset your password. Error: ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["尝试重置密码时发生错误。错误： "])},
        "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮件"])},
        "Reset Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重设密码"])},
        "You are required to reset your password.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您需要重设密码。"])},
        "Current password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当前密码"])},
        "New password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新密码"])},
        "Repeat new password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重复新密码"])},
        "Enter current password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入当前密码"])},
        "Enter new password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入新密码"])},
        "Email does not match logged in user.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮件与登录用户不匹配。"])},
        "Please enter the email you used to sign up.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入您用于注册的电子邮件。"])},
        "Current Password field is empty.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当前密码字段为空。"])},
        "Please enter your current password.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入您的当前密码。"])},
        "New Password field is empty.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新密码字段为空。"])},
        "Please enter your new password.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入您的新密码。"])},
        "Repeated New Password field is empty.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重复新密码字段为空。"])},
        "Please repeat your new password.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请重复您的新密码。"])},
        "The repeated password does not match.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重复的密码不匹配。"])},
        "Please make sure the new password and repeated new password match.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请确保新密码和重复的新密码匹配。"])},
        "Invalid email format.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮件格式无效。"])},
        "Please enter a valid email, with the format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入有效的电子邮件，格式为"])}
      }
    }
  })
}
