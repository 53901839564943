export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "EN": {
        "Optimizations Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimizations Library"])},
        "New Reciprocator Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Reciprocator Optimization"])},
        "Line Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Line Name"])},
        "Create Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Optimization"])}
      },
      "DE": {
        "Optimizations Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimierungsliste"])},
        "New Reciprocator Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Optimierung"])},
        "Line Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name der Linie"])},
        "Create Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimierung Erstellen"])}
      },
      "ES": {
        "Optimizations Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de Optimizaciones"])},
        "New Reciprocator Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva Optimización del reciprocador"])},
        "Line Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de la línea"])},
        "Create Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear Optimización"])}
      },
      "FR": {
        "Optimizations Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des Optimisations"])},
        "New Reciprocator Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle Optimisation du Réciprocateur"])},
        "Line Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de la Ligne"])},
        "Create Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer l'Optimisation"])}
      },
      "IT": {
        "Optimizations Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elenco delle Ottimizzazioni"])},
        "New Reciprocator Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuova Ottimizzazione del Reciprocator"])},
        "Line Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome della Linea"])},
        "Create Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creare l'Ottimizzazione"])}
      },
      "PL": {
        "Optimizations Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista Optymalizacji"])},
        "New Reciprocator Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nowa optymalizacja Reciprocatora"])},
        "Line Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa Linii"])},
        "Create Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tworzenie Optymalizacji"])}
      },
      "TR": {
        "Optimizations Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimizasyonlar Listesi"])},
        "New Reciprocator Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Reciprocator Optimizasyonu"])},
        "Line Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hat Adı"])},
        "Create Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimizasyon Oluşturun"])}
      },
      "ZH": {
        "Optimizations Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["优化列表"])},
        "New Reciprocator Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新优化"])},
        "Line Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["线路名称"])},
        "Create Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建优化"])}
      }
    }
  })
}
