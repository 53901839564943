export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "EN": {
        "Animation generation unsuccessful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animation generation unsuccessful"])},
        "Applied powder cannot be visualized as an animation for this line and parameters. Optimal settings and results images are still available in the results section.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applied powder cannot be visualized as an animation for this line and parameters. Optimal settings and results images are still available in the results section."])},
        "Something went wrong...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong..."])},
        "Simulation results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simulation results"])},
        "Current Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Settings"])},
        "Uniformity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uniformity"])},
        "Powder Waste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powder Waste"])},
        "CoatingAI Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CoatingAI Settings"])},
        "Visualize graphs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualize graphs"])},
        "Activate Toggle to visualize simulation images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate Toggle to visualize simulation images"])},
        "Calculation running": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculation running"])},
        "Uniformity comparison": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uniformity comparison"])},
        "Applied spray": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applied spray"])},
        "Movement chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Movement chart"])},
        "Applied spray animation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applied spray animation"])},
        "Powder Pie Chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powder Pie Chart"])},
        "Current Settings Coated Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Settings Coated Area"])},
        "CoatingAI Settings Coated Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CoatingAI Settings Coated Area"])},
        "Current Settings Reciprocator Movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Settings Reciprocator Movement"])},
        "CoatingAI Settings Reciprocator Movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CoatingAI Settings Reciprocator Movement"])},
        "Current Settings Pie Chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Settings Pie Chart"])},
        "CoatingAI Settings Pie Chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CoatingAI Settings Pie Chart"])},
        "You could save up to ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You could save up to "])},
        "per year!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per year!"])},
        "tons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tons"])},
        "tonnes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tonnes"])},
        "Overcoated powder:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overcoated powder:"])},
        "Oversprayed powder:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oversprayed powder:"])},
        "The amount of extra powder applied because of an uneven powder distribution on the substrate.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The amount of extra powder applied because of an uneven powder distribution on the substrate."])},
        "The powder that does not stick to the part and is then not reclaimed by the recycling system.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The powder that does not stick to the part and is then not reclaimed by the recycling system."])}
      },
      "DE": {
        "Animation generation unsuccessful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erzeugung von Animationen erfolglos"])},
        "Applied powder cannot be visualized as an animation for this line and parameters. Optimal settings and results images are still available in the results section.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das aufgetragene Pulver kann für diese Linie und Parameter nicht als Animation dargestellt werden. Optimale Einstellungen und Ergebnisbilder sind weiterhin im Ergebnisbereich verfügbar."])},
        "Something went wrong...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etwas ist schief gelaufen..."])},
        "Simulation results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simulationsergebnisse"])},
        "Current Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelle Einstellungen"])},
        "Uniformity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gleichmäßigkeit"])},
        "Powder Waste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pulverabfall"])},
        "CoatingAI Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CoatingAI Einstellungen"])},
        "Visualize graphs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualisieren Sie Diagramme"])},
        "Activate Toggle to visualize simulation images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivieren Sie den Umschalter, um Simulationen zu visualisieren"])},
        "Calculation running": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berechnung läuft"])},
        "Uniformity comparison": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gleichmäßigkeitsvergleich"])},
        "Applied spray": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufgetragener Sprühnebel"])},
        "Movement chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewegungsdiagramm"])},
        "Applied spray animation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufgetragene Sprühnebelanimation"])},
        "Powder Pie Chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pulverkuchen-Diagramm"])},
        "Current Settings Coated Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelle Einstellungen Beschichtete Fläche"])},
        "CoatingAI Settings Coated Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CoatingAI Einstellungen Beschichtete Fläche"])},
        "Current Settings Reciprocator Movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelle Einstellungen Pistolenbewegung"])},
        "CoatingAI Settings Reciprocator Movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CoatingAI Einstellungen Pistolenbewegung"])},
        "Current Settings Pie Chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelle Einstellungen Kuchen-Diagramm"])},
        "CoatingAI Settings Pie Chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CoatingAI Einstellungen Kuchen-Diagramm"])},
        "You could save up to ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie könnten bis zu "])},
        "per year!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pro Jahr sparen!"])},
        "tons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tonnen"])},
        "tonnes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tonnen"])},
        "Overcoated powder:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overcoated powder:"])},
        "Oversprayed powder:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oversprayed powder:"])},
        "The amount of extra powder applied because of an uneven powder distribution on the substrate.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Menge an zusätzlichem Pulver, die aufgrund einer ungleichmäßigen Verteilung des Pulvers auf dem Substrat aufgetragen wird."])},
        "The powder that does not stick to the part and is then not reclaimed by the recycling system.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Pulver, das nicht auf dem Teil haften bleibt und vom Recycling-System nicht zurückgewonnen wird."])}
      },
      "ES": {
        "Animation generation unsuccessful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La animación no se ha podido generar"])},
        "Applied powder cannot be visualized as an animation for this line and parameters. Optimal settings and results images are still available in the results section.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El polvo aplicado no puede visualizarse como animación para esta línea y parámetros. Los ajustes óptimos y las imágenes de los resultados siguen estando disponibles en la sección de resultados."])},
        "Something went wrong...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algo salió mal..."])},
        "Simulation results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultados de la simulación"])},
        "Current Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración actual"])},
        "Uniformity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uniformidad"])},
        "Powder Waste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desperdicio de pintura en polvo"])},
        "CoatingAI Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración de CoatingAI"])},
        "Visualize graphs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizar gráficos"])},
        "Activate Toggle to visualize simulation images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activar el interruptor para visualizar imágenes de simulación"])},
        "Calculation running": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cálculo en proceso"])},
        "Uniformity comparison": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comparación de la uniformidad"])},
        "Applied spray": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pintura aplicada"])},
        "Movement chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gráfico del movimiento"])},
        "Applied spray animation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animación de la pintura aplicada"])},
        "Powder Pie Chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grafico del uso de la pintura en polvo"])},
        "Current Settings Coated Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Área recubierta de la configuración actual"])},
        "CoatingAI Settings Coated Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Área recubierta de la configuración de CoatingAI"])},
        "Current Settings Reciprocator Movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Movimiento del reciprocador de la configuración actual"])},
        "CoatingAI Settings Reciprocator Movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Movimiento del reciprocador de la configuración de CoatingAI"])},
        "Current Settings Pie Chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración actual"])},
        "CoatingAI Settings Pie Chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración de CoatingAI"])},
        "You could save up to ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Podrías ahorrar hasta "])},
        "per year!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["por año!"])},
        "tons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["toneladas cortas"])},
        "tonnes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["toneladas"])},
        "Overcoated powder:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overcoated powder:"])},
        "Oversprayed powder:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oversprayed powder:"])},
        "The amount of extra powder applied because of an uneven powder distribution on the substrate.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La cantidad de polvo adicional aplicada debido a una distribución desigual del polvo sobre el sustrato."])},
        "The powder that does not stick to the part and is then not reclaimed by the recycling system.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El polvo que no se adhiere a la pieza y que no es recuperado por el sistema de reciclado."])}
      },
      "FR": {
        "Animation generation unsuccessful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La génération d'animation n'a pas abouti"])},
        "Applied powder cannot be visualized as an animation for this line and parameters. Optimal settings and results images are still available in the results section.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La poudre appliquée ne peut pas être visualisée sous forme d'animation pour cette ligne et ces paramètres. Les réglages optimaux et les images des résultats sont toujours disponibles dans la section des résultats."])},
        "Something went wrong...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quelque chose s'est mal passé..."])},
        "Simulation results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultats de la simulation"])},
        "Current Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres actuels"])},
        "Uniformity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uniformité"])},
        "Powder Waste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déchets de poudre"])},
        "CoatingAI Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres de CoatingAI"])},
        "Visualize graphs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualiser les graphiques"])},
        "Activate Toggle to visualize simulation images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activer le basculement pour visualiser les images de simulation"])},
        "Calculation running": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calcul en cours"])},
        "Uniformity comparison": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comparaison de l'uniformité"])},
        "Applied spray": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pulvérisation appliquée"])},
        "Movement chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graphique de mouvement"])},
        "Applied spray animation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animation de la pulvérisation appliquée"])},
        "Powder Pie Chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diagramme circulaire de la poudre"])},
        "Current Settings Coated Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zone recouverte des paramètres actuels"])},
        "CoatingAI Settings Coated Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zone recouverte des paramètres de CoatingAI"])},
        "Current Settings Reciprocator Movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mouvement du pistolet des paramètres actuels"])},
        "CoatingAI Settings Reciprocator Movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mouvement du pistolet des paramètres de CoatingAI"])},
        "Current Settings Pie Chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diagramme circulaire des paramètres actuels"])},
        "CoatingAI Settings Pie Chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diagramme circulaire des paramètres de CoatingAI"])},
        "You could save up to ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pourriez économiser jusqu'à "])},
        "per year!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["par an!"])},
        "tons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tonnes courtes"])},
        "tonnes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tonnes"])},
        "Overcoated powder:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overcoated powder:"])},
        "Oversprayed powder:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oversprayed powder:"])},
        "The amount of extra powder applied because of an uneven powder distribution on the substrate.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La quantité de poudre supplémentaire appliquée en raison d'une distribution inégale de la poudre sur le substrat."])},
        "The powder that does not stick to the part and is then not reclaimed by the recycling system.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La poudre qui n'adhère pas à la pièce et qui n'est donc pas récupérée par le système de recyclage."])}
      }
    }
  })
}
