<template>
  <div class="py-5 container-fluid">
    <div id="topinfo">
      <div class="row mt-4 justify-content-center">
        <div class="card">
          <div class="card-header mb-0 text-center">
            <h4> Guns Equalization <b> FineTune </b> </h4>
            <h5>
              for Line <i>{{ line.name }}</i>
            </h5>
          </div>
        </div>
      </div>
      <div class="row mt-4 justify-content-left">
        <div class="card">
          <div class="justify-content-left mt-4 ms-4 mb-4">
            <label v-if="selected_powder != null" class="mt-2 mb-2">
              Selected Powder
            </label>
            <div
              class="mt-2 mb-2"
              :style="{ position: 'relative', pointerEvents: powders.length === 1 ? 'none' : 'auto' }"
              @click.stop="(powders.length > 1) && (dropdownVisible = !dropdownVisible)"
            >
              <button class="form-control">
                {{ selected_powder ? selected_powder.name : (powders.length > 0 ? "Click here to select a Powder": "No Powders to select") }}
              </button>
                <div
                  v-if="dropdownVisible"
                  class="dropdown-menu show w-100"
                  style="border: 1px solid #ccc; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);"
                >
                <input
                  v-model="searchQuery"
                  class="form-control mt-1 mb-2 ms-2 w-90"
                  type="text"
                  placeholder="Search..."
                  @click.stop
                />
                <div
                  v-for="powder in filteredPowders"
                  :key="powder.id"
                  class="dropdown-item"
                  style="cursor: pointer"
                  @click="
                    clearData();
                    selected_powder = powder;
                    getLatestPowderThroughput();
                    showLineLayout();
                  "
                >
                  {{ powder.name }}
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="selected_powder"
            class="card-body"
            style="padding-top: 0.2rem"
          >
            <div class="row mb-4">
              <h5 class="mt-2">Line Layout</h5>
              <canvas
                id="lineLayoutCanvas"
                class="col-12 mt-0 mb-4 justify-content-center"
                width="700"
                height="300"
                :style="`max-width: 700px; ${line.id !== null ? '' : 'display: none;'}`"
              >
              </canvas>
            </div>
            <div class="row mb-4">
              <h5>Powder Amount Parameters</h5>
              <div class="row">
                <div class="col-4">
                  <label> Minimum Powder Amount </label>
                </div>
                <div class="col-4">
                  <label> Maximum Powder Amount </label>
                </div>
                <div class="col-4">
                  <label> Time Interval [s]</label>
                </div>
              </div>
              <div class="row">
                <div class="col-4">&nbsp; {{ minPowderAmountSetting }}</div>
                <div class="col-4">&nbsp; {{ maxPowderAmountSetting }}</div>
                <div class="col-4">&nbsp; {{ time_interval }} s</div>
              </div>
            </div>
            <div class="d-flex justify-content-between align-items-center mb-3">
              <h5 class="text-start mb-2">Powder Output Measurements</h5>
            </div>
            <div style="overflow-x: auto">
              <div class="table-responsive">
                <table
                  class="table table-sm text-start"
                  style="border-width: 0 !important"
                >
                  <thead>
                    <tr>
                      <th>Gun</th>
                      <th>Empty bag weight</th>
                      <th
                        v-for="index in maxPairs"
                        :key="'header-' + index"
                      >
                        Setting {{ index }} Weight {{ index }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(gun, gunIndex) in powderoutput_measurements"
                      :key="gunIndex"
                    >
                      <td>{{ gunIndex + 1 }}</td>
                      <td>
                        <input
                          v-model="gun.empty_bag_weight"
                          type="number"
                          class="form-control form-control-sm"
                          :placeholder="`Bag weight [${$store.state.units_system[$store.state.user_data.unit_system].grams}] `"
                          style="max-width: 190px"
                        />
                      </td>
                      <td
                        v-for="index in maxPairs"
                        :key="'pair-' + gunIndex + '-' + index"
                      >
                        <div
                          v-if="gun.gun_measurements[index - 1]"
                          class="d-flex"
                        >
                          <div style="width: 70px;">
                            {{ gun.gun_measurements[index - 1].setting }}
                          </div>
                          <input
                            v-model="gun.gun_measurements[index - 1].weight"
                            type="number"
                            class="form-control form-control-sm me-2"
                            placeholder="Weight"
                            style="width: 80px"
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div
              v-if="saveButtonPressed"
              class="row"
            >
              <div class="col-lg-10">
                <h5 class="">Powder Output Chart</h5>
                <guns-equalization-chart />
              </div>
              <gun-throughput
                title="Guns Equalization"
                :gun-throughput="gunExpectedOutput"
                :line="line"
                :powder-amount-parameters="powder_amount_parameters"
              />
            </div>
            <div
              v-if="save_button_green"
              class="col-12 text-center mt-4 mb-4"
            >
              <button
                type="button"
                class="mt-4 mb-2 text-center btn"
                :class="save_button_green ? 'bg-gradient-success' : 'bg-gradient-secondary'"
                style="width: 90%"
                @click="calculateResults(); successSwal();"
              >
                Calculate Powder Amount adjustments
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import setTooltip from "@/assets/js/tooltip.js";
import axios from "axios";

import { generateMeasurementList } from "@/views/applications/blueprint/utils/utils.js";
import { nextTick } from "vue";
import eventBus from "../utils/eventBus";
import GunsEqualizationChart from "../components/GunsEqualizationChart.vue";
import GunThroughput from "../components/GunThroughput.vue";


export default {
  name: "GunsEqualizationFinetune",
  components: {
    GunsEqualizationChart,
    GunThroughput,
  },
  props: {
    lineId: {
      type: String,
      default: "",
    },
    gunsEqualizationId: {
      type: String,
      default: "",
    },
  },
  data() {
    this.$i18n.locale = this.$store.state.user_data.language;

    return {
      original_powder_throughput_statistics: {
        min_value: null,
        max_value: null,
        diff_value: null,
        std_value: null,
      },
      powderoutput_measurements: [
        {
          empty_bag_weight: null,
          gun_measurements: [
            {
              setting: "",
              weight: "",
            },
            {
              setting: "",
              weight: "",
            },
          ],
        },
      ],
      line: {
        name: "",
        total_pistols: [0],
      },
      visitId: null,
      powders: [],
      selected_powder: null,
      dropdownVisible: false,
      searchQuery: "",
      minPowderAmountSetting: 0,
      maxPowderAmountSetting: 200,
      time_interval: 60,
      thickness_measurements: [null, null, null, null, null],
      save_button_green: true,
      saveButtonPressed: false,
      // received_gunsequalization_data: {
      //   min_powder_amount_setting: null,
      //   max_powder_amount_setting: null,
      //   powderoutput_measurements: null,
      //   thickness_measurements: Array(5).fill(null),
      // },
      max_metric_decimals: 0,
      max_imperial_decimals: 2,
      powder_amount_calibration_measures: null,
      reference_gun_index: null,
      selected_guns_for_powder_amount: [],
      expected_powder_per_minute: null,
      powder_amount_parameters: [],
      // Line Layout image variables
      canvas_width: null,
      canvas_height: null,
      ctx_line_layout: null,
      line_layout_image: [],
    };
  },
  computed: {
    gunExpectedOutput: {
      get() {
        if (this.expected_powder_per_minute == null || this.expected_powder_per_minute == "") {
          return 0;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (this.expected_powder_per_minute * this.$store.state.conversion_factors.g_to_oz).toFixed(2),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(this.expected_powder_per_minute.toFixed(0));
        } else {
          return this.expected_powder_per_minute;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.expected_powder_per_minute = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.expected_powder_per_minute = value / this.$store.state.conversion_factors.g_to_oz;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.expected_powder_per_minute = value;
          }
        }
      },
    },
    maxPairs() {
      let maxPairs = 0;
      this.powderoutput_measurements.forEach(gun => {
        if (gun.gun_measurements.length > maxPairs) {
          maxPairs = gun.gun_measurements.length;
        }
      });
      return maxPairs;
    },
    filteredPowders() {
      return this.powders.filter(powder =>
        powder.name.toLowerCase().includes(this.searchQuery.toLowerCase()),
      );
    },
    emptyPowderAmountMeasurements() {
      const empty_powder_amount_measurements = this.powderoutput_measurements.some(gun =>
        gun.gun_measurements.some(pair => pair.setting == "" || pair.weight == ""),
      );
      return empty_powder_amount_measurements;
    },
  },
  mounted() {
    this.$store.state.isAbsolute = true;
    setTooltip(this.$store.state.bootstrap);
    document.addEventListener("click", this.closeDropdown);
    this.getData();
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
    document.removeEventListener("click", this.closeDropdown);
  },
  methods: {
    generateMeasurementList,
    async getData() {
      await Promise.all([this.geFineTuneLine(), this.getLatestPowderThroughput(), this.getPowdersFineTune()]);
    },
    async getLatestPowderThroughput() {
      if (this.selected_powder == null) {
        return
      }
      try {
        const response = await axios.get("api/v1/finetune/lastvisitpowderthroughput/" + this.lineId + "/" + this.selected_powder.id + "/");
        this.expected_powder_per_minute = response.data.last_powder_throughput;
        this.minPowderAmountSetting = response.data.min_powder_amount_setting;
        this.maxPowderAmountSetting = response.data.max_powder_amount_setting;
        this.time_interval = response.data.time_interval;
        this.visitId = response.data.visit_id;
        this.powderoutput_measurements = this.clearWeightMeasurements(response.data.powder_output_measurements)
      } catch (error) {
        console.error(error);
        if (error.response && (error.response.status === 404 || error.response.status === 500)) {
          this.$swal({
            title: "Powder Throughput could not be retrieved.",
            text: error.response.data.error,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      }
      // this.populatePowderAmountMeasures();
    },
    clearWeightMeasurements(powderoutput_measurements) {
      const powderoutput_measurements_cleared_weights = powderoutput_measurements.map(gun => ({
        empty_bag_weight: null,
        gun_measurements: gun.gun_measurements.map(pair => (
          {
            setting: pair.setting,
            weight: "",
          }
        )),
      }));

      return powderoutput_measurements_cleared_weights;
    },
    closeDropdown() {
      this.dropdownVisible = false;
    },
    async getGunsEqualizationChartDataset() {
      let body = {
        min_powder_amount_setting: this.minPowderAmountSetting,
        max_powder_amount_setting: this.maxPowderAmountSetting,
        time_interval: this.time_interval,
        powderoutput_measurements: this.powderoutput_measurements.map(gun => ({
          ...gun,
          empty_bag_weight: gun.empty_bag_weight == null || gun.empty_bag_weight === "" ? 0 : gun.empty_bag_weight,
        })),
        cumulative_powderoutput_measurements: true,
      };

      const response = await axios.post("/api/v1/blueprint/gunsequalizationchart/", body);

      this.$store.state.gunsEqualizationChart = response.data.powderoutput_measurements;
    },
    async calculateResults() {
      await this.computePowderAmountParameterResults();
      await this.showGunsEqualizationGraph();
      await this.getPowderAmountCalibrationStatistic();
    },
    async computePowderAmountParameterResults() {
      if (this.emptyPowderAmountMeasurements) {
        return;
      }

      try {
        let body = {
          line: this.lineId,
          time_interval: this.time_interval,
          measures_list: this.generateMeasurementList(this.powderoutput_measurements),
          powder_per_minute: this.expected_powder_per_minute,
          gun_idx: null,
          powder_amount_param: null,
          cumulative_powderoutput_measurements: true,
        };

        let response = await axios.post("/api/v1/fp/computepowderamountcalibration/", body);
        this.powder_amount_parameters = response.data.powder_amount_params;
        this.roundPowderAmountParameters();
      } catch (error) {
        console.error(error);
      }
    },
    async getPowderAmountCalibrationStatistic() {
      if (this.emptyPowderAmountMeasurements || this.powder_amount_parameters.length == 0) {
        return;
      }

      try {
        let body = {
          time_interval: this.time_interval,
          measures_list: this.generateMeasurementList(this.powderoutput_measurements),
          powder_amounts: this.powder_amount_parameters,
          cumulative_powderoutput_measurements: true,
          min_powder_amount_setting: this.minPowderAmountSetting,
        };

        const response = await axios.post("/api/v1/fp/computepowderamountcalibrationstatistic/", body);
        this.min_powder_throughput = response.data.min_powder_throughput;
      } catch (error) {
        console.error(error);
      }
    },
    roundPowderAmountParameters() {
      this.powder_amount_parameters = this.powder_amount_parameters.map(param => {
        return parseFloat(param.toFixed(1));
      });
    },
    checkPowderAmountResultsRangeAlert() {
      let calibration_out_of_range_popup_shown = false;
      for (let index = 0; index < this.powder_amount_parameters.length; index++) {
        if (
          this.powder_amount_parameters[index] !== null &&
          this.powder_amount_parameters[index] !== "" &&
          (this.powder_amount_parameters[index] < this.minPowderAmountSetting ||
            this.powder_amount_parameters[index] > this.maxPowderAmountSetting)
        ) {
          if (!calibration_out_of_range_popup_shown) {
            this.$swal({
              title: "Calibration Out of Range",
              text: `${"The resulting Powder Amount setting is out of range for Gun"} ${index + 1}.\n Min: ${
                this.minPowderAmountSetting
              }, Max: ${this.maxPowderAmountSetting}.
              ${"Obtained Parameter"}: ${this.powder_amount_parameters[index]}`,
              icon: "error",
              confirmButtonText: "OK",
            });
            calibration_out_of_range_popup_shown = true;
          }
        }
      }
    },
    clearData() {
      this.expected_powder_per_minute = null;
      this.powder_amount_parameters = [];
      this.save_button_green = true;
      this.saveButtonPressed = false;
    },
    populatePowderAmountMeasures() {
      let total_guns = this.line.total_pistols.reduce((a, b) => a + b, 0);

      this.powderoutput_measurements = Array.from({ length: total_guns }, (_, index) => ({
        empty_bag_weight: 12,
        gun_measurements: [
          {
            setting: 3.1,
            weight: 23,
          },
          {
            setting: 5,
            weight: (20 * (2 + index)) / (index + 1),
          },
        ],
      }));
    },

    isPowderAmountMeasurementsFilled() {
      let total_empty_measures = this.powderoutput_measurements
        .map(powderoutput_measurement => powderoutput_measurement.gun_measurements)
        .filter(gun_measurment =>
          gun_measurment.some(
            gun_measurment =>
              gun_measurment.setting == "" ||
              gun_measurment.setting == null ||
              gun_measurment.weight == "" ||
              gun_measurment.weight == null,
          ),
        ).length;

      let is_powder_output_measurements_filled = total_empty_measures == 0;

      return is_powder_output_measurements_filled;
    },
    getFormProgressStatus() {
      const PROGRESS_STATUS_PENDING = "Pending";
      const PROGRESS_STATUS_IN_PROGRESS = "In Progress";
      const PROGRESS_STATUS_COMPLETED = "Completed";

      if (
        this.powderoutput_measurements
          .map(gun => gun.gun_measurements)
          .filter(gun_measurment =>
            gun_measurment.some(gun_measurment => gun_measurment.setting !== "" && gun_measurment.weight !== ""),
          ) &&
        this.expected_powder_per_minute !== null
      ) {
        return PROGRESS_STATUS_COMPLETED;
      } else if (
        this.powderoutput_measurements
          .map(gun => gun.gun_measurements)
          .filter(gun_measurment =>
            gun_measurment.some(gun_measurment => gun_measurment.setting === "" && gun_measurment.weight === ""),
          ) ||
        this.expected_powder_per_minute === null
      ) {
        return PROGRESS_STATUS_IN_PROGRESS;
      } else {
        return PROGRESS_STATUS_PENDING;
      }
    },
    async geFineTuneLine() {
      try {
        const response = await axios.get("api/v1/finetune/line/" + this.lineId + "/");
        this.line = response.data;
        this.line.total_pistols = JSON.parse(this.line.total_pistols);
        this.$store.state.isLoading = false;
      } catch (error) {
        console.error(error);
      }
    },
    // async getGunsEqualizationData() {
    //   if (!this.$route.params.gunsEqualizationId) {
    //     return;
    //   }
    //   try {
    //     const response = await axios.get(
    //       "api/v1/finetune/gunsequalization/" + this.$route.params.gunsEqualizationId,
    //     );
    //     this.received_gunsequalization_data = response.data;
    //     this.powderoutput_measurements = this.received_gunsequalization_data.powderoutput_measurements;
    //     this.expected_powder_per_minute = this.received_gunsequalization_data.gun_powder_throughput;
    //   } catch (error) {
    //     console.error(error);
    //   }
    // },
    async getPowdersFineTune() {
      try {
        const response = await axios.get("api/v1/finetune/powdersbyline/" + this.lineId + "/");
        this.powders = response.data;

        if (this.powders.length == 1) {
          this.selected_powder = this.powders[0];
          this.getLatestPowderThroughput();
          this.showLineLayout();
        }
      } catch (error) {
        console.error(error);
      }
    },
    // async saveFormProgress() {
    //   const formData = {
    //     powder_amount_measurements: this.powderoutput_measurements,
    //     gun_powder_throughput: this.expected_powder_per_minute,
    //   };

    //   try {
    //     await axios.post("api/v1/finetune/gunsequalization/", formData);
    //   } catch (error) {
    //     console.error(error);
    //   }
    // },
    PowderAmountSettingInputRangeCheck(target_value) {
      if (
        this.powderoutput_measurements.some(gun =>
          gun.gun_measurements.some(pair => pair.setting !== "" && pair.setting < this.minPowderAmountSetting),
        )
      ) {
        this.$swal({
          title: `Invalid Powder Output: ${target_value}<br>Value out of range.`,
          text: `Powder Output must be greater than the minimum allowed of ${this.minPowderAmountSetting}.`,
          icon: "error",
          confirmButtonText: "OK",
        }).then(() => this.removePowderMeasurementInput(target_value));
        return;
      } else if (
        this.powderoutput_measurements.some(gun =>
          gun.gun_measurements.some(pair => pair.setting !== "" && pair.setting > this.maxPowderAmountSetting),
        )
      ) {
        this.$swal({
          title: `Invalid Powder Output: ${target_value}<br>Value out of range.`,
          text: `Powder Output must be smaller than the maximum allowed of ${this.maxPowderAmountSetting}.`,
          icon: "error",
          confirmButtonText: "OK",
        }).then(() => this.removePowderMeasurementInput(target_value));

        return;
      }
    },
    removePowderMeasurementInput(target_value) {
      this.powderoutput_measurements.map(gun =>
        gun.gun_measurements.map(pair => {
          if (pair.setting == target_value) {
            pair.setting = "";
          }
        }),
      );
    },
    async successSwal() {
      try {
        this.save_button_green = false;
        this.saveButtonPressed = true;

        await this.$swal({
          title: "Gun Equlization success",
          text: "Powder amounts fine tune adjustment calculation successful",
          icon: "success",
          confirmButtonText: "OK",
        });
      } catch (error) {
        console.error(error);
      }
    },
    addPair(gunIndex) {
      this.powderoutput_measurements[gunIndex].gun_measurements.push({ setting: "", weight: "" });
    },
    deletePair(gunIndex) {
      this.powderoutput_measurements[gunIndex].gun_measurements.pop();
    },
    async showGunsEqualizationGraph() {
      if (this.isPowderAmountMeasurementsFilled()) {
        await this.getGunsEqualizationChartDataset();
        eventBus.emit("draw-gunsequlization-chart");
      }
    },
    async showLineLayout() {
      nextTick(() => {
          var lineLayoutCanvas = document.getElementById("lineLayoutCanvas");
          this.canvas_width = lineLayoutCanvas.width;
          this.canvas_height = lineLayoutCanvas.height;
          this.ctx_line_layout = lineLayoutCanvas.getContext("2d");;

          this.getLineLayouImage().then(() => {
            this.fillLineLayoutCanvas();
          });
      });
    },
    async getLineLayouImage() {
      try {
        var body = {
          line: this.lineId,
          canvas_width: this.canvas_width,
          canvas_height: this.canvas_height,
        };

        var response = await axios.post("/api/v1/fp/computepowderamountlineimage/", body);
        this.line_layout_image = response.data["layout_image"];
      } catch (error) {
        console.error(error);
      }
    },
    fillLineLayoutCanvas() {
      let line_layout_imageData = new ImageData(Uint8ClampedArray.from(this.line_layout_image.values()), this.canvas_width, this.canvas_height);
      this.ctx_line_layout.putImageData(line_layout_imageData, 0, 0);
    },
  },
};
</script>
<style scoped>
.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
  border-collapse: collapse; /* Ensure borders are collapsed */
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}

.table-border tbody tr:last-child td {
  border-width: 1px;
}

.container-fluid {
  padding-top: 20px;
}
</style>
