<template>
  <div class="py-5 container-fluid">
    <div class="row mt-4 justify-content-center">
      <div class="col-12">
        <div class="mx-auto col-xl-8 col-lg-9 col-md-10 text-start mt-3 mb-0">
          <button
            class="btn btn-outline-secondary btn-sm px-3 ms-2 mt-1"
            @click="$router.push(`/applications/blueprint/linevisits/${lineId}`)"
          >
            <font-awesome-icon :icon="['fas', 'arrow-rotate-left']" />
            Go back to workflows
          </button>
        </div>
        <div class="mx-auto col-xl-8 col-lg-9 col-md-10 d-flex flex-column card card-body blur shadow-blur mb-0">
          <div class="card-header text-start">
            <h3 class="font-weight-bolder text-success text-gradient">Spray Pattern</h3>
            <p class="mb-0">Calculate your potential powder savings in a few simple steps</p>
          </div>
        </div>
        <div class="mx-auto col-xl-8 col-lg-9 col-md-10 d-flex flex-column">
          <div class="row my-4">
            <div class="multisteps-form mt-4">
              <div class="multisteps-form__progress">
                <button
                  class="multisteps-form__progress-btn"
                  type="button"
                  title="Powder Selection"
                  :class="currentPage >= 0 ? activeClass : ''"
                  @click="currentPage = 0;"
                >
                  1. Powder Selection
                </button>
                <button
                  class="multisteps-form__progress-btn"
                  type="button"
                  title="Spray Pattern Selection"
                  :class="currentPage >= 1 ? activeClass : ''"
                  @click="currentPage = 1;"
                >
                  2. Spray Pattern Selection
                </button>
                <button
                  class="multisteps-form__progress-btn"
                  type="button"
                  title="Summary"
                  :class="currentPage >= 2 ? activeClass : ''"
                  @click="handleNextClick()"
                >
                  3. Summary
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="currentPage == 0"
          class="mx-auto col-xl-8 col-lg-9 col-md-10 d-flex mb-2 flex-column card card-body blur shadow-blur"
        >
          <div class="card-header pb-0 text-center my-2">
            <h4>
              <b>Select a Powder</b>
            </h4>
          </div>
          <div class="card-body text-center">
            <div class="d-flex justify-content-center">
              <select
                id="choices-category"
                v-model="benchmark_stage_data.powder"
                class="form-control col-4 mr-2 w-60"
                style="appearance: listbox"
                @change="updateSelectedPowder()"
              >
                <option
                  disabled
                  :value="null"
                >
                  Please select one
                </option>
                <option
                  v-for="powder in powders.slice().reverse()"
                  :key="powder.id"
                  :value="powder.id"
                >
                  {{ powder.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div
          v-if="currentPage == 1"
          class="mx-auto col-xl-8 col-lg-9 col-md-10 d-flex mb-2 flex-column card card-body blur shadow-blur"
        >
          <div class="card-header pb-0 text-center my-3">
            <h4>
              <b>
                Select spray pattern for powder
                <i>{{ powder.name }}</i>
              </b>
            </h4>
          </div>
          <div
            class="card-body pb-3 text-end"
            style="padding-top: 0.5rem"
          >
            <div class="d-flex flex-column">
              <div class="text-center">
                <button
                  class="h-120 btn bg-gradient-info ms-lg-auto me-lg-0 me-auto mt-lg-0 mb-0"
                  style="width: 300px; font-size: 0.91rem; height: 100px; background-color: rgb(241, 241, 241)"
                  @click="
                    $router.push('/applications/blueprint/powdermodeladd/' + lineId + '/' + visitId + '/' + powderId)
                  "
                >
                  Calibrate new Spray Pattern
                  <p
                    style="
                      font-size: 0.91rem;
                      line-height: 1rem;
                      padding-top: 5px;
                      padding-bottom: 0px;
                      margin-bottom: 0px;
                    "
                  >
                    (Estimated 10 mins)
                  </p>
                </button>
              </div>
              <div
                v-if="powderId"
                class="row"
              >
                <PowderModelListBluePrint
                  :line-name="line.name"
                  :line-id="lineId"
                  :visit-id="visitId"
                  :powder-id="powderId"
                  @updated-selected-spraypattern="updateSelectedSprayPattern($event);"
                />
              </div>
            </div>
          </div>
        </div>
        <div v-if="currentPage == 2">
          <div class="col-14 ms-auto">
            <div class="mx-auto col-xl-8 col-lg-9 col-md-10 d-flex mb-2 flex-column card card-body blur shadow-blur">
              <div class="card-body pt-4 mb-0">
                <div>
                  <h5 class="font-weight-bolder mb-2">Calibrated Spray Pattern Summary</h5>
                </div>
                <div class="row mt-4">
                  <div class="col-4">
                    <label class="mb-2 h6">Selected Powder Model Name</label>
                    <div class="mt-2 mb-2 mx-2">
                      {{ powdermodel.name }}
                    </div>
                  </div>
                  <div class="col-4">
                    <label class="mt-2 mb-2 h6"> Powder name </label>
                    <div class="mt-2 mb-2 mx-2">
                      {{ powder.name }}
                    </div>
                  </div>
                  <div
                    v-if="isCalibratedPowderModel"
                    class="col-4"
                  >
                    <label class="mt-2 mb-2 h6">Calibrated line</label>
                    <div class="mt-2 mb-2 mx-2">
                      {{ line.name }}
                    </div>
                  </div>
                  <div
                    v-else-if="isGeneralPreCalibratedPowderModel"
                    class="col-4"
                  >
                    <label class="mt-2 mb-2">Powder Model Type</label>
                    <div class="mt-2 mb-2 mx-2">Pre-Calibrated Powder Model</div>
                  </div>
                </div>
                <div
                  v-if="isCalibratedPowderModel"
                  class="accordion"
                >
                  <div class="accordion-item mb-0">
                    <h5 id="panelsStayOpen-headingOne">
                      <button
                        class="accordion-button collapsed mb-0"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseParams"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseParams"
                        style="padding: 0"
                      >
                        <p
                          class="font-weight-bolder mt-2"
                          style="
                            font-weight: 700 !important;
                            font-size: 1.05rem;
                            vertical-align: middle;
                            margin-bottom: 0;
                          "
                        >
                          Calibration Parameters
                          <i
                            class="fa fa-chevron-down"
                            aria-hidden="true"
                          ></i>
                          <i
                            class="fa fa-chevron-up"
                            aria-hidden="true"
                          ></i>
                        </p>
                      </button>
                    </h5>
                    <div
                      id="panelsStayOpen-collapseParams"
                      class="accordion-collapse collapse"
                      aria-labelledby="panelsStayOpen-headingOne"
                    >
                      <div
                        class="accordion-body"
                        style="padding: 0"
                      >
                        <div class="row">
                          <div
                            v-if="!isAdvancedCalibrationPowderModel && LoadedCalibrationMeasures"
                            class="col-4"
                          >
                            <label class="mt-2 mb-2"> Pistol to substrate distance </label>
                            <div class="mt-2 mb-2 mx-2">
                              {{ powdermodel_calibration_measures.max_pistol_to_substrate_distance_measurements }}
                              [{{ $store.state.units_system[$store.state.user_data.unit_system].distance }}]
                            </div>
                          </div>
                          <div
                            v-if="isAdvancedCalibrationPowderModel"
                            class="col-4"
                          >
                            <label class="mt-2 mb-2"> MIN & MAX Pistol to substrate distance </label>
                            <div class="mt-2 mb-2 mx-2">
                              {{ minPistolToSubstrateDistance }} & {{ maxPistolToSubstrateDistance }} [{{
                                $store.state.units_system[$store.state.user_data.unit_system].distance
                              }}]
                            </div>
                          </div>
                          <div
                            v-if="!isAdvancedCalibrationPowderModel && LoadedCalibrationMeasures"
                            class="col-4"
                          >
                            <label class="mt-2 mb-2">
                              {{
                                LoadedLine ? `${line.air_parameter_name} ${line.air_parameter_units}` : "Air parameter"
                              }}
                            </label>
                            <div class="mt-2 mb-2 mx-2">
                              {{ powdermodel_calibration_measures.max_air_parameter_measurements }}
                              {{ line.air_parameter_units }}
                            </div>
                          </div>
                          <div
                            v-else
                            class="col-4"
                          >
                            <label class="mt-2 mb-2">
                              {{ LoadedLine ? `Min and Max ${line.air_parameter_name}` : "Air parameter" }}
                            </label>
                            <div class="mt-2 mb-2 mx-2">
                              {{ powdermodel_calibration_measures.min_air_parameter_measurements }} &
                              {{ powdermodel_calibration_measures.max_air_parameter_measurements }}
                              {{ line.air_parameter_units }}
                            </div>
                          </div>
                          <div class="col-4">
                            <label class="mt-2 mb-2">Line speed</label>
                            <div class="mt-2 mb-2 mx-2">
                              {{ calibrationLineSpeed }} [{{
                                $store.state.units_system[$store.state.user_data.unit_system].velocity_minutes
                              }}]
                            </div>
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-4">
                            <label class="mt-2 MB-2">
                              Powder Amount
                              <i
                                title="Quantity of powder dispensed by the gun per unit time"
                                class="fa fa-info-circle"
                                aria-hidden="true"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                data-container="body"
                                data-animation="true"
                              >
                              </i>
                            </label>
                            <div class="mt-2 mb-2 mx-2">
                              {{ powderAmount }}
                            </div>
                          </div>
                          <div class="col-4">
                            <label class="mt-2 MB-2">Nozzle Type</label>
                            <div class="mt-2 mb-2 mx-2">
                              {{ nozzleType }}
                            </div>
                          </div>
                          <div class="col-4">
                            <label class="mt-2 mb-2">Booth side used for calibration</label>
                            <div class="mt-2 mb-2 mx-2">{{ boothSide }}</div>
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-4">
                            <label class="mt-2 MB-2">Powder Manufacturer</label>
                            <div class="mt-2 mb-2 mx-2">
                              {{ powderManufacturer }}
                            </div>
                          </div>
                          <div class="col-4">
                            <label class="mt-2 MB-2">
                              {{ powderLabel }}
                            </label>
                            <div class="mt-2 mb-2 mx-2">
                              {{ powderArticleId }}
                            </div>
                          </div>
                          <div
                            v-if="this.$store.state.flightpath_pro_license"
                            class="col-4"
                          >
                            <label class="mt-2 MB-2">Batch ID</label>
                            <div class="mt-2 mb-2 mx-2">
                              {{ batchId }}
                            </div>
                          </div>
                        </div>
                        <div
                          v-if="this.$store.state.flightpath_pro_license"
                          class="row mt-2"
                        >
                          <div class="col-4">
                            <label class="mt-2 MB-2">Booth Temperature</label>
                            <div class="mt-2 mb-2 mx-2">
                              {{ boothTemperature }}
                              {{ $store.state.units_system[$store.state.user_data.unit_system].temperature }}
                            </div>
                          </div>
                          <div class="col-4">
                            <label class="mt-2 MB-2">Booth Humidity</label>
                            <div class="mt-2 mb-2 mx-2">{{ boothHumidity }} %</div>
                          </div>
                          <div class="col-4">
                            <label class="mt-2 MB-2">Guns Spacing</label>
                            <div class="mt-2 mb-2 mx-2">{{ gunsSpacing }}</div>
                          </div>
                        </div>
                        <div
                          v-if="this.$store.state.flightpath_pro_license"
                          class="row mt-2"
                        >
                          <div class="col-4">
                            <label class="mt-2 MB-2">{{ operationalHoursLabel }}</label>
                            <div class="mt-2 mb-2 mx-2">{{ pumpOperationalHours }} hours</div>
                          </div>
                          <div class="col-8">
                            <label class="mt-2 MB-2">Additional Info</label>
                            <div class="mt-2 mb-2 mx-2">
                              {{ additionalInfo }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item mb-0">
                    <h5 id="panelsStayOpen-headingOne">
                      <button
                        class="accordion-button collapsed mb-0"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseMeasurements"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseMeasurements"
                        style="padding: 0"
                      >
                        <p
                          class="font-weight-bolder"
                          style="
                            font-weight: 700 !important;
                            font-size: 1.05rem;
                            vertical-align: middle;
                            margin-bottom: 0;
                          "
                        >
                          Calibration Measurements
                          <i
                            class="fa fa-chevron-down"
                            aria-hidden="true"
                          ></i>
                          <i
                            class="fa fa-chevron-up"
                            aria-hidden="true"
                          ></i>
                        </p>
                      </button>
                    </h5>
                    <div
                      id="panelsStayOpen-collapseMeasurements"
                      class="accordion-collapse collapse"
                      aria-labelledby="panelsStayOpen-headingOne"
                    >
                      <div
                        class="accordion-body"
                        style="padding: 0"
                      >
                        <div
                          v-if="!isStaticCalibrationPowderModel"
                          class="row"
                        >
                          <h6>Experiment 1</h6>
                          <div class="table-responsive w-90">
                            <table class="table table-sm table-bordered mt-1 mb-4">
                              <tbody>
                                <tr>
                                  <th>Point 1</th>
                                  <th>Point 2</th>
                                  <th>Point 3</th>
                                  <th>Point 4</th>
                                  <th>Point 5</th>
                                  <th>Point 6</th>
                                  <th>Point 7</th>
                                </tr>
                                <tr>
                                  <td style="border-width: 1px">
                                    {{ experiment1Point1 }} [{{
                                      $store.state.units_system[$store.state.user_data.unit_system].thickness
                                    }}]
                                  </td>
                                  <td style="border-width: 1px">
                                    {{ experiment1Point2 }} [{{
                                      $store.state.units_system[$store.state.user_data.unit_system].thickness
                                    }}]
                                  </td>
                                  <td style="border-width: 1px">
                                    {{ experiment1Point3 }} [{{
                                      $store.state.units_system[$store.state.user_data.unit_system].thickness
                                    }}]
                                  </td>
                                  <td style="border-width: 1px">
                                    {{ experiment1Point4 }} [{{
                                      $store.state.units_system[$store.state.user_data.unit_system].thickness
                                    }}]
                                  </td>
                                  <td style="border-width: 1px">
                                    {{ experiment1Point5 }} [{{
                                      $store.state.units_system[$store.state.user_data.unit_system].thickness
                                    }}]
                                  </td>
                                  <td style="border-width: 1px">
                                    {{ experiment1Point6 }} [{{
                                      $store.state.units_system[$store.state.user_data.unit_system].thickness
                                    }}]
                                  </td>
                                  <td style="border-width: 1px">
                                    {{ experiment1Point7 }} [{{
                                      $store.state.units_system[$store.state.user_data.unit_system].thickness
                                    }}]
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <h6>Experiment 2</h6>
                          <div class="table-responsive w-90">
                            <table class="table table-sm table-bordered mt-1 mb-4">
                              <tbody>
                                <tr>
                                  <th>Point 1</th>
                                  <th>Point 2</th>
                                  <th>Point 3</th>
                                  <th>Point 4</th>
                                  <th>Point 5</th>
                                  <th>Point 6</th>
                                  <th>Point 7</th>
                                </tr>
                                <tr>
                                  <td style="border-width: 1px">
                                    {{ experiment2Point1 }} [{{
                                      $store.state.units_system[$store.state.user_data.unit_system].thickness
                                    }}]
                                  </td>
                                  <td style="border-width: 1px">
                                    {{ experiment2Point2 }} [{{
                                      $store.state.units_system[$store.state.user_data.unit_system].thickness
                                    }}]
                                  </td>
                                  <td style="border-width: 1px">
                                    {{ experiment2Point3 }} [{{
                                      $store.state.units_system[$store.state.user_data.unit_system].thickness
                                    }}]
                                  </td>
                                  <td style="border-width: 1px">
                                    {{ experiment2Point4 }} [{{
                                      $store.state.units_system[$store.state.user_data.unit_system].thickness
                                    }}]
                                  </td>
                                  <td style="border-width: 1px">
                                    {{ experiment2Point5 }} [{{
                                      $store.state.units_system[$store.state.user_data.unit_system].thickness
                                    }}]
                                  </td>
                                  <td style="border-width: 1px">
                                    {{ experiment2Point6 }} [{{
                                      $store.state.units_system[$store.state.user_data.unit_system].thickness
                                    }}]
                                  </td>
                                  <td style="border-width: 1px">
                                    {{ experiment2Point7 }} [{{
                                      $store.state.units_system[$store.state.user_data.unit_system].thickness
                                    }}]
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div v-if="isAdvancedCalibrationPowderModel">
                            <h6>Experiment 3</h6>
                            <div class="table-responsive w-90">
                              <table class="table table-sm table-bordered mt-1 mb-4">
                                <tbody>
                                  <tr>
                                    <th>Point 1</th>
                                    <th>Point 2</th>
                                    <th>Point 3</th>
                                    <th>Point 4</th>
                                    <th>Point 5</th>
                                    <th>Point 6</th>
                                    <th>Point 7</th>
                                  </tr>
                                  <tr>
                                    <td style="border-width: 1px">
                                      {{ experiment3Point1 }} [{{
                                        $store.state.units_system[$store.state.user_data.unit_system].thickness
                                      }}]
                                    </td>
                                    <td style="border-width: 1px">
                                      {{ experiment3Point2 }} [{{
                                        $store.state.units_system[$store.state.user_data.unit_system].thickness
                                      }}]
                                    </td>
                                    <td style="border-width: 1px">
                                      {{ experiment3Point3 }} [{{
                                        $store.state.units_system[$store.state.user_data.unit_system].thickness
                                      }}]
                                    </td>
                                    <td style="border-width: 1px">
                                      {{ experiment3Point4 }} [{{
                                        $store.state.units_system[$store.state.user_data.unit_system].thickness
                                      }}]
                                    </td>
                                    <td style="border-width: 1px">
                                      {{ experiment3Point5 }} [{{
                                        $store.state.units_system[$store.state.user_data.unit_system].thickness
                                      }}]
                                    </td>
                                    <td style="border-width: 1px">
                                      {{ experiment3Point6 }} [{{
                                        $store.state.units_system[$store.state.user_data.unit_system].thickness
                                      }}]
                                    </td>
                                    <td style="border-width: 1px">
                                      {{ experiment3Point7 }} [{{
                                        $store.state.units_system[$store.state.user_data.unit_system].thickness
                                      }}]
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <h6>Experiment 4</h6>
                            <div class="table-responsive w-90">
                              <table class="table table-sm table-bordered mt-1 mb-4">
                                <tbody>
                                  <tr>
                                    <th>Point 1</th>
                                    <th>Point 2</th>
                                    <th>Point 3</th>
                                    <th>Point 4</th>
                                    <th>Point 5</th>
                                    <th>Point 6</th>
                                    <th>Point 7</th>
                                  </tr>
                                  <tr>
                                    <td style="border-width: 1px">
                                      {{ experiment4Point1 }} [{{
                                        $store.state.units_system[$store.state.user_data.unit_system].thickness
                                      }}]
                                    </td>
                                    <td style="border-width: 1px">
                                      {{ experiment4Point2 }} [{{
                                        $store.state.units_system[$store.state.user_data.unit_system].thickness
                                      }}]
                                    </td>
                                    <td style="border-width: 1px">
                                      {{ experiment4Point3 }} [{{
                                        $store.state.units_system[$store.state.user_data.unit_system].thickness
                                      }}]
                                    </td>
                                    <td style="border-width: 1px">
                                      {{ experiment4Point4 }} [{{
                                        $store.state.units_system[$store.state.user_data.unit_system].thickness
                                      }}]
                                    </td>
                                    <td style="border-width: 1px">
                                      {{ experiment4Point5 }} [{{
                                        $store.state.units_system[$store.state.user_data.unit_system].thickness
                                      }}]
                                    </td>
                                    <td style="border-width: 1px">
                                      {{ experiment4Point6 }} [{{
                                        $store.state.units_system[$store.state.user_data.unit_system].thickness
                                      }}]
                                    </td>
                                    <td style="border-width: 1px">
                                      {{ experiment4Point7 }} [{{
                                        $store.state.units_system[$store.state.user_data.unit_system].thickness
                                      }}]
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <h6>Experiment 5</h6>
                            <div class="table-responsive w-90">
                              <table class="table table-sm table-bordered mt-1 mb-4">
                                <tbody>
                                  <tr>
                                    <th>Point 1</th>
                                    <th>Point 2</th>
                                    <th>Point 3</th>
                                    <th>Point 4</th>
                                    <th>Point 5</th>
                                    <th>Point 6</th>
                                    <th>Point 7</th>
                                  </tr>
                                  <tr>
                                    <td style="border-width: 1px">
                                      {{ experiment5Point1 }} [{{
                                        $store.state.units_system[$store.state.user_data.unit_system].thickness
                                      }}]
                                    </td>
                                    <td style="border-width: 1px">
                                      {{ experiment5Point2 }} [{{
                                        $store.state.units_system[$store.state.user_data.unit_system].thickness
                                      }}]
                                    </td>
                                    <td style="border-width: 1px">
                                      {{ experiment5Point3 }} [{{
                                        $store.state.units_system[$store.state.user_data.unit_system].thickness
                                      }}]
                                    </td>
                                    <td style="border-width: 1px">
                                      {{ experiment5Point4 }} [{{
                                        $store.state.units_system[$store.state.user_data.unit_system].thickness
                                      }}]
                                    </td>
                                    <td style="border-width: 1px">
                                      {{ experiment5Point5 }} [{{
                                        $store.state.units_system[$store.state.user_data.unit_system].thickness
                                      }}]
                                    </td>
                                    <td style="border-width: 1px">
                                      {{ experiment5Point6 }} [{{
                                        $store.state.units_system[$store.state.user_data.unit_system].thickness
                                      }}]
                                    </td>
                                    <td style="border-width: 1px">
                                      {{ experiment5Point7 }} [{{
                                        $store.state.units_system[$store.state.user_data.unit_system].thickness
                                      }}]
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <h6>Experiment 6</h6>
                            <div class="table-responsive w-90">
                              <table class="table table-sm table-bordered mt-1 mb-4">
                                <tbody>
                                  <tr>
                                    <th>Point 1</th>
                                    <th>Point 2</th>
                                    <th>Point 3</th>
                                    <th>Point 4</th>
                                    <th>Point 5</th>
                                    <th>Point 6</th>
                                    <th>Point 7</th>
                                  </tr>
                                  <tr>
                                    <td style="border-width: 1px">
                                      {{ experiment6Point1 }} [{{
                                        $store.state.units_system[$store.state.user_data.unit_system].thickness
                                      }}]
                                    </td>
                                    <td style="border-width: 1px">
                                      {{ experiment6Point2 }} [{{
                                        $store.state.units_system[$store.state.user_data.unit_system].thickness
                                      }}]
                                    </td>
                                    <td style="border-width: 1px">
                                      {{ experiment6Point3 }} [{{
                                        $store.state.units_system[$store.state.user_data.unit_system].thickness
                                      }}]
                                    </td>
                                    <td style="border-width: 1px">
                                      {{ experiment6Point4 }} [{{
                                        $store.state.units_system[$store.state.user_data.unit_system].thickness
                                      }}]
                                    </td>
                                    <td style="border-width: 1px">
                                      {{ experiment6Point5 }} [{{
                                        $store.state.units_system[$store.state.user_data.unit_system].thickness
                                      }}]
                                    </td>
                                    <td style="border-width: 1px">
                                      {{ experiment6Point6 }} [{{
                                        $store.state.units_system[$store.state.user_data.unit_system].thickness
                                      }}]
                                    </td>
                                    <td style="border-width: 1px">
                                      {{ experiment6Point7 }} [{{
                                        $store.state.units_system[$store.state.user_data.unit_system].thickness
                                      }}]
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <h6>Experiment 7</h6>
                            <div class="table-responsive w-90">
                              <table class="table table-sm table-bordered mt-1 mb-4">
                                <tbody>
                                  <tr>
                                    <th>Point 1</th>
                                    <th>Point 2</th>
                                    <th>Point 3</th>
                                    <th>Point 4</th>
                                    <th>Point 5</th>
                                    <th>Point 6</th>
                                    <th>Point 7</th>
                                  </tr>
                                  <tr>
                                    <td style="border-width: 1px">
                                      {{ experiment7Point1 }} [{{
                                        $store.state.units_system[$store.state.user_data.unit_system].thickness
                                      }}]
                                    </td>
                                    <td style="border-width: 1px">
                                      {{ experiment7Point2 }} [{{
                                        $store.state.units_system[$store.state.user_data.unit_system].thickness
                                      }}]
                                    </td>
                                    <td style="border-width: 1px">
                                      {{ experiment7Point3 }} [{{
                                        $store.state.units_system[$store.state.user_data.unit_system].thickness
                                      }}]
                                    </td>
                                    <td style="border-width: 1px">
                                      {{ experiment7Point4 }} [{{
                                        $store.state.units_system[$store.state.user_data.unit_system].thickness
                                      }}]
                                    </td>
                                    <td style="border-width: 1px">
                                      {{ experiment7Point5 }} [{{
                                        $store.state.units_system[$store.state.user_data.unit_system].thickness
                                      }}]
                                    </td>
                                    <td style="border-width: 1px">
                                      {{ experiment7Point6 }} [{{
                                        $store.state.units_system[$store.state.user_data.unit_system].thickness
                                      }}]
                                    </td>
                                    <td style="border-width: 1px">
                                      {{ experiment7Point7 }} [{{
                                        $store.state.units_system[$store.state.user_data.unit_system].thickness
                                      }}]
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <h6>Experiment 8</h6>
                            <div class="table-responsive w-90">
                              <table class="table table-sm table-bordered mt-1 mb-4">
                                <tbody>
                                  <tr>
                                    <th>Point 1</th>
                                    <th>Point 2</th>
                                    <th>Point 3</th>
                                    <th>Point 4</th>
                                    <th>Point 5</th>
                                    <th>Point 6</th>
                                    <th>Point 7</th>
                                  </tr>
                                  <tr>
                                    <td style="border-width: 1px">
                                      {{ experiment8Point1 }} [{{
                                        $store.state.units_system[$store.state.user_data.unit_system].thickness
                                      }}]
                                    </td>
                                    <td style="border-width: 1px">
                                      {{ experiment8Point2 }} [{{
                                        $store.state.units_system[$store.state.user_data.unit_system].thickness
                                      }}]
                                    </td>
                                    <td style="border-width: 1px">
                                      {{ experiment8Point3 }} [{{
                                        $store.state.units_system[$store.state.user_data.unit_system].thickness
                                      }}]
                                    </td>
                                    <td style="border-width: 1px">
                                      {{ experiment8Point4 }} [{{
                                        $store.state.units_system[$store.state.user_data.unit_system].thickness
                                      }}]
                                    </td>
                                    <td style="border-width: 1px">
                                      {{ experiment8Point5 }} [{{
                                        $store.state.units_system[$store.state.user_data.unit_system].thickness
                                      }}]
                                    </td>
                                    <td style="border-width: 1px">
                                      {{ experiment8Point6 }} [{{
                                        $store.state.units_system[$store.state.user_data.unit_system].thickness
                                      }}]
                                    </td>
                                    <td style="border-width: 1px">
                                      {{ experiment8Point7 }} [{{
                                        $store.state.units_system[$store.state.user_data.unit_system].thickness
                                      }}]
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                        <div
                          v-else
                          class="row"
                        >
                          <h6>Calibration Plate Measurements</h6>
                          <div class="table-responsive w-90">
                            <table class="table table-sm table-bordered mt-1 mb-4">
                              <tbody>
                                <tr>
                                  <th>Point 1</th>
                                  <th>Point 2</th>
                                  <th>Point 3</th>
                                  <th>Point 4</th>
                                  <th>Point 5</th>
                                </tr>
                                <td style="border-width: 1px">
                                  {{ measurement1 }} [{{
                                    $store.state.units_system[$store.state.user_data.unit_system].thickness
                                  }}]
                                </td>
                                <td style="border-width: 1px">
                                  {{ measurement2 }} [{{
                                    $store.state.units_system[$store.state.user_data.unit_system].thickness
                                  }}]
                                </td>
                                <td style="border-width: 1px">
                                  {{ measurement3 }} [{{
                                    $store.state.units_system[$store.state.user_data.unit_system].thickness
                                  }}]
                                </td>
                                <td style="border-width: 1px">
                                  {{ measurement4 }} [{{
                                    $store.state.units_system[$store.state.user_data.unit_system].thickness
                                  }}]
                                </td>
                                <td style="border-width: 1px">
                                  {{ measurement5 }} [{{
                                    $store.state.units_system[$store.state.user_data.unit_system].thickness
                                  }}]
                                </td>
                                <tr>
                                  <th>Point 6</th>
                                  <th>Point 7</th>
                                  <th>Point 8</th>
                                  <th>Point 9</th>
                                  <th>Point 10</th>
                                </tr>
                                <tr>
                                  <td style="border-width: 1px">
                                    {{ measurement6 }} [{{
                                      $store.state.units_system[$store.state.user_data.unit_system].thickness
                                    }}]
                                  </td>
                                  <td style="border-width: 1px">
                                    {{ measurement7 }} [{{
                                      $store.state.units_system[$store.state.user_data.unit_system].thickness
                                    }}]
                                  </td>
                                  <td style="border-width: 1px">
                                    {{ measurement8 }} [{{
                                      $store.state.units_system[$store.state.user_data.unit_system].thickness
                                    }}]
                                  </td>
                                  <td style="border-width: 1px">
                                    {{ measurement9 }} [{{
                                      $store.state.units_system[$store.state.user_data.unit_system].thickness
                                    }}]
                                  </td>
                                  <td style="border-width: 1px">
                                    {{ measurement10 }} [{{
                                      $store.state.units_system[$store.state.user_data.unit_system].thickness
                                    }}]
                                  </td>
                                </tr>
                                <tr>
                                  <th>Point 11</th>
                                  <th>Point 12</th>
                                  <th>Point 13</th>
                                  <th>Point 14</th>
                                  <th>Point 15</th>
                                </tr>
                                <tr>
                                  <td style="border-width: 1px">
                                    {{ measurement11 }} [{{
                                      $store.state.units_system[$store.state.user_data.unit_system].thickness
                                    }}]
                                  </td>
                                  <td style="border-width: 1px">
                                    {{ measurement12 }} [{{
                                      $store.state.units_system[$store.state.user_data.unit_system].thickness
                                    }}]
                                  </td>
                                  <td style="border-width: 1px">
                                    {{ measurement13 }} [{{
                                      $store.state.units_system[$store.state.user_data.unit_system].thickness
                                    }}]
                                  </td>
                                  <td style="border-width: 1px">
                                    {{ measurement14 }} [{{
                                      $store.state.units_system[$store.state.user_data.unit_system].thickness
                                    }}]
                                  </td>
                                  <td style="border-width: 1px">
                                    {{ measurement15 }} [{{
                                      $store.state.units_system[$store.state.user_data.unit_system].thickness
                                    }}]
                                  </td>
                                </tr>
                                <tr>
                                  <th>Point 16</th>
                                  <th>Point 17</th>
                                  <th>Point 18</th>
                                  <th>Point 19</th>
                                  <th>Point 20</th>
                                </tr>
                                <tr>
                                  <td style="border-width: 1px">
                                    {{ measurement16 }} [{{
                                      $store.state.units_system[$store.state.user_data.unit_system].thickness
                                    }}]
                                  </td>
                                  <td style="border-width: 1px">
                                    {{ measurement17 }} [{{
                                      $store.state.units_system[$store.state.user_data.unit_system].thickness
                                    }}]
                                  </td>
                                  <td style="border-width: 1px">
                                    {{ measurement18 }} [{{
                                      $store.state.units_system[$store.state.user_data.unit_system].thickness
                                    }}]
                                  </td>
                                  <td style="border-width: 1px">
                                    {{ measurement19 }} [{{
                                      $store.state.units_system[$store.state.user_data.unit_system].thickness
                                    }}]
                                  </td>
                                  <td style="border-width: 1px">
                                    {{ measurement20 }} [{{
                                      $store.state.units_system[$store.state.user_data.unit_system].thickness
                                    }}]
                                  </td>
                                </tr>
                                <tr>
                                  <th>Point 21</th>
                                  <th>Point 22</th>
                                  <th>Point 23</th>
                                  <th>Point 24</th>
                                  <th>Point 25</th>
                                </tr>
                                <tr>
                                  <td style="border-width: 1px">
                                    {{ measurement21 }} [{{
                                      $store.state.units_system[$store.state.user_data.unit_system].thickness
                                    }}]
                                  </td>
                                  <td style="border-width: 1px">
                                    {{ measurement22 }} [{{
                                      $store.state.units_system[$store.state.user_data.unit_system].thickness
                                    }}]
                                  </td>
                                  <td style="border-width: 1px">
                                    {{ measurement23 }} [{{
                                      $store.state.units_system[$store.state.user_data.unit_system].thickness
                                    }}]
                                  </td>
                                  <td style="border-width: 1px">
                                    {{ measurement24 }} [{{
                                      $store.state.units_system[$store.state.user_data.unit_system].thickness
                                    }}]
                                  </td>
                                  <td style="border-width: 1px">
                                    {{ measurement25 }} [{{
                                      $store.state.units_system[$store.state.user_data.unit_system].thickness
                                    }}]
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mx-auto col-xl-8 col-lg-9 col-md-10 d-flex flex-column card card-body blur shadow-blur mt-3">
            <div class="card-header text-center">
              <h5 class="font-weight-bolder mb-0 pb-0">PowderModel visualization</h5>
            </div>
            <div class="card-body pt-0 d-flex justify-content-center">
              <div class="mr-4">
                <canvas
                  id="powdermodelcanvas"
                  class="w-100"
                  width="500"
                  height="500"
                  style="max-width: 500px"
                ></canvas>
                <div class="row">
                  <label>
                    The scale of this simulation is {{ lastSimulatedPowderDiameter }}x{{
                      lastSimulatedPowderDiameter
                    }}
                    [{{ $store.state.units_system[$store.state.user_data.unit_system].distance }}]</label
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mx-auto col-xl-8 col-lg-9 col-md-10 d-flex button-row mt-3">
        <button
          v-if="currentPage === 0"
          type="button"
          data-bs-toggle="modal"
          data-bs-target="#modal-form"
          class="me-auto btn btn-primary w-40 ml-3 mb-0"
          style="max-width: 220px"
        >
          Create new Powder
        </button>
        <div
          v-if="currentPage === 0"
          id="modal-form"
          ref="modal"
          class="modal fade"
          tabindex="-1"
          role="dialog"
          aria-labelledby="modal-form"
          aria-hidden="true"
          style="z-index: 1050 !important"
        >
          <div
            class="modal-dialog modal-dialog-centered modal-sm"
            role="document"
          >
            <div
              class="modal-content"
              style="text-align: left !important"
            >
              <div class="modal-body p-0">
                <div class="card card-plain">
                  <div class="card-body justify-content-left">
                    <button
                      ref="dismissButton"
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label=""
                    ></button>
                    <form
                      role="form"
                      class=""
                    >
                      <label>Powder name</label>
                      <div class="input-group mb-3">
                        <input
                          v-model="newPowderName"
                          type="text"
                          :class="powder_name_input_error ? `form-control is-invalid` : `form-control`"
                          placeholder="Powder name"
                          @input="powder_name_input_error = false"
                        />
                      </div>
                      <label>Powder Manufacturer</label>
                      <div class="input-group mb-3">
                        <input
                          v-model="newPowderManufacturer"
                          type="text"
                          :class="powder_manufacturer_input_error ? `form-control is-invalid` : `form-control`"
                          placeholder="Powder Manufacturer"
                          @input="powder_manufacturer_input_error = false"
                        />
                      </div>
                      <label>Powder ID</label>
                      <p class="text-xs form-text text-muted ms-1 d-inline">(Optional)</p>
                      <div class="input-group mb-3">
                        <input
                          v-model="newPowderId"
                          type="text"
                          class="form-control"
                          placeholder="Powder ID"
                        />
                      </div>
                      <div class="text-center">
                        <button
                          type="button"
                          class="btn bg-gradient-success btn-lg w-100 mt-4 mb-0"
                          @click="createPowder()"
                        >
                          Create Powder
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <soft-button
          v-if="currentPage === 1 || currentPage === 2"
          color="secondary"
          variant="gradient"
          class="mb-0 js-btn-prev w-20"
          title="Previous"
          @click="currentPage = currentPage - 1;"
        >
          Previous
        </soft-button>
        <soft-button
          v-if="currentPage === 0"
          type="button"
          :color="benchmark_stage_data.powder ? 'success':'secondary'"
          variant="gradient"
          class="mb-0 ms-auto js-btn-next w-20"
          title="Next"
          @click="handleNextClick();"
        >
          Next &nbsp;&nbsp;<font-awesome-icon :icon="['fas', 'arrow-right']" inverse/>
        </soft-button>
        <soft-button
          v-if="currentPage === 1"
          type="button"
          :color=" sprayPatternIsSelected ? 'success':'secondary'"
          variant="gradient"
          class="mb-0 ms-auto js-btn-next w-20"
          title="Next"
          @click="handleNextClick();"
        >
          Next &nbsp;&nbsp;<font-awesome-icon :icon="['fas', 'arrow-right']" inverse/>
        </soft-button>
        <soft-button
          v-if="currentPage === 2"
          type="button"
          color="success"
          variant="gradient"
          class="mb-0 ms-auto js-btn-next w-50"
          style="max-width: 370px"
          title="Continue to guns equalization"
          @click="$router.push('/applications/blueprint/reciprocatoroptimizationslibrary/' + lineId + '/' + visitId)"
        >
          Continue to reciprocator optimization &nbsp;
          <font-awesome-icon
            :icon="['fas', 'circle-chevron-right']"
            size="lg"
          />
        </soft-button>
      </div>
    </div>
  </div>
  <div class="mx-auto col-xl-9 col-lg-10 col-md-11">
    <stepper-line-visit
      :line-id="lineId"
      :visit="visit"
    />
  </div>
</template>

<script>
import setTooltip from "@/assets/js/tooltip.js";
import axios from "axios";
import PowderModelListBluePrint from "./components/PowderModelListBluePrint.vue";
import { useGetLine, useGetPowders } from "@/views/composables.js";
import StepperLineVisit from "./components/StepperLineVisit.vue";
import { useGetBenchmark, useGetVisit } from "./utils/utils";
import SoftButton from "../../../components/SoftButton.vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faArrowRotateLeft, faCircleChevronRight } from "@fortawesome/free-solid-svg-icons";

library.add(faArrowRotateLeft, faCircleChevronRight);

export default {
  name: "SprayPatternStage",
  components: {
    PowderModelListBluePrint,
    StepperLineVisit,
    SoftButton,
    FontAwesomeIcon,
  },
  props: {
    lineId: {
      type: String,
      default: null,
    },
    visitId: {
      type: String,
      default: null,
    },
  },
  setup() {
    const { line, getLine } = useGetLine();
    const { visit, getVisit } = useGetVisit();
    const { powders, getPowders } = useGetPowders();
    const { benchmark_stage_data, getBenchmarkData } = useGetBenchmark();

    return { line, getLine, visit, getVisit, powders, getPowders, benchmark_stage_data, getBenchmarkData };
  },
  data() {
    this.$i18n.locale = this.$store.state.user_data.language;
    {
      return {
        activeClass: "js-active position-relative",
        formSteps: 2,
        currentPage: 0,
        showMenu: false,
        powder: {
          name: "",
          powder_manufacturer: "",
          powder_id: "",
        },
        showAdvancedCalibrationButton: false,
        powderId: null,
        newPowderName: "",
        powder_name_input_error: false,
        newPowderManufacturer: "",
        powder_manufacturer_input_error: false,
        newPowderId: "",
        powdermodel: [],
        powdermodel_calibration_measures: [],
        powdermodelcanvas: null,
        distribution_imgData: null,
        disable_ranges: false,
        powdermodel_visulization_params_form: {
          simulated_spray_diameter: 60,
          min_pistol_to_substrate_distance: 10,
          max_pistol_to_substrate_distance: 60,
          pistol_to_substrate_distance: 20,
          min_air_parameter_value: 1,
          max_air_parameter_value: 10,
          air_parameter_value: 2,
        },
        last_powdermodel_visualization_params: {
          simulated_spray_diameter: 60,
          min_pistol_to_substrate_distance: 10,
          max_pistol_to_substrate_distance: 60,
          pistol_to_substrate_distance: 20,
          min_air_parameter_value: 1,
          max_air_parameter_value: 10,
          air_parameter_value: 2,
        },
        max_thickness_metric_decimals: 0,
        max_distance_metric_decimals: 1,
        max_line_speed_metric_decimals: 1,
        max_imperial_decimals: 2,
        max_temperature_decimals: 1,
        powdermodelType: "calibrated",
      };
    }
  },
  computed: {
    sprayPatternIsSelected () {
      if (
        this.visit.spraypatternstage.selected_powder_models == null ||
        this.visit.spraypatternstage.selected_powder_models == undefined ||
        this.visit.spraypatternstage.selected_powder_models.length == 0 ||
        this.visit.spraypatternstage.selected_powder_models[0] == null ||
        this.visit.spraypatternstage.selected_powder_models[0] == undefined ||
        this.visit.spraypatternstage.selected_powder_models[0] == "" ||
        typeof this.visit.spraypatternstage.selected_powder_models[0] !== 'number'
      ) {
        return false;
      }
      return true;
    },
    isSimpleCalibrationPowderModel() {
      return this.powdermodel_calibration_measures.calibration_type === "simple";
    },
    isStaticCalibrationPowderModel() {
      return this.powdermodel_calibration_measures.calibration_type === "static";
    },
    isAdvancedCalibrationPowderModel() {
      return this.powdermodel_calibration_measures.calibration_type === "advanced";
    },
    BluePrintFrontend() {
      return window.location.pathname.includes("blueprint");
    },
    CalibrationType() {
      if (this.powdermodelType == "generalprecalibrated") {
        return this.$t("Pre-Calibrated Powder Model");
      } else if (this.isSimpleCalibrationPowderModel) {
        return this.$t("simple");
      } else if (this.isStaticCalibrationPowderModel) {
        return this.$t("static");
      } else if (this.isAdvancedCalibrationPowderModel) {
        return this.$t("advanced");
      } else {
        return "";
      }
    },
    isCalibratedPowderModel() {
      return this.powdermodelType === "calibrated";
    },
    isGeneralPreCalibratedPowderModel() {
      return this.powdermodelType === "generalprecalibrated";
    },
    LoadedCalibrationMeasures() {
      return Object.prototype.hasOwnProperty.call(this.powdermodel_calibration_measures, "powdermodel");
    },
    LoadedLine: function () {
      return Object.prototype.hasOwnProperty.call(this.line, "id");
    },
    PowderModelVisParamsUpdatedForm: function () {
      if (
        JSON.stringify(this.last_powdermodel_visualization_params) !==
        JSON.stringify(this.powdermodel_visulization_params_form)
      ) {
        if (this.currentPage === 2) {
          this.fillCanvasBlue();
        }
        return true;
      } else {
        if (this.distribution_imgData != null) {
          this.powdermodelcanvas.putImageData(this.distribution_imgData, 0, 0);
        }
        return false;
      }
    },
    powderManufacturer: {
      get() {
        if (
          this.powdermodel_calibration_measures.powder_manufacturer == null ||
          this.powdermodel_calibration_measures.powder_manufacturer == ""
        ) {
          return "-";
        } else {
          return this.powdermodel_calibration_measures.powder_manufacturer;
        }
      },
    },
    powderAmount: {
      get() {
        if (
          this.powdermodel_calibration_measures.powder_amount == null ||
          this.powdermodel_calibration_measures.powder_amount == ""
        ) {
          return "-";
        } else {
          return this.powdermodel_calibration_measures.powder_amount;
        }
      },
    },
    powderArticleId: {
      get() {
        if (
          this.powdermodel_calibration_measures.powder_article_id == null ||
          this.powdermodel_calibration_measures.powder_article_id == ""
        ) {
          return "-";
        } else {
          return this.powdermodel_calibration_measures.powder_article_id;
        }
      },
    },
    boothSide: {
      get() {
        if (
          this.powdermodel_calibration_measures.booth_side == null ||
          this.powdermodel_calibration_measures.booth_side == ""
        ) {
          return "-";
        } else {
          return `${this.powdermodel_calibration_measures.booth_side} booth side`;
        }
      },
    },
    boothTemperature: {
      get() {
        if (
          this.powdermodel_calibration_measures.booth_temperature == null ||
          this.powdermodel_calibration_measures.booth_temperature == ""
        ) {
          return "-";
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.booth_temperature * this.$store.state.conversion_factors.c_to_f +
              this.$store.state.conversion_factors.c_to_f_offset
            ).toFixed(this.max_temperature_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.booth_temperature.toFixed(this.max_temperature_decimals),
          );
        } else {
          return null;
        }
      },
    },
    boothHumidity: {
      get() {
        if (
          this.powdermodel_calibration_measures.booth_humidity == null ||
          this.powdermodel_calibration_measures.booth_humidity == ""
        ) {
          return "-";
        } else {
          return this.powdermodel_calibration_measures.booth_humidity;
        }
      },
    },
    batchId: {
      get() {
        if (
          this.powdermodel_calibration_measures.batch_id == null ||
          this.powdermodel_calibration_measures.batch_id == ""
        ) {
          return "-";
        } else {
          return this.powdermodel_calibration_measures.batch_id;
        }
      },
    },
    gunsSpacing: {
      get() {
        if (
          this.powdermodel_calibration_measures.guns_spacing == null ||
          this.powdermodel_calibration_measures.guns_spacing == ""
        ) {
          return "-";
        } else {
          return this.powdermodel_calibration_measures.guns_spacing;
        }
      },
    },
    pumpOperationalHours: {
      get() {
        if (
          this.powdermodel_calibration_measures.pump_operational_hours == null ||
          this.powdermodel_calibration_measures.pump_operational_hours == ""
        ) {
          return "-";
        } else {
          return this.powdermodel_calibration_measures.pump_operational_hours;
        }
      },
    },
    nozzleType: {
      get() {
        if (
          this.powdermodel_calibration_measures.nozzles_type == null ||
          this.powdermodel_calibration_measures.nozzles_type == ""
        ) {
          return "-";
        } else {
          return this.powdermodel_calibration_measures.nozzles_type;
        }
      },
    },
    additionalInfo: {
      get() {
        if (
          this.powdermodel_calibration_measures.additional_info == null ||
          this.powdermodel_calibration_measures.additional_info == ""
        ) {
          return "-";
        } else {
          return this.powdermodel_calibration_measures.additional_info;
        }
      },
    },
    calibrationLineSpeed: {
      get() {
        if (this.isStaticCalibrationPowderModel) {
          return 0;
        }
        if (
          this.powdermodel_calibration_measures.calibration_line_speed == null ||
          this.powdermodel_calibration_measures.calibration_line_speed == ""
        ) {
          return "-";
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.calibration_line_speed *
              this.$store.state.conversion_factors.m_to_ft
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.calibration_line_speed.toFixed(this.max_line_speed_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.calibration_line_speed = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.calibration_line_speed =
              value / this.$store.state.conversion_factors.m_to_ft;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.powdermodel_calibration_measures.calibration_line_speed = value;
          } else {
            this.powdermodel_calibration_measures.calibration_line_speed = null;
          }
        }
      },
    },
    maxPistolToSubstrateDistance: {
      get() {
        if (
          this.powdermodel_visulization_params_form.max_pistol_to_substrate_distance == null ||
          this.powdermodel_visulization_params_form.max_pistol_to_substrate_distance == ""
        ) {
          return this.powdermodel_visulization_params_form.max_pistol_to_substrate_distance;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_visulization_params_form.max_pistol_to_substrate_distance *
              this.$store.state.conversion_factors.cm_to_in
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_visulization_params_form.max_pistol_to_substrate_distance.toFixed(
              this.max_distance_metric_decimals,
            ),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_visulization_params_form.max_pistol_to_substrate_distance = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_visulization_params_form.max_pistol_to_substrate_distance =
              value / this.$store.state.conversion_factors.cm_to_in;
          } else {
            this.powdermodel_visulization_params_form.max_pistol_to_substrate_distance = value;
          }
        }
      },
    },
    minPistolToSubstrateDistance: {
      get() {
        if (
          this.powdermodel_visulization_params_form.min_pistol_to_substrate_distance == null ||
          this.powdermodel_visulization_params_form.min_pistol_to_substrate_distance == ""
        ) {
          return this.powdermodel_visulization_params_form.min_pistol_to_substrate_distance;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_visulization_params_form.min_pistol_to_substrate_distance *
              this.$store.state.conversion_factors.cm_to_in
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_visulization_params_form.min_pistol_to_substrate_distance.toFixed(
              this.max_distance_metric_decimals,
            ),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_visulization_params_form.min_pistol_to_substrate_distance = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_visulization_params_form.min_pistol_to_substrate_distance =
              value / this.$store.state.conversion_factors.cm_to_in;
          } else {
            this.powdermodel_visulization_params_form.min_pistol_to_substrate_distance = value;
          }
        }
      },
    },
    simulatedPowderDiameter: {
      get() {
        if (
          this.powdermodel_visulization_params_form.simulated_spray_diameter == null ||
          this.powdermodel_visulization_params_form.simulated_spray_diameter == ""
        ) {
          return this.powdermodel_visulization_params_form.simulated_spray_diameter;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_visulization_params_form.simulated_spray_diameter *
              this.$store.state.conversion_factors.cm_to_in
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_visulization_params_form.simulated_spray_diameter.toFixed(
              this.max_distance_metric_decimals,
            ),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_visulization_params_form.simulated_spray_diameter = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_visulization_params_form.simulated_spray_diameter =
              value / this.$store.state.conversion_factors.cm_to_in;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.powdermodel_visulization_params_form.simulated_spray_diameter = value;
          } else {
            this.powdermodel_visulization_params_form.simulated_spray_diameter = null;
          }
        }
      },
    },
    lastSimulatedPowderDiameter: {
      get() {
        if (
          this.last_powdermodel_visualization_params.simulated_spray_diameter == null ||
          this.last_powdermodel_visualization_params.simulated_spray_diameter == ""
        ) {
          return this.last_powdermodel_visualization_params.simulated_spray_diameter;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.last_powdermodel_visualization_params.simulated_spray_diameter *
              this.$store.state.conversion_factors.cm_to_in
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.last_powdermodel_visualization_params.simulated_spray_diameter.toFixed(
              this.max_distance_metric_decimals,
            ),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.last_powdermodel_visualization_params.simulated_spray_diameter = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.last_powdermodel_visualization_params.simulated_spray_diameter =
              value / this.$store.state.conversion_factors.cm_to_in;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.last_powdermodel_visualization_params.simulated_spray_diameter = value;
          } else {
            this.last_powdermodel_visualization_params.simulated_spray_diameter = null;
          }
        }
      },
    },
    pistolToSubstrateDistance: {
      get() {
        if (
          this.powdermodel_visulization_params_form.pistol_to_substrate_distance == null ||
          this.powdermodel_visulization_params_form.pistol_to_substrate_distance == ""
        ) {
          return this.powdermodel_visulization_params_form.pistol_to_substrate_distance;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            parseFloat(
              this.powdermodel_visulization_params_form.pistol_to_substrate_distance *
                this.$store.state.conversion_factors.cm_to_in,
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            parseFloat(this.powdermodel_visulization_params_form.pistol_to_substrate_distance).toFixed(
              this.max_distance_metric_decimals,
            ),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_visulization_params_form.pistol_to_substrate_distance = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_visulization_params_form.pistol_to_substrate_distance =
              value / this.$store.state.conversion_factors.cm_to_in;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.powdermodel_visulization_params_form.pistol_to_substrate_distance = value;
          } else {
            this.powdermodel_visulization_params_form.pistol_to_substrate_distance = null;
          }
        }
      },
    },
    operationalHoursLabel: {
      get() {
        if (this.line.pump_type == "injector_venturi") {
          return "Operational Hours Injector";
        } else if (this.line.pump_type == "dense_phase") {
          return "Operational Hours Since Last Maintanance";
        } else {
          return "Pump Operational Hours";
        }
      },
    },
    powderLabel: {
      get() {
        if (
          this.powdermodel_calibration_measures.powder_manufacturer == "AkzoNobel" &&
          this.$store.state.flightpath_pro_license
        ) {
          return "Interpon ID";
        } else {
          return "Powder ID";
        }
      },
    },
    experiment1Point1: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_1_point_1 == null ||
          this.powdermodel_calibration_measures.experiment_1_point_1 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_1_point_1;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_1_point_1 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_1_point_1.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_1_point_1 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_1_point_1 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_1_point_1 = value;
          }
        }
      },
    },
    experiment1Point2: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_1_point_2 == null ||
          this.powdermodel_calibration_measures.experiment_1_point_2 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_1_point_2;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_1_point_2 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_1_point_2.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_1_point_2 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_1_point_2 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_1_point_2 = value;
          }
        }
      },
    },
    experiment1Point3: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_1_point_3 == null ||
          this.powdermodel_calibration_measures.experiment_1_point_3 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_1_point_3;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_1_point_3 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_1_point_3.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_1_point_3 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_1_point_3 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_1_point_3 = value;
          }
        }
      },
    },
    experiment1Point4: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_1_point_4 == null ||
          this.powdermodel_calibration_measures.experiment_1_point_4 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_1_point_4;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_1_point_4 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_1_point_4.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_1_point_4 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_1_point_4 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_1_point_4 = value;
          }
        }
      },
    },
    experiment1Point5: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_1_point_5 == null ||
          this.powdermodel_calibration_measures.experiment_1_point_5 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_1_point_5;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_1_point_5 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_1_point_5.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_1_point_5 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_1_point_5 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_1_point_5 = value;
          }
        }
      },
    },
    experiment1Point6: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_1_point_6 == null ||
          this.powdermodel_calibration_measures.experiment_1_point_6 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_1_point_6;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_1_point_6 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_1_point_6.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_1_point_6 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_1_point_6 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_1_point_6 = value;
          }
        }
      },
    },
    experiment1Point7: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_1_point_7 == null ||
          this.powdermodel_calibration_measures.experiment_1_point_7 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_1_point_7;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_1_point_7 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_1_point_7.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_1_point_7 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_1_point_7 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_1_point_7 = value;
          }
        }
      },
    },
    experiment2Point1: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_2_point_1 == null ||
          this.powdermodel_calibration_measures.experiment_2_point_1 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_2_point_1;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_2_point_1 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_2_point_1.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_2_point_1 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_2_point_1 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_2_point_1 = value;
          }
        }
      },
    },
    experiment2Point2: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_2_point_2 == null ||
          this.powdermodel_calibration_measures.experiment_2_point_2 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_2_point_2;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_2_point_2 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_2_point_2.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_2_point_2 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_2_point_2 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_2_point_2 = value;
          }
        }
      },
    },
    experiment2Point3: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_2_point_3 == null ||
          this.powdermodel_calibration_measures.experiment_2_point_3 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_2_point_3;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_2_point_3 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_2_point_3.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_2_point_3 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_2_point_3 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_2_point_3 = value;
          }
        }
      },
    },
    experiment2Point4: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_2_point_4 == null ||
          this.powdermodel_calibration_measures.experiment_2_point_4 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_2_point_4;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_2_point_4 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_2_point_4.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_2_point_4 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_2_point_4 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_2_point_4 = value;
          }
        }
      },
    },
    experiment2Point5: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_2_point_5 == null ||
          this.powdermodel_calibration_measures.experiment_2_point_5 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_2_point_5;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_2_point_5 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_2_point_5.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_2_point_5 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_2_point_5 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_2_point_5 = value;
          }
        }
      },
    },
    experiment2Point6: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_2_point_6 == null ||
          this.powdermodel_calibration_measures.experiment_2_point_6 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_2_point_6;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_2_point_6 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_2_point_6.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_2_point_6 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_2_point_6 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_2_point_6 = value;
          }
        }
      },
    },
    experiment2Point7: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_2_point_7 == null ||
          this.powdermodel_calibration_measures.experiment_2_point_7 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_2_point_7;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_2_point_7 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_2_point_7.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_2_point_7 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_2_point_7 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_2_point_7 = value;
          }
        }
      },
    },
    experiment3Point1: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_3_point_1 == null ||
          this.powdermodel_calibration_measures.experiment_3_point_1 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_3_point_1;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_3_point_1 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_3_point_1.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_3_point_1 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_3_point_1 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_3_point_1 = value;
          }
        }
      },
    },
    experiment3Point2: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_3_point_2 == null ||
          this.powdermodel_calibration_measures.experiment_3_point_2 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_3_point_2;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_3_point_2 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_3_point_2.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_3_point_2 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_3_point_2 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_3_point_2 = value;
          }
        }
      },
    },
    experiment3Point3: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_3_point_3 == null ||
          this.powdermodel_calibration_measures.experiment_3_point_3 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_3_point_3;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_3_point_3 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_3_point_3.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_3_point_3 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_3_point_3 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_3_point_3 = value;
          }
        }
      },
    },
    experiment3Point4: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_3_point_4 == null ||
          this.powdermodel_calibration_measures.experiment_3_point_4 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_3_point_4;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_3_point_4 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_3_point_4.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_3_point_4 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_3_point_4 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_3_point_4 = value;
          }
        }
      },
    },
    experiment3Point5: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_3_point_5 == null ||
          this.powdermodel_calibration_measures.experiment_3_point_5 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_3_point_5;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_3_point_5 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_3_point_5.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_3_point_5 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_3_point_5 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_3_point_5 = value;
          }
        }
      },
    },
    experiment3Point6: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_3_point_6 == null ||
          this.powdermodel_calibration_measures.experiment_3_point_6 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_3_point_6;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_3_point_6 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_3_point_6.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_3_point_6 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_3_point_6 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_3_point_6 = value;
          }
        }
      },
    },
    experiment3Point7: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_3_point_7 == null ||
          this.powdermodel_calibration_measures.experiment_3_point_7 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_3_point_7;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_3_point_7 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_3_point_7.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_3_point_7 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_3_point_7 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_3_point_7 = value;
          }
        }
      },
    },
    experiment4Point1: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_4_point_1 == null ||
          this.powdermodel_calibration_measures.experiment_4_point_1 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_4_point_1;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_4_point_1 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_4_point_1.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_4_point_1 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_4_point_1 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_4_point_1 = value;
          }
        }
      },
    },
    experiment4Point2: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_4_point_2 == null ||
          this.powdermodel_calibration_measures.experiment_4_point_2 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_4_point_2;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_4_point_2 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_4_point_2.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_4_point_2 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_4_point_2 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_4_point_2 = value;
          }
        }
      },
    },
    experiment4Point3: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_4_point_3 == null ||
          this.powdermodel_calibration_measures.experiment_4_point_3 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_4_point_3;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_4_point_3 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_4_point_3.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_4_point_3 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_4_point_3 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_4_point_3 = value;
          }
        }
      },
    },
    experiment4Point4: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_4_point_4 == null ||
          this.powdermodel_calibration_measures.experiment_4_point_4 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_4_point_4;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_4_point_4 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_4_point_4.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_4_point_4 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_4_point_4 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_4_point_4 = value;
          }
        }
      },
    },
    experiment4Point5: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_4_point_5 == null ||
          this.powdermodel_calibration_measures.experiment_4_point_5 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_4_point_5;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_4_point_5 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_4_point_5.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_4_point_5 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_4_point_5 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_4_point_5 = value;
          }
        }
      },
    },
    experiment4Point6: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_4_point_6 == null ||
          this.powdermodel_calibration_measures.experiment_4_point_6 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_4_point_6;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_4_point_6 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_4_point_6.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_4_point_6 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_4_point_6 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_4_point_6 = value;
          }
        }
      },
    },
    experiment4Point7: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_4_point_7 == null ||
          this.powdermodel_calibration_measures.experiment_4_point_7 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_4_point_7;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_4_point_7 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_4_point_7.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_4_point_7 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_4_point_7 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_4_point_7 = value;
          }
        }
      },
    },
    experiment5Point1: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_5_point_1 == null ||
          this.powdermodel_calibration_measures.experiment_5_point_1 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_5_point_1;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_5_point_1 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_5_point_1.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_5_point_1 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_5_point_1 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_5_point_1 = value;
          }
        }
      },
    },
    experiment5Point2: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_5_point_2 == null ||
          this.powdermodel_calibration_measures.experiment_5_point_2 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_5_point_2;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_5_point_2 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_5_point_2.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_5_point_2 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_5_point_2 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_5_point_2 = value;
          }
        }
      },
    },
    experiment5Point3: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_5_point_3 == null ||
          this.powdermodel_calibration_measures.experiment_5_point_3 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_5_point_3;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_5_point_3 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_5_point_3.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_5_point_3 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_5_point_3 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_5_point_3 = value;
          }
        }
      },
    },
    experiment5Point4: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_5_point_4 == null ||
          this.powdermodel_calibration_measures.experiment_5_point_4 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_5_point_4;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_5_point_4 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_5_point_4.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_5_point_4 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_5_point_4 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_5_point_4 = value;
          }
        }
      },
    },
    experiment5Point5: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_5_point_5 == null ||
          this.powdermodel_calibration_measures.experiment_5_point_5 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_5_point_5;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_5_point_5 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_5_point_5.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_5_point_5 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_5_point_5 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_5_point_5 = value;
          }
        }
      },
    },
    experiment5Point6: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_5_point_6 == null ||
          this.powdermodel_calibration_measures.experiment_5_point_6 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_5_point_6;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_5_point_6 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_5_point_6.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_5_point_6 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_5_point_6 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_5_point_6 = value;
          }
        }
      },
    },
    experiment5Point7: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_5_point_7 == null ||
          this.powdermodel_calibration_measures.experiment_5_point_7 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_5_point_7;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_5_point_7 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_5_point_7.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_5_point_7 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_5_point_7 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_5_point_7 = value;
          }
        }
      },
    },
    experiment6Point1: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_6_point_1 == null ||
          this.powdermodel_calibration_measures.experiment_6_point_1 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_6_point_1;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_6_point_1 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_6_point_1.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_6_point_1 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_6_point_1 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_6_point_1 = value;
          }
        }
      },
    },
    experiment6Point2: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_6_point_2 == null ||
          this.powdermodel_calibration_measures.experiment_6_point_2 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_6_point_2;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_6_point_2 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_6_point_2.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_6_point_2 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_6_point_2 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_6_point_2 = value;
          }
        }
      },
    },
    experiment6Point3: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_6_point_3 == null ||
          this.powdermodel_calibration_measures.experiment_6_point_3 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_6_point_3;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_6_point_3 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_6_point_3.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_6_point_3 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_6_point_3 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_6_point_3 = value;
          }
        }
      },
    },
    experiment6Point4: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_6_point_4 == null ||
          this.powdermodel_calibration_measures.experiment_6_point_4 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_6_point_4;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_6_point_4 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_6_point_4.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_6_point_4 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_6_point_4 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_6_point_4 = value;
          }
        }
      },
    },
    experiment6Point5: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_6_point_5 == null ||
          this.powdermodel_calibration_measures.experiment_6_point_5 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_6_point_5;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_6_point_5 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_6_point_5.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_6_point_5 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_6_point_5 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_6_point_5 = value;
          }
        }
      },
    },
    experiment6Point6: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_6_point_6 == null ||
          this.powdermodel_calibration_measures.experiment_6_point_6 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_6_point_6;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_6_point_6 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_6_point_6.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_6_point_6 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_6_point_6 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_6_point_6 = value;
          }
        }
      },
    },
    experiment6Point7: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_6_point_7 == null ||
          this.powdermodel_calibration_measures.experiment_6_point_7 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_6_point_7;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_6_point_7 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_6_point_7.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_6_point_7 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_6_point_7 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_6_point_7 = value;
          }
        }
      },
    },
    experiment7Point1: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_7_point_1 == null ||
          this.powdermodel_calibration_measures.experiment_7_point_1 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_7_point_1;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_7_point_1 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_7_point_1.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_7_point_1 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_7_point_1 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_7_point_1 = value;
          }
        }
      },
    },
    experiment7Point2: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_7_point_2 == null ||
          this.powdermodel_calibration_measures.experiment_7_point_2 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_7_point_2;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_7_point_2 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_7_point_2.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_7_point_2 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_7_point_2 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_7_point_2 = value;
          }
        }
      },
    },
    experiment7Point3: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_7_point_3 == null ||
          this.powdermodel_calibration_measures.experiment_7_point_3 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_7_point_3;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_7_point_3 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_7_point_3.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_7_point_3 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_7_point_3 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_7_point_3 = value;
          }
        }
      },
    },
    experiment7Point4: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_7_point_4 == null ||
          this.powdermodel_calibration_measures.experiment_7_point_4 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_7_point_4;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_7_point_4 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_7_point_4.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_7_point_4 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_7_point_4 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_7_point_4 = value;
          }
        }
      },
    },
    experiment7Point5: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_7_point_5 == null ||
          this.powdermodel_calibration_measures.experiment_7_point_5 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_7_point_5;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_7_point_5 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_7_point_5.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_7_point_5 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_7_point_5 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_7_point_5 = value;
          }
        }
      },
    },
    experiment7Point6: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_7_point_6 == null ||
          this.powdermodel_calibration_measures.experiment_7_point_6 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_7_point_6;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_7_point_6 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_7_point_6.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_7_point_6 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_7_point_6 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_7_point_6 = value;
          }
        }
      },
    },
    experiment7Point7: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_7_point_7 == null ||
          this.powdermodel_calibration_measures.experiment_7_point_7 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_7_point_7;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_7_point_7 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_7_point_7.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_7_point_7 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_7_point_7 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_7_point_7 = value;
          }
        }
      },
    },
    experiment8Point1: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_8_point_1 == null ||
          this.powdermodel_calibration_measures.experiment_8_point_1 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_8_point_1;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_8_point_1 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_8_point_1.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_8_point_1 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_8_point_1 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_8_point_1 = value;
          }
        }
      },
    },
    experiment8Point2: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_8_point_2 == null ||
          this.powdermodel_calibration_measures.experiment_8_point_2 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_8_point_2;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_8_point_2 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_8_point_2.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_8_point_2 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_8_point_2 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_8_point_2 = value;
          }
        }
      },
    },
    experiment8Point3: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_8_point_3 == null ||
          this.powdermodel_calibration_measures.experiment_8_point_3 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_8_point_3;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_8_point_3 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_8_point_3.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_8_point_3 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_8_point_3 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_8_point_3 = value;
          }
        }
      },
    },
    experiment8Point4: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_8_point_4 == null ||
          this.powdermodel_calibration_measures.experiment_8_point_4 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_8_point_4;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_8_point_4 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_8_point_4.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_8_point_4 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_8_point_4 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_8_point_4 = value;
          }
        }
      },
    },
    experiment8Point5: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_8_point_5 == null ||
          this.powdermodel_calibration_measures.experiment_8_point_5 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_8_point_5;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_8_point_5 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_8_point_5.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_8_point_5 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_8_point_5 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_8_point_5 = value;
          }
        }
      },
    },
    experiment8Point6: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_8_point_6 == null ||
          this.powdermodel_calibration_measures.experiment_8_point_6 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_8_point_6;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_8_point_6 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_8_point_6.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_8_point_6 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_8_point_6 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_8_point_6 = value;
          }
        }
      },
    },
    experiment8Point7: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_8_point_7 == null ||
          this.powdermodel_calibration_measures.experiment_8_point_7 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_8_point_7;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_8_point_7 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_8_point_7.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_8_point_7 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_8_point_7 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_8_point_7 = value;
          }
        }
      },
    },
    measurement1: {
      get() {
        if (
          this.powdermodel_calibration_measures.measurement_1 == null ||
          this.powdermodel_calibration_measures.measurement_1 == ""
        ) {
          return this.powdermodel_calibration_measures.measurement_1;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.measurement_1 * this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.measurement_1.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.measurement_1 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.measurement_1 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.measurement_1 = value;
          }
        }
      },
    },
    measurement2: {
      get() {
        if (
          this.powdermodel_calibration_measures.measurement_2 == null ||
          this.powdermodel_calibration_measures.measurement_2 == ""
        ) {
          return this.powdermodel_calibration_measures.measurement_2;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.measurement_2 * this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.measurement_2.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.measurement_2 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.measurement_2 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.measurement_2 = value;
          }
        }
      },
    },
    measurement3: {
      get() {
        if (
          this.powdermodel_calibration_measures.measurement_3 == null ||
          this.powdermodel_calibration_measures.measurement_3 == ""
        ) {
          return this.powdermodel_calibration_measures.measurement_3;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.measurement_3 * this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.measurement_3.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.measurement_3 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.measurement_3 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.measurement_3 = value;
          }
        }
      },
    },
    measurement4: {
      get() {
        if (
          this.powdermodel_calibration_measures.measurement_4 == null ||
          this.powdermodel_calibration_measures.measurement_4 == ""
        ) {
          return this.powdermodel_calibration_measures.measurement_4;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.measurement_4 * this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.measurement_4.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.measurement_4 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.measurement_4 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.measurement_4 = value;
          }
        }
      },
    },
    measurement5: {
      get() {
        if (
          this.powdermodel_calibration_measures.measurement_5 == null ||
          this.powdermodel_calibration_measures.measurement_5 == ""
        ) {
          return this.powdermodel_calibration_measures.measurement_5;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.measurement_5 * this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.measurement_5.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.measurement_5 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.measurement_5 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.measurement_5 = value;
          }
        }
      },
    },
    measurement6: {
      get() {
        if (
          this.powdermodel_calibration_measures.measurement_6 == null ||
          this.powdermodel_calibration_measures.measurement_6 == ""
        ) {
          return this.powdermodel_calibration_measures.measurement_6;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.measurement_6 * this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.measurement_6.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.measurement_6 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.measurement_6 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.measurement_6 = value;
          }
        }
      },
    },
    measurement7: {
      get() {
        if (
          this.powdermodel_calibration_measures.measurement_7 == null ||
          this.powdermodel_calibration_measures.measurement_7 == ""
        ) {
          return this.powdermodel_calibration_measures.measurement_7;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.measurement_7 * this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.measurement_7.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.measurement_7 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.measurement_7 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.measurement_7 = value;
          }
        }
      },
    },
    measurement8: {
      get() {
        if (
          this.powdermodel_calibration_measures.measurement_8 == null ||
          this.powdermodel_calibration_measures.measurement_8 == ""
        ) {
          return this.powdermodel_calibration_measures.measurement_8;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.measurement_8 * this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.measurement_8.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.measurement_8 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.measurement_8 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.measurement_8 = value;
          }
        }
      },
    },
    measurement9: {
      get() {
        if (
          this.powdermodel_calibration_measures.measurement_9 == null ||
          this.powdermodel_calibration_measures.measurement_9 == ""
        ) {
          return this.powdermodel_calibration_measures.measurement_9;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.measurement_9 * this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.measurement_9.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.measurement_9 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.measurement_9 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.measurement_9 = value;
          }
        }
      },
    },
    measurement10: {
      get() {
        if (
          this.powdermodel_calibration_measures.measurement_10 == null ||
          this.powdermodel_calibration_measures.measurement_10 == ""
        ) {
          return this.powdermodel_calibration_measures.measurement_10;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.measurement_10 * this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.measurement_10.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.measurement_10 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.measurement_10 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.measurement_10 = value;
          }
        }
      },
    },
    measurement11: {
      get() {
        if (
          this.powdermodel_calibration_measures.measurement_11 == null ||
          this.powdermodel_calibration_measures.measurement_11 == ""
        ) {
          return this.powdermodel_calibration_measures.measurement_11;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.measurement_11 * this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.measurement_11.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.measurement_11 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.measurement_11 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.measurement_11 = value;
          }
        }
      },
    },
    measurement12: {
      get() {
        if (
          this.powdermodel_calibration_measures.measurement_12 == null ||
          this.powdermodel_calibration_measures.measurement_12 == ""
        ) {
          return this.powdermodel_calibration_measures.measurement_12;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.measurement_12 * this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.measurement_12.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.measurement_12 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.measurement_12 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.measurement_12 = value;
          }
        }
      },
    },
    measurement13: {
      get() {
        if (
          this.powdermodel_calibration_measures.measurement_13 == null ||
          this.powdermodel_calibration_measures.measurement_13 == ""
        ) {
          return this.powdermodel_calibration_measures.measurement_13;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.measurement_13 * this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.measurement_13.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.measurement_13 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.measurement_13 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.measurement_13 = value;
          }
        }
      },
    },
    measurement14: {
      get() {
        if (
          this.powdermodel_calibration_measures.measurement_14 == null ||
          this.powdermodel_calibration_measures.measurement_14 == ""
        ) {
          return this.powdermodel_calibration_measures.measurement_14;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.measurement_14 * this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.measurement_14.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.measurement_14 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.measurement_14 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.measurement_14 = value;
          }
        }
      },
    },
    measurement15: {
      get() {
        if (
          this.powdermodel_calibration_measures.measurement_15 == null ||
          this.powdermodel_calibration_measures.measurement_15 == ""
        ) {
          return this.powdermodel_calibration_measures.measurement_15;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.measurement_15 * this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.measurement_15.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.measurement_15 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.measurement_15 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.measurement_15 = value;
          }
        }
      },
    },
    measurement16: {
      get() {
        if (
          this.powdermodel_calibration_measures.measurement_16 == null ||
          this.powdermodel_calibration_measures.measurement_16 == ""
        ) {
          return this.powdermodel_calibration_measures.measurement_16;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.measurement_16 * this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.measurement_16.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.measurement_16 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.measurement_16 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.measurement_16 = value;
          }
        }
      },
    },
    measurement17: {
      get() {
        if (
          this.powdermodel_calibration_measures.measurement_17 == null ||
          this.powdermodel_calibration_measures.measurement_17 == ""
        ) {
          return this.powdermodel_calibration_measures.measurement_17;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.measurement_17 * this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.measurement_17.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.measurement_17 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.measurement_17 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.measurement_17 = value;
          }
        }
      },
    },
    measurement18: {
      get() {
        if (
          this.powdermodel_calibration_measures.measurement_18 == null ||
          this.powdermodel_calibration_measures.measurement_18 == ""
        ) {
          return this.powdermodel_calibration_measures.measurement_18;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.measurement_18 * this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.measurement_18.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.measurement_18 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.measurement_18 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.measurement_18 = value;
          }
        }
      },
    },
    measurement19: {
      get() {
        if (
          this.powdermodel_calibration_measures.measurement_19 == null ||
          this.powdermodel_calibration_measures.measurement_19 == ""
        ) {
          return this.powdermodel_calibration_measures.measurement_19;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.measurement_19 * this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.measurement_19.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.measurement_19 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.measurement_19 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.measurement_19 = value;
          }
        }
      },
    },
    measurement20: {
      get() {
        if (
          this.powdermodel_calibration_measures.measurement_20 == null ||
          this.powdermodel_calibration_measures.measurement_20 == ""
        ) {
          return this.powdermodel_calibration_measures.measurement_20;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.measurement_20 * this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.measurement_20.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.measurement_20 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.measurement_20 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.measurement_20 = value;
          }
        }
      },
    },
    measurement21: {
      get() {
        if (
          this.powdermodel_calibration_measures.measurement_21 == null ||
          this.powdermodel_calibration_measures.measurement_21 == ""
        ) {
          return this.powdermodel_calibration_measures.measurement_21;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.measurement_21 * this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.measurement_21.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.measurement_21 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.measurement_21 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.measurement_21 = value;
          }
        }
      },
    },
    measurement22: {
      get() {
        if (
          this.powdermodel_calibration_measures.measurement_22 == null ||
          this.powdermodel_calibration_measures.measurement_22 == ""
        ) {
          return this.powdermodel_calibration_measures.measurement_22;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.measurement_22 * this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.measurement_22.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.measurement_22 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.measurement_22 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.measurement_22 = value;
          }
        }
      },
    },
    measurement23: {
      get() {
        if (
          this.powdermodel_calibration_measures.measurement_23 == null ||
          this.powdermodel_calibration_measures.measurement_23 == ""
        ) {
          return this.powdermodel_calibration_measures.measurement_23;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.measurement_23 * this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.measurement_23.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.measurement_23 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.measurement_23 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.measurement_23 = value;
          }
        }
      },
    },
    measurement24: {
      get() {
        if (
          this.powdermodel_calibration_measures.measurement_24 == null ||
          this.powdermodel_calibration_measures.measurement_24 == ""
        ) {
          return this.powdermodel_calibration_measures.measurement_24;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.measurement_24 * this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.measurement_24.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.measurement_24 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.measurement_24 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.measurement_24 = value;
          }
        }
      },
    },
    measurement25: {
      get() {
        if (
          this.powdermodel_calibration_measures.measurement_25 == null ||
          this.powdermodel_calibration_measures.measurement_25 == ""
        ) {
          return this.powdermodel_calibration_measures.measurement_25;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.measurement_25 * this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.measurement_25.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.measurement_25 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.measurement_25 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.measurement_25 = value;
          }
        }
      },
    },
    isFixedLineLayout() {
      return this.line.pistols_layout == "f" || this.line.pistols_layout == "fixedmixed";
    },
  },
  watch: {
    "$store.state.user_data.language"() {
      this.$i18n.locale = this.$store.state.user_data.language;
    },
  },
  mounted() {
    this.$store.state.isAbsolute = true;

    this.getData();
    setTooltip(this.$store.state.bootstrap);
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
  },
  methods: {
    async getCanvasData() {
      var canvas = document.getElementById("powdermodelcanvas");
      var ctx = canvas.getContext("2d");
      this.canvas_width = canvas.width;
      this.canvas_height = canvas.height;
      this.powdermodelcanvas = ctx;
      await this.drawDistribution();
    },
    async getData() {
      await Promise.all([
        this.getLine(this.lineId),
        this.getVisit(this.visitId),
        this.getPowders(this.lineId),
        this.getBenchmarkData(this.visitId, 0),
      ]);

      this.powderId = this.benchmark_stage_data.powder ? this.benchmark_stage_data.powder : null;

      if (this.powders.length == 1) {
        this.benchmark_stage_data.powder = this.powders[0].id;
        this.updateSelectedPowder();
      }

      if (this.powderId) {
        await this.getPowder();
        this.currentPage = 1;
        if (this.sprayPatternIsSelected) {
          this.currentPage = 2;
          await this.getPowderModel()
          await this.getPowderCalibrationMeasures();
          await this.getCanvasData();
        }
      }
      this.$store.state.isLoading = false;
    },
    async getPowderModel() {
      try {
        if (!this.sprayPatternIsSelected) {
          return;
        }

        if (this.powdermodelType == "calibrated") {
          let response = await axios.get(
            "/api/v1/fp/powdermodels/" + this.visit.spraypatternstage.selected_powder_models[0],
          );
          this.powdermodel = response.data;
        } else if (this.powdermodelType == "generalprecalibrated") {
          let response = await axios.get("/api/v1/fp/generalprecalibratedpowdermodels/" + this.powdermodelId);
          this.powdermodel = response.data;
        }
      } catch (error) {
        console.error(error);
      }
    },
    updateSelectedSprayPattern(new_selected_powder_models) {
      this.visit.spraypatternstage.selected_powder_models = new_selected_powder_models;
    },
    async getPowderCalibrationMeasures() {
      try {
        if (this.powdermodelType == "calibrated") {
          let response = await axios.get("/api/v1/fp/powdercalibrationmeasures/" + this.powdermodel.id + "/");

          response.data.max_pistol_to_substrate_distance_measurements *= 100;
          response.data.min_pistol_to_substrate_distance_measurements *= 100;
          response.data.calibration_line_speed *= 60;
          this.powdermodel_calibration_measures = response.data;
        }
      } catch (error) {
        console.error(error);
      }
    },
    checkFilledCreatePowderForm() {
      if (this.newPowderName == "") {
        this.powder_name_input_error = true;
      }
      if (this.newPowderManufacturer == "") {
        this.powder_manufacturer_input_error = true;
      }
      if (this.powder_name_input_error || this.powder_manufacturer_input_error) {
        this.$swal({
          title: "Please fill in all required fields",
          icon: "error",
          customClass: {
            confirmButton: "btn bg-gradient-success",
          },
          buttonsStyling: false,
        });
        return false;
      }
      return true;
    },
    closeModal() {
      const dismissButton = this.$refs.dismissButton;
      if (dismissButton) {
        dismissButton.click();
      }
    },
    async createPowder() {
      if (!this.checkFilledCreatePowderForm()) {
        return;
      }

      try {
        const response = await axios.post("/api/v1/blueprint/powder/", {
          line: this.lineId,
          name: this.newPowderName,
          powder_manufacturer: this.newPowderManufacturer,
          powder_id: this.newPowderId,
        });

        this.powderId = response.data.id;
        await this.getPowders(this.lineId);

        this.newPowderName = "";
        this.newPowderManufacturer = "";
        this.newPowderId = "";
      } catch (error) {
        console.error(error);
      };
      this.closeModal();
    },
    async drawDistribution() {
      this.$store.state.isLoading = true;
      this.disable_ranges = true;
      this.fillCanvasBlue();

      var distribution = await this.getGeneralizedGaussianDistribution();
      this.distribution_imgData = new ImageData(
        Uint8ClampedArray.from(distribution.values()),
        this.canvas_width,
        this.canvas_height,
      );
      this.powdermodelcanvas.putImageData(this.distribution_imgData, 0, 0);

      this.last_powdermodel_visualization_params = Object.assign({}, this.powdermodel_visulization_params_form);

      this.disable_ranges = false;
      this.$store.state.isLoading = false;
    },
    async getGeneralizedGaussianDistribution() {
      var distribution_window_pixel_size = this.canvas_width;
      var pixels_per_meter = Math.round(
        distribution_window_pixel_size / (this.powdermodel_visulization_params_form.simulated_spray_diameter / 100),
      );

      var body = {
        powdermodel_id: this.powdermodel.id,
        powdermodel_type: this.powdermodelType,
        pistol_to_substrate_distance: parseFloat(
          this.powdermodel_visulization_params_form.pistol_to_substrate_distance / 100,
        ),
        air_parameter_value: parseFloat(this.powdermodel_visulization_params_form.air_parameter_value),
        distribution_window_pixel_size: parseFloat(distribution_window_pixel_size),
        pixels_per_meter: pixels_per_meter,
      };

      var response = await axios.post("/api/v1/fp/computegeneralizedgaussiandistribution/", body);

      return response.data["distribution"];
    },
    async handleNextClick() {
      if (this.currentPage === 0) {
        if (!this.powderId) {
          this.$swal({
            title: "Warning",
            text: "Please select a Powder before proceeding.",
            icon: "warning",
            customClass: {
              confirmButton: "btn bg-gradient-warning",
            },
            buttonsStyling: false,
          });
          return;
        }
      }
      if (this.currentPage === 1) {
        if (!this.sprayPatternIsSelected) {
          this.$swal({
            title: "Warning",
            text: "Please select a spray pattern before proceeding. You may calibrate a new spray pattern.",
            icon: "warning",
            customClass: {
              confirmButton: "btn bg-gradient-warning",
            },
            buttonsStyling: false,
          });
          return;
        }
      }

      this.currentPage += 1;

      if (this.currentPage === 2) {
        if (this.powderId) {
          await this.getVisit(this.visitId);
          await this.getPowder();
          await this.getPowderModel();
          await this.getCanvasData();
        }
      }
    },
    async updateSelectedPowder() {
      try {
        if (
          this.benchmark_stage_data.powder == null ||
          this.benchmark_stage_data.powder == "" ||
          this.benchmark_stage_data.powder == undefined
        ) {
          return
        }

        await axios.patch(`/api/v1/blueprint/benchmarkstage/` + this.visitId + "/", {
          powder: this.benchmark_stage_data.powder,
        });

        await this.getBenchmarkData(this.visitId, 0);

        this.powderId = this.benchmark_stage_data.powder;
      } catch (error) {
        console.error(error);
      }
    },
    fillCanvasBlue() {
      this.powdermodelcanvas.fillStyle = "#000080";
      this.powdermodelcanvas.fillRect(0, 0, this.canvas_width, this.canvas_height);
    },
    async getPowder() {
      try {
        const response = await axios.get("/api/v1/blueprint/powder/" + this.powderId);
        this.powder = response.data;
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<i18n>
  {
    "EN": {
    },
    "DE": {
    },
    "ES": {
    },
  }
</i18n>
