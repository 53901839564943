export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "EN": {
        "Line Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Line Name"])},
        "Created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created"])},
        "Optimizations Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimizations Library"])},
        "New Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Optimization"])},
        "Powder Models Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powder Models Library"])},
        "Edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
        "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
        "Powder Amount Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powder Amount Library"])}
      },
      "DE": {
        "Line Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linienname"])},
        "Created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellt"])},
        "Optimizations Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimierungsliste"])},
        "New Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Optimierung"])},
        "Powder Models Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bibliothek der Sprühbilder"])},
        "Edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeiten"])},
        "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
        "Powder Amount Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pulvermengenbibliothek"])}
      },
      "ES": {
        "Line Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de la Línea"])},
        "Created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creada"])},
        "Optimizations Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de Optimizaciones"])},
        "New Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva Optimización"])},
        "Powder Models Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de Modelos de Polvo"])},
        "Edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar"])},
        "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar"])},
        "Powder Amount Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biblioteca de Flujo de Pintura"])}
      },
      "FR": {
        "Line Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de la Ligne"])},
        "Created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créée"])},
        "Optimizations Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des Optimisations"])},
        "New Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle Optimisation"])},
        "Powder Models Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bibliothèque de Modèles de Poudres"])},
        "Edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éditer"])},
        "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effacer"])},
        "Powder Amount Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bibliothèque de Quantité de Poudre"])}
      },
      "IT": {
        "Line Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome della Linea"])},
        "Created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creata"])},
        "Optimizations Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elenco delle Ottimizzazioni"])},
        "New Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuova Ottimizzazione"])},
        "Powder Models Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libreria dei Modelli di Polvere"])},
        "Edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica"])},
        "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina"])},
        "Powder Amount Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libreria della Quantità di Polvere"])}
      },
      "PL": {
        "Line Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa Linii"])},
        "Created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utworzony"])},
        "Optimizations Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista Optymalizacji"])},
        "New Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nowa optymalizacja"])},
        "Powder Models Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biblioteka Modeli Proszkowych"])},
        "Edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edytować"])},
        "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kasować"])},
        "Powder Amount Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biblioteka ilości proszku"])}
      },
      "TR": {
        "Line Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hat İsmi"])},
        "Created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oluşturuldu"])},
        "Optimizations Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimizasyonlar Listesi"])},
        "New Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Optimizasyon"])},
        "Powder Models Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toz Modelleri Kütüphanesi"])},
        "Edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Düzenle"])},
        "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sil"])},
        "Powder Amount Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toz Miktarı Kütüphanesi"])}
      },
      "ZH": {
        "Line Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["线路名称"])},
        "Created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建"])},
        "Optimizations Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["优化列表"])},
        "New Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新优化"])},
        "Powder Models Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["粉末模型库"])},
        "Edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑"])},
        "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除"])},
        "Powder Amount Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["粉末量库"])}
      }
    }
  })
}
