import { ref } from 'vue';
import axios from 'axios';

export function useGetLine() {
  // Reactive variable to hold the line data
  const line = ref({
    id: null,
    name: null,
    used_at: null,
    line_manufacturer: null,
    pump_type: null,
    pistols_layout: null,
    air_parameter_name: null,
    air_parameter_units: null,
    total_pistols: [],
    pistol_to_pistol_distance: [],
    pistols_min_velocity: null,
    pistols_max_velocity: null,
    pistols_max_movement_range: null,
    pistol_columns_distances: [],
    pistol_columns_vertical_offsets: [],
    powder_recycling_efficiency_percentage: null,
    pistols_max_acceleration: null,
  });

  const getLine = async (lineId) => {
    try {
      let line_response = await axios.get(`/api/v1/fp/line/${lineId}`);

      line_response.data.total_pistols = JSON.parse(line_response.data.total_pistols);
      line_response.data.pistol_to_pistol_distance = JSON.parse(line_response.data.pistol_to_pistol_distance);
      line_response.data.pistol_columns_distances = JSON.parse(line_response.data.pistol_columns_distances);
      line_response.data.pistol_columns_vertical_offsets = JSON.parse(
        line_response.data.pistol_columns_vertical_offsets,
      );

      line_response.data.pistols_max_movement_range = line_response.data.pistols_max_movement_range * 100;

      line.value = line_response.data;
    } catch (error) {
      console.error(error);
    }
  };

  return {
    line,
    getLine,
  };
}

export function useGetPowders() {
  // Reactive variable to hold the line data
  const powders = ref([]);

  const getPowders = async (lineId) => {
    try {
      if (lineId == undefined || lineId == null || lineId == "") {
        const response = await axios.get("/api/v1/blueprint/powder/");
        powders.value = JSON.parse(JSON.stringify(response.data));
      }
      else {
        const response = await axios.get("/api/v1/blueprint/powdersbyline/" + lineId + "/");
        powders.value = JSON.parse(JSON.stringify(response.data));
      }
    } catch (error) {
      console.error(error);
    }
  };

  return {
    powders,
    getPowders,
  };
}

export function useGetCalibratedPowderModels() {
  // Reactive variable to hold the line data
  const calibrated_powdermodels = ref([]);

  const getCalibratedPowderModels = async (lineId) => {
    try {
      const response = await axios.get("/api/v1/fp/powdermodels/lineid/" + lineId);
      calibrated_powdermodels.value = JSON.parse(JSON.stringify(response.data));
    } catch (error) {
      console.error(error);
    }
  };

  return {
    calibrated_powdermodels,
    getCalibratedPowderModels,
  };
}


