<template>
  <div class="col-12">
    <div class="row mt-4">
      <div class="col-6 px-5">
        <h5 class="mb-0">{{ $t("Lines") }}</h5>
        <p class="mb-0 text-md text-dark">{{ $t("List of created Lines") }}</p>
      </div>
    </div>
    <div
      v-if="line_list.length"
      class="row p-4 mt-2 mt-lg-4 pt-0 card-container"
    >
      <line-card
        v-for="(line, index) in OrderByDateUpdated(line_list)"
        :key="line.id"
        :line-name="line.name"
        :line-id="String(line.id)"
        :date-time="dateToString(line.created_at)"
        :is-active="activeCardIndex === index"
        :line-layout="line.pistols_layout"
        @cardClicked="handleCardClicked(index)"
      />
    </div>
    <div
      v-if="$store.state.flightpath_standard_license && line_list.length == 0"
      class="mt-4 mx-5"
      style="font-weight: bold"
    >
      {{ $t("No Lines Created. To create a line, contact us at") }}
      <a href="mailto:info@coatingai.com"> info@coatingai.com</a>.
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { orderBy } from "lodash";
import LineCard from "../../components/LineCard.vue";

export default {
  name: "LinesListProLicense",
  components: {
    LineCard,
  },
  data() {
    this.$i18n.locale = this.$store.state.user_data.language;
    return {
      line_list: [],
      activeCardIndex: null,
    };
  },
  watch: {
    "$store.state.user_data.language"() {
      this.$i18n.locale = this.$store.state.user_data.language;
    },
  },
  mounted() {
    this.getLineList();
  },
  methods: {
    handleCardClicked(index) {
      if (this.line_list.length == 1) {
        this.activeCardIndex = 1;
      }
      if (this.activeCardIndex === index) {
        this.activeCardIndex = null;
      } else {
        this.activeCardIndex = index;
      }
    },
    OrderByDateUpdated(array) {
      return orderBy(array, ["updated_at", "created_at"], ["desc"]);
    },
    getLineList() {
      axios
        .get("/api/v1/fp/line/")
        .then(response => {
          this.line_list = response.data;
          this.$store.state.isLoading = false;
          if (this.line_list.length == 1) {
            this.handleCardClicked(0);
          }
        })
        .catch(error => {
          console.error(error);
          setTimeout(
            function () {
              this.getLineList();
            }.bind(this),
            5000,
          );
        });
    },
    dateToString(date) {
      const date_object = new Date(date);
      if (this.$store.state.user_data.unit_system == "metric") {
        return date_object.toLocaleDateString("en-GB");
      } else if (this.$store.state.user_data.unit_system == "imperial") {
        return date_object.toLocaleDateString("en-US");
      } else {
        return null;
      }
    },
  },
};
</script>

<style scoped>
.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
}
</style>
<i18n>
  {
    "EN": {
        "Lines":"Lines",
        "List of created Lines":"List of created Lines",
        "No Lines Created. To create a line, contact us at":"No Lines Created. To create a line, contact us at",
    },
    "DE": {
        "Lines":"Anlagen",
        "List of created Lines":"Liste der erstellten Anlagen",
        "No Lines Created. To create a line, contact us at":"Keine Linien erstellt. Um eine Leitung zu erstellen, kontaktieren Sie uns unter",
    },
    "ES": {
        "Lines":"Líneas",
        "List of created Lines":"Lista de líneas creadas",
        "No Lines Created. To create a line, contact us at":"No existe ninguna línea. Para crear una línea, contáctenos en",
    },
    "FR": {
        "Lines":"Lignes",
        "List of created Lines":"Liste des lignes créées",
        "No Lines Created. To create a line, contact us at":"Aucune ligne créée. Pour créer une ligne, contactez-nous à",
    },
    "IT": {
        "Lines":"Linee",
        "List of created Lines":"Elenco delle linee create",
        "No Lines Created. To create a line, contact us at":"Nessuna linea creata. Per creare una linea, contattaci a",
    },
    "PL": {
        "Lines":"Linie",
        "List of created Lines":"Lista utworzonych linii",
        "No Lines Created. To create a line, contact us at":"Nie utworzono żadnych linii. Aby utworzyć linię, skontaktuj się z nami pod adresem",
    },
    "TR": {
        "Lines":"Hatlar",
        "List of created Lines":"Oluşturulan Hatların Listesi",
        "No Lines Created. To create a line, contact us at":"Hat oluşturulmadı. Bir hat oluşturmak için bizimle iletişime geçin",
    },
    "ZH": {
        "Lines":"线",
        "List of created Lines":"创建的线列表",
        "No Lines Created. To create a line, contact us at":"未创建线。要创建线，请联系我们",
    },
  }
</i18n>
