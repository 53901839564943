<template>
  <footer class="py-3 footer">
    <div class="container-fluid">
      <div class="row align-items-center justify-content-lg-between">
        <div class="mb-4 col-lg-6 mb-lg-0 justify-content-center">
          <div class="text-sm text-center copyright text-muted">
            ©
            {{ new Date().getFullYear() }} coatingAI
          </div>
        </div>
        <div class="col-lg-6">
          <ul class="nav nav-footer justify-content-center">
            <li class="nav-item">
              <a
                class="nav-link text-muted"
                href="/"
              >
                {{ $t("Main Page") }}
              </a>
            </li>
            <!-- <li class="nav-item">
              <a
                href="https://www.coatingai.com"
                class="nav-link text-muted"
                target="_blank"
                >Tutorials</a
              >
            </li> -->
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "AppFooter",
  data() {
    this.$i18n.locale = this.$store.state.user_data.language;
    {
      return {};
    }
  },
  watch: {
    "$store.state.user_data.language"() {
      this.$i18n.locale = this.$store.state.user_data.language;
    },
  },
};
</script>

<i18n>
  {
    "EN": {
       "Main Page":"Main Page",
    },
    "DE": {
        "Main Page":"Hauptseite",
    },
    "ES": {
        "Main Page":"Página Principal",
    },
    "FR": {
        "Main Page":"Page Principale",
    },
    "IT": {
        "Main Page":"Pagina Iniziale",
    },
    "PL": {
        "Main Page":"Strona Główna",
    },
    "TR": {
        "Main Page":"Ana Sayfa",
    },
    "ZH": {
        "Main Page":"主页",
    }
  }
</i18n>
