export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "EN": {
        "Powder Amount Calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powder Amount Calibration"])},
        "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "Number of Measures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of Measures"])},
        "Please select one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select one"])},
        "Time Interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time Interval"])},
        "Min Powder Amount Param": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min Powder Amount Param"])},
        "Max Powder Amount Param": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max Powder Amount Param"])},
        "Select Powder Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Powder Model"])},
        "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["optional"])},
        "Not Specified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Specified"])},
        "Calibration Steps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibration Steps"])},
        "Prepare the Vacuum Bags:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prepare the Vacuum Bags:"])},
        "Select a vacuum bag for each gun.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a vacuum bag for each gun."])},
        "Weigh the empty vacuum bag and record its weight.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weigh the empty vacuum bag and record its weight."])},
        "Set Up the Machine:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set Up the Machine:"])},
        "Attach the vacuum bag to the tip of the gun.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attach the vacuum bag to the tip of the gun."])},
        "Turn on the machine using the specified setting for the defined time interval.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turn on the machine using the specified setting for the defined time interval."])},
        "Weigh the Bags:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weigh the Bags:"])},
        "After the machine runs for the set time, weigh each vacuum bag again.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After the machine runs for the set time, weigh each vacuum bag again."])},
        "Record the new weight of each bag.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Record the new weight of each bag."])},
        "Repeat for Different Parameters:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat for Different Parameters:"])},
        "Empty the vacuum bags before proceeding to the next parameter.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empty the vacuum bags before proceeding to the next parameter."])},
        "Repeat the entire process for each new parameter setting.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat the entire process for each new parameter setting."])},
        "Set your machine's powder amount setting to:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set your machine's powder amount setting to:"])},
        "Gun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun"])},
        "Empty Bag Weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empty Bag Weight"])},
        "Full Bag Weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full Bag Weight"])},
        "Back to Powder Amount Param": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to Powder Amount Param"])},
        "Continue to Powder Amount Param": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue to Powder Amount Param"])},
        "Save Powder Amount Calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Powder Amount Calibration"])},
        "Missing Required Fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Missing Required Fields"])},
        "Measurement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Measurement"])},
        "The following fields are required:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The following fields are required:"])},
        "Guns in Improper State": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guns in Improper State"])},
        "Powder Amount Calibration failed.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powder Amount Calibration failed."])},
        "has a deviation above 20%.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["has a deviation above 20%."])},
        "Consider reviewing the state of the guns.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consider reviewing the state of the guns."])},
        "Powder Amount Calibration Created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powder Amount Calibration Created"])},
        "was saved successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["was saved successfully"])},
        "Invalid Value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid Value"])},
        "Please enter a positive value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a positive value"])},
        "Full Bag Weight cannot be less than Empty Bag Weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full Bag Weight cannot be less than Empty Bag Weight"])},
        "Do you want to create it anyway?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to create it anyway?"])},
        "Yes, create it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, create it"])},
        "No, cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No, cancel"])}
      },
      "DE": {
        "Powder Amount Calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pulvermengenkalibrierung"])},
        "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "Number of Measures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Messungen"])},
        "Please select one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wählen Sie eine"])},
        "Time Interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeitintervall"])},
        "Min Powder Amount Param": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestpulvermengenparameter"])},
        "Max Powder Amount Param": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximaler Pulvermengenparameter"])},
        "Select Powder Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pulvermodell auswählen"])},
        "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["optional"])},
        "Not Specified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht spezifiziert"])},
        "Calibration Steps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrierschritte"])},
        "Prepare the Vacuum Bags:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bereiten Sie die Vakuumbeutel vor:"])},
        "Select a vacuum bag for each gun.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie einen Vakuumbeutel für jede Waffe aus."])},
        "Weigh the empty vacuum bag and record its weight.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiegen Sie den leeren Vakuumbeutel und notieren Sie sein Gewicht."])},
        "Set Up the Machine:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maschine einrichten:"])},
        "Attach the vacuum bag to the tip of the gun.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Befestigen Sie den Vakuumbeutel an der Spitze der Waffe."])},
        "Turn on the machine using the specified setting for the defined time interval.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schalten Sie die Maschine mit der angegebenen Einstellung für das definierte Zeitintervall ein."])},
        "Weigh the Bags:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiegen Sie die Taschen:"])},
        "After the machine runs for the set time, weigh each vacuum bag again.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiegen Sie nach Ablauf der eingestellten Zeit erneut jeden Vakuumbeutel."])},
        "Record the new weight of each bag.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notieren Sie das neue Gewicht jedes Beutels."])},
        "Repeat for Different Parameters:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiederholen für verschiedene Parameter:"])},
        "Empty the vacuum bags before proceeding to the next parameter.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leeren Sie die Vakuumbeutel, bevor Sie zum nächsten Parameter übergehen."])},
        "Repeat the entire process for each new parameter setting.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiederholen Sie den gesamten Prozess für jede neue Parameter-Einstellung."])},
        "Set your machine's powder amount setting to:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stellen Sie die Pulvermengeneinstellung Ihrer Maschine auf:"])},
        "Gun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waffe"])},
        "Empty Bag Weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leergewicht des Beutels"])},
        "Full Bag Weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vollgewicht des Beutels"])},
        "Back to Powder Amount Param": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück zum Pulvermengenparameter"])},
        "Continue to Powder Amount Param": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter zum Pulvermengenparameter"])},
        "Save Powder Amount Calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pulvermengenkalibrierung speichern"])},
        "Missing Required Fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erforderliche Felder fehlen"])},
        "Measurement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messung"])},
        "The following fields are required:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die folgenden Felder sind erforderlich:"])},
        "Guns in Improper State": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waffen in unangemessenem Zustand"])},
        "Powder Amount Calibration failed.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pulvermengenkalibrierung fehlgeschlagen."])},
        "has a deviation above 20%.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hat eine Abweichung von über 20%."])},
        "Consider reviewing the state of the guns.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfen Sie den Zustand der Waffen."])},
        "Powder Amount Calibration Created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pulvermengenkalibrierung erstellt"])},
        "was saved successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wurde erfolgreich gespeichert"])},
        "Invalid Value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültiger Wert"])},
        "Please enter a positive value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie einen positiven Wert ein"])},
        "Full Bag Weight cannot be less than Empty Bag Weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Vollgewicht des Beutels darf nicht kleiner sein als das Leergewicht des Beutels"])},
        "Do you want to create it anyway?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie es trotzdem erstellen?"])},
        "Yes, create it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja, erstellen"])},
        "No, cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nein, abbrechen"])}
      },
      "ES": {
        "Powder Amount Calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibración de la flujo de pintura"])},
        "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
        "Number of Measures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de medidas"])},
        "Please select one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, seleccione uno"])},
        "Time Interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intervalo de tiempo"])},
        "Min Powder Amount Param": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parámetro de flujo de pintura mínimo"])},
        "Max Powder Amount Param": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parámetro de flujo de pintura máximo"])},
        "Select Powder Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar modelo de polvo"])},
        "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["opcional"])},
        "Not Specified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No especificado"])},
        "Calibration Steps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasos de calibración"])},
        "Prepare the Vacuum Bags:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preparar las bolsas de aspiradora:"])},
        "Select a vacuum bag for each gun.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione una bolsa de aspiradora para cada pistola."])},
        "Weigh the empty vacuum bag and record its weight.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pese la bolsa de aspiradora vacía y registre su peso."])},
        "Set Up the Machine:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurar la máquina:"])},
        "Attach the vacuum bag to the tip of the gun.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fije la bolsa de aspiradora en la punta del pistola."])},
        "Turn on the machine using the specified setting for the defined time interval.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encienda la máquina utilizando la configuración especificada para el intervalo de tiempo definido."])},
        "Weigh the Bags:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pese las bolsas:"])},
        "After the machine runs for the set time, weigh each vacuum bag again.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Después de que la máquina funcione durante el tiempo establecido, pese cada bolsa de aspiradora nuevamente."])},
        "Record the new weight of each bag.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registre el nuevo peso de cada bolsa."])},
        "Repeat for Different Parameters:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repetir para diferentes parámetros:"])},
        "Empty the vacuum bags before proceeding to the next parameter.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vacíe las bolsas de aspiradora antes de pasar al siguiente parámetro."])},
        "Repeat the entire process for each new parameter setting.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repita todo el proceso para cada nueva configuración de parámetros."])},
        "Set your machine's powder amount setting to:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajuste la configuración de la flujo de pintura de su máquina a:"])},
        "Gun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pistola"])},
        "Empty Bag Weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peso de la bolsa vacía"])},
        "Full Bag Weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peso de la bolsa llena"])},
        "Back to Powder Amount Param": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver al parámetro de flujo de pintura"])},
        "Continue to Powder Amount Param": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar con el parámetro de flujo de pintura"])},
        "Save Powder Amount Calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar calibración de la flujo de pintura"])},
        "Missing Required Fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campos obligatorios faltantes"])},
        "Measurement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medición"])},
        "The following fields are required:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los siguientes campos son obligatorios:"])},
        "Guns in Improper State": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pistolas en estado inadecuado"])},
        "Powder Amount Calibration failed.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falló la calibración de la flujo de pintura."])},
        "has a deviation above 20%.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tiene una desviación superior al 20%."])},
        "Consider reviewing the state of the guns.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Considere revisar el estado de las pistolas."])},
        "Powder Amount Calibration Created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibración de la flujo de pintura creada"])},
        "was saved successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["se guardó con éxito"])},
        "Invalid Value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor inválido"])},
        "Please enter a positive value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, introduzca un valor positivo"])},
        "Full Bag Weight cannot be less than Empty Bag Weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El peso de la bolsa llena no puede ser menor que el peso de la bolsa vacía"])},
        "Do you want to create it anyway?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Desea crearlo de todos modos?"])},
        "Yes, create it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sí, créalo"])},
        "No, cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No, cancelar"])}
      },
      "FR": {
        "Powder Amount Calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibration de la quantité de poudre"])},
        "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
        "Number of Measures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de mesures"])},
        "Please select one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez en sélectionner un"])},
        "Time Interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intervalle de temps"])},
        "Min Powder Amount Param": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètre de quantité de poudre minimale"])},
        "Max Powder Amount Param": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètre de quantité de poudre maximale"])},
        "Select Powder Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez le modèle de poudre"])},
        "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["optionnel"])},
        "Not Specified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non spécifié"])},
        "Calibration Steps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Étapes de calibration"])},
        "Prepare the Vacuum Bags:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Préparez les sacs sous vide :"])},
        "Select a vacuum bag for each gun.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez un sac sous vide pour chaque arme."])},
        "Weigh the empty vacuum bag and record its weight.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesez le sac sous vide vide et enregistrez son poids."])},
        "Set Up the Machine:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurer la machine :"])},
        "Attach the vacuum bag to the tip of the gun.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixez le sac sous vide à l'extrémité de l'arme."])},
        "Turn on the machine using the specified setting for the defined time interval.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allumez la machine en utilisant le réglage spécifié pour l'intervalle de temps défini."])},
        "Weigh the Bags:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesez les sacs :"])},
        "After the machine runs for the set time, weigh each vacuum bag again.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Après que la machine a fonctionné pendant le temps défini, pesez à nouveau chaque sac sous vide."])},
        "Record the new weight of each bag.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrez le nouveau poids de chaque sac."])},
        "Repeat for Different Parameters:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Répétez pour différents paramètres :"])},
        "Empty the vacuum bags before proceeding to the next parameter.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Videz les sacs sous vide avant de passer au paramètre suivant."])},
        "Repeat the entire process for each new parameter setting.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Répétez l'ensemble du processus pour chaque nouvelle configuration de paramètres."])},
        "Set your machine's powder amount setting to:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réglez la quantité de poudre de votre machine sur :"])},
        "Gun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arme"])},
        "Empty Bag Weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poids du sac vide"])},
        "Full Bag Weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poids du sac plein"])},
        "Back to Powder Amount Param": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour au paramètre de quantité de poudre"])},
        "Continue to Powder Amount Param": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer au paramètre de quantité de poudre"])},
        "Save Powder Amount Calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer la calibration de la quantité de poudre"])},
        "Missing Required Fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Champs obligatoires manquants"])},
        "Measurement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesure"])},
        "The following fields are required:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les champs suivants sont obligatoires :"])},
        "Guns in Improper State": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Armes en mauvais état"])},
        "Powder Amount Calibration failed.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec de la calibration de la quantité de poudre."])},
        "has a deviation above 20%.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a une déviation supérieure à 20%."])},
        "Consider reviewing the state of the guns.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envisagez de revoir l'état des armes."])},
        "Powder Amount Calibration Created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibration de la quantité de poudre créée"])},
        "was saved successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a été enregistrée avec succès"])},
        "Invalid Value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur invalide"])},
        "Please enter a positive value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez entrer une valeur positive"])},
        "Full Bag Weight cannot be less than Empty Bag Weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le poids du sac plein ne peut pas être inférieur au poids du sac vide"])},
        "Do you want to create it anyway?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous le créer quand même?"])},
        "Yes, create it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui, créez-le"])},
        "No, cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non, annuler"])}
      },
      "IT": {
        "Powder Amount Calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibrazione della quantità di polvere"])},
        "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
        "Number of Measures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di misure"])},
        "Please select one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona uno"])},
        "Time Interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intervallo di tempo"])},
        "Min Powder Amount Param": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametro minimo della quantità di polvere"])},
        "Max Powder Amount Param": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametro massimo della quantità di polvere"])},
        "Select Powder Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona il modello di polvere"])},
        "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["opzionale"])},
        "Not Specified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non specificato"])},
        "Calibration Steps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passaggi di calibrazione"])},
        "Prepare the Vacuum Bags:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prepara i sacchetti sottovuoto:"])},
        "Select a vacuum bag for each gun.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona un sacchetto sottovuoto per ogni pistola."])},
        "Weigh the empty vacuum bag and record its weight.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesare il sacchetto sottovuoto vuoto e registrare il suo peso."])},
        "Set Up the Machine:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configura la macchina:"])},
        "Attach the vacuum bag to the tip of the gun.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attacca il sacchetto sottovuoto all'estremità della pistola."])},
        "Turn on the machine using the specified setting for the defined time interval.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accendi la macchina utilizzando l'impostazione specificata per l'intervallo di tempo definito."])},
        "Weigh the Bags:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesare i sacchetti:"])},
        "After the machine runs for the set time, weigh each vacuum bag again.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dopo che la macchina è stata in funzione per il tempo stabilito, pesare nuovamente ciascun sacchetto sottovuoto."])},
        "Record the new weight of each bag.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registra il nuovo peso di ciascun sacchetto."])},
        "Repeat for Different Parameters:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ripeti per diversi parametri:"])},
        "Empty the vacuum bags before proceeding to the next parameter.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svuota i sacchetti sottovuoto prima di passare al parametro successivo."])},
        "Repeat the entire process for each new parameter setting.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ripeti l'intero processo per ciascuna nuova impostazione del parametro."])},
        "Set your machine's powder amount setting to:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imposta la quantità di polvere della tua macchina su:"])},
        "Gun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pistola"])},
        "Empty Bag Weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peso del sacchetto vuoto"])},
        "Full Bag Weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peso del sacchetto pieno"])},
        "Back to Powder Amount Param": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Torna al parametro della quantità di polvere"])},
        "Continue to Powder Amount Param": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continua al parametro della quantità di polvere"])},
        "Save Powder Amount Calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva la calibrazione della quantità di polvere"])},
        "Missing Required Fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campi obbligatori mancanti"])},
        "Measurement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Misura"])},
        "The following fields are required:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I seguenti campi sono obbligatori:"])},
        "Guns in Improper State": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Armi in stato improprio"])},
        "Powder Amount Calibration failed.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibrazione della quantità di polvere fallita."])},
        "has a deviation above 20%.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ha una deviazione superiore al 20%."])},
        "Consider reviewing the state of the guns.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Considera di rivedere lo stato delle armi."])},
        "Powder Amount Calibration Created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibrazione della quantità di polvere creata"])},
        "was saved successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["è stato salvato con successo"])},
        "Invalid Value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valore non valido"])},
        "Please enter a positive value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si prega di inserire un valore positivo"])},
        "Full Bag Weight cannot be less than Empty Bag Weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il peso del sacchetto pieno non può essere inferiore al peso del sacchetto vuoto"])},
        "Do you want to create it anyway?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vuoi crearlo comunque?"])},
        "Yes, create it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sì, crealo"])},
        "No, cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No, annulla"])}
      },
      "PL": {
        "Powder Amount Calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibracja ilości proszku"])},
        "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa"])},
        "Number of Measures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba pomiarów"])},
        "Please select one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz jedno"])},
        "Time Interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interwał czasu"])},
        "Min Powder Amount Param": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimalny parametr ilości proszku"])},
        "Max Powder Amount Param": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksymalny parametr ilości proszku"])},
        "Select Powder Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz model proszku"])},
        "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["opcjonalne"])},
        "Not Specified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie określono"])},
        "Calibration Steps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kroki kalibracji"])},
        "Prepare the Vacuum Bags:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przygotuj worki próżniowe:"])},
        "Select a vacuum bag for each gun.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz worek próżniowy dla każdej broni."])},
        "Weigh the empty vacuum bag and record its weight.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zważ pusty worek próżniowy i zapisz jego wagę."])},
        "Set Up the Machine:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skonfiguruj maszynę:"])},
        "Attach the vacuum bag to the tip of the gun.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przymocuj worek próżniowy do końca broni."])},
        "Turn on the machine using the specified setting for the defined time interval.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Włącz maszynę, korzystając z określonego ustawienia dla zdefiniowanego interwału czasu."])},
        "Weigh the Bags:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zważ worki:"])},
        "After the machine runs for the set time, weigh each vacuum bag again.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Po upływie ustalonego czasu pracy maszyny zważ ponownie każdy worek próżniowy."])},
        "Record the new weight of each bag.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz nową wagę każdego worka."])},
        "Repeat for Different Parameters:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powtórz dla różnych parametrów:"])},
        "Empty the vacuum bags before proceeding to the next parameter.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opróżnij worki próżniowe przed przejściem do następnego parametru."])},
        "Repeat the entire process for each new parameter setting.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powtórz cały proces dla każdej nowej konfiguracji parametrów."])},
        "Set your machine's powder amount setting to:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ustaw ilość proszku w maszynie na:"])},
        "Gun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Broń"])},
        "Empty Bag Weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waga pustego worka"])},
        "Full Bag Weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waga pełnego worka"])},
        "Back to Powder Amount Param": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powrót do parametru ilości proszku"])},
        "Continue to Powder Amount Param": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontynuuj do parametru ilości proszku"])},
        "Save Powder Amount Calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz kalibrację ilości proszku"])},
        "Missing Required Fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brakujące wymagane pola"])},
        "Measurement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pomiar"])},
        "The following fields are required:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Następujące pola są wymagane:"])},
        "Guns in Improper State": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Broń w niewłaściwym stanie"])},
        "Powder Amount Calibration failed.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibracja ilości proszku nie powiodła się."])},
        "has a deviation above 20%.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ma odchylenie powyżej 20%."])},
        "Consider reviewing the state of the guns.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rozważ ponowne sprawdzenie stanu broni."])},
        "Powder Amount Calibration Created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utworzono kalibrację ilości proszku"])},
        "was saved successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["został pomyślnie zapisany"])},
        "Invalid Value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieprawidłowa wartość"])},
        "Please enter a positive value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proszę podać wartość dodatnią"])},
        "Full Bag Weight cannot be less than Empty Bag Weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waga pełnego worka nie może być mniejsza niż waga pustego worka"])},
        "Do you want to create it anyway?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czy chcesz je utworzyć mimo to?"])},
        "Yes, create it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tak, utwórz"])},
        "No, cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie, anuluj"])}
      },
      "TR": {
        "Powder Amount Calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toz Miktarı Kalibrasyonu"])},
        "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad"])},
        "Number of Measures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ölçü Sayısı"])},
        "Please select one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen birini seçin"])},
        "Time Interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaman Aralığı"])},
        "Min Powder Amount Param": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min Toz Miktarı Parametresi"])},
        "Max Powder Amount Param": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maks Toz Miktarı Parametresi"])},
        "Select Powder Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toz Modeli Seç"])},
        "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["isteğe bağlı"])},
        "Not Specified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belirtilmedi"])},
        "Calibration Steps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrasyon Adımları"])},
        "Prepare the Vacuum Bags:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vakum Torbalarını Hazırla:"])},
        "Select a vacuum bag for each gun.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Her tabanca için bir vakum torbası seçin."])},
        "Weigh the empty vacuum bag and record its weight.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boş vakum torbasını tartın ve ağırlığını kaydedin."])},
        "Set Up the Machine:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Makineyi Kur:"])},
        "Attach the vacuum bag to the tip of the gun.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vakum torbasını tabancanın ucuna takın."])},
        "Turn on the machine using the specified setting for the defined time interval.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belirtilen ayarları kullanarak makineyi belirlenen zaman aralığında açın."])},
        "Weigh the Bags:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Torbaları Tartın:"])},
        "After the machine runs for the set time, weigh each vacuum bag again.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Makine belirlenen süre çalıştıktan sonra her vakum torbasını tekrar tartın."])},
        "Record the new weight of each bag.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Her torbanın yeni ağırlığını kaydedin."])},
        "Repeat for Different Parameters:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Farklı Parametreler İçin Tekrarlayın:"])},
        "Empty the vacuum bags before proceeding to the next parameter.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir sonraki parametreye geçmeden önce vakum torbalarını boşaltın."])},
        "Repeat the entire process for each new parameter setting.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Her yeni parametre ayarı için tüm süreci tekrarlayın."])},
        "Set your machine's powder amount setting to:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Makinenizin toz miktarı ayarını şuna ayarlayın:"])},
        "Gun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabanca"])},
        "Empty Bag Weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boş Torba Ağırlığı"])},
        "Full Bag Weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dolu Torba Ağırlığı"])},
        "Back to Powder Amount Param": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toz Miktarı Parametresine Geri Dön"])},
        "Continue to Powder Amount Param": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toz Miktarı Parametresine Devam Et"])},
        "Save Powder Amount Calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toz Miktarı Kalibrasyonunu Kaydet"])},
        "Missing Required Fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eksik Gerekli Alanlar"])},
        "Measurement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ölçüm"])},
        "The following fields are required:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aşağıdaki alanlar gereklidir:"])},
        "Guns in Improper State": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabancalar Uygun Olmayan Durumda"])},
        "Powder Amount Calibration failed.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toz Miktarı Kalibrasyonu başarısız oldu."])},
        "has a deviation above 20%.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["20%'nin üzerinde bir sapma var."])},
        "Consider reviewing the state of the guns.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabancaların durumunu gözden geçirmeyi düşünün."])},
        "Powder Amount Calibration Created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toz Miktarı Kalibrasyonu Oluşturuldu"])},
        "was saved successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["başarıyla kaydedildi"])},
        "Invalid Value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geçersiz Değer"])},
        "Please enter a positive value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen pozitif bir değer girin"])},
        "Full Bag Weight cannot be less than Empty Bag Weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dolu Torba Ağırlığı Boş Torba Ağırlığından küçük olamaz"])},
        "Do you want to create it anyway?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yine de oluşturmak ister misiniz?"])},
        "Yes, create it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evet, oluştur"])},
        "No, cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hayır, iptal"])}
      },
      "ZH": {
        "Powder Amount Calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["粉量校准"])},
        "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名称"])},
        "Number of Measures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["测量次数"])},
        "Please select one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请选择一个"])},
        "Time Interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["时间间隔"])},
        "Min Powder Amount Param": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最小粉量参数"])},
        "Max Powder Amount Param": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最大粉量参数"])},
        "Select Powder Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择粉末模型"])},
        "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可选"])},
        "Not Specified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未指定"])},
        "Calibration Steps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["校准步骤"])},
        "Prepare the Vacuum Bags:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["准备真空袋："])},
        "Select a vacuum bag for each gun.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["为每支枪选择一个真空袋。"])},
        "Weigh the empty vacuum bag and record its weight.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["称量空真空袋并记录其重量。"])},
        "Set Up the Machine:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["设置机器："])},
        "Attach the vacuum bag to the tip of the gun.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将真空袋连接到枪的尖端。"])},
        "Turn on the machine using the specified setting for the defined time interval.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用定义的时间间隔的指定设置打开机器。"])},
        "Weigh the Bags:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["称量袋子："])},
        "After the machine runs for the set time, weigh each vacuum bag again.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["机器运行一段时间后，再次称量每个真空袋。"])},
        "Record the new weight of each bag.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["记录每个袋子的新重量。"])},
        "Repeat for Different Parameters:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重复不同参数："])},
        "Empty the vacuum bags before proceeding to the next parameter.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在继续下一个参数之前清空真空袋。"])},
        "Repeat the entire process for each new parameter setting.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["为每个新参数设置重复整个过程。"])},
        "Set your machine's powder amount setting to:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将机器的粉量设置为："])},
        "Gun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["枪"])},
        "Empty Bag Weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["空袋重量"])},
        "Full Bag Weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["满袋重量"])},
        "Back to Powder Amount Param": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返回到粉量参数"])},
        "Continue to Powder Amount Param": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["继续到粉量参数"])},
        "Save Powder Amount Calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存粉量校准"])},
        "Missing Required Fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["缺少必填字段"])},
        "Measurement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["测量"])},
        "The following fields are required:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以下字段是必需的："])},
        "Guns in Improper State": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["枪支状态不当"])},
        "Powder Amount Calibration failed.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["粉量校准失败。"])},
        "has a deviation above 20%.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["偏差超过20%。"])},
        "Consider reviewing the state of the guns.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["考虑审查枪支的状态。"])},
        "Powder Amount Calibration Created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["粉量校准已创建"])},
        "was saved successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已成功保存"])},
        "Invalid Value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无效值"])},
        "Please enter a positive value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入正值"])},
        "Full Bag Weight cannot be less than Empty Bag Weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["满袋重量不能小于空袋重量"])},
        "Do you want to create it anyway?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您仍要创建吗？"])},
        "Yes, create it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是的，创建"])},
        "No, cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不，取消"])}
      }
    }
  })
}
