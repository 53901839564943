<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="310" height="100" viewBox="0 0 310 100">
      <g transform="translate(0.000000,100.000000) scale(0.100000,-0.100000)"
      :fill="fillColor" stroke="none">
      <path d="M218 591 c-15 -15 -18 -37 -18 -149 0 -71 3 -137 6 -146 6 -14 65
        -16 570 -16 l564 0 0 101 0 101 -66 64 -65 64 -487 -1 c-453 0 -488 -1 -504
        -18z"/>
      <path d="M1370 386 l0 -106 205 0 c113 0 205 4 205 9 0 5 9 11 20 14 18 5 20
        13 20 79 0 40 -5 80 -10 88 -8 12 -46 16 -225 18 l-215 3 0 -105z"/>
      <circle cx="2365" cy="700" r="50" />
      <circle cx="2170" cy="550" r="50" />
      <circle cx="2565" cy="550" r="50" />
      <circle cx="2450" cy="400" r="50" />
      <circle cx="2050" cy="400" r="50" />
      <circle cx="2280" cy="400" r="50" />
      <circle cx="2170" cy="250" r="50" />
      <circle cx="2570" cy="250" r="50" />
      <circle cx="2370" cy="100" r="50" />
      </g>
    </svg>
  </template>
  
  <script>
  export default {
    name: 'GunSvg',
    props: {
      fillColor: {
        type: String,
        default: '#707070'
      }
    }
  };
  </script>