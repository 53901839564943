<template>
  <div class="py-5 container-fluid">
    <div id="topinfo">
      <div class="row mt-4 justify-content-center">
        <div class="col-12">
          <div class="mx-auto mx-auto col-xl-9 col-lg-10 col-md-11 col-sm-12 text-start mt-3 mb-0">
            <button
              class="btn btn-outline-secondary btn-sm px-3 mt-1 mb-3"
              @click="$router.push(`/applications/blueprint/linevisits/${lineId}`)"
            >
              <font-awesome-icon :icon="['fas', 'arrow-rotate-left']" />
              Go back to workflows
            </button>
          </div>
          <div
            class="mx-auto col-xl-9 col-lg-10 col-md-11 col-sm-12 d-flex flex-column card card-body blur shadow-blur"
          >
            <div class="card-header text-center">
              <h4 class="font-weight-bolder">Guns Equalization</h4>
            </div>
            <div
              class="card-body mb-0 pb-3"
              style="padding-top: 0.5rem"
            >
              <div class="row">
                <div class="row mb-3">
                  <h5>Powder Amount Parameters</h5>
                  <div class="row mb-3">
                    <div class="col-3">
                      <label> Min </label>
                      <input
                        v-model="minPowderAmountSetting"
                        type="number"
                        class="form-control my-2"
                        placeholder="MIN"
                        @keydown="numericOnly"
                        @change="saveFormProgress(); getPowderAmountCalibrationStatistic();"
                      />
                    </div>
                    <div class="col-3">
                      <label> Max </label>
                      <input
                        v-model="maxPowderAmountSetting"
                        type="number"
                        class="form-control my-2"
                        placeholder="MAX"
                        @keydown="numericOnly"
                        @change="saveFormProgress"
                      />
                    </div>
                    <div class="col-3">
                      <label> Time Interval [s]</label>
                      <input
                        v-model="timeInterval"
                        type="number"
                        min="15"
                        class="form-control my-2"
                        placeholder="TIME INTERVAL"
                        @keydown="numericOnly"
                        @change="saveFormProgress"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <h5 class="mt-2">Line Layout</h5>
                <canvas
                    id="lineLayoutCanvas"
                    class="col-12 mt-0 mb-4 justify-content-center"
                    width="700"
                    height="300"
                    :style="`max-width: 700px; ${line.id !== null ? '' : 'display: none;'}`"
                  >
                </canvas>
              </div>
              <div class="row">
                <h5 class="text-start">Powder Output Measurements</h5>
                <div style="overflow-x: auto">
                  <div class="table-responsive">
                    <table
                      class="table table-sm text-start"
                      style="border-width: 0 !important"
                    >
                      <thead>
                        <tr>
                          <th>Gun</th>
                          <th>Empty bag weight</th>
                          <th
                            v-for="index in maxPairs"
                            :key="'header-' + index"
                          >
                            Setting {{ index }} Weight {{ index }}
                          </th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(gun, gunIndex) in powderoutput_measurements"
                          :key="gunIndex"
                        >
                          <td>
                            {{ gunIndex + 1 }}
                          </td>
                          <td>
                            <input
                              v-model="gun.empty_bag_weight"
                              type="number"
                              class="form-control form-control-sm"
                              style="max-width: 60px; "
                              @change="saveFormProgress"
                            />
                          </td>
                          <td
                            v-for="index in maxPairs"
                            :key="'pair-' + gunIndex + '-' + index"
                          >
                            <div
                              v-if="gun.gun_measurements[index - 1]"
                              class="d-flex"
                            >
                              <input
                                v-model="gun.gun_measurements[index - 1].setting"
                                type="number"
                                :class="`form-control ${
                                  gun.gun_measurements[index - 1].setting != '' &&
                                  (gun.gun_measurements[index - 1].setting < minPowderAmountSetting ||
                                    gun.gun_measurements[index - 1].setting > maxPowderAmountSetting)
                                    ? 'is-invalid'
                                    : ''
                                } form-control-sm me-1`"
                                placeholder="Setting"
                                style="width: 60px"
                                @change="saveFormProgress"
                                @blur="PowderAmountSettingInputRangeCheck($event.target.value)"
                              />
                              <input
                                v-model="gun.gun_measurements[index - 1].weight"
                                type="number"
                                class="form-control form-control-sm me-1"
                                placeholder="Weight"
                                style="width: 60px"
                                @change="saveFormProgress"
                              />
                            </div>
                          </td>
                          <td>
                            <div class="d-flex">
                              <button
                                class="btn btn-success"
                                @click="addPair(gunIndex)"
                              >
                                Add
                              </button>
                              <div class="col-1"></div>
                              <button
                                v-if="gun.gun_measurements.length > 2"
                                class="btn btn-danger"
                                @click="deletePair(gunIndex)"
                              >
                                Delete
                              </button>
                            </div>
                          </td>
                        </tr>
                        <td :colspan="maxPairs + 2">
                          <div class="text-center mt-0 mb-0">
                            <button
                              class="btn btn-primary"
                              @click="showGunsEqualizationGraph"
                            >
                              Show Powder Output Chart
                            </button>
                          </div>
                        </td>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div
                v-if="!emptyPowderAmountMeasurements && showPowderThroughputData"
                class="row"
              >
                <div class="col-lg-7">
                  <h5 class="">Powder Output Chart</h5>
                  <guns-equalization-chart />
                </div>
                <div class="col-lg-5">
                  <div class="card text-bolder mt-6 text-center">
                    Statistics of current throughput per gun:
                    <p class="mt-4">Min throughput: {{ original_powder_throughput_statistics.min_value }} {{ `[${$store.state.units_system[$store.state.user_data.unit_system].grams}/min]` }}</p>
                    <p>Max throughput: {{ original_powder_throughput_statistics.max_value }} {{ `[${$store.state.units_system[$store.state.user_data.unit_system].grams}/min]` }}</p>
                    <p>Max throughput difference: {{  original_powder_throughput_statistics.diff_value }} {{ `[${$store.state.units_system[$store.state.user_data.unit_system].grams}/min]` }}</p>
                    <p>Throughput std dev: {{ original_powder_throughput_statistics.std_value }} {{ `[${$store.state.units_system[$store.state.user_data.unit_system].grams}/min]` }}</p>
                  </div>
                </div>
              </div>
              <div v-if="emptyBenchmarkPowderAmounts">
                To see equalized Powder Amount settings, first provide Powder Amount settings for each gun at Benchmark
                Stage.
              </div>
              <div v-if="!emptyBenchmarkPowderAmounts && emptyPowderAmountMeasurements">
                To see equalized Powder Amount settings, first provide Powder Output Measurements above.
              </div>
              <div v-if="!emptyBenchmarkPowderAmounts && !emptyPowderAmountMeasurements && showPowderThroughputData">
                <gun-throughput
                  title="Guns Equalization"
                  :gun-throughput="gunExpectedOutput"
                  :line="line"
                  :powder-amount-parameters="powder_amount_parameters"
                />
              </div>
              <div class="row mt-4">
                <div class="col-lg-6">
                  <h5>
                    Thickness Measurements [{{
                      this.$store.state.units_system[$store.state.user_data.unit_system].thickness
                    }}]
                  </h5>
                  <div class="table-responsive">
                    <table class="table table-sm text-center text-xs">
                      <thead>
                        <tr>
                          <th>Measurement</th>
                          <th>Thickness</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(_, index) in thickness_measurements"
                          :key="index"
                        >
                          <td>{{ index + 1 }}</td>
                          <td>
                            <input
                              v-model="thickness_measurements[index]"
                              type="number"
                              class="form-control text-center"
                              style="width: 50%; margin: 0 auto"
                              min="0"
                              @input="
                                checkLastRow();
                                saveFormProgress();
                              "
                              @keydown="numericOnly"
                            />
                          </td>
                        </tr>
                        <td colspan="2">
                          <div class="text-center mt-0 mb-0">
                            <button
                              class="btn btn-primary"
                              @click="showThicknessGraph()"
                            >
                              Show thickness distribution chart
                            </button>
                          </div>
                        </td>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="col-lg-6">
                  <gaussian-chart
                    title="Thickness Distribution Chart"
                    chart-name="gaussianGunsEqualizationChart"
                  />
                  <thickness-measurement-statistic
                    :benchmark-thickness-measurements="benchmark_stage_data.thickness_measurements"
                    :guns-equalization-thickness-measurements="statistics_thickness_measurements"
                  />
                </div>
              </div>
              <div class="col-12 text-center mt-4">
                <button
                  v-if="show_save_bttn"
                  type="button"
                  class="mt-4 mb-2 text-center btn"
                  :class="show_save_bttn ? 'bg-gradient-success' : 'bg-gradient-secondary'"
                  style="width: 90%"
                  @click="changesSavedSwal()"
                >
                  Save changes
                </button>
                <div
                  v-if="!show_save_bttn"
                  class="col-12 text-end mt-5"
                >
                  <soft-button
                    type="button"
                    color="success"
                    variant="gradient"
                    class="mb-0 ms-auto js-btn-next w-40"
                    style="max-width: 300px;"
                    title="Continue to guns equalization"
                    @click="$router.push('/applications/blueprint/powdermodels/' + lineId + '/' + visitId)"
                  >
                    Continue to Spray Pattern &nbsp;
                    <font-awesome-icon
                      :icon="['fas', 'circle-chevron-right']"
                      size="lg"
                    />
                  </soft-button>
                </div>
              </div>
            </div>
          </div>
          <stepper-line-visit
            :line-id="lineId"
            :visit="visit"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import setTooltip from "@/assets/js/tooltip.js";
import axios from "axios";
import eventBus from "./utils/eventBus";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { nextTick } from "vue";
import { faArrowRotateLeft, faCircleChevronRight } from "@fortawesome/free-solid-svg-icons";

import {
  calculateMu,
  calculateSigma,
  gaussian,
  generateX,
  generateXforMultipleMuSigma,
  generateY,
  generateYwithXvalues,
} from "./utils/gaussianchart";
import {
  useGetVisit,
  useGetBenchmark,
  getStdDifferencePercentage,
  measurementsMax,
  measurementsMin,
  numericOnly,
  generateMeasurementList,
} from "./utils/utils";
import { useGetLine } from "@/views/composables.js";
import GaussianChart from "./components/GaussianChart.vue";
import GunsEqualizationChart from "./components/GunsEqualizationChart.vue";
import GunThroughput from "./components/GunThroughput.vue";
import SoftButton from "../../../components/SoftButton.vue";
import StepperLineVisit from "./components/StepperLineVisit.vue";
import ThicknessMeasurementStatistic from "./components/ThicknessMeasurementStatistic.vue";

library.add([faArrowRotateLeft, faCircleChevronRight]);

export default {
  name: "GunsEqualization",
  components: {
    StepperLineVisit,
    GaussianChart,
    GunsEqualizationChart,
    GunThroughput,
    ThicknessMeasurementStatistic,
    SoftButton,
    FontAwesomeIcon,
  },
  props: {
    lineId: {
      type: String,
      default: "",
    },
    visitId: {
      type: String,
      default: "",
    },
  },
  setup() {
    const { line, getLine } = useGetLine();
    const { visit, getVisit } = useGetVisit();
    const { benchmark_stage_data, getBenchmarkData } = useGetBenchmark();
    return { visit, getVisit, benchmark_stage_data, getBenchmarkData, line, getLine };
  },
  data() {
    this.$i18n.locale = this.$store.state.user_data.language;

    return {
      original_powder_throughput_statistics: {
        min_value: null,
        max_value: null,
        diff_value: null,
        std_value: null,
      },
      powderoutput_measurements: [
        {
          empty_bag_weight: null,
          gun_measurements: [
            {
              setting: "",
              weight: "",
            },
            {
              setting: "",
              weight: "",
            },
            {
              setting: "",
              weight: "",
            },
          ],
        },
      ],
      showPowderThroughputData: false,
      showThicknessMeasurementStatistic: false,
      minPowderAmountSetting: null,
      maxPowderAmountSetting: null,
      min_powder_throughput: null,
      timeInterval: 60,
      thickness_measurements: [null, null, null, null, null],
      statistics_thickness_measurements: [null, null, null, null, null],
      show_save_bttn: false,
      received_gunsequalization_data: {
        min_powder_amount_setting: null,
        max_powder_amount_setting: null,
        powderoutput_measurements: null,
        thickness_measurements: Array(5).fill(null),
      },
      max_metric_decimals: 0,
      max_imperial_decimals: 2,
      powder_amount_calibrations: [],
      powder_amount_calibration_measures: null,
      reference_gun_index: null,
      selected_guns_for_powder_amount: [],
      expected_powder_per_minute: null,
      powder_amount_parameters: [],
      selected_powder_amount_calibration: null,
      canvas_width: null,
      canvas_height: null,
      ctx_line_layout: null,
      line_layout_image: [],
    };
  },
  computed: {
    gunExpectedOutput: {
      get() {
        if (this.expected_powder_per_minute == null || this.expected_powder_per_minute == "") {
          return 0;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (this.expected_powder_per_minute * this.$store.state.conversion_factors.g_to_oz).toFixed(2),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(this.expected_powder_per_minute.toFixed(0));
        } else {
          return this.expected_powder_per_minute;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.expected_powder_per_minute = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.expected_powder_per_minute = value / this.$store.state.conversion_factors.g_to_oz;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.expected_powder_per_minute = value;
          }
        }
      },
    },
    maxPairs() {
      let maxPairs = 0;
      this.powderoutput_measurements.forEach(gun => {
        if (gun.gun_measurements.length > maxPairs) {
          maxPairs = gun.gun_measurements.length;
        }
      });
      return maxPairs;
    },
    emptyBenchmarkPowderAmounts() {
      if (this.benchmark_stage_data.gun_settings == null) {
        return true;
      }
      const powderAmountSettings = this.benchmark_stage_data.gun_settings.map(item => item.powder);
      const emptyPowderAmounts = powderAmountSettings.some(
        powder_amount_setting => powder_amount_setting == "" || powder_amount_setting == null,
      );
      return emptyPowderAmounts;
    },
    emptyPowderAmountMeasurements() {
      const empty_powder_amount_measurements = this.powderoutput_measurements.some(gun =>
        gun.gun_measurements.some(pair => pair.setting == "" || pair.weight == ""),
      );
      return empty_powder_amount_measurements;
    },
    totalLineGuns() {
      return this.line.total_pistols.reduce((a, b) => a + b, 0);
    },
  },
  mounted() {
    var lineLayoutCanvas = document.getElementById("lineLayoutCanvas");
    this.canvas_width = lineLayoutCanvas.width;
    this.canvas_height = lineLayoutCanvas.height;
    this.ctx_line_layout = lineLayoutCanvas.getContext("2d");

    this.$store.state.isAbsolute = true;
    setTooltip(this.$store.state.bootstrap);
    this.getData();
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
  },
  methods: {
    generateX,
    gaussian,
    generateY,
    calculateMu,
    calculateSigma,
    generateXforMultipleMuSigma,
    generateYwithXvalues,
    getStdDifferencePercentage,
    measurementsMin,
    measurementsMax,
    numericOnly,
    generateMeasurementList,
    async getData() {
      await this.getVisit(this.visitId);
      await this.getLine(this.lineId);
      await this.getBenchmarkData(this.visitId, this.totalLineGuns);

      this.powderoutput_measurements = this.generateGuns(this.totalLineGuns);

      await this.getGunEqualizationData();
      await this.getPowderAmountCalibrationStatistic();
      await this.showGunsEqualizationGraph();

      await this.getLineLayoutImage();
      this.fillLineLayoutCanvas();

      this.showThicknessGraph();
    },
    generateGuns(total_guns) {
      return Array.from({ length: total_guns }, () => ({
        empty_bag_weight: null,
        gun_measurements: [
          {
            setting: "",
            weight: "",
          },
          {
            setting: "",
            weight: "",
          },
          {
            setting: "",
            weight: "",
          },
        ],
      }));
    },
    async getGunsEqualizationChartDataset() {
      let body = {
        min_powder_amount_setting: this.minPowderAmountSetting,
        max_powder_amount_setting: this.maxPowderAmountSetting,
        time_interval: this.timeInterval,
        powderoutput_measurements: this.powderoutput_measurements.map(gun => ({
          ...gun,
          empty_bag_weight: gun.empty_bag_weight == null || gun.empty_bag_weight === "" ? 0 : gun.empty_bag_weight,
        })),
        cumulative_powderoutput_measurements: true,
      };

      let response = await axios.post("/api/v1/blueprint/gunsequalizationchart/", body);

      this.$store.state.gunsEqualizationChart = response.data.powderoutput_measurements;
    },
    async getPowderAmountCalibrationStatistic() {
      if (this.emptyBenchmarkPowderAmounts || this.emptyPowderAmountMeasurements) {
        return;
      }

      try {
        let body = {
          time_interval: this.timeInterval,
          measures_list: this.generateMeasurementList(this.powderoutput_measurements),
          powder_amounts: this.visit.benchmarkstage.gun_settings.map(setting => parseFloat(setting.powder)),
          cumulative_powderoutput_measurements: true,
          min_powder_amount_setting: this.minPowderAmountSetting,
        };

        const response = await axios.post("/api/v1/fp/computepowderamountcalibrationstatistic/", body);

        this.original_powder_throughput_statistics = response.data;
        this.expected_powder_per_minute = this.original_powder_throughput_statistics.mean_powder_throughput;
        this.min_powder_throughput = this.original_powder_throughput_statistics.min_powder_throughput;
        await this.saveFormProgress();
        this.show_save_bttn = false;

        this.powder_amount_calibrations = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    async computePowderAmountParameterResults() {
      if (this.emptyBenchmarkPowderAmounts || this.emptyPowderAmountMeasurements) {
        return;
      }
      try {
        let body = {
          line: this.lineId,
          time_interval: this.timeInterval,
          measures_list: this.generateMeasurementList(this.powderoutput_measurements),
          powder_per_minute: this.expected_powder_per_minute,
          gun_idx: null,
          powder_amount_param: null,
          cumulative_powderoutput_measurements: true,
        };

        let response = await axios.post("/api/v1/fp/computepowderamountcalibration/", body);
        this.powder_amount_parameters = response.data.powder_amount_params;
        this.roundPowderAmountParameters();
      } catch (error) {
        console.error(error);
      }
    },
    roundPowderAmountParameters() {
      this.powder_amount_parameters = this.powder_amount_parameters.map(param => {
        return parseFloat(param.toFixed(1));
      });
    },
    clearPowderAmountParameters() {
      this.powder_amount_parameters = Array.from({ length: this.totalLineGuns }, () => null);
      this.expected_powder_per_minute = null;
    },
    checkPowderThroughputAlert() {
      if (this.expected_powder_per_minute <= 0) {
        this.$swal({
          title: "Invalid Expected Throughput",
          text: "The expected throughput must be greater than 0.",
          icon: "error",
          confirmButtonText: "OK",
        });
        this.clearPowderAmountParameters();
      }
    },
    checkPowderAmountResultsRangeAlert() {
      let calibration_out_of_range_popup_shown = false;
      for (let index = 0; index < this.powder_amount_parameters.length; index++) {
        if (
          this.powder_amount_parameters[index] !== null &&
          this.powder_amount_parameters[index] !== "" &&
          (this.powder_amount_parameters[index] < this.minPowderAmountSetting ||
            this.powder_amount_parameters[index] > this.maxPowderAmountSetting)
        ) {
          if (!calibration_out_of_range_popup_shown) {
            this.$swal({
              title: "Calibration Out of Range",
              text: `${"The resulting Powder Amount setting is out of range for Gun"} ${index + 1}.\n Min: ${
                this.minPowderAmountSetting
              }, Max: ${this.maxPowderAmountSetting}.
              ${"Obtained Parameter"}: ${this.powder_amount_parameters[index]}`,
              icon: "error",
              confirmButtonText: "OK",
            });
            calibration_out_of_range_popup_shown = true;
          }
        }
      }
    },
    async getGunEqualizationData() {
      try {
        let response = await axios.get("/api/v1/blueprint/gunsequalization/" + this.visitId);

        if (response.data == null || response.data == undefined || response.status !== 200) {
          return;
        }

        this.received_gunsequalization_data.min_powder_amount_setting = response.data.min_powder_amount_setting;
        this.received_gunsequalization_data.max_powder_amount_setting = response.data.max_powder_amount_setting;

        this.received_gunsequalization_data.powderoutput_measurements = response.data.powder_amount_measurements;
        this.received_gunsequalization_data.thickness_measurements = response.data.thickness_measurements;

        this.minPowderAmountSetting = response.data.min_powder_amount_setting;
        this.maxPowderAmountSetting = response.data.max_powder_amount_setting;

        if (response.data.time_interval !== null) {
          this.timeInterval = response.data.time_interval;
        }

        if (response.data.powder_amount_measurements !== null) {
          this.powderoutput_measurements = response.data.powder_amount_measurements;
        }
        if (response.data.thickness_measurements !== null) {
          this.thickness_measurements = response.data.thickness_measurements;
          this.checkLastRow();
        }

        this.benchmark_stage_data = JSON.parse(JSON.stringify(this.benchmark_stage_data));

        if (response.data.powder_amount_measurements !== null && this.isPowderAmountMeasurementsFilled()) {
          await this.showGunsEqualizationGraph();
        }
      } catch (error) {
        console.error(error);
      }
      this.$store.state.isLoaded = false;
    },
    isPowderAmountMeasurementsFilled() {
      let total_empty_measures = this.powderoutput_measurements
        .map(powderoutput_measurement => powderoutput_measurement.gun_measurements)
        .filter(gun_measurment =>
          gun_measurment.some(
            gun_measurment =>
              gun_measurment.setting == "" ||
              gun_measurment.setting == null ||
              gun_measurment.weight == "" ||
              gun_measurment.weight == null,
          ),
        ).length;

      let is_powder_output_measurements_filled = total_empty_measures == 0;

      return is_powder_output_measurements_filled;
    },
    getFormProgressStatus() {
      const PROGRESS_STATUS_PENDING = "Pending";
      const PROGRESS_STATUS_IN_PROGRESS = "In Progress";
      const PROGRESS_STATUS_COMPLETED = "Completed";

      if (
        this.powderoutput_measurements
          .map(gun => gun.gun_measurements)
          .filter(gun_measurment =>
            gun_measurment.some(gun_measurment => gun_measurment.setting !== "" && gun_measurment.weight !== ""),
          ) &&
        this.thickness_measurements.filter(m => m !== null && m !== "") &&
        this.expected_powder_per_minute !== null
      ) {
        return PROGRESS_STATUS_COMPLETED;
      } else if (
        this.powderoutput_measurements
          .map(gun => gun.gun_measurements)
          .filter(gun_measurment =>
            gun_measurment.some(gun_measurment => gun_measurment.setting === "" && gun_measurment.weight === ""),
          ) ||
        this.thickness_measurements.filter(m => m === null && m === "") ||
        this.expected_powder_per_minute === null
      ) {
        return PROGRESS_STATUS_IN_PROGRESS;
      } else {
        return PROGRESS_STATUS_PENDING;
      }
    },
    async saveFormProgress() {
      this.show_save_bttn = true;
      const nonEmptyThicknessMeasurements = this.thickness_measurements.filter(m => m !== null && m !== "");
      const formData = {
        powder_amount_measurements: this.powderoutput_measurements,
        thickness_measurements: nonEmptyThicknessMeasurements,
        min_powder_throughput: this.min_powder_throughput,
        min_powder_amount_setting: this.minPowderAmountSetting ? this.minPowderAmountSetting : null,
        max_powder_amount_setting: this.maxPowderAmountSetting ? this.maxPowderAmountSetting : null,
        time_interval: this.timeInterval,
        last_average_powder_throughput_per_gun: this.expected_powder_per_minute,
        progress_status: this.getFormProgressStatus(),
      };

      try {
        await axios.patch("/api/v1/blueprint/gunsequalization/" + this.visitId + "/", formData);
      } catch (error) {
        console.error(error);
      }
    },
    PowderAmountSettingInputRangeCheck(target_value) {
      if (
        this.powderoutput_measurements.some(gun =>
          gun.gun_measurements.some(pair => pair.setting !== "" && pair.setting < this.minPowderAmountSetting),
        )
      ) {
        this.$swal({
          title: `Invalid Powder Output: ${target_value}<br>Value out of range.`,
          text: `Powder Output must be greater than the minimum allowed of ${this.minPowderAmountSetting}.`,
          icon: "error",
          confirmButtonText: "OK",
        }).then(() => this.removePowderMeasurementInput(target_value));
        return;
      } else if (
        this.powderoutput_measurements.some(gun =>
          gun.gun_measurements.some(pair => pair.setting !== "" && pair.setting > this.maxPowderAmountSetting),
        )
      ) {
        this.$swal({
          title: `Invalid Powder Output: ${target_value}<br>Value out of range.`,
          text: `Powder Output must be smaller than the maximum allowed of ${this.maxPowderAmountSetting}.`,
          icon: "error",
          confirmButtonText: "OK",
        }).then(() => this.removePowderMeasurementInput(target_value));

        return;
      }
    },
    removePowderMeasurementInput(target_value) {
      this.powderoutput_measurements.map(gun =>
        gun.gun_measurements.map(pair => {
          if (pair.setting == target_value) {
            pair.setting = "";
          }
        }),
      );
    },
    async changesSavedSwal() {
      try {
        this.show_save_bttn = false;
        await Promise.all([
          this.getVisit(this.visitId),
          this.getBenchmarkData(this.visitId, this.totalLineGuns),
          this.getGunEqualizationData(),
          this.getPowderAmountCalibrationStatistic(),
        ]);

        await this.computePowderAmountParameterResults();

        await this.$swal({
          title: "Changes saved",
          text: "Powder Output was saved successfully",
          icon: "success",
          confirmButtonText: "OK",
        });
      } catch (error) {
        console.error(error);
      }
    },
    checkLastRow() {
      while (this.thickness_measurements.length < 5) {
        this.thickness_measurements.push("");
      }

      if (this.thickness_measurements.every(m => m !== null && m !== "" && m !== 0)) {
        this.thickness_measurements.push("");
      } else if (
        (this.thickness_measurements[this.thickness_measurements.length - 1] == null ||
          this.thickness_measurements[this.thickness_measurements.length - 1] == "") &&
        (this.thickness_measurements[this.thickness_measurements.length - 2] == null ||
          this.thickness_measurements[this.thickness_measurements.length - 2] == "") &&
        this.thickness_measurements.length > 5
      ) {
        this.thickness_measurements.pop();
      }
    },
    thicknessMeasurementsUpdateChartData() {
      const mu = this.calculateMu(this.thickness_measurements);
      const sigma = this.calculateSigma(this.thickness_measurements, mu);

      this.$store.state.gaussianGunsEqualizationChart.labels = this.generateX(mu, sigma);

      const Xarray = this.$store.state.gaussianGunsEqualizationChart.labels.map(num => parseFloat(num));

      this.$store.state.minTargetThickness = Xarray.findIndex(
        num => num > this.benchmark_stage_data.minimum_target_thickness,
      );
      this.$store.state.maxTargetThickness = Xarray.findIndex(
        num => num > this.benchmark_stage_data.maximum_target_thickness,
      );

      this.$store.state.gaussianGunsEqualizationChart.datasets[0] = {
        label: "Guns Equalization",
        data: this.generateY(sigma, mu),
      };

      if (this.benchmark_stage_data.thickness_measurements !== null) {
        const benchmarkMu = this.calculateMu(this.benchmark_stage_data.thickness_measurements);
        const benchmarkSigma = this.calculateSigma(this.benchmark_stage_data.thickness_measurements, benchmarkMu);

        this.$store.state.gaussianGunsEqualizationChart.labels = this.generateXforMultipleMuSigma([
          [benchmarkMu, benchmarkSigma],
          [mu, sigma],
        ]);
        const Xarray = this.$store.state.gaussianGunsEqualizationChart.labels.map(num => parseFloat(num));

        this.$store.state.minTargetThickness = Xarray.findIndex(
          num => num > this.benchmark_stage_data.minimum_target_thickness,
        );
        this.$store.state.maxTargetThickness = Xarray.findIndex(
          num => num > this.benchmark_stage_data.maximum_target_thickness,
        );

        this.$store.state.gaussianGunsEqualizationChart.datasets[0] = {
          label: "Initial Benchmark",
          data: this.generateYwithXvalues(
            benchmarkSigma,
            benchmarkMu,
            this.$store.state.gaussianGunsEqualizationChart.labels,
          ),
        };

        this.$store.state.gaussianGunsEqualizationChart.datasets[1] = {
          label: "Guns Equalization",
          data: this.generateYwithXvalues(sigma, mu, this.$store.state.gaussianGunsEqualizationChart.labels),
        };
      }
    },
    addPair(gunIndex) {
      this.powderoutput_measurements[gunIndex].gun_measurements.push({ setting: "", weight: "" });
    },
    async deletePair(gunIndex) {
      this.powderoutput_measurements[gunIndex].gun_measurements.pop();
      await this.saveFormProgress();
    },
    async showGunsEqualizationGraph() {
      if (this.isPowderAmountMeasurementsFilled()) {
        this.showPowderThroughputData = true;
        await this.getGunsEqualizationChartDataset();

        await this.getPowderAmountCalibrationStatistic();
        await this.computePowderAmountParameterResults();
        eventBus.emit("draw-gunsequlization-chart");
      }
    },
    showThicknessGraph() {
      this.thicknessMeasurementsUpdateChartData();
      this.showThicknessMeasurementStatistic = true;
      this.statistics_thickness_measurements = JSON.parse(JSON.stringify(this.thickness_measurements));
      eventBus.emit("draw-gaussian-chart");
    },
    async getLineLayoutImage() {
      try {
        var body = {
          line: this.lineId,
          canvas_width: this.canvas_width,
          canvas_height: this.canvas_height,
        };
        var response = await axios.post("/api/v1/fp/computepowderamountlineimage/", body);
        this.line_layout_image = response.data["layout_image"];
      } catch (error) {
        console.error(error);
      }
    },
    fillLineLayoutCanvas() {
      nextTick(() => {
        var lineLayoutCanvas = document.getElementById("lineLayoutCanvas");
        this.canvas_width = lineLayoutCanvas.width;
        this.canvas_height = lineLayoutCanvas.height;
        this.ctx_line_layout = lineLayoutCanvas.getContext("2d");;
  
        let line_layout_imageData = new ImageData(Uint8ClampedArray.from(this.line_layout_image.values()), this.canvas_width, this.canvas_height);
        this.ctx_line_layout.putImageData(line_layout_imageData, 0, 0);
        });
    },
  },
};
</script>
<style scoped>
.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
  border-collapse: collapse; /* Ensure borders are collapsed */
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}

.table-border tbody tr:last-child td {
  border-width: 1px;
}

.container-fluid {
  padding-top: 20px;
}
</style>
