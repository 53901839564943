<template>
  <main class="mt-0 main-content main-content-bg">
    <div class="mt-4 mx-4">
      <a class="text-bold">{{ $t("Language") }}:&nbsp;</a>
        <select
          id="language-choices-category"
          v-model="this.$i18n.locale"
          name="language-choices"
          class="bg-transparent border-0 text-black"
          @change="changeLanguage()"
        >
        <option
          v-for="(language_option, key) in languages"
          :key="key"
          :value="key"
          class="text-dark"
        >
          <span class="d-sm-inline d-none"> {{ language_option.emoji }} {{ key }} - {{ language_option.name }} </span>
        </option>
      </select>
    </div>
    <section>
      <div class="page-header min-vh-75">
        <div class="container">
          <div class="row">
            <div class="px-5 col-xl-5 col-lg-6 col-md-8 col-12 d-flex flex-column">
              <div class="mt-8 card card-plain">
                <div class="pb-0 text-left card-header">
                  <h3 class="text-info text-gradient">{{ $t("Reset Password") }}</h3>
                  <p class="mb-0">
                    {{ $t("You are required to reset your password.") }}
                  </p>
                </div>
                <div class="pb-3 card-body">
                  <form role="form">
                    <label>{{ $t("New password") }}</label
                    >&nbsp;
                    <i
                      :class="showPassword ? 'fa fa-eye-slash' : 'fa fa-eye'"
                      style="font-size: 0.73em"
                      @click="showPassword = !showPassword"
                    >
                    </i>
                    <div class="mb-3">
                      <input
                        v-model="new_password"
                        :class="new_password_input_error ? `form-control is-invalid` : `form-control`"
                        :type="showPassword ? 'text' : 'password'"
                        :placeholder="$t('Enter new password')"
                        @input="new_password_input_error = false"
                      />
                    </div>
                    <label>{{ $t("Repeat new password") }}</label
                    >&nbsp;
                    <i
                      :class="showPassword ? 'fa fa-eye-slash' : 'fa fa-eye'"
                      style="font-size: 0.73em"
                      @click="showPassword = !showPassword"
                    >
                    </i>
                    <div class="mb-3">
                      <input
                        v-model="re_new_password"
                        :class="re_new_password_input_error ? `form-control is-invalid` : `form-control`"
                        :type="showPassword ? 'text' : 'password'"
                        :placeholder="$t('Repeat new password')"
                        @input="re_new_password_input_error = false"
                        @keypress.enter.prevent="ResetPassword"
                      />
                    </div>
                    <div class="text-center">
                      <button
                        class="w-100 mt-2 mb-0 btn bg-gradient-info ms-lg-auto me-lg-0 me-auto mt-lg-0"
                        type="button"
                        @click="ResetPassword"
                      >
                        {{ $t("Reset Password") }}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="top-0 oblique position-absolute h-100 d-md-block d-none me-n8">
                <div
                  class="bg-cover oblique-image position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6"
                  :style="{
                    backgroundImage: 'url(' + require('@/assets/img/curved-images/curved6.jpg') + ')',
                  }"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import axios from "axios";
import router from "@/router";
import { mapMutations } from "vuex";

const body = document.getElementsByTagName("body")[0];

export default {
  name: "ResetPasswordConfirmation",
  components: {},
  data() {
    this.$i18n.locale = this.$store.state.user_data.language;
    return {
      new_password: "",
      re_new_password: "",

      new_password_input_error: false,
      re_new_password_input_error: false,
      showPassword: false,
      languages: {
        EN: { name: "English", emoji: "🇬🇧" },
        DE: { name: "Deutsch", emoji: "🇩🇪" },
        ES: { name: "Español", emoji: "🇪🇸" },
        FR: { name: "Français", emoji: "🇫🇷" },
        IT: { name: "Italiano", emoji: "🇮🇹" },
        PL: { name: "Polski", emoji: "🇵🇱" },
        TR: { name: "Türkçe", emoji: "🇹🇷" },
        ZH: { name: "中文", emoji: "🇨🇳" },
      },
    };
  },
  watch: {
    "$store.state.user_data.language"() {
      this.$i18n.locale = this.$store.state.user_data.language;
    },
  },
  created() {
    this.$store.state.showSidenav = !this.$store.state.showSidenav;
    body.classList.remove("bg-gray-100");
    this.$store.state.isLoading = false;
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  beforeUnmount() {
    this.$store.state.showSidenav = !this.$store.state.showSidenav;
    body.classList.add("bg-gray-100");
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  mounted() {
    this.email = this.$store.state.user_data.email;
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    async ResetPassword() {
      let bad_form = false;

      if (this.new_password == null || this.new_password == "") {
        this.$swal({
          title: `${this.$t("New Password field is empty.")}`,
          text: `${this.$t("Please enter your new password.")}`,
          icon: "warning",
          confirmButtonText: "OK",
        });
        this.new_password_input_error = true;
        bad_form = true;
      } else if (this.re_new_password == null || this.re_new_password == "") {
        this.$swal({
          title: `${this.$t("Repeated New Password field is empty.")}`,
          text: `${this.$t("Please repeat your new password.")}`,
          icon: "warning",
          confirmButtonText: "OK",
        });
        this.re_new_password_input_error = true;
        bad_form = true;
      } else if (this.new_password != this.re_new_password) {
        this.$swal({
          title: `${this.$t("The repeated password does not match.")}`,
          text: `${this.$t("Please make sure the new password and repeated new password match.")}`,
          icon: "warning",
          confirmButtonText: "OK",
        });
        this.new_password_input_error = true;
        this.re_new_password_input_error = true;
        this.new_password = "";
        this.re_new_password = "";
        bad_form = true;
      }
      if (!bad_form) {
        try {
          await this.postResetPassword();
          this.$swal({
            title: this.$t("Reset password"),
            text: this.$t("You have successfully reset your password."),
            icon: "success",
            confirmButtonText: "OK",
          });
          router.push({ name: "SignIn" });
        } catch (error) {
          console.error(error);
        }
      }
    },
    async postResetPassword() {
      let error_message = "";

      try {
        await axios.post("/api/v1/users/reset_password_confirm/", {
          uid: this.$route.params.uid,
          token: this.$route.params.token,
          new_password: this.new_password,
          re_new_password: this.re_new_password,
        });
      } catch (error) {
        console.error(error);

        error_message = Object.values(error.response.data)[0][0];
        // error_message must be string
        if (typeof error_message !== "string") {
          error_message = "";
        }
        this.$swal({
          title: "Reset password error",
          text: "An error occurred while trying to reset your password. Error: " + error_message,
          icon: "error",
          confirmButtonText: "OK",
        });

        await new Promise(resolve => setTimeout(resolve, 200));
      }
    },
  },
};
</script>
<i18n>
  {
    "EN": {
      "Reset Password": "Reset Password",
      "You are required to reset your password.": "You are required to reset your password.",
      "New password": "New password",
      "Repeat new password": "Repeat new password",
      "Enter current password": "Enter current password",
      "Enter new password": "Enter new password",
      "New Password field is empty.": "New Password field is empty.",
      "Please enter your new password.": "Please enter your new password.",
      "Repeated New Password field is empty.": "Repeated New Password field is empty.",
      "Please repeat your new password.": "Please repeat your new password.",
      "The repeated password does not match.": "The repeated password does not match.",
      "Please make sure the new password and repeated new password match.": "Please make sure the new password and repeated new password match.",
      "You have successfully reset your password.": "You have successfully reset your password.",
    },
    "DE": {
      "Reset Password": "Passwort zurücksetzen",
      "You are required to reset your password.": "Sie müssen Ihr Passwort zurücksetzen.",
      "New password": "Neues Passwort",
      "Repeat new password": "Neues Passwort wiederholen",
      "Enter current password": "Aktuelles Passwort eingeben",
      "Enter new password": "Neues Passwort eingeben",
      "New Password field is empty.": "Das Feld für das neue Passwort ist leer.",
      "Please enter your new password.": "Bitte geben Sie Ihr neues Passwort ein.",
      "Repeated New Password field is empty.": "Das Feld für das wiederholte neue Passwort ist leer.",
      "Please repeat your new password.": "Bitte wiederholen Sie Ihr neues Passwort.",
      "The repeated password does not match.": "Das wiederholte Passwort stimmt nicht überein.",
      "Please make sure the new password and repeated new password match.": "Bitte stellen Sie sicher, dass das neue Passwort und das wiederholte neue Passwort übereinstimmen.",
      "You have successfully reset your password.": "Sie haben Ihr Passwort erfolgreich zurückgesetzt.",
    },
    "ES": {
      "Reset Password": "Restablecer Contraseña",
      "You are required to reset your password.": "Debe restablecer su contraseña.",
      "New password": "Nueva contraseña",
      "Repeat new password": "Repetir nueva contraseña",
      "Enter current password": "Introduzca la contraseña actual",
      "Enter new password": "Introduzca la nueva contraseña",
      "New Password field is empty.": "El campo de la nueva contraseña está vacío.",
      "Please enter your new password.": "Por favor, introduzca su nueva contraseña.",
      "Repeated New Password field is empty.": "El campo de la nueva contraseña repetida está vacío.",
      "Please repeat your new password.": "Por favor, repita su nueva contraseña.",
      "The repeated password does not match.": "La contraseña repetida no coincide.",
      "Please make sure the new password and repeated new password match.": "Por favor, asegúrese de que la nueva contraseña y la nueva contraseña repetida coinciden.",
      "You have successfully reset your password.": "Ha restablecido su contraseña con éxito.",
    },
    "FR": {
      "Reset Password": "Réinitialiser le mot de passe",
      "You are required to reset your password.": "Vous devez réinitialiser votre mot de passe.",
      "New password": "Nouveau mot de passe",
      "Repeat new password": "Répéter le nouveau mot de passe",
      "Enter current password": "Entrez le mot de passe actuel",
      "Enter new password": "Entrez le nouveau mot de passe",
      "New Password field is empty.": "Le champ Nouveau mot de passe est vide.",
      "Please enter your new password.": "Veuillez saisir votre nouveau mot de passe.",
      "Repeated New Password field is empty.": "Le champ Nouveau mot de passe répété est vide.",
      "Please repeat your new password.": "Veuillez répéter votre nouveau mot de passe.",
      "The repeated password does not match.": "Le mot de passe répété ne correspond pas.",
      "Please make sure the new password and repeated new password match.": "Veuillez vous assurer que le nouveau mot de passe et le nouveau mot de passe répété correspondent.",
      "You have successfully reset your password.": "Vous avez réinitialisé votre mot de passe avec succès.",
    },
    "IT": {
      "Reset Password": "Reimpostare la password",
      "You are required to reset your password.": "È necessario reimpostare la password.",
      "New password": "Nuova password",
      "Repeat new password": "Ripeti la nuova password",
      "Enter current password": "Inserisci la password corrente",
      "Enter new password": "Inserisci la nuova password",
      "New Password field is empty.": "Il campo Nuova password è vuoto.",
      "Please enter your new password.": "Inserisci la tua nuova password.",
      "Repeated New Password field is empty.": "Il campo Nuova password ripetuta è vuoto.",
      "Please repeat your new password.": "Ripeti la tua nuova password.",
      "The repeated password does not match.": "La password ripetuta non corrisponde.",
      "Please make sure the new password and repeated new password match.": "Assicurati che la nuova password e la nuova password ripetuta corrispondano.",
      "You have successfully reset your password.": "Hai reimpostato la tua password con successo.",
    },
    "PL": {
      "Reset Password": "Zresetuj hasło",
      "You are required to reset your password.": "Musisz zresetować swoje hasło.",
      "New password": "Nowe hasło",
      "Repeat new password": "Powtórz nowe hasło",
      "Enter current password": "Wprowadź aktualne hasło",
      "Enter new password": "Wprowadź nowe hasło",
      "New Password field is empty.": "Pole Nowe hasło jest puste.",
      "Please enter your new password.": "Wprowadź swoje nowe hasło.",
      "Repeated New Password field is empty.": "Pole Powtórz nowe hasło jest puste.",
      "Please repeat your new password.": "Powtórz swoje nowe hasło.",
      "The repeated password does not match.": "Powtórzone hasło nie pasuje.",
      "Please make sure the new password and repeated new password match.": "Upewnij się, że nowe hasło i powtórzone nowe hasło są zgodne.",
      "You have successfully reset your password.": "Pomyślnie zresetowano hasło.",
    },
    "TR": {
      "Email": "E-posta",
      "Enter your e-mail": "E-postanızı girin",
      "Email field is empty.": "E-posta alanı boş.",
      "Reset Password": "Şifreyi yenile",
      "You are required to reset your password.": "Şifrenizi sıfırlamanız gerekiyor.",
      "New password": "Yeni şifre",
      "Repeat new password": "Yeni şifreyi tekrarlayın",
      "Enter current password": "Geçerli şifreyi girin",
      "Enter new password": "Yeni şifreyi girin",
      "New Password field is empty.": "Yeni Şifre alanı boş.",
      "Please enter your new password.": "Lütfen yeni şifrenizi girin.",
      "Repeated New Password field is empty.": "Yeni Şifre Tekrarı alanı boş.",
      "Please repeat your new password.": "Lütfen yeni şifrenizi tekrarlayın.",
      "The repeated password does not match.": "Tekrarlanan şifre eşleşmiyor.",
      "Please make sure the new password and repeated new password match.": "Yeni şifre ve tekrarlanan yeni şifrenin eşleştiğinden emin olun.",
      "You have successfully reset your password.": "Şifrenizi başarıyla sıfırladınız.",
    },
    "ZH": {
      "Email": "电子邮件",
      "Enter your e-mail": "输入您的电子邮件",
      "Email field is empty.": "电子邮件字段为空。",
      "Reset Password": "重设密码",
      "You are required to reset your password.": "您需要重设密码。",
      "New password": "新密码",
      "Repeat new password": "重复新密码",
      "Enter current password": "输入当前密码",
      "Enter new password": "输入新密码",
      "New Password field is empty.": "新密码字段为空。",
      "Please enter your new password.": "请输入您的新密码。",
      "Repeated New Password field is empty.": "重复新密码字段为空。",
      "Please repeat your new password.": "请重复您的新密码。",
      "The repeated password does not match.": "重复的密码不匹配。",
      "Please make sure the new password and repeated new password match.": "请确保新密码和重复的新密码匹配。",
      "You have successfully reset your password.": "您已成功重设密码。",
    },
  }
</i18n>
