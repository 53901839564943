export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "EN": {
        "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
        "Go Back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go Back"])},
        "Powder Savings Calculator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powder Savings Calculator"])}
      },
      "DE": {
        "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hauptseite"])},
        "Go Back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurückgehen"])},
        "Powder Savings Calculator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pulvereinsparungenrechner"])}
      },
      "ES": {
        "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicio"])},
        "Go Back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver"])},
        "Powder Savings Calculator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculadora de ahorro de polvo"])}
      },
      "FR": {
        "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page d'accueil"])},
        "Go Back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arrière"])},
        "Powder Savings Calculator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculateur d'économie de poudre"])}
      },
      "IT": {
        "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagina iniziale"])},
        "Go Back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indietro"])},
        "Powder Savings Calculator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calcolatore di risparmio di polvere"])}
      },
      "PL": {
        "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strona główna"])},
        "Go Back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powrót"])},
        "Powder Savings Calculator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalkulator oszczędności proszku"])}
      },
      "TR": {
        "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ana Sayfa"])},
        "Go Back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geri dön"])},
        "Powder Savings Calculator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toz Tasarruf Hesaplayıcısı"])}
      },
      "ZH": {
        "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主页"])},
        "Go Back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["转回去"])},
        "Powder Savings Calculator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["粉末节约计算器"])}
      }
    }
  })
}
