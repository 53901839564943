export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "EN": {
        "Benchmark & Target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benchmark & Target"])},
        "Save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
        "Changes saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changes saved"])},
        "was saved successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["was saved successfully"])}
      },
      "DE": {
        "Benchmark & Target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benchmark & Ziel"])},
        "Save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
        "Changes saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Änderungen gespeichert"])},
        "was saved successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wurde erfolgreich gespeichert"])}
      },
      "ES": {
        "Benchmark & Target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punto de referencia y objetivo"])},
        "Save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])},
        "Changes saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambios guardados"])},
        "was saved successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["se guardó correctamente"])}
      },
      "FR": {
        "Benchmark & Target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Référence et objectif"])},
        "Save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder"])},
        "Changes saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifications enregistrées"])},
        "was saved successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a été enregistré avec succès"])}
      },
      "IT": {
        "Benchmark & Target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benchmark e obiettivo"])},
        "Save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])},
        "Changes saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifiche salvate"])},
        "was saved successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["è stato salvato con successo"])}
      },
      "PL": {
        "Benchmark & Target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punkt odniesienia i cel"])},
        "Save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz"])},
        "Changes saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmiany zapisane"])},
        "was saved successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["został zapisany pomyślnie"])}
      },
      "TR": {
        "Benchmark & Target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referans ve Hedef"])},
        "Save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaydet"])},
        "Changes saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değişiklikler kaydedildi"])},
        "was saved successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["başarıyla kaydedildi"])}
      },
      "ZH": {
        "Benchmark & Target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["基准和目标"])},
        "Save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存"])},
        "Changes saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更改已保存"])},
        "was saved successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已成功保存"])}
      }
    }
  })
}
