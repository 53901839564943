export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "EN": {
        "Health Check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Health Check"])}
      },
      "DE": {
        "Health Check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesundheitscheck"])}
      },
      "ES": {
        "Health Check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chequeo de salud"])}
      },
      "FR": {
        "Health Check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérification de santé"])}
      },
      "IT": {
        "Health Check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controllo di salute"])}
      },
      "PL": {
        "Health Check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Badanie lekarskie"])}
      },
      "TR": {
        "Health Check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sağlık kontrolü"])}
      },
      "ZH": {
        "Health Check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["健康检查"])}
      }
    }
  })
}
