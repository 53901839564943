export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "EN": {
        
      },
      "DE": {
        
      },
      "ES": {
        
      }
    }
  })
}
