<template>
  <div class="py-2 container-fluid">
    <div
      class="page-header min-height-300 border-radius-xl"
      :style="{
        backgroundImage: 'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
        backgroundPositionY: '50%',
      }"
    >
      <span class="mask bg-gradient-success opacity-6"></span>
      <div style="display: flex; align-items: center; justify-content: center">
        <div style="position: absolute; left: 50%; transform: translateX(-50%)">
          <img
            :src="logo"
            style="height: 60px; z-index: 999"
            alt="main_logo"
          />
        </div>
      </div>
    </div>
    <div class="mx-4 overflow-hidden card card-body blur shadow-blur mt-n6">
      <div class="row gx-4">
        <div class="col my-auto">
          <LineListBluePrint/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import setTooltip from "@/assets/js/tooltip.js";
import LineListBluePrint from "../components/LinesListBluePrint.vue";
import logo from "@/assets/img/logo-ct-white.png";

export default {
  name: "FineTune",
  components: {
    LineListBluePrint,
  },
  data() {
    return {
      logo,
    };
  },
  computed: {},
  mounted() {
    this.$store.state.isAbsolute = true;
    setTooltip(this.$store.state.bootstrap);
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
  },
  methods: {},
};
</script>
