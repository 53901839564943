export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "EN": {
        "Sign In": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign In"])},
        "Language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
        "Unit System": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit System"])},
        "metric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metric"])},
        "imperial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imperial"])},
        "Log Out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log Out"])}
      },
      "DE": {
        "Sign In": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden"])},
        "Language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache"])},
        "Unit System": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einheiten"])},
        "metric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metrisch"])},
        "imperial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imperial"])},
        "Log Out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abmelden"])}
      },
      "ES": {
        "Sign In": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar Sesión"])},
        "Language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idioma"])},
        "Unit System": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sistema de Unidades"])},
        "metric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Métrico"])},
        "imperial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imperial"])},
        "Log Out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar Sesión"])}
      },
      "FR": {
        "Sign In": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter"])},
        "Language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langue"])},
        "Unit System": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Système d'Unités"])},
        "metric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Métrique"])},
        "imperial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impérial"])},
        "Log Out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déconnexion"])}
      },
      "IT": {
        "Sign In": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accedi"])},
        "Language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lingua"])},
        "Unit System": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sistemi di Misura"])},
        "metric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metrico"])},
        "imperial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imperiale"])},
        "Log Out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esci"])}
      },
      "PL": {
        "Sign In": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaloguj"])},
        "Language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Język"])},
        "Unit System": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Układ Jednostek"])},
        "metric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metryczny"])},
        "imperial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imperialny"])},
        "Log Out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyloguj"])}
      },
      "TR": {
        "Sign In": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oturum Aç"])},
        "Language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dil"])},
        "Unit System": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ölçü Sistemi"])},
        "metric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metrik"])},
        "imperial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İmparatorluk"])},
        "Log Out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oturumu Kapat"])}
      },
      "ZH": {
        "Sign In": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录"])},
        "Language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["语言"])},
        "Unit System": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["计量单位制"])},
        "metric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公制"])},
        "imperial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["英制"])},
        "Log Out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退出登录"])}
      }
    }
  })
}
