<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card-body text-start">
          <div v-if="powdermodellist.length">
            <h5 class="mb-0">Spray Patterns calibrated</h5>
            <div class="table-responsive mt-4">
              <table
                v-if="visit.spraypatternstage.selected_powder_models"
                class="table table-flush table-hover"
              >
                <thead class="thead-light">
                  <tr>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                      style="padding: 0.5rem 0.5rem"
                    >
                      Select
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                      style="padding: 0.5rem 0.5rem"
                    >
                      {{ $t("Name") }}
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                      style="padding: 0.5rem 0.5rem"
                    >
                      {{ $t("Created") }}
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                      style="padding: 0.5rem 0.5rem"
                    ></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="powdermodel in OrderByDateUpdated(
                      powdermodellist.filter(item => item.visit == parseInt(visitId) && item.line == parseInt(lineId)),
                    )"
                    :key="powdermodel.id"
                  >
                    <td class="text-center">
                      <SoftCheckbox
                        :id="`checkbox-${powdermodel.id}`"
                        :name="`checkbox-${powdermodel.id}`"
                        :checked="visit.spraypatternstage.selected_powder_models.includes(powdermodel.id)"
                        :disabled="visit.spraypatternstage.selected_powder_models.includes(powdermodel.id)"
                        @change="updateSelectedPowderModel(powdermodel.id)"
                      />
                    </td>
                    <td
                      class="text-sm font-weight-normal clickable"
                      :onclick="`window.location='/applications/blueprint/powdermodel/calibrated/${powdermodel.id}'`"
                      style="cursor: pointer; vertical-align: middle"
                    >
                      {{ powdermodel.name }}
                    </td>
                    <td
                      class="text-sm font-weight-normal clickable"
                      :onclick="`window.location='/applications/blueprint/powdermodel/calibrated/${powdermodel.id}'`"
                      style="cursor: pointer; vertical-align: middle"
                    >
                      {{ dateToString(powdermodel.created_at) }}
                    </td>
                    <td
                      class="text-sm text-end font-weight-normal clickable"
                      :onclick="`deletePowderModel(${powdermodel.id}, '${powdermodel.name}')`"
                      style="vertical-align: middle"
                    >
                      <a class="btn btn-link text-danger text-gradient px-3 mb-0 my-auto text-end">
                        <i
                          class="far fa-trash-alt me-2"
                          aria-hidden="true"
                        ></i
                        >{{ $t("Delete") }}
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div v-if="powdermodellist.length">
            <h5 class="mt-4 mb-0">Spray Patterns calibrated on previous Workflows</h5>
            <div class="table-responsive mt-4">
              <table class="table table-flush table-hover">
                <thead class="thead-light">
                  <tr>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                      style="padding: 0.5rem 0.5rem"
                    >
                      Select
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                      style="padding: 0.5rem 0.5rem"
                    >
                      {{ $t("Name") }}
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                      style="padding: 0.5rem 0.5rem"
                    >
                      {{ $t("Created") }}
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                      style="padding: 0.5rem 0.5rem"
                    ></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="powdermodel in OrderByDateUpdated(
                      powdermodellist.filter(item => item.visit != parseInt(visitId) && item.line == parseInt(lineId)),
                    )"
                    :key="powdermodel.id"
                  >
                    <td class="text-center">
                      <SoftCheckbox
                        :id="`checkbox-${powdermodel.id}`"
                        :name="`checkbox-${powdermodel.id}`"
                        :checked="visit.spraypatternstage.selected_powder_models.includes(powdermodel.id)"
                        :disabled="visit.spraypatternstage.selected_powder_models.includes(powdermodel.id)"
                        @change="updateSelectedPowderModel(powdermodel.id)"
                      />
                    </td>
                    <td
                      class="text-sm font-weight-normal clickable"
                      :onclick="`window.location='/applications/blueprint/powdermodel/calibrated/${powdermodel.id}'`"
                      style="cursor: pointer; vertical-align: middle"
                    >
                      {{ powdermodel.name }}
                    </td>
                    <td
                      class="text-sm font-weight-normal clickable"
                      :onclick="`window.location='/applications/blueprint/powdermodel/calibrated/${powdermodel.id}'`"
                      style="cursor: pointer; vertical-align: middle"
                    >
                      {{ dateToString(powdermodel.created_at) }}
                    </td>
                    <td
                      class="text-sm text-end font-weight-normal clickable"
                      :onclick="`deletePowderModel(${powdermodel.id}, '${powdermodel.name}')`"
                      style="vertical-align: middle"
                    >
                      <a class="btn btn-link text-danger text-gradient px-3 mb-0 my-auto">
                        <i
                          class="far fa-trash-alt me-2"
                          aria-hidden="true"
                        ></i
                        >{{ $t("Delete") }}
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-if="currentPowderSelectedPowdermodels.length == 0">
              <p class="text-lg text-center mt-3">
                <b>Please select a Spray Pattern calibration</b>
              </p>
            </div>
          </div>
          <div v-else>
            <p>No Spray Pattern calibrations. Calibrate a new one.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { orderBy } from "lodash";
import { useGetVisit } from "../utils/utils.js";
import axios from "axios";
import SoftCheckbox from "@/components/SoftCheckbox.vue";

export default {
  name: "PowderModelListBluePrint",
  components: {
    SoftCheckbox,
  },
  props: {
    lineName: {
      type: String,
      default: null,
    },
    lineId: {
      type: String,
      default: null,
    },
    visitId: {
      type: String,
      default: null,
    },
    powderId: {
      type: Number,
      default: null,
    },
  },
  emits: [
    "updated-selected-spraypattern",
  ],
  setup() {
    const { visit, getVisit } = useGetVisit();
    return { visit, getVisit };
  },
  data() {
    this.$i18n.locale = this.$store.state.user_data.language;
    return {
      powdermodellist: [],
      powder: {
        selected_powder_model: null,
      },
    };
  },
  computed: {
    selectedPowdersUpdated() {
      const result = this.recieved_data.selected_powder_modal_ids.find(id => id === this.selectedPowderModelId);
      return this.selectedPowderModelId !== null && result !== this.selectedPowderModelId;
    },
    currentPowderSelectedPowdermodels() {
      const current_powder_powdermodels = this.powdermodellist.map(item => item.id);

      if (this.visit.spraypatternstage.selected_powder_models) {
        return this.visit.spraypatternstage.selected_powder_models.filter(id =>
          current_powder_powdermodels.includes(id),
        );
      }
      return current_powder_powdermodels;
    },
  },
  watch: {
    "$store.state.user_data.language"() {
      this.$i18n.locale = this.$store.state.user_data.language;
    },
  },
  mounted() {
    this.getData();
  },
  created() {
    window.deletePowderModel = this.deletePowderModel;
  },
  methods: {
    async getData() {
      if (this.powderId !== null) {
        Promise.all([this.getVisit(this.visitId), this.getPowder(), this.getPowderModelList()]);
        this.$store.state.isLoading = false;
      }
    },
    async getPowder() {
      await axios
        .get("/api/v1/blueprint/powder/" + this.powderId + "/")
        .then(response => {
          this.powder = response.data;
        })
        .catch(error => {
          console.error(error);
        });
    },
    OrderByDateUpdated(array) {
      return orderBy(array, ["updated_at", "created_at"], ["desc"]);
    },
    async getPowderModelList() {
      await axios
        .get("/api/v1/fp/powdermodels/")
        .then(response => {
          this.powdermodellist = response.data.filter(item => item.powder == parseInt(this.powderId));
        })
        .catch(error => {
          console.error(error);
        });
    },
    deletePowderModel(powdermodel_id, powdermodel_name) {
      if (this.currentPowderSelectedPowdermodels.includes(powdermodel_id)) {
        this.$swal({
          title: `${this.$t("Error")}`,
          text: `${this.$t(
            "You cannot delete the selected powder model. Create a new powder model and select it before deleting this one.",
          )}`,
          icon: "error",
          customClass: {
            confirmButton: "btn bg-gradient-success",
          },
          buttonsStyling: false,
        });
        return;
      }
      this.$swal({
        title: `${this.$t("Are you sure you want to delete Powder Model")} ${powdermodel_name}?`,
        text: `${this.$t("You will not be able to revert this!")}`,
        showCancelButton: true,
        confirmButtonText: `${this.$t("Yes, delete it")}`,
        cancelButtonText: `${this.$t("No, cancel")}`,
        reverseButtons: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          this.$store.state.isLoading = true;
          axios
            .delete("/api/v1/fp/powdermodels/" + powdermodel_id)
            .then(() => {
              this.$store.state.isLoading = false;
              this.powderModelDeletedSweetAlert();
            })
            .catch(error => {
              console.error(error);
            });
        }
      });
    },
    powderModelDeletedSweetAlert() {
      this.$swal({
        title: `${this.$t("Deleted")}`,
        text: `${this.$t("Your powder model has been deleted.")}`,
        icon: "success",
        customClass: {
          confirmButton: "btn bg-gradient-success",
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.isConfirmed) {
          window.location.reload(true);
        }
      });
    },
    dateToString(date) {
      const date_object = new Date(date);
      if (this.$store.state.user_data.unit_system == "metric") {
        return date_object.toLocaleString("en-GB", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        });
      } else if (this.$store.state.user_data.unit_system == "imperial") {
        return date_object.toLocaleString("en-US", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        });
      } else {
        return null;
      }
    },
    updateSelectedPowderModel(powdermodel_id) {
      // get previously selected powder models belonging to this powder
      // and that now should be unselected
      const current_powder_previously_selected_powdermodel = this.currentPowderSelectedPowdermodels[0];

      // remove all previously selected powder models belonging to this powder
      const new_selected_powder_models = this.visit.spraypatternstage.selected_powder_models.filter(
        id => id != current_powder_previously_selected_powdermodel,
      );

      // add the new selected powder model
      new_selected_powder_models.push(powdermodel_id);

      const PROGRESS_STATUS_COMPLETED = "Completed";
      axios
        .patch("/api/v1/blueprint/spraypattern/" + this.visitId + "/", {
          selected_powder_models: new_selected_powder_models,
          progress_status: PROGRESS_STATUS_COMPLETED,
        })
        .catch(error => {
          console.error(error);
        });
      this.visit.spraypatternstage.selected_powder_models = new_selected_powder_models;

      this.$emit("updated-selected-spraypattern", new_selected_powder_models);
    },
  },
};
</script>

<i18n>
  {
    "EN": {
    "Powder Model List for line ":"Powder Model List for line ",
    "Name":"Name",
    "Updated":"Updated",
    "Created":"Created",
    "Remove Powder Model":"Remove Powder Model",
    "No Powder Models created.":"No Powder Models created.",
    "Are you sure you want to delete Powder Model":"Are you sure you want to delete Powder Model",
    "You will not be able to revert this!":"You will not be able to revert this!",
    "Yes, delete it":"Yes, delete it",
    "No, cancel":"No, cancel",
    "Deleted":"Deleted",
    "Your powder model has been deleted.":"Your powder model has been deleted.",
    "Delete":"Delete",
    },
    "DE": {
    "Powder Model List for line ":"Sprühbildliste für inie ",
    "Name":"Name",
    "Updated":"Aktualisiert",
    "Created":"Erstellt",
    "Remove Powder Model":"Pulvermodell entfernen",
    "No Powder Models created.":"Keine Sprühbilder erstellt.",
    "Are you sure you want to delete Powder Model":"Möchten Sie das Pulvermodell wirklich löschen",
    "You will not be able to revert this!":"Sie können dies nicht rückgängig machen!",
    "Yes, delete it":"Ja, lösche es",
    "No, cancel":"Nein, abbrechen",
    "Deleted":"Gelöscht",
    "Your powder model has been deleted.":"Ihr Pulvermodell wurde gelöscht.",
    "Delete":"Löschen",
    },
    "ES": {
    "Powder Model List for line ":"Lista de modelos de polvo para la línea ",
    "Name":"Nombre",
    "Updated":"Actualizado",
    "Created":"Creado",
    "Remove Powder Model":"Eliminar modelo de polvo",
    "No Powder Models created.":"No se ha creado ningún modelo de polvo.",
    "Are you sure you want to delete Powder Model":"¿Estás seguro de que quieres eliminar el modelo de polvo",
    "You will not be able to revert this!":"¡No podrás deshacer esto!",
    "Yes, delete it":"Sí, bórralo",
    "No, cancel":"No, cancelar",
    "Deleted":"Eliminado",
    "Your powder model has been deleted.":"Su modelo de polvo ha sido eliminado.",
    "Delete":"Eliminar",
    },
    "FR": {
    "Powder Model List for line ":"Liste des modèles de poudres pour la ligne ",
    "Name":"Nom",
    "Updated":"Mis à jour",
    "Created":"Créé",
    "Remove Powder Model":"Supprimer le modèle de poudre",
    "No Powder Models created.":"Aucun modèle de poudre n'a été créé.",
    "Are you sure you want to delete Powder Model":"Voulez-vous vraiment supprimer le modèle de poudre",
    "You will not be able to revert this!":"Vous ne pourrez pas revenir en arrière!",
    "Yes, delete it":"Oui, supprimez-le",
    "No, cancel":"Non, annuler",
    "Deleted":"Supprimé",
    "Your powder model has been deleted.":"Votre modèle de poudre a été supprimé.",
    "Delete":"Effacer",
    },
    "IT": {
    "Powder Model List for line ":"Elenco dei modelli di polvere per la linea ",
    "Name":"Nome",
    "Updated":"Aggiornato",
    "Created":"Creato",
    "Remove Powder Model":"Rimuovi modello di polvere",
    "No Powder Models created.":"Nessun modello di polvere creato.",
    "Are you sure you want to delete Powder Model":"Sei sicuro di voler eliminare il modello di polvere",
    "You will not be able to revert this!":"Non sarai in grado di annullare questa operazione!",
    "Yes, delete it":"Sì, cancellalo",
    "No, cancel":"No, annulla",
    "Deleted":"Eliminato",
    "Your powder model has been deleted.":"Il tuo modello di polvere è stato eliminato.",
    "Delete":"Elimina",
    },
    "PL": {
    "Powder Model List for line ":"Lista modeli proszków dla linii ",
    "Name":"Nazwa",
    "Updated":"Zaktualizowano",
    "Created":"Utworzony",
    "Remove Powder Model":"Usuń model proszku",
    "No Powder Models created.":"Nie utworzono modelu proszku.",
    "Are you sure you want to delete Powder Model":"Czy na pewno chcesz usunąć model proszku",
    "You will not be able to revert this!":"Nie będziesz mógł tego cofnąć!",
    "Yes, delete it":"Tak, usuń to",
    "No, cancel":"Nie, anuluj",
    "Deleted":"Usunięty",
    "Your powder model has been deleted.":"Twój model proszku został usunięty.",
    "Delete":"Kasować",
    },
    "TR": {
    "Powder Model List for line ":"Hat için Toz Model listesi ",
    "Name":"İsim",
    "Updated":"Güncellenmiş",
    "Created":"Oluşturuldu",
    "Remove Powder Model":"Toz Modelini Çıkarın",
    "No Powder Models created.":"Toz Modeli oluşturulmadı.",
    "Are you sure you want to delete Powder Model":"Toz Modelini silmek istediğinizden emin misiniz",
    "You will not be able to revert this!":"Bunu geri alamayacaksın!",
    "Yes, delete it":"Evet, sil",
    "No, cancel":"Hayır, iptal et",
    "Deleted":"Silindi",
    "Your powder model has been deleted.":"Toz modeliniz silindi.",
    "Delete":"Sil",
    },
    "ZH": {
    "Powder Model List for line ":"生产线粉末模型列表 ",
    "Name":"产品名称",
    "Updated":"已更新",
    "Created":"已创建",
    "Remove Powder Model":"删除粉末模型",
    "No Powder Models created.":"未创建粉末模型",
    "Are you sure you want to delete Powder Model":"您确定要删除粉末模型吗",
    "You will not be able to revert this!":"您将无法撤消此操作！",
    "Yes, delete it":"是的，删除它",
    "No, cancel":"不，取消",
    "Deleted":"已删除",
    "Your powder model has been deleted.":"您的粉末模型已被删除。",
    "Delete":"删除",
    }
  }
</i18n>
