<template>
  <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
    <!-- nav -->
    <Spinner v-if="$store.state.isLoading" />
    <Navbar
      v-if="showNavbar"
      :class="[isNavFixed ? navbarFixed : '', isAbsolute ? absolute : '']"
      :text-white="isAbsolute ? 'text-white opacity-8' : ''"
      :min-nav="navbarMinimize"
    />
    <router-view />
    <AppFooter v-show="showFooter" />
  </main>
</template>
<script>
import axios from "axios";
import router from "@/router";
import Navbar from "@/containers/Navbar.vue";
import AppFooter from "@/containers/Footer.vue";
import { mapMutations, mapState } from "vuex";
import Spinner from "@/components/LoadingSpinner.vue";

export default {
  name: "App",
  components: {
    Navbar,
    AppFooter,
    Spinner,
  },
  computed: {
    ...mapState([
      "isNavFixed",
      "isAbsolute",
      "navbarFixed",
      "absolute",
      "color",
      "showNavbar",
      "showFooter",
      "showConfig",
    ]),
  },
  watch: {
    "$store.state.token"() {
      this.createWebSocketConnection();
    },
    "$store.state.user_data.changed_password"() {
      this.redirectToResetPassword();
    },
  },
  beforeCreate() {
    this.$store.commit("initializeStore");
    const token = this.$store.state.token;
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Token " + token;
    } else {
      axios.defaults.headers.common["Authorization"] = "";
    }
  },
  created() {
    this.createWebSocketConnection();
  },

  beforeMount() {
    this.$store.state.isTransparent = "bg-transparent";
  },

  methods: {
    ...mapMutations(["navbarMinimize"]),
    logout() {
      axios.post("/api/v1/token/logout/");
      this.$store.commit("removeToken");
      router.push({ name: "SignIn" });
    },
    redirectToResetPassword() {
      if (
        // if user has never changed the password
        !this.$store.state.user_data.changed_password
      ) {
        // redirect to password change page
        router.push({ name: "ResetPassword" });
      }
    },
    createWebSocketConnection() {
      if (this.$store.state.token == "") {
        return;
      }
      if (window.location.hostname == "localhost" || window.location.hostname == "127.0. 0.1") {
        var websocket_address =
          "ws://" + window.location.hostname + ":8000/ws/statusupdates/?token=" + this.$store.state.token;
      } else {
        websocket_address = "wss://" + window.location.host + "/ws/statusupdates/?token=" + this.$store.state.token;
      }
      this.socket = new WebSocket(websocket_address);

      let _this = this;
      _this.socket.onmessage = function (event) {
        let data = JSON.parse(event.data);
        if (data["type"] == "status_update") {
          if (data["status"] == "completed") {
            _this.$store.commit("setLastMessageType", "completed");
            if ("email" in data) {
              _this.$store.commit("removePowderSavingsCalculationInProgress", data["email"]);
            } else if ("flightpathid" in data) {
              if (data["settings_type"] == "optimal") {
                _this.$store.commit("removeOptimalSettingsCalculationInProgress", data["flightpathid"][0]);
              } else if (data["settings_type"] == "specific") {
                _this.$store.commit("removeCurrentSettingsCalculationInProgress", data["flightpathid"][0]);
              } else if (data["settings_type"] == "general") {
                _this.$store.commit("removeOptimalSettingsCalculationInProgress", data["flightpathid"][0]);
                _this.$store.commit("removeCurrentSettingsCalculationInProgress", data["flightpathid"][0]);
              }
            }
          } else if (data["status"] == "error") {
            _this.$store.commit("setLastMessageType", "error");
            if ("email" in data) {
              _this.$store.commit("removePowderSavingsCalculationInProgress", data["email"]);
            } else if ("flightpathid" in data) {
              if (data["settings_type"] == "optimal") {
                _this.$store.commit("removeOptimalSettingsCalculationInProgress", data["flightpathid"][0]);
              } else if (data["settings_type"] == "specific") {
                _this.$store.commit("removeCurrentSettingsCalculationInProgress", data["flightpathid"][0]);
              } else if (data["settings_type"] == "general") {
                _this.$store.commit("removeOptimalSettingsCalculationInProgress", data["flightpathid"][0]);
                _this.$store.commit("removeCurrentSettingsCalculationInProgress", data["flightpathid"][0]);
              }
            }
            _this.$swal({
              title: "Error",
              text: data["message"],
              icon: "error",
              button: "OK",
            });
          }
        }
      };
      this.socket.onopen = function () {
        console.log("Successfully connected to the echo websocket server...");
      };

      this.socket.onerror = function (event) {
        console.log(event);
        console.log("Socket connection onerror message");
      };

      this.socket.onclose = function (event) {
        console.log("Socket closed. Reconnect will be attempted in 4 seconds.", event.reason);
        setTimeout(function () {
          _this.createWebSocketConnection();
        }, 4000);
      };
    },
  },
};
</script>
