export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "EN": {
        "Lines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lines"])},
        "List of created Lines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of created Lines"])},
        "No Lines Created. To create a line, contact us at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Lines Created. To create a line, contact us at"])}
      },
      "DE": {
        "Lines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anlagen"])},
        "List of created Lines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste der erstellten Anlagen"])},
        "No Lines Created. To create a line, contact us at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Linien erstellt. Um eine Leitung zu erstellen, kontaktieren Sie uns unter"])}
      },
      "ES": {
        "Lines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Líneas"])},
        "List of created Lines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de líneas creadas"])},
        "No Lines Created. To create a line, contact us at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No existe ninguna línea. Para crear una línea, contáctenos en"])}
      },
      "FR": {
        "Lines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lignes"])},
        "List of created Lines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des lignes créées"])},
        "No Lines Created. To create a line, contact us at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune ligne créée. Pour créer une ligne, contactez-nous à"])}
      },
      "IT": {
        "Lines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linee"])},
        "List of created Lines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elenco delle linee create"])},
        "No Lines Created. To create a line, contact us at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna linea creata. Per creare una linea, contattaci a"])}
      },
      "PL": {
        "Lines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linie"])},
        "List of created Lines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista utworzonych linii"])},
        "No Lines Created. To create a line, contact us at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie utworzono żadnych linii. Aby utworzyć linię, skontaktuj się z nami pod adresem"])}
      },
      "TR": {
        "Lines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hatlar"])},
        "List of created Lines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oluşturulan Hatların Listesi"])},
        "No Lines Created. To create a line, contact us at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hat oluşturulmadı. Bir hat oluşturmak için bizimle iletişime geçin"])}
      },
      "ZH": {
        "Lines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["线"])},
        "List of created Lines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建的线列表"])},
        "No Lines Created. To create a line, contact us at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未创建线。要创建线，请联系我们"])}
      }
    }
  })
}
