/**
=========================================================
* Vue Soft UI Dashboard PRO - v3.0.0
=========================================================
* Product Page: https://creative-tim.com/product/vue-soft-ui-dashboard-pro
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { createApp } from "vue";
import { createI18n } from "vue-i18n";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import VueTilt from "vue-tilt.js";
import VueSweetalert2 from "vue-sweetalert2";
import SoftUIDashboard from "./soft-ui-dashboard";
import axios from "axios";

const i18n = createI18n({
  locale: "EN",
  fallbackLocale: "EN",
});

if (window.location.hostname == "localhost" || window.location.hostname == "127.0. 0.1") {
  axios.defaults.baseURL = window.location.protocol + "//" + window.location.hostname + ":8000";
} else {
  axios.defaults.baseURL = window.location.protocol + "//" + window.location.host;
}
const appInstance = createApp(App);
appInstance.use(store);
appInstance.use(i18n);
appInstance.use(router);
appInstance.use(VueTilt);
appInstance.use(VueSweetalert2);
appInstance.use(SoftUIDashboard);
appInstance.mount("#app");
