<template>
  <div class="card-body pt-0 p-3">
    <ul class="list-group">
      <li
        class="list-group-item border-0 d-flex p-3 mb-1 border-radius-lg"
        style="background-color: rgb(230, 230, 230)"
      >
        <div
          class="col-3 p-2"
          style="min-width: 100px"
        >
          <span class="text-sm">
            {{ $t("Line Name") }}
          </span>
          <div
            class="my-auto"
            onmouseover="this.style.textDecoration = 'underline'"
            onmouseout="this.style.textDecoration = 'none'"
            style="cursor: pointer"
            @click="$router.push('/applications/line/' + lineId)"
          >
            <h6>{{ lineName }}</h6>
          </div>
          <span class="text-xs">
            {{ $t("Created") }}
          </span>
          <div class="my-auto mb-0">
            <p style="font-size: 0.8rem">
              <b>{{ dateTime }}</b>
            </p>
          </div>
        </div>
        <div class="col-7">
          <div class="row mt-4">
            <div
              class="col-lg-3 col-md-6 p-3 p-lg-2"
              style="min-width: 100px"
            >
              <button
                class="btn py-5 mt-2 mb-0 ms-lg-auto me-lg-0 me-auto mt-lg-0"
                type="button"
                style="background-color: rgb(231, 245, 221); word-wrap: normal; font-size: min(0.9rem, max(1vw, 0.7rem))"
                @click="routeToOptimizationDetail"
              >
                {{ $t("New Optimization") }}
              </button>
            </div>
            <div
              class="col-lg-3 col-md-6 p-3"
              style="min-width: 100px; margin-top: auto; margin-bottom: auto"
            >
              <button
                class="btn py-3 mt-2 mb-0 ms-lg-auto me-lg-0 me-auto mt-lg-0"
                type="button"
                style="background-color: rgb(241, 241, 241); font-size: 0.7rem; word-wrap: normal"
                @click="$router.push('/applications/flightpath/optimizationslibrary/' + lineId)"
              >
                <i
                  class="fa fa-book"
                  aria-hidden="true"
                ></i>
                {{ $t("Optimizations Library") }}
              </button>
            </div>
            <div
              class="col-lg-3 col-md-6 p-3"
              style="margin-top: auto; margin-bottom: auto"
            >
              <button
                class="btn py-3 mt-2 mb-0 ms-lg-auto me-lg-0 me-auto mt-lg-0"
                type="button"
                style="background-color: rgb(241, 241, 241); font-size: 0.7rem; word-wrap: normal"
                @click="$router.push('/applications/flightpath/powdermodelslibrary/' + lineId)"
              >
                <i class="fa fa-dot-circle-o"></i>
                {{ $t("Powder Models Library") }}
              </button>
            </div>
            <div
              v-if="!isMixedLayout"
              class="col-lg-3 col-md-5 p-3"
              style="margin-top: auto; margin-bottom: auto"
            >
              <button
                class="btn py-3 mt-2 mb-0 ms-lg-auto me-lg-0 me-auto mt-lg-0"
                type="button"
                style="background-color: rgb(241, 241, 241); font-size: 0.7rem; word-wrap: normal"
                @click="$router.push('/applications/flightpath/powderamountcalibrationslibrary/' + lineId)"
              >
                <i class="fa fa-dot-circle-o"></i>
                {{ $t("Powder Amount Library") }}
              </button>
            </div>
          </div>
        </div>
        <div
          class="col-2 text-end"
          style="margin-top: auto; margin-bottom: auto"
        >
          <a
            class="btn btn-link text-dark px-3 mb-0 my-auto"
            @click="
              this.$store.state.isLoading = true;
              $router.push('/applications/line/' + lineId);
            "
          >
            <i
              class="fas fa-pencil-alt text-dark me-2"
              aria-hidden="true"
            ></i
            >{{ $t("Edit") }}
          </a>
          <a
            class="btn btn-link text-danger text-gradient px-3 mb-0 my-auto"
            @click="$emit('delete-line', lineName, lineId)"
          >
            <i
              class="far fa-trash-alt me-2"
              aria-hidden="true"
            ></i
            >{{ $t("Delete") }}
          </a>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "LineCardPro",
  props: {
    lineName: {
      type: String,
      default: "",
    },
    lineId: {
      type: String,
      default: "",
    },
    dateTime: {
      type: String,
      default: "",
    },
    lineLayout: {
      type: String,
      default: "",
    },
  },
  emits: ["delete-line"],
  data() {
    this.$i18n.locale = this.$store.state.user_data.language;
    return {};
  },
  computed: {
    isFixedLineLayout() {
      return this.lineLayout == "f" || this.lineLayout == "fixedmixed";
    },
    isMixedLayout() {
      return (this.lineLayout === "m") | (this.lineLayout == "fixedmixed");
    },
  },
  watch: {
    "$store.state.user_data.language"() {
      this.$i18n.locale = this.$store.state.user_data.language;
    },
  },
  methods: {
    routeToOptimizationDetail() {
      if (this.isFixedLineLayout) this.$router.push("/applications/flightpath/fixedlineoptimization/" + this.lineId);
      else {
        this.$router.push("/applications/flightpath/optimization/" + this.lineId);
      }
    },
  },
};
</script>
<i18n>
  {
    "EN": {
        "Line Name":"Line Name",
        "Created":"Created",
        "Optimizations Library":"Optimizations Library",
        "New Optimization":"New Optimization",
        "Powder Models Library":"Powder Models Library",
        "Edit":"Edit",
        "Delete":"Delete",
        "Powder Amount Library":"Powder Amount Library",
    },
    "DE": {
        "Line Name":"Linienname",
        "Created":"Erstellt",
        "Optimizations Library":"Optimierungsliste",
        "New Optimization":"Neue Optimierung",
        "Powder Models Library":"Bibliothek der Sprühbilder",
        "Edit":"Bearbeiten",
        "Delete":"Löschen",
        "Powder Amount Library":"Pulvermengenbibliothek",
    },
    "ES": {
        "Line Name":"Nombre de la Línea",
        "Created":"Creada",
        "Optimizations Library":"Lista de Optimizaciones",
        "New Optimization":"Nueva Optimización",
        "Powder Models Library":"Lista de Modelos de Polvo",
        "Edit":"Editar",
        "Delete":"Eliminar",
        "Powder Amount Library":"Biblioteca de Flujo de Pintura",
    },
    "FR": {
        "Line Name":"Nom de la Ligne",
        "Created":"Créée",
        "Optimizations Library":"Liste des Optimisations",
        "New Optimization":"Nouvelle Optimisation",
        "Powder Models Library":"Bibliothèque de Modèles de Poudres",
        "Edit":"Éditer",
        "Delete":"Effacer",
        "Powder Amount Library":"Bibliothèque de Quantité de Poudre",
    },
    "IT": {
        "Line Name":"Nome della Linea",
        "Created":"Creata",
        "Optimizations Library":"Elenco delle Ottimizzazioni",
        "New Optimization":"Nuova Ottimizzazione",
        "Powder Models Library":"Libreria dei Modelli di Polvere",
        "Edit":"Modifica",
        "Delete":"Elimina",
        "Powder Amount Library":"Libreria della Quantità di Polvere",
    },
    "PL": {
        "Line Name":"Nazwa Linii",
        "Created":"Utworzony",
        "Optimizations Library":"Lista Optymalizacji",
        "New Optimization":"Nowa optymalizacja",
        "Powder Models Library":"Biblioteka Modeli Proszkowych",
        "Edit":"Edytować",
        "Delete":"Kasować",
        "Powder Amount Library":"Biblioteka ilości proszku",
    },
    "TR": {
        "Line Name":"Hat İsmi",
        "Created":"Oluşturuldu",
        "Optimizations Library":"Optimizasyonlar Listesi",
        "New Optimization":"Yeni Optimizasyon",
        "Powder Models Library":"Toz Modelleri Kütüphanesi",
        "Edit":"Düzenle",
        "Delete":"Sil",
        "Powder Amount Library":"Toz Miktarı Kütüphanesi",
    },
    "ZH": {
        "Line Name":"线路名称",
        "Created":"创建",
        "Optimizations Library":"优化列表",
        "New Optimization":"新优化",
        "Powder Models Library":"粉末模型库",
        "Edit":"编辑",
        "Delete":"删除",
        "Powder Amount Library":"粉末量库",
    },
  }
</i18n>
