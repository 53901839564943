export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "EN": {
        "Reset Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset Password"])},
        "You are required to reset your password.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are required to reset your password."])},
        "New password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password"])},
        "Repeat new password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat new password"])},
        "Enter current password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter current password"])},
        "Enter new password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter new password"])},
        "New Password field is empty.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Password field is empty."])},
        "Please enter your new password.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your new password."])},
        "Repeated New Password field is empty.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeated New Password field is empty."])},
        "Please repeat your new password.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please repeat your new password."])},
        "The repeated password does not match.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The repeated password does not match."])},
        "Please make sure the new password and repeated new password match.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please make sure the new password and repeated new password match."])},
        "You have successfully reset your password.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have successfully reset your password."])}
      },
      "DE": {
        "Reset Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort zurücksetzen"])},
        "You are required to reset your password.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie müssen Ihr Passwort zurücksetzen."])},
        "New password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Passwort"])},
        "Repeat new password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Passwort wiederholen"])},
        "Enter current password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelles Passwort eingeben"])},
        "Enter new password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Passwort eingeben"])},
        "New Password field is empty.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Feld für das neue Passwort ist leer."])},
        "Please enter your new password.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie Ihr neues Passwort ein."])},
        "Repeated New Password field is empty.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Feld für das wiederholte neue Passwort ist leer."])},
        "Please repeat your new password.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wiederholen Sie Ihr neues Passwort."])},
        "The repeated password does not match.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das wiederholte Passwort stimmt nicht überein."])},
        "Please make sure the new password and repeated new password match.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte stellen Sie sicher, dass das neue Passwort und das wiederholte neue Passwort übereinstimmen."])},
        "You have successfully reset your password.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben Ihr Passwort erfolgreich zurückgesetzt."])}
      },
      "ES": {
        "Reset Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restablecer Contraseña"])},
        "You are required to reset your password.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe restablecer su contraseña."])},
        "New password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva contraseña"])},
        "Repeat new password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repetir nueva contraseña"])},
        "Enter current password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduzca la contraseña actual"])},
        "Enter new password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduzca la nueva contraseña"])},
        "New Password field is empty.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El campo de la nueva contraseña está vacío."])},
        "Please enter your new password.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, introduzca su nueva contraseña."])},
        "Repeated New Password field is empty.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El campo de la nueva contraseña repetida está vacío."])},
        "Please repeat your new password.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, repita su nueva contraseña."])},
        "The repeated password does not match.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La contraseña repetida no coincide."])},
        "Please make sure the new password and repeated new password match.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, asegúrese de que la nueva contraseña y la nueva contraseña repetida coinciden."])},
        "You have successfully reset your password.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha restablecido su contraseña con éxito."])}
      },
      "FR": {
        "Reset Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser le mot de passe"])},
        "You are required to reset your password.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez réinitialiser votre mot de passe."])},
        "New password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau mot de passe"])},
        "Repeat new password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Répéter le nouveau mot de passe"])},
        "Enter current password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez le mot de passe actuel"])},
        "Enter new password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez le nouveau mot de passe"])},
        "New Password field is empty.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le champ Nouveau mot de passe est vide."])},
        "Please enter your new password.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir votre nouveau mot de passe."])},
        "Repeated New Password field is empty.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le champ Nouveau mot de passe répété est vide."])},
        "Please repeat your new password.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez répéter votre nouveau mot de passe."])},
        "The repeated password does not match.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot de passe répété ne correspond pas."])},
        "Please make sure the new password and repeated new password match.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez vous assurer que le nouveau mot de passe et le nouveau mot de passe répété correspondent."])},
        "You have successfully reset your password.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez réinitialisé votre mot de passe avec succès."])}
      },
      "IT": {
        "Reset Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reimpostare la password"])},
        "You are required to reset your password.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È necessario reimpostare la password."])},
        "New password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuova password"])},
        "Repeat new password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ripeti la nuova password"])},
        "Enter current password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci la password corrente"])},
        "Enter new password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci la nuova password"])},
        "New Password field is empty.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il campo Nuova password è vuoto."])},
        "Please enter your new password.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci la tua nuova password."])},
        "Repeated New Password field is empty.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il campo Nuova password ripetuta è vuoto."])},
        "Please repeat your new password.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ripeti la tua nuova password."])},
        "The repeated password does not match.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La password ripetuta non corrisponde."])},
        "Please make sure the new password and repeated new password match.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assicurati che la nuova password e la nuova password ripetuta corrispondano."])},
        "You have successfully reset your password.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hai reimpostato la tua password con successo."])}
      },
      "PL": {
        "Reset Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zresetuj hasło"])},
        "You are required to reset your password.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Musisz zresetować swoje hasło."])},
        "New password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nowe hasło"])},
        "Repeat new password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powtórz nowe hasło"])},
        "Enter current password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadź aktualne hasło"])},
        "Enter new password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadź nowe hasło"])},
        "New Password field is empty.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pole Nowe hasło jest puste."])},
        "Please enter your new password.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadź swoje nowe hasło."])},
        "Repeated New Password field is empty.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pole Powtórz nowe hasło jest puste."])},
        "Please repeat your new password.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powtórz swoje nowe hasło."])},
        "The repeated password does not match.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powtórzone hasło nie pasuje."])},
        "Please make sure the new password and repeated new password match.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upewnij się, że nowe hasło i powtórzone nowe hasło są zgodne."])},
        "You have successfully reset your password.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pomyślnie zresetowano hasło."])}
      },
      "TR": {
        "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-posta"])},
        "Enter your e-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-postanızı girin"])},
        "Email field is empty.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-posta alanı boş."])},
        "Reset Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifreyi yenile"])},
        "You are required to reset your password.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifrenizi sıfırlamanız gerekiyor."])},
        "New password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni şifre"])},
        "Repeat new password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni şifreyi tekrarlayın"])},
        "Enter current password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geçerli şifreyi girin"])},
        "Enter new password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni şifreyi girin"])},
        "New Password field is empty.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Şifre alanı boş."])},
        "Please enter your new password.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen yeni şifrenizi girin."])},
        "Repeated New Password field is empty.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Şifre Tekrarı alanı boş."])},
        "Please repeat your new password.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen yeni şifrenizi tekrarlayın."])},
        "The repeated password does not match.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tekrarlanan şifre eşleşmiyor."])},
        "Please make sure the new password and repeated new password match.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni şifre ve tekrarlanan yeni şifrenin eşleştiğinden emin olun."])},
        "You have successfully reset your password.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifrenizi başarıyla sıfırladınız."])}
      },
      "ZH": {
        "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮件"])},
        "Enter your e-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入您的电子邮件"])},
        "Email field is empty.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮件字段为空。"])},
        "Reset Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重设密码"])},
        "You are required to reset your password.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您需要重设密码。"])},
        "New password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新密码"])},
        "Repeat new password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重复新密码"])},
        "Enter current password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入当前密码"])},
        "Enter new password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入新密码"])},
        "New Password field is empty.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新密码字段为空。"])},
        "Please enter your new password.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入您的新密码。"])},
        "Repeated New Password field is empty.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重复新密码字段为空。"])},
        "Please repeat your new password.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请重复您的新密码。"])},
        "The repeated password does not match.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重复的密码不匹配。"])},
        "Please make sure the new password and repeated new password match.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请确保新密码和重复的新密码匹配。"])},
        "You have successfully reset your password.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您已成功重设密码。"])}
      }
    }
  })
}
