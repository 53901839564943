export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "EN": {
        "Powder Amount Calibrations Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powder Amount Calibrations Library"])},
        "New Powder Amount Calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Powder Amount Calibration"])}
      },
      "DE": {
        "Powder Amount Calibrations Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pulverbetragkalibrierungen"])},
        "New Powder Amount Calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Pulverbetragkalibrierung"])}
      },
      "ES": {
        "Powder Amount Calibrations Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biblioteca de Calibraciones de Flujo de Pintura"])},
        "New Powder Amount Calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva Calibración de Flujo de Pintura"])}
      },
      "FR": {
        "Powder Amount Calibrations Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bibliothèque de Calibrations de Quantité de Poudre"])},
        "New Powder Amount Calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle Calibration de Quantité de Poudre"])}
      },
      "IT": {
        "Powder Amount Calibrations Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libreria di Calibrazioni della Quantità di Polvere"])},
        "New Powder Amount Calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuova Calibrazione della Quantità di Polvere"])}
      },
      "PL": {
        "Powder Amount Calibrations Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biblioteka Kalibracji Ilości Proszku"])},
        "New Powder Amount Calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nowa Kalibracja Ilości Proszku"])}
      },
      "TR": {
        "Powder Amount Calibrations Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toz Miktarı Kalibrasyonları Kütüphanesi"])},
        "New Powder Amount Calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Toz Miktarı Kalibrasyonu"])}
      },
      "ZH": {
        "Powder Amount Calibrations Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["粉量校准库"])},
        "New Powder Amount Calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新粉量校准"])}
      }
    }
  })
}
