<template>
  <div class="container-fluid">
    <div
      class="mt-4 page-header min-height-250 border-radius-xl"
      :style="{
        backgroundImage: 'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
        backgroundPositionY: '10%',
      }"
    >
      <span
        class="mask opacity-5"
        style="background-color: rgb(44, 222, 44)"
      ></span>
      <div style="display: flex; align-items: center; justify-content: center">
        <div style="position: absolute; left: 50%; transform: translateX(-50%); top: 30%">
          <h3 style="color: rgb(255, 255, 255); text-align: center">{{ $t("Visits for Line") }}</h3>
          <h5 style="color: rgb(255, 255, 255); text-align: center">{{ line.name }}</h5>
        </div>
      </div>
    </div>
    <div class="mx-4 mt-n6 overflow-hidden card card-body blur shadow-blur">
      <div class="row gx-4">
        <div class="row text-center">
          <div
            class="col p-3"
            style="min-width: 100px; height: 150px"
          >
            <button
              class="w-60px mt-2 mb-0 btn ms-lg-auto me-lg-0 me-auto mt-lg-0"
              type="button"
              style="width: 200px; height: 100px; background-color: rgb(231, 245, 221); font-size: 0.9rem"
              @click="createNewVisit"
            >
              {{ $t("New Workflow") }}
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div
              class="table-responsive"
              style="margin: 0px"
            >
              <table
                id="order-list"
                class="table table-flush"
              >
                <thead class="thead-light">
                  <tr>
                    <th class="text-center">Date</th>
                    <th class="text-center">Benchmark &amp; Target</th>
                    <th class="text-center">Health Check</th>
                    <th class="text-center">Guns Equalization</th>
                    <th class="text-center">Spray Pattern</th>
                    <th class="text-center">Reciprocator Optimization</th>
                    <th class="text-center">Powder Saving</th>
                    <th class="text-center"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="visit in visitsList"
                    :key="visit.date"
                  >
                    <visit-row
                      :date-time="visit ? formattedDate(visit.created_at) : formattedDate(new Date())"
                      :benchmark-checked="visit.benchmarkstage ? visit.benchmarkstage.progress_status : 'Pending'"
                      :health-checked="visit.healthcheckstage ? visit.healthcheckstage.progress_status : 'Pending'"
                      :guns-equalization-checked="
                        visit.gunsequalizationstage ? visit.gunsequalizationstage.progress_status : 'Pending'
                      "
                      :spray-pattern-checked="
                        visit.spraypatternstage ? visit.spraypatternstage.progress_status : 'Pending'
                      "
                      :reciprocator-checked="
                        visit.reciprocatorstage ? visit.reciprocatorstage.progress_status : 'Pending'
                      "
                      :powdersaving-checked="
                        visit.powdersavingstage ? visit.powdersavingstage.progress_status : 'Pending'
                      "
                      :line-id="lineId"
                      :visit-id="visit.id"
                      :powder-id="visit.benchmarkstage.powder"
                      @delete-visit="(visit_id, datetime) => deleteVisit(visit_id, datetime)"
                    ></visit-row>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mx-auto col-12 button-row text-center mt-3">
      <button
        type="button"
        data-bs-toggle="modal"
        data-bs-target="#modal-form"
        class="me-auto btn btn-dark w-60 ml-3 mb-0"
        style="max-width: 60%"
        @click="clearShareLineForm"
      >
        Share Line with FineTune user
      </button>
      <div
        id="modal-form"
        ref="modal"
        class="modal fade"
        tabindex="-1"
        role="dialog"
        aria-labelledby="modal-form"
        aria-hidden="true"
        style="z-index: 1900 !important"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div
            class="modal-content"
            style="text-align: left !important"
          >
            <div class="modal-body p-0">
              <div class="card card-plain">
                <div class="card-body justify-content-left">
                  <button
                    ref="dismissButton"
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label=""
                  ></button>
                  <form
                    role="form"
                    class=""
                  >
                    <!-- <div v-if="finetune_user_emails_with_shared_line.length > 0">
                      <h5 class="mb-0 mt-n3">Sharing Status</h5>
                      <p>
                        This line has already been shared with {{ finetune_user_emails_with_shared_line.length > 1 ? "users" : "users" }}:
                      </p>
                      <ul
                        v-for="email in finetune_user_emails_with_shared_line"
                        :key="email"
                      >
                        <li>
                          <strong>{{ email }}</strong>
                        </li>
                      </ul>
                    </div> -->
                    <div v-if="showShareLineSpinner" class="text-center">
                      <div class="spinner-border text-success" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                    <div v-else>
                      <h5 class="mb-0">Share Line</h5>
                      <p class="mt-0 mb-0"> Share Line with existing FineTune user</p>
                      <p class="mt-0 mb-0"> or create new FineTune user and share</p>
                      <label>User email</label>
                      <div class="input-group">
                        <input
                          v-model="finetune_user_email"
                          type="text"
                          :class="finetune_user_email_input_error ? `form-control is-invalid` : `form-control`"
                          placeholder="user@company.com"
                          @input="finetune_user_email_input_error = false; showInvalidEmailFormatErrorLabel = false; showEmptyEmailErrorLabel = false;"
                        />
                      </div>
                      <small v-if="showInvalidEmailFormatErrorLabel" class="text-danger mt-0 mb-2">
                        Please enter a valid email address.
                        <br/>
                      </small>
                      <small v-if="showEmptyEmailErrorLabel" class="text-danger mt-0 mb-2">
                        Please enter an email address.
                        <br/>
                      </small>
                      <label>User or company name</label>
                      <p class="text-xs form-text text-muted ms-1 d-inline">(Optional)</p>
                      <div class="input-group mb-3">
                        <input
                          v-model="finetune_user_name"
                          type="text"
                          :class="finetune_user_name_input_error ? `form-control is-invalid` : `form-control`"
                          placeholder="User or company name"
                          @input="finetune_user_name_input_error = false"
                        />
                      </div>
                      <div class="text-center">
                        <button
                          type="button"
                          class="btn bg-gradient-success btn-lg w-100 mt-4 mb-0"
                          @click="shareLineWithFineTuneUser()"
                        >
                          Share line "{{ line.name }}"
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import logo from "@/assets/img/logo-ct-white.png";
import setTooltip from "@/assets/js/tooltip.js";
import VisitRow from "../components/VisitRow.vue";
import { useGetLine } from "@/views/composables.js";

export default {
  name: "LineVisits",
  components: {
    VisitRow,
  },
  props: {
    lineId: {
      type: String,
      default: "",
    },
  },
  setup() {
    const { line, getLine } = useGetLine();
    return { line, getLine };
  },
  data() {
    this.$i18n.locale = this.$store.state.user_data.language;
    return {
      logo,
      line_name: "",
      finetune_user_email: null,
      finetune_user_name: null,
      finetune_user_email_input_error: false,
      finetune_user_name_input_error: false,
      visitsList: [],
      showShareLineSpinner: false,
      showEmptyEmailErrorLabel: false,
      showInvalidEmailFormatErrorLabel: false,
      // finetune_user_emails_with_shared_line: ["email@email.com", "email@email.com"],
    };
  },
  computed: {},
  watch: {
    "$store.state.user_data.language"() {
      this.$i18n.locale = this.$store.state.user_data.language;
    },
  },
  mounted() {
    this.$store.state.isAbsolute = true;
    setTooltip(this.$store.state.bootstrap);
    this.getData();
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
  },
  methods: {
    async getData() {
      await Promise.all([this.getLine(this.lineId), this.getLineVisits()]);
    },
    async getLineVisits() {
      if (this.lineId) {
        try {
          let response = await axios.get("/api/v1/blueprint/visits/" + this.lineId);
          this.visitsList = response.data;
          this.visitsList.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        } catch (error) {
          console.error(error);
        }
      }
    },
    createNewVisit() {
      if (this.lineId) {
        try {
          axios
            .post("/api/v1/blueprint/visits/", {
              line: this.lineId,
            })
            .then(() => {
              this.getLineVisits();
            })
            .catch(error => {
              console.error(error);
            });
        } catch (error) {
          console.error(error);
        }
      }
    },
    deleteVisit(visitId, dateTime) {
      this.$swal({
        title: `Are you sure you want to delete Visit from ${dateTime}?`,
        text: "You will not be able to revert this!",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it",
        cancelButtonText: "No, cancel",
        reverseButtons: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          this.$store.state.isLoading = true;
          axios
            .delete("/api/v1/blueprint/visit/" + visitId)
            .then(() => {
              this.$store.state.isLoading = false;
              this.$swal({
                title: "Deleted",
                text: `Visit from ${this.dateTime} has been deleted.`,
                icon: "success",
                customClass: {
                  confirmButton: "btn bg-gradient-success",
                },
                buttonsStyling: false,
              }).then(() => {
                this.getLineVisits();
              });
            })
            .catch(error => {
              console.error(error);
            });
        }
      });
    },
    async shareLineWithFineTuneUser() {
      if (!this.validShareLineForm()) {
        return;
      }

      try {
        this.showShareLineSpinner = true;
        const response = await axios.post("/api/v1/blueprint/sharelinetofinetuneapp/", {
          line_id: this.lineId,
          email: this.finetune_user_email,
          name: this.finetune_user_name,
        });

        this.showShareLineSpinner = false;
        this.closeModal();
        this.clearShareLineForm();

        await this.getData(this.lineId);

        this.$swal({
          title: "Line shared successfully",
          text: response.data.message,
          icon: "success",
          customClass: {
            confirmButton: "btn bg-gradient-success",
          },
          buttonsStyling: false,
        })
      } catch (error) {
        console.error(error);
        this.showShareLineSpinner = false;
        this.closeModal();
        this.clearShareLineForm();

        this.$swal({
          title: "Line sharing error",
          text: error.response.data.error,
          icon: "error",
          customClass: {
            confirmButton: "btn bg-gradient-success",
          },
          buttonsStyling: false,
        });
      }
    },
    clearShareLineForm() {
      this.finetune_user_email = null;
      this.finetune_user_name = null;
      this.showShareLineSpinner = false;
      this.showEmptyEmailErrorLabel = false;
      this.showInvalidEmailFormatErrorLabel = false;
    },
    validShareLineForm() {
      const regex = /^\S+@\S+\.\S+$/;
      if (this.finetune_user_email == null || this.finetune_user_email == "") {
        this.showEmptyEmailErrorLabel = true;
        this.finetune_user_email_input_error = true;
        return false;
      }
      else if (!regex.test(this.finetune_user_email)) {
        this.showInvalidEmailFormatErrorLabel = true;
        this.finetune_user_email_input_error = true;
        return false;
      }

      return true;
    },
    closeModal() {
      const dismissButton = this.$refs.dismissButton;
      if (dismissButton) {
        dismissButton.click();
      }
    },
    formattedDate(date_str) {
      return new Date(date_str).toLocaleString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    },
  },
};
</script>

<i18n>
  {
    "EN": {
      "Visits for Line": "Visits for Line",
      "New Workflow": "New Workflow",
    },
    "DE": {
      "Visits for Line": "Visits for Line",
      "New Workflow": "New Workflowlowlow",
    },
    "ES": {
      "Visits for Line": "Visits for Line",
      "New Workflowlow": "New Workflowlowlowlow",
    },
    "FR": {
      "Visits for Line": "Visits for Line",
      "New Workflow": "New Workflow",
    },
    "IT": {
      "Visits for Line": "Visits for Line",
      "New Workflow": "New Workflowlowlow",
    },
    "PL": {
      "Visits for Line": "Visits for Line",
      "New Workflowlowlowlowlowlowlowlowlow": "New Workflowlow",
    },
    "TR": {
      "Visits for Line": "Visits for Line",
      "New Workflow": "New Workflow",
    },
    "ZH": {
      "Visits for Line": "Visits for Line",
      "New Workflow": "New Workflow",
    }
  }
</i18n>
