export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "EN": {
        "Lines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lines"])},
        "List of created Lines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of created Lines"])},
        "No Lines Created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Lines Created"])},
        "New Line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Line"])},
        "Sort by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort by"])},
        "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "Line Creation Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Line Creation Date"])},
        "Line Updated Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Line Updated Date"])},
        "Line Last Used Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Line Last Used Date"])},
        "Search line here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search line here"])},
        "Are you sure you want to delete Line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete Line"])},
        "You will not be able to revert this!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will not be able to revert this!"])},
        "Yes, delete it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, delete it"])},
        "No, cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No, cancel"])},
        "Deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleted"])},
        "Your line has been deleted.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your line has been deleted."])}
      },
      "DE": {
        "Lines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anlagen"])},
        "List of created Lines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste der erstellten Linien"])},
        "No Lines Created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Linien erstellt"])},
        "New Line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Linie"])},
        "Sort by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sortieren nach"])},
        "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "Line Creation Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linien Erstellungsdatum"])},
        "Line Updated Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linien Aktualisierungsdatum"])},
        "Line Last Used Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeile Zuletzt verwendetes Datum"])},
        "Search line here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche Linie"])},
        "Are you sure you want to delete Line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sind Sie sicher, dass Sie die Linie löschen möchten"])},
        "You will not be able to revert this!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können dies nicht rückgängig machen!"])},
        "Yes, delete it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja, lösche es"])},
        "No, cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nein, abbrechen"])},
        "Deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelöscht"])},
        "Your line has been deleted.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Linie wurde gelöscht."])}
      },
      "ES": {
        "Lines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Líneas"])},
        "List of created Lines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de líneas creadas"])},
        "No Lines Created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No existe ninguna línea"])},
        "New Line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva línea"])},
        "Sort by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordenar por"])},
        "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
        "Line Creation Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de creación"])},
        "Line Updated Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de actualización"])},
        "Line Last Used Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de último uso"])},
        "Search line here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar línea"])},
        "Are you sure you want to delete Line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Estás seguro de que quieres eliminar la línea"])},
        "You will not be able to revert this!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡No podrás deshacer esto!"])},
        "Yes, delete it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sí, bórrala"])},
        "No, cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No, cancelar"])},
        "Deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminada"])},
        "Your line has been deleted.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu línea ha sido eliminada."])}
      },
      "FR": {
        "Lines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lignes"])},
        "List of created Lines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des lignes créées"])},
        "No Lines Created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune ligne créée"])},
        "New Line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle ligne"])},
        "Sort by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trier par"])},
        "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
        "Line Creation Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de création"])},
        "Line Updated Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de mise à jour"])},
        "Line Last Used Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de la dernière utilisation"])},
        "Search line here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher une ligne"])},
        "Are you sure you want to delete Line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous vraiment supprimer la ligne"])},
        "You will not be able to revert this!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous ne pourrez pas revenir en arrière!"])},
        "Yes, delete it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui, supprimez-le"])},
        "No, cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non, annuler"])},
        "Deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimé"])},
        "Your line has been deleted.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre ligne a été supprimée."])}
      },
      "IT": {
        "Lines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linee"])},
        "List of created Lines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elenco delle linee create"])},
        "No Lines Created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna linea creata Per creare una linea, contattaci a"])},
        "New Line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuova linea"])},
        "Sort by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordina per"])},
        "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
        "Line Creation Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di creazione"])},
        "Line Updated Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di aggiornamento"])},
        "Line Last Used Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linea Data ultimo utilizzo"])},
        "Search line here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca linea"])},
        "Are you sure you want to delete Line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sei sicuro di voler eliminare la linea"])},
        "You will not be able to revert this!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non sarai in grado di annullare questa operazione!"])},
        "Yes, delete it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sì, cancellalo"])},
        "No, cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No, annulla"])},
        "Deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminato"])},
        "Your line has been deleted.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La tua linea è stata eliminata."])}
      },
      "PL": {
        "Lines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linie"])},
        "List of created Lines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista utworzonych linii"])},
        "No Lines Created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie utworzono żadnych linii Aby utworzyć linię, skontaktuj się z nami pod adresem"])},
        "New Line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nowa linia"])},
        "Sort by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sortuj według"])},
        "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa"])},
        "Line Creation Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data utworzenia"])},
        "Line Updated Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data aktualizacji"])},
        "Line Last Used Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linia Data ostatniego użycia"])},
        "Search line here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szukaj linii"])},
        "Are you sure you want to delete Line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czy na pewno chcesz usunąć linię"])},
        "You will not be able to revert this!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie będziesz mógł tego cofnąć!"])},
        "Yes, delete it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tak, usuń to"])},
        "No, cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie, anuluj"])},
        "Deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usunięty"])},
        "Your line has been deleted.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twoja linia została usunięta."])}
      },
      "TR": {
        "Lines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hatlar"])},
        "List of created Lines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oluşturulan Hatların Listesi"])},
        "No Lines Created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hat oluşturulmadı Bir hat oluşturmak için bizimle iletişime geçin"])},
        "New Line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Hat"])},
        "Sort by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Göre sırala"])},
        "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İsim"])},
        "Line Creation Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hat Oluşturma Tarihi"])},
        "Line Updated Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hat Güncelleme Tarihi"])},
        "Line Last Used Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hat Son Kullanım Tarihi"])},
        "Search line here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hat ara"])},
        "Are you sure you want to delete Line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hatı silmek istediğinizden emin misiniz"])},
        "You will not be able to revert this!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bunu geri alamayacaksın!"])},
        "Yes, delete it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evet, sil"])},
        "No, cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hayır, iptal et"])},
        "Deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silindi"])},
        "Your line has been deleted.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hatınız silindi."])}
      },
      "ZH": {
        "Lines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["线"])},
        "List of created Lines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建的线列表"])},
        "No Lines Created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未创建线。要创建线，请联系我们"])},
        "New Line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新线"])},
        "Sort by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["排序方式"])},
        "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名称"])},
        "Line Creation Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建日期"])},
        "Line Updated Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新日期"])},
        "Line Last Used Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["行 最后使用日期"])},
        "Search line here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜索线"])},
        "Are you sure you want to delete Line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您确定要删除该行吗"])},
        "You will not be able to revert this!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您将无法撤消此操作！"])},
        "Yes, delete it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是的，删除它"])},
        "No, cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不，取消"])},
        "Deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已删除"])},
        "Your line has been deleted.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的线已被删除。"])}
      }
    }
  })
}
