/* eslint-disable */

// initialization of Tooltips
export default function setTooltip(bootstrap) {
  var tooltipTriggerList = [].slice.call(
    document.querySelectorAll('[data-bs-toggle="tooltip"]')
  );
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    // Enable HTML content inside the tooltip
    return new bootstrap.Tooltip(tooltipTriggerEl, {
      html: true
    });
  });
}
