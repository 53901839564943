<template>
  <section class="my-10">
    <div class="container">
      <div class="row">
        <div class="my-auto flex">
          <h1 class="display-1 text-bolder text-gradient text-danger">404</h1>

          <p class="lead mt-4">
            {{ $t("Page not found") }}
          </p>
        </div>
        <button
          type="button"
          class="mt-6 mb-0 btn bg-gradient-secondary w-100 mt-4 p-3"
          style="width: 300px; height: 60px"
          @click="$router.push('/')"
        >
          {{ $t("Go Back") }} {{ $t("Home") }}
        </button>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "NotFound",

  watch: {
    "$store.state.user_data.language"() {
      this.$i18n.locale = this.$store.state.user_data.language;
    },
  },
};
</script>

<i18n>
  {
    "EN": {
      "Go Back": "Go Back",
      "Page not found":"Ooops.. We couldn't find the page you were looking for.",
      "Home":"Home",
    },
    "DE": {
      "Go Back": "Zurück gehen",
      "Page not found":"Seite nicht gefunden",
      "Home":"Startseite",
    },
    "ES": {
      "Go Back": "Volver atrás",
      "Page not found":"Página no encontrada",
      "Home":"Inicio",
    },
    "FR": {
      "Go Back": "Retourner à la page précédente",
      "Page not found":"Page non trouvée",
      "Home":"Accueil",
    },
    "IT": {
      "Go Back": "Torna Torna indietro",
      "Page not found":"Pagina non trovata",
      "Home":"La casa",
    },
    "PL": {
      "Go Back": "Wróć",
      "Page not found":"Nie znaleziono strony",
      "Home":"Strona główna",
    },
    "TR": {
        "Go Back": "Geri Dön",
        "Page not found":"Sayfa bulunamadı",
        "Home":"Ev",
    },
    "ZH": {
        "Page not found":"返回",
        "Go Back": "页面未找到",
        "Home":"首页",
    },
  }
</i18n>
