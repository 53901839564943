export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "EN": {
        "Optimization List for line ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimization List for line "])},
        "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "Line Speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Line Speed"])},
        "Distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance"])},
        "Coated Height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coated Height"])},
        "Updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated"])},
        "Created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created"])},
        "Remove Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove Optimization"])},
        "Are you sure you want to delete Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete Optimization"])},
        "You will not be able to revert this!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will not be able to revert this!"])},
        "Yes, delete it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, delete it"])},
        "No, cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No, cancel"])},
        "Deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleted"])},
        "Your optimization has been deleted.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your optimization has been deleted."])},
        "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
        "No Optimizations created.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Optimizations created."])}
      },
      "DE": {
        "Optimization List for line ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimierungsliste für die linie "])},
        "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "Line Speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kettengeschwindigkeit"])},
        "Distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entfernung"])},
        "Coated Height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschichtete Höhe"])},
        "Updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualisiert"])},
        "Created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellt"])},
        "Remove Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimierung Entfernen"])},
        "Are you sure you want to delete Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sind Sie sicher, dass Sie die Optimierung löschen möchten"])},
        "You will not be able to revert this!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können dies nicht rückgängig machen!"])},
        "Yes, delete it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja, lösche es"])},
        "No, cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nein, abbrechen"])},
        "Deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelöscht"])},
        "Your optimization has been deleted.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Optimierung wurde gelöscht."])},
        "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
        "No Optimizations created.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Optimierungen erstellt."])}
      },
      "ES": {
        "Optimization List for line ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de Optimizaciónes de la línea "])},
        "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
        "Line Speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velocidad de la Línea"])},
        "Distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distancia"])},
        "Coated Height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altura Recubrimiento"])},
        "Updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizada"])},
        "Created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creada"])},
        "Remove Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar Optimización"])},
        "Are you sure you want to delete Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Estás seguro de que quieres eliminar la optimización"])},
        "You will not be able to revert this!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡No podrás deshacer esto!"])},
        "Yes, delete it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sí, bórrala"])},
        "No, cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No, cancelar"])},
        "Deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminada"])},
        "Your optimization has been deleted.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su optimización ha sido eliminada."])},
        "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar"])},
        "No Optimizations created.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No existe ninguna optimización"])}
      },
      "FR": {
        "Optimization List for line ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des Optimisations pour la ligne "])},
        "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
        "Line Speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vitesse de la Ligne"])},
        "Distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance"])},
        "Coated Height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hauteur Revêtu"])},
        "Updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mise à jour"])},
        "Created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créée"])},
        "Remove Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer l'Optimisation"])},
        "Are you sure you want to delete Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir supprimer l'optimisation"])},
        "You will not be able to revert this!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous ne pourrez pas revenir en arrière!"])},
        "Yes, delete it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui, supprimez-le"])},
        "No, cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non, annuler"])},
        "Deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimé"])},
        "Your optimization has been deleted.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre optimisation a été supprimée."])},
        "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effacer"])},
        "No Optimizations created.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune optimisation créée."])}
      },
      "IT": {
        "Optimization List for line ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista di Ottimizzazioni per la linea "])},
        "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
        "Line Speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velocitá della Linea"])},
        "Distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distanza"])},
        "Coated Height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altezza Rivestita"])},
        "Updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiornata"])},
        "Created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creata"])},
        "Remove Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rimuovere l'Ottimizzazione"])},
        "Are you sure you want to delete Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sei sicuro di voler eliminare l'ottimizzazione"])},
        "You will not be able to revert this!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non sarai in grado di annullare questa operazione!"])},
        "Yes, delete it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sì, cancellalo"])},
        "No, cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No, annulla"])},
        "Deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminato"])},
        "Your optimization has been deleted.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La tua ottimizzazione è stata eliminata."])},
        "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina"])},
        "No Optimizations created.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna ottimizzazione creata."])}
      },
      "PL": {
        "Optimization List for line ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista optymalizacji dla linii "])},
        "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa"])},
        "Line Speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prędkość Linii"])},
        "Distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odległość"])},
        "Coated Height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wysokość Powłoki"])},
        "Updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualizacja"])},
        "Created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utworzony"])},
        "Remove Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń optymalizację"])},
        "Are you sure you want to delete Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czy na pewno chcesz usunąć optymalizację"])},
        "You will not be able to revert this!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie będziesz mógł tego cofnąć!"])},
        "Yes, delete it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tak, usuń to"])},
        "No, cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie, anuluj"])},
        "Deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usunięty"])},
        "Your optimization has been deleted.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twoja optymalizacja została usunięta."])},
        "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kasować"])},
        "No Optimizations created.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie utworzono optymalizacji."])}
      },
      "TR": {
        "Optimization List for line ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hattı için Optimizasyonlar listesi "])},
        "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İsim"])},
        "Line Speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hat Hızı"])},
        "Distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesafe"])},
        "Coated Height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaplamalı Yükseklik"])},
        "Updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Güncellenmiş"])},
        "Created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oluşturuldu"])},
        "Remove Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimizasyonu Kaldır"])},
        "Are you sure you want to delete Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimizasyonu silmek istediğinizden emin misiniz?"])},
        "You will not be able to revert this!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bunu geri alamayacaksın!"])},
        "Yes, delete it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evet, sil"])},
        "No, cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hayır, iptal et"])},
        "Deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silindi"])},
        "Your optimization has been deleted.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimizasyonunuz silindi."])},
        "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sil"])},
        "No Optimizations created.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oluşturulan Optimizasyon Yok."])}
      },
      "ZH": {
        "Optimization List for line ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["线路优化列表 "])},
        "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名称"])},
        "Line Speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["线路速度"])},
        "Distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["距离"])},
        "Coated Height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["涂布高度"])},
        "Updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已更新"])},
        "Created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建"])},
        "Remove Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除优化"])},
        "Are you sure you want to delete Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您确定要删除优化吗"])},
        "You will not be able to revert this!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您将无法撤消此操作！"])},
        "Yes, delete it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是的，删除它"])},
        "No, cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不，取消"])},
        "Deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已删除"])},
        "Your optimization has been deleted.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的优化已被删除。"])},
        "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除"])},
        "No Optimizations created.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未创建优化。"])}
      }
    }
  })
}
