<template>
  <nav
    id="navbarBlur"
    class="shadow-none navbar navbar-main navbar-expand-lg border-radius-xl"
    v-bind="$attrs"
    data-scroll="true"
  >
    <div class="py-0 container-fluid">
      <breadcrumbs v-if="!$store.state.isPublicInfo" :text-white="navbar_color"/>
      <div v-if="!$store.state.isPublicInfo">
        <div
          id="navbar"
          :class="`collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4 text-${navbar_color}`"
        >
          <ul
            class="px-0 pt-1 pb-0 mb-0 bg-transparent justify-content-end text-right ms-md-auto"
            style="justify-content: flex-end !important"
          >
            <li
              v-if="$store.state.isAuthenticated == false"
              class="nav-item d-flex align-items-center"
              style="justify-content: flex-end !important"
            >
              <router-link
                :to="{ name: 'SignIn' }"
                class="px-0 nav-link font-weight-bold"
              >
                <span :class="`d-sm-inline d-none text-${navbar_color}`">{{ $t("Sign In") }} </span>
              </router-link>
            </li>
            <li
              v-if="$store.state.isAuthenticated"
              class="nav-item d-flex align-items-center"
            >
              <a>{{ $t("Language") }}:&nbsp;</a>
              <select
                id="language-choices-category"
                v-model="$store.state.user_data.language"
                name="language-choices"
                :class="`bg-transparent border-0 text-${navbar_color}`"
                @change="changeLanguage()"
              >
                <option
                  v-for="(language_option, key) in languages"
                  :key="key"
                  :value="key"
                  class="text-dark"
                >
                  <span class="d-sm-inline d-none">
                    {{ language_option.emoji }} {{ key }} - {{ language_option.name }}
                  </span>
                </option>
              </select>
              <a
                style="
                  -webkit-touch-callout: none;
                  -webkit-user-select: none;
                  -khtml-user-select: none;
                  -moz-user-select: none;
                  -ms-user-select: none;
                  user-select: none;
                "
              >
                &nbsp; | &nbsp;
              </a>
              <a>{{ $t("Unit System") }}:&nbsp;</a>
              <select
                id="choices-category"
                v-model="$store.state.user_data.unit_system"
                name="unit-system-choices"
                :class="`bg-transparent border-0 text-${navbar_color}`"
                @change="changeUnitSystem()"
              >
                <option
                  v-for="unit_system_option in unit_system"
                  :key="unit_system_option"
                  :value="unit_system_option"
                  class="text-dark"
                >
                  <span class="d-sm-inline d-none">
                    {{ capitalizeFirstLetter($t(unit_system_option)) }}
                  </span>
                </option>
              </select>
              <a
                style="
                  -webkit-touch-callout: none;
                  -webkit-user-select: none;
                  -khtml-user-select: none;
                  -moz-user-select: none;
                  -ms-user-select: none;
                  user-select: none;
                "
              >
                &nbsp; | &nbsp;
              </a>
              <i class="fa fa-user me-sm-1"> </i>
              <a
                class="pt-2 nav-link font-weight-bold d-sm-inline d-none"
                style="cursor: pointer"
              >
                <span :class="`d-sm-inline d-none text-${navbar_color}`">
                  {{ $store.state.user_data.first_name + " " + $store.state.user_data.last_name }}
                </span>
              </a>
              <a
                style="
                  -webkit-touch-callout: none;
                  -webkit-user-select: none;
                  -khtml-user-select: none;
                  -moz-user-select: none;
                  -ms-user-select: none;
                  user-select: none;
                "
              >
                &nbsp; | &nbsp;
              </a>
              <i
                class="fa fa-sign-out me-sm-1"
                style="cursor: pointer"
                @click="logout"
              >
              </i>
              <a
                onmouseover="this.style.textDecoration = 'underline'"
                onmouseout="this.style.textDecoration = 'none'"
                class="px-0 nav-link font-weight-bold d-sm-inline d-none text-body"
                style="cursor: pointer"
                @click="logout"
              >
                <span :class="`d-sm-inline d-none text-${navbar_color}`">
                  {{ $t("Log Out") }}
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div v-else>
        <div
          id="navbar"
          :class="`collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4 text-${navbar_color}`"
        >
          <ul
            class="px-0 pt-1 pb-0 mb-0 bg-transparent justify-content-end text-right ms-md-auto"
            style="justify-content: flex-end !important"
          >
            <li class="nav-item d-flex align-items-end">
              <a class="text-bold">{{ $t("Language") }}:&nbsp;</a>
              <select
                id="language-choices-category"
                v-model="$store.state.user_data.language"
                name="language-choices"
                class="bg-transparent border-0 text-black"
                @change="changeLanguageWithoutLogin()"
              >
                <option
                  v-for="(language_option, key) in languages"
                  :key="key"
                  :value="key"
                  class="text-dark"
                >
                  <span class="d-sm-inline d-none">
                    {{ language_option.emoji }} {{ key }} - {{ language_option.name }}
                  </span>
                </option>
              </select>
              <a
                style="
                  -webkit-touch-callout: none;
                  -webkit-user-select: none;
                  -khtml-user-select: none;
                  -moz-user-select: none;
                  -ms-user-select: none;
                  user-select: none;
                "
              >
                &nbsp; | &nbsp;
              </a>
              <a>{{ $t("Unit System") }}:&nbsp;</a>
              <select
                id="choices-category"
                v-model="$store.state.user_data.unit_system"
                name="unit-system-choices"
                :class="`bg-transparent border-0 text-${navbar_color}`"
                @change="changeUnitSystem()"
              >
                <option
                  v-for="unit_system_option in unit_system"
                  :key="unit_system_option"
                  :value="unit_system_option"
                  class="text-dark"
                >
                  <span class="d-sm-inline d-none">
                    {{ capitalizeFirstLetter($t(unit_system_option)) }}
                  </span>
                </option>
              </select>
              <a
                style="
                  -webkit-touch-callout: none;
                  -webkit-user-select: none;
                  -khtml-user-select: none;
                  -moz-user-select: none;
                  -ms-user-select: none;
                  user-select: none;
                "
              >
                &nbsp; | &nbsp;
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</template>
<script>
import { mapMutations, mapActions } from "vuex";
import Breadcrumbs from "../components/Breadcrumbs.vue";
import router from "@/router";
import axios from "axios";

export default {
  name: "Navbar",
  components: {
    Breadcrumbs,
  },
  props: {
    textWhite: {
      type: String,
      default: "white",
    },
  },
  data() {
    this.$i18n.locale = this.$store.state.user_data.language;
    return {
      navbar_color: "dark",
      showMenu: false,
      unit_system: ["metric", "imperial"],
      languages: {
        EN: { name: "English", emoji: "🇬🇧" },
        DE: { name: "Deutsch", emoji: "🇩🇪" },
        ES: { name: "Español", emoji: "🇪🇸" },
        FR: { name: "Français", emoji: "🇫🇷" },
        IT: { name: "Italiano", emoji: "🇮🇹" },
        PL: { name: "Polski", emoji: "🇵🇱" },
        TR: { name: "Türkçe", emoji: "🇹🇷" },
        ZH: { name: "中文", emoji: "🇨🇳" },
        // 'BG' : {'name': 'Bulgarian', 'emoji': '🇧🇬'},
        // 'CS' : {'name': 'Czech', 'emoji': '🇨🇿'},
        // 'DA' : {'name': 'Danish', 'emoji': '🇩🇰'},
        // 'EL' : {'name': 'Greek', 'emoji': '🇬🇷'},
        // 'ET' : {'name': 'Estonian', 'emoji': '🇪🇪'},
        // 'FI' : {'name': 'Finnish', 'emoji': '🇫🇮'},
        // 'HU' : {'name': 'Hungarian', 'emoji': '🇭🇺'},
        // 'ID' : {'name': 'Indonesian', 'emoji': '🇮🇩'},
        // 'JA' : {'name': 'Japanese', 'emoji': '🇯🇵'},
        // 'KO' : {'name': 'Korean', 'emoji': '🇰🇷'},
        // 'LT' : {'name': 'Lithuanian', 'emoji': '🇱🇹'},
        // 'LV' : {'name': 'Latvian', 'emoji': '🇱🇻'},
        // 'NB' : {'name': 'Norwegian', 'emoji': '🇳🇴'},
        // 'NL' : {'name': 'Dutch', 'emoji': '🇳🇱'},
        // 'PT' : {'name': 'Portuguese', 'emoji': '🇵🇹'},
        // 'RO' : {'name': 'Romanian', 'emoji': '🇷🇴'},
        // 'RU' : {'name': 'Russian', 'emoji': '🇷🇺'},
        // 'SK' : {'name': 'Slovak', 'emoji': '🇸🇰'},
        // 'SL' : {'name': 'Slovenian', 'emoji': '🇸🇮'},
        // 'SV' : {'name': 'Swedish', 'emoji': '🇸🇪'},
        // 'UK' : {'name': 'Ukrainian', 'emoji': '🇺🇦'},
      },
    };
  },
  watch: {
    "$route.name"() {
      if (this.$route.name === undefined) {
        this.navbar_color = 'white'
      } else if (
        this.$route.name === 'PowderModelsLibrary'
        || this.$route.name === 'FlightPath'
        || this.$route.name === 'BluePrint'
        || this.$route.name === 'OptimizationsLibrary'
        || this.$route.name === 'PowderAmountCalibrationsLibrary'
        || this.$route.name === 'LineVisits'
        || this.$route.name === 'FineTune'
      ) {
        this.navbar_color = 'white'
      } else if (
        this.$route.name === "NotFound"
      ) {
        this.navbar_color = 'dark'
      } else {
        this.navbar_color = 'dark'
      }
    },
    "$store.state.user_data"() {
      this.$i18n.locale = this.$store.state.user_data.language;
    },
  },
  created() {
  },
  updated() {},
  methods: {
    ...mapMutations(["navbarMinimize", "toggleConfigurator", "removeToken"]),
    ...mapActions(["toggleSidebarColor"]),
    toggleSidebar() {
      this.toggleSidebarColor("bg-white");
      this.navbarMinimize();
    },
    logout() {
      this.$store.commit("removeToken");
      router.push({ name: "SignIn" });
    },
    changeUnitSystem() {
      axios
        .put("/api/v1/unitssystemusersetting/", {
          user: this.$store.state.user_data.id,
          unit_system: this.$store.state.user_data.unit_system,
        })
        .catch(error => {
          console.log(error);
        });
    },
    changeLanguage() {
      axios
        .put("/api/v1/languageusersetting/", {
          user: this.$store.state.user_data.id,
          language: this.$store.state.user_data.language,
        })
        .catch(error => {
          console.log(error);
        });
      this.$i18n.locale = this.$store.state.user_data.language;
    },
    changeLanguageWithoutLogin() {
      this.$i18n.locale = this.$store.state.user_data.language;
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  },
};
</script>

<i18n>
  {
    "EN": {
      "Sign In": "Sign In",
      "Language": "Language",
      "Unit System": "Unit System",
      "metric": "Metric",
      "imperial": "Imperial",
      "Log Out": "Log Out",
    },
    "DE": {
      "Sign In": "Anmelden",
      "Language": "Sprache",
      "Unit System": "Einheiten",
      "metric": "Metrisch",
      "imperial": "Imperial",
      "Log Out": "Abmelden",
    },
    "ES": {
      "Sign In": "Iniciar Sesión",
      "Language": "Idioma",
      "Unit System": "Sistema de Unidades",
      "metric": "Métrico",
      "imperial": "Imperial",
      "Log Out": "Cerrar Sesión",
    },
    "FR": {
      "Sign In": "Se connecter",
      "Language": "Langue",
      "Unit System": "Système d'Unités",
      "metric": "Métrique",
      "imperial": "Impérial",
      "Log Out": "Déconnexion",
    },
    "IT": {
      "Sign In": "Accedi",
      "Language": "Lingua",
      "Unit System": "Sistemi di Misura",
      "metric": "Metrico",
      "imperial": "Imperiale",
      "Log Out": "Esci",
    },
    "PL": {
      "Sign In": "Zaloguj",
      "Language": "Język",
      "Unit System": "Układ Jednostek",
      "metric": "Metryczny",
      "imperial": "Imperialny",
      "Log Out": "Wyloguj",
    },
    "TR": {
      "Sign In": "Oturum Aç",
      "Language": "Dil",
      "Unit System": "Ölçü Sistemi",
      "metric": "Metrik",
      "imperial": "İmparatorluk",
      "Log Out": "Oturumu Kapat",
    },
    "ZH": {
      "Sign In": "登录",
      "Language": "语言",
      "Unit System": "计量单位制",
      "metric": "公制",
      "imperial": "英制",
      "Log Out": "退出登录",
    }
  }
</i18n>
